import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  ArchiveRounded,
  CreateRounded,
  DeleteRounded,
  DescriptionRounded,
  HistoryRounded,
  VisibilityRounded,
} from "@material-ui/icons";
import Axios from "axios";
import docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import JSzip from "jszip";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import creditNoteTemplate from "../../../assets/templates/credit_note_template.docx";
import debitNoteTemplate from "../../../assets/templates/debit_note_template.docx";
import CustomModal from "../../../components/CustomModal";
import DebitCreditDetail from "../../../components/DebitCreditDetail";
import DebitCreditTable from "../../../components/DebitCreditTable";
import SlideUp from "../../../components/SlideUp";
import TabPanel from "../../../components/TabPanel";
import {
  DataContext,
  PopupContext,
  SettingContext,
} from "../../../DataContext";
import { getDefaultCaseObj } from "../../../model";
import { Modules, RoleTypes } from "../../../utils/constants";
import { getError } from "../../../utils/functions";

const AccountTabPanel = ({
  data = getDefaultCaseObj(),
  openEditModal,
  ...others
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    debitCreditList,
    setDebitCreditList,
    currentUser,
    alertSuccess,
    alertError,
  } = useContext(DataContext);
  const { setAuditOptions, openDialog } = useContext(PopupContext);
  const { setProcessing } = useContext(SettingContext);

  const [debitCreditListByCase, setDebitCreditListByCase] = useState([]);
  const [isDebitCreditDetailOpen, setIsDebitCreditDetailOpen] = useState(false);
  const [selectedDebitCredit, setSelectedDebitCredit] = useState(null);

  const fetchDebitCreditListByCase = () => {
    Axios.get("/debit-credit/" + data.caseSid).then((res) => {
      setDebitCreditListByCase(res.data);
    });
  };

  useEffect(() => {
    fetchDebitCreditListByCase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debitCreditList, data]);

  const openDebitCreditDetailModal = async (debitCredit) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSelectedDebitCredit(debitCredit);
      setIsDebitCreditDetailOpen(true);
    });
  };

  const openAuditModal = (debitCreditSid) =>
    setAuditOptions({
      open: true,
      moduleType: Modules.DEBIT_CREDIT,
      sid: debitCreditSid,
    });

  const goDelete = (debitCreditSid) => {
    if (debitCreditSid) {
      Axios.delete("/debit-credit/" + debitCreditSid)
        .then((res) => {
          fetchDebitCreditListByCase();
          alertSuccess("Successfully deleted!");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const goToggleArchive = (debitCredit) => {
    const term = debitCredit.active ? "archive" : "unarchive";
    setProcessing(true);
    Axios.patch(`/debit-credit/${term}/${debitCredit.id}`)
      .then((res) => {
        setProcessing(false);
        setDebitCreditList((prev) => {
          const oldInd = prev.findIndex((c) => c.id === debitCredit.id);
          const newDebitCredit = prev[oldInd];
          newDebitCredit.active = !newDebitCredit.active;
          prev.splice(oldInd, 1, newDebitCredit);
          return prev;
        });
        alertSuccess(`Successfully ${term}d!`);
        fetchDebitCreditListByCase();
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, `Error! Unable to ${term}.`));
      });
  };

  const generateDebitNote = (debitCredit) => {
    let debitCreditNoteTemplate =
      debitCredit.type === "debit" ? debitNoteTemplate : creditNoteTemplate;
    fetch(debitCreditNoteTemplate)
      .then((d) => d.arrayBuffer())
      .then((ab) => {
        let zip = new JSzip(ab);
        let doc = new docxtemplater(zip);

        doc.setData(debitCredit);
        doc.render();

        let out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        debitCredit.type === "debit"
          ? saveAs(out, `${"DB-" + debitCredit.debitCreditNumber}.docx`)
          : saveAs(out, `${"CD-" + debitCredit.debitCreditNumber}.docx`);
      });
  };

  const getAllMenuItems = (debitCredit) => {
    let menuItems = [
      {
        text: `View ${debitCredit.type === "debit" ? "Debit" : "Credit"} Note`,
        icon: <VisibilityRounded fontSize="small" />,
        handler: () => openDebitCreditDetailModal(debitCredit),
      },
      currentUser.roleType.roleTypeName === RoleTypes.MANAGER && {
        text: "View Audit History",
        icon: <HistoryRounded fontSize="small" />,
        disabled: !debitCredit,
        handler: () => debitCredit && openAuditModal(debitCredit.id),
      },
      {
        text: "Edit",
        icon: <CreateRounded fontSize="small" />,
        disabled: !debitCredit || !debitCredit.active,
        tooltip:
          debitCredit &&
          !debitCredit.active &&
          "Archived items are non-editable",
        handler: () => {
          openEditModal(debitCredit);
        },
      },
      {
        text: debitCredit && !debitCredit.active ? "Unarchive" : "Archive",
        icon: <ArchiveRounded fontSize="small" />,
        disabled: !debitCredit,
        handler: () => confirmToggleArchive(debitCredit),
      },
      {
        text: "Delete",
        icon: <DeleteRounded fontSize="small" />,
        disabled: !debitCredit,
        handler: () => confirmDelete(debitCredit),
      },
      {
        text: "Export as Word",
        icon: <DescriptionRounded fontSize="small" />,
        handler: () => generateDebitNote(debitCredit),
      },
    ];
    return menuItems;
  };

  const confirmDelete = (debitCredit) =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Delete",
      okBtnColor: "secondary",
      onOkClicked: () => goDelete(debitCredit),
      title: "Delete",
      message: `Are you sure you want to delete this ${debitCredit.type} note ${
        debitCredit.type === "credit" ? "CN" : "DN"
      }${debitCredit.debitCreditNumber}?`,
    }));

  const confirmToggleArchive = (debitCredit) => {
    const term = debitCredit.active ? "archive" : "unarchive";
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: term,
      okBtnColor: debitCredit.active ? "secondary" : "primary",
      onOkClicked: () => goToggleArchive(debitCredit),
      title: term,
      message: `Are you sure you want to ${term} this ${
        debitCredit.type
      } note ${debitCredit.type === "credit" ? "CN" : "DN"}${
        debitCredit.debitCreditNumber
      }?`,
    }));
  };

  return (
    <TabPanel className="AccountTabPanel" {...others}>
      <DebitCreditTable
        data={debitCreditListByCase}
        getAllMenuItems={getAllMenuItems}
      />
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="md"
        isOpen={isDebitCreditDetailOpen}
        title={`${
          selectedDebitCredit?.type === "debit" ? "Debit" : "Credit"
        } Detail`}
        handleClose={() => setIsDebitCreditDetailOpen(false)}
        hideFooter
      >
        <DebitCreditDetail debitCredit={selectedDebitCredit} />
      </CustomModal>
    </TabPanel>
  );
};

export { AccountTabPanel };
