import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useContext } from "react";
import { PopupContext } from "../DataContext";
import { InfoRounded } from "@material-ui/icons";

const ConfirmDialog = () => {
  const theme = useTheme();
  const { dialogOptions, openDialog } = useContext(PopupContext);

  const okClicked = () => {
    dialogOptions.onOkClicked && dialogOptions.onOkClicked();
    openDialog((prev) => ({ ...prev, open: false }));
  };
  const cancelClicked = () => {
    dialogOptions.onCancelClicked && dialogOptions.onCancelClicked();
    openDialog((prev) => ({ ...prev, open: false }));
  };

  return (
    <Dialog
      className="ConfirmDialog"
      open={dialogOptions.open}
      onClose={() => openDialog((prev) => ({ ...prev, open: false }))}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      {dialogOptions.title && (
        <DialogTitle id="confirm-dialog-title">
          <div className="d-flex align-items-center">
            {dialogOptions.Icon || <InfoRounded color="primary" />}
            <Typography
              id="confirm-dialog-title"
              color="primary"
              variant="h6"
              style={{ marginLeft: "8px", textTransform: "capitalize" }}
            >
              {dialogOptions.title}
            </Typography>
          </div>
        </DialogTitle>
      )}
      <DialogContent>
        {dialogOptions.message && (
          <DialogContentText id="confirm-dialog-description">
            {dialogOptions.message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={cancelClicked}
          color={dialogOptions.cancelBtnColor}
          style={{ color: theme.palette.text.secondary }}
        >
          {dialogOptions.cancelBtnText}
        </Button>
        <Button
          onClick={okClicked}
          color={dialogOptions.okBtnColor}
          autoFocus
          style={{ marginRight: "12px" }}
        >
          {dialogOptions.okBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
