import React, { useContext, useState, useEffect } from "react";
import { PopupContext, DataContext, SettingContext } from "../DataContext";
import CustomModal from "./CustomModal";
import { getDefaultTimeExpObj } from "../model";
import {
  getDefaultErrorObj,
  getError,
  hasMissingDataOnlyOn,
  hasErrorOnlyOn,
} from "../utils/functions";
import QuickExpenseEntryForm from "../pages/cases/QuickExpenseEntryForm";
import { useTheme, useMediaQuery } from "@material-ui/core";
import SlideUp from "./SlideUp";
import Axios from "axios";

const QeeModal = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { openQee, setOpenQee, selectedTimeExp, setSelectedTimeExp } =
    useContext(PopupContext);
  const { alertError, alertSuccess, alertWarning } = useContext(DataContext);
  const { setProcessing, setRefetch } = useContext(SettingContext);

  const [hasSaveAnother, setHasSaveAnother] = useState(false);

  const [error, setError] = useState(
    getDefaultErrorObj(getDefaultTimeExpObj("exp"))
  );

  useEffect(() => {
    setError(getDefaultErrorObj(getDefaultTimeExpObj("exp")));
  }, [openQee]);

  const handleChange = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setSelectedTimeExp({
      ...selectedTimeExp,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const _handleSaveAnother = selectedTimeExp.id
    ? null
    : (isChecked) => setHasSaveAnother(isChecked);

  const _handleClose = () => {
    setOpenQee(false);
    setHasSaveAnother(false);
  };

  const handleChangeByKey = (k, v) => {
    setSelectedTimeExp({
      ...selectedTimeExp,
      [k]: v,
    });
  };

  const onBlur = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const saveOrEditQee = () => {
    try {
      let fieldsToChk = [
        "caseDto",
        "staff",
        "type",
        "date",
        "actExpCode",
        "desc",
      ];

      const { amount } = selectedTimeExp;

      if (hasMissingDataOnlyOn(selectedTimeExp, ...fieldsToChk)) {
        alertWarning("Warning! Missing data in required fields.");
      } else if (hasErrorOnlyOn(error, ...fieldsToChk)) {
        alertWarning("Invalid! Check your input data entered.");
      } else if (Number(amount) <= 0) {
        alertWarning("Invalid expense amount.");
        setError({
          ...error,
          amount: true,
        });
      } else {
        error.amount &&
          setError({
            ...error,
            amount: false,
          });
        const timeExpToBeSaved = {
          ...selectedTimeExp,
          amount: isNaN(amount) ? 0 : Number(amount),
        };
        if (selectedTimeExp.id) {
          setProcessing(true);
          Axios.put("/quick-entries", timeExpToBeSaved)
            .then((res) => {
              setOpenQee(false);
              alertSuccess("Successfully updated!");
              setProcessing(false);
              setRefetch((prev) => !prev);
            })
            .catch((err) => {
              alertError(getError(err, "Error! Unable to update."));
              setProcessing(false);
            });
        } else {
          setProcessing(true);
          Axios.post("/quick-entries", timeExpToBeSaved)
            .then((res) => {
              if (hasSaveAnother) {
                const qeeObj = {
                  ...getDefaultTimeExpObj(),
                  caseDto: selectedTimeExp.caseDto,
                  staff: selectedTimeExp.staff,
                  type: "exp",
                };
                setSelectedTimeExp(qeeObj);
              } else {
                _handleClose();
              }
              alertSuccess("Successfully saved!");
              setProcessing(false);
              setRefetch((prev) => !prev);
            })
            .catch((err) => {
              alertError(getError(err, "Error! Unable to Save."));
              setProcessing(false);
            });
        }
      }
    } catch (error) {
      if (error.message) {
        alertError(
          `Error! Failed to ${selectedTimeExp.id ? "update" : "save"}. ${
            error.response && error.response.message
          }`
        );
      } else {
        alertError(`Network Error! Failed to update.`);
      }
    }
  };

  return (
    <CustomModal
      TransitionComponent={fullScreen ? SlideUp : undefined}
      fullScreen={fullScreen}
      maxWidth="md"
      isOpen={openQee}
      title={selectedTimeExp.id ? "Edit Expense Entry" : "Quick Expense Entry"}
      saveBtnTitle={selectedTimeExp.id ? "Update" : "Save"}
      handleClose={() => setOpenQee(false)}
      handleSave={saveOrEditQee}
      handleSaveAnother={_handleSaveAnother}
    >
      <QuickExpenseEntryForm
        data={selectedTimeExp}
        handleChange={handleChange}
        handleChangeByKey={handleChangeByKey}
        onBlur={onBlur}
        error={error}
      />
    </CustomModal>
  );
};

export default QeeModal;
