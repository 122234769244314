import { Grid, Link, makeStyles, Typography } from "@material-ui/core";
import {
  AssignmentIndRounded,
  CallRounded,
  EmailRounded,
  HomeRounded,
  PersonRounded,
  PhoneIphoneRounded,
  WidgetsRounded,
} from "@material-ui/icons";
import React from "react";
import { getDefaultClientObj } from "../../model";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    opacity: 0.7,
    marginRight: "10px",
  },
}));

const ClientSection = ({ clientInfo = getDefaultClientObj(), showDetail }) => {
  const classes = useStyles();

  if (!clientInfo) {
    return (
      <Grid container>
        <Grid item xs={12} className="p175">
          <Typography variant="body2" color="textSecondary">
            Client information not available
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {showDetail && (
        <Grid container>
          <Grid item xs={12} className="p175">
            <div className="d-flex align-items-center py-5">
              <AssignmentIndRounded fontSize="small" className={classes.icon} />
              <Typography variant="body1" color="primary" noWrap>
                {clientInfo.isClientCompany
                  ? clientInfo.companyName
                  : `${clientInfo.firstName} ${clientInfo.lastName}`}
              </Typography>
            </div>
            <div className="d-flex align-items-center py-5">
              <WidgetsRounded fontSize="small" className={classes.icon} />
              <Typography variant="body1" color="secondary" noWrap>
                {clientInfo.clientId}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12} md={4} className="p175">
          <Typography
            variant="body2"
            color="textSecondary"
            className="segLabel"
            noWrap
          >
            Contact (Default POC)
          </Typography>
          <div className="d-flex align-items-center py-5">
            <PersonRounded fontSize="small" className={classes.icon} />
            <Typography variant="body2" color="textSecondary" noWrap>
              {`${clientInfo.salutation.value} ${clientInfo.firstName} ${clientInfo.lastName}`}
            </Typography>
          </div>

          <div className="d-flex align-items-center py-5">
            <EmailRounded fontSize="small" className={classes.icon} />
            <Typography variant="body2" color="textSecondary" noWrap>
              <Link href={`mailto:${clientInfo.email}`} color="textSecondary">
                {clientInfo.email}
              </Link>
            </Typography>
          </div>

          <div className="d-flex align-items-center py-5">
            <PhoneIphoneRounded fontSize="small" className={classes.icon} />
            <Typography variant="body2" color="textSecondary" noWrap>
              <Link href={`tel:${clientInfo.mobile}`} color="textSecondary">
                {clientInfo.mobile}
              </Link>
            </Typography>
          </div>

          {clientInfo.phone && (
            <div className="d-flex align-items-center py-5">
              <CallRounded fontSize="small" className={classes.icon} />
              <Typography variant="body2" color="textSecondary" noWrap>
                <Link href={`tel:${clientInfo.phone}`} color="textSecondary">
                  {clientInfo.phone}
                </Link>
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={4} className="p175">
          <Typography
            variant="body2"
            color="textSecondary"
            className="segLabel"
            noWrap
          >
            Postal Address
          </Typography>
          <div className="d-flex py-5">
            <HomeRounded fontSize="small" className={classes.icon} />
            <div>
              <Typography variant="body2" color="textSecondary">
                {clientInfo.postalAddressOne}
              </Typography>

              <Typography variant="body2" color="textSecondary">
                {clientInfo.postalCity +
                  ", " +
                  clientInfo.postalState +
                  ", " +
                  clientInfo.postalCountry?.value.split("-")[1]}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {clientInfo.postalPostalCode}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} className="p175">
          <Typography
            variant="body2"
            color="textSecondary"
            className="segLabel"
            noWrap
          >
            Billing Address
          </Typography>
          {clientInfo.isBillingSameAsPostal ? (
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontStyle: "italic" }}
            >
              - same as postal address
            </Typography>
          ) : (
            <div className="d-flex py-5">
              <HomeRounded fontSize="small" className={classes.icon} />
              <div>
                <Typography variant="body2" color="textSecondary">
                  {clientInfo.billingAddressOne}
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  {clientInfo.billingCity +
                    ", " +
                    clientInfo.billingState +
                    ", " +
                    clientInfo.billingCountry?.value.split("-")[1]}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {clientInfo.billingPostalCode}
                </Typography>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ClientSection;
