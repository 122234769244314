import React from "react";
import {
  HistoryRounded,
  CreateRounded,
  DeleteRounded,
} from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PopupMenuButton from "../../components/PopupMenuButton";
import { useTableStyle } from "../../theme/tableStyles";

export default function TransactionTable(props) {
  const classes = useTableStyle();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Case</TableCell>
            <TableCell align="center">Invoice Number</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Date</TableCell>
            <TableCell align="right">Amount($)</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {props.transactions &&
            props.transactions.map((row) => (
              <TableRow key={row.name}>
                <TableCell>{row.caseInfo}</TableCell>
                <TableCell align="center">
                  {row.invoiceNumber && row.invoiceNumber.length !== 0
                    ? row.invoiceNumber
                    : "-"}
                </TableCell>
                <TableCell>{row.createdBy}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                <TableCell>
                  <PopupMenuButton
                    size="small"
                    color="default"
                    isIconButton
                    menuItems={[
                      {
                        text: "View Audit History",
                        icon: <HistoryRounded fontSize="small" />,
                        // handler: () => m && openAuditModal(m),
                      },
                      {
                        text: "Edit",
                        icon: <CreateRounded fontSize="small" />,
                        // handler: () => {
                        //   setIsFormOpen(true);
                        //   setMasterCodeType(mapMasterCodeType(m));
                        // },
                      },
                      {
                        text: "Delete",
                        icon: <DeleteRounded fontSize="small" />,
                        // handler: () => {
                        //   confirmDelete(mapMasterCodeType(m));
                        // },
                        // disabled: m.used,
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
