import React, { useState, useContext, useEffect } from "react";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import {
  AddRounded,
  CreateRounded,
  ArchiveRounded,
  DeleteRounded,
  HistoryRounded,
} from "@material-ui/icons";
import PageSidebar from "../../components/PageSidebar";
import CustomModal from "../../components/CustomModal";
import CommonPage from "../CommonPage";
import PopupMenuButton from "../../components/PopupMenuButton";
import CompanyForm from "./CompanyForm";

import { getDefaultCompanyObj } from "../../model";
import {
  getDefaultErrorObj,
  hasMissingDataOnlyOn,
  hasErrorOnlyOn,
  isBrn,
  getError,
} from "../../utils/functions";
import { DataContext, PopupContext, SettingContext } from "../../DataContext";
import CompanyDetail from "./CompanyDetail";
import SlideUp from "../../components/SlideUp";
import Axios from "axios";
import { Modules, SortColumns } from "../../utils/constants";

const CompaniesPage = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState(false);
  const { alertSuccess, alertWarning, alertError } = useContext(DataContext);
  const { openDialog, setAuditOptions } = useContext(PopupContext);
  const { companySetting, setCompanySetting } = useContext(SettingContext);
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [comTemp, setComTemp] = useState(getDefaultCompanyObj());
  const [error, setError] = useState(
    getDefaultErrorObj(getDefaultCompanyObj())
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [sorts, setSorts] = useState([]);

  const fetchCompanyListWithPagination = (
    page = companySetting.currentPage,
    size = companySetting.pageSize,
    showArchivedData = companySetting.showArchivedData,
    q = searchTerm,
    sort = sorts
  ) => {
    setLoading(true);
    Axios.get(
      `/companies/paginate?page=${page}&size=${size}&showArchivedData=${showArchivedData}${
        q ? "&q=" + q : ""
      }${
        sort.length > 0
          ? "&sort=" + sort.map((s) => s.field + "-" + s.order).join(",")
          : ""
      }`
    )
      .then((res) => {
        setLoading(false);
        setCompanyList(res.data.content);
        setCompanySetting((prev) => ({
          ...prev,
          totalPages: res.data.totalPages,
          currentPage: page,
        }));
      })
      .catch((err) => {
        setLoading(false);
        setCompanyList([]);
        setCompanySetting((prev) => ({ ...prev, totalPages: 0 }));
      });
  };

  useEffect(() => {
    fetchCompanyListWithPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companySetting.currentPage,
    companySetting.pageSize,
    companySetting.showArchivedData,
    searchTerm,
    sorts,
  ]);

  const setSearchTermAndGotToFirstPage = (term) => {
    setSearchTerm(term);
    setCompanySetting((prev) => ({ ...prev, currentPage: 1 }));
  };

  const openSaveModal = () => {
    setError(getDefaultErrorObj(getDefaultCompanyObj()));
    setComTemp(getDefaultCompanyObj());
    setIsOpen(true);
  };

  const openEditModal = () => {
    setError(getDefaultErrorObj(getDefaultCompanyObj()));
    setComTemp(company);
    setIsOpen(true);
  };

  const openAuditModal = (companySid) =>
    setAuditOptions({
      open: true,
      moduleType: Modules.COMPANY,
      sid: companySid,
    });

  const handleChange = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setComTemp({
      ...comTemp,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]:
        (event.target.validity && !event.target.validity.valid) ||
        (name === "businessRegistrationNumber" && !isBrn(event.target.value)),
    });
  };

  const handleChangeByKey = (k, v) => {
    setComTemp({
      ...comTemp,
      [k]: v,
    });
  };

  const onBlur = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setError({
      ...error,
      [name]:
        (event.target.validity && !event.target.validity.valid) ||
        (name === "businessRegistrationNumber" && !isBrn(event.target.value)),
    });
  };

  const saveOrEdit = () => {
    try {
      let fieldsToCheck = [
        "taxRegistered",
        "businessRegistrationNumber",
        "companyName",
      ];

      if (hasMissingDataOnlyOn(comTemp, ...fieldsToCheck)) {
        alertWarning("Warning! Missing data in required fields.");
      } else if (hasErrorOnlyOn(error, ...fieldsToCheck)) {
        alertWarning("Invalid! Check your input data entered.");
      } else {
        if (comTemp.companySid) {
          // UPDATE
          Axios.put("/companies", comTemp)
            .then((res) => {
              fetchCompanyListWithPagination();
              setIsOpen(false);
              alertSuccess("Successfully updated!");
              setCompany(res.data);
            })
            .catch((err) =>
              alertError(getError(err, "Error! Failed to update."))
            );
        } else {
          // SAVE
          Axios.post("/companies", comTemp)
            .then((res) => {
              setIsOpen(false);
              alertSuccess("Successfully saved!");
              fetchCompanyListWithPagination(1);
              setCompany(res.data);
            })
            .catch((err) =>
              alertError(getError(err, "Error! Failed to save."))
            );
        }
      }
    } catch (error) {
      alertError(
        `Error! Unable to process before ${
          comTemp.companySid ? "update" : "save"
        }.`
      );
    }
  };

  const goToggleArchive = () => {
    const term = company.active ? "archive" : "unarchive";
    Axios.patch(`/companies/${term}/${company.companySid}`)
      .then((res) => {
        alertSuccess(`Successfully ${term}d!`);
        fetchCompanyListWithPagination();
        if (!company.active || companySetting.showArchivedData) {
          setCompany((prev) => ({ ...prev, active: !prev.active }));
        } else {
          setCompany(null);
        }
      })
      .catch((err) => {
        alertError(getError(err, `Error! Unable to ${term}.`));
      });
  };

  const confirmToggleArchive = () => {
    const term = company.active ? "archive" : "unarchive";
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: term,
      okBtnColor: company.active ? "secondary" : "primary",
      onOkClicked: goToggleArchive,
      title: term,
      message: `Are you sure you want to ${term} "${company.companyName}"?`,
    }));
  };

  const goDelete = () => {
    Axios.delete("/companies/" + company.companySid)
      .then((res) => {
        fetchCompanyListWithPagination(1);
        setCompany(null);
        alertSuccess("Successfully deleted!");
      })
      .catch((err) => alertError(getError(err, "Error! Failed to delete.")));
  };

  const confirmDelete = () =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Delete",
      okBtnColor: "secondary",
      onOkClicked: goDelete,
      title: "Delete",
      message: `Are you sure you want to delete "${company.companyName}"?`,
    }));

  return (
    <>
      <CommonPage
        className="CompaniesPage"
        setSelectedData={setCompany}
        detailTitle={company && company.companyName}
        archived={company && !company.active}
        PageSidebar={
          <PageSidebar
            loading={loading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTermAndGotToFirstPage}
            sortFields={SortColumns.COMPANY}
            defaultSortDesc="Sort companies by Last Modified date in most recent first order"
            setSorts={setSorts}
            searchInputPlaceholder="Search Company"
            listName="Registered Companies"
            listData={companyList.map((c) => ({
              active: c.active,
              id: c.id,
              primary: c.companyName,
              secondary: "BRN# " + c.businessRegistrationNumber,
              selected: company && c.companySid === company.companySid,
              onClick: () =>
                setCompany(
                  companyList.find((cl) => cl.companySid === c.companySid)
                ),
            }))}
            addClicked={openSaveModal}
            module={Modules.COMPANY}
          />
        }
        Buttons={
          <>
            <Grid item>
              <PopupMenuButton
                size="small"
                color="default"
                isIconButton
                menuItems={[
                  {
                    text: "Create New Company",
                    icon: <AddRounded fontSize="small" />,
                    handler: openSaveModal,
                  },
                  {
                    text: "View Audit History",
                    icon: <HistoryRounded fontSize="small" />,
                    disabled: !company,
                    handler: () =>
                      company && openAuditModal(company.companySid),
                  },
                  {
                    text: "Edit",
                    icon: <CreateRounded fontSize="small" />,
                    disabled: Boolean(!company || !company.active),
                    tooltip:
                      company &&
                      !company.active &&
                      "Archived items are non-editable",
                    handler: openEditModal,
                  },
                  {
                    text: company && !company.active ? "Unarchive" : "Archive",
                    icon: <ArchiveRounded fontSize="small" />,
                    disabled: !company,
                    handler: confirmToggleArchive,
                  },
                  {
                    text: "Delete",
                    icon: <DeleteRounded fontSize="small" />,
                    disabled: !company,
                    handler: confirmDelete,
                  },
                ]}
              />
            </Grid>
          </>
        }
      >
        {company && companyList.length && <CompanyDetail company={company} />}
      </CommonPage>
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="sm"
        isOpen={isOpen}
        title={comTemp.companySid ? "Edit Company" : "New Company"}
        closeBtnTitle="Cancel"
        saveBtnTitle={comTemp.companySid ? "Update" : "Save"}
        handleClose={() => setIsOpen(false)}
        handleSave={saveOrEdit}
      >
        <CompanyForm
          data={comTemp}
          handleChange={handleChange}
          handleChangeByKey={handleChangeByKey}
          error={error}
          setError={setError}
          onBlur={onBlur}
        />
      </CustomModal>
    </>
  );
};

export default CompaniesPage;
