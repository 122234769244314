import React, { useState, useContext, useEffect } from "react";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import {
  CreateRounded,
  ArchiveRounded,
  DeleteRounded,
  PersonAddRounded,
  HistoryRounded,
  VpnKeyRounded,
} from "@material-ui/icons";
import PageSidebar from "../../components/PageSidebar";
import PopupMenuButton from "../../components/PopupMenuButton";
import CustomModal from "../../components/CustomModal";
import CommonPage from "../CommonPage";
import CompanyAdminForm from "./CompanyAdminForm";

import { DataContext, PopupContext, SettingContext } from "../../DataContext";
import { getDefaultCompanyAdminObj } from "../../model";
import {
  getDefaultErrorObj,
  hasMissingDataOnlyOn,
  hasErrorOnlyOn,
  getError,
} from "../../utils/functions";
import SlideUp from "../../components/SlideUp";
import Axios from "axios";
import { Modules, SortColumns } from "../../utils/constants";
import { StaffProfile } from "../staff/components";

const CompanyAdminPage = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState(false);
  const { alertSuccess, alertWarning, alertError } = useContext(DataContext);
  const { openDialog, setAuditOptions, setChangePasswordOptions } =
    useContext(PopupContext);
  const { companyAdminSetting, setCompanyAdminSetting } =
    useContext(SettingContext);
  const [comAdminList, setComAdminList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState(null);
  const [staffTemp, setStaffTemp] = useState(getDefaultCompanyAdminObj());
  const [error, setError] = useState(
    getDefaultErrorObj(getDefaultCompanyAdminObj())
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [sorts, setSorts] = useState([]);

  const fetchCompanyList = () => {
    Axios.get("/companies")
      .then((res) => setCompanyList(res.data))
      .catch((err) => setCompanyList([]));
  };

  const fetchCompanyAdminsWithPagination = (
    page = companyAdminSetting.currentPage,
    size = companyAdminSetting.pageSize,
    showArchivedData = companyAdminSetting.showArchivedData,
    q = searchTerm,
    sort = sorts
  ) => {
    setLoading(true);
    Axios.get(
      `/companyadmins/paginate?page=${page}&size=${size}&showArchivedData=${showArchivedData}
      ${q ? "&q=" + q : ""}${
        sort.length > 0
          ? "&sort=" + sort.map((s) => s.field + "-" + s.order).join(",")
          : ""
      }
      `
    )
      .then((res) => {
        setLoading(false);
        setComAdminList(res.data.content);
        setCompanyAdminSetting((prev) => ({
          ...prev,
          totalPages: res.data.totalPages,
          currentPage: page,
        }));
      })
      .catch((err) => {
        setLoading(false);
        setComAdminList([]);
        setCompanyAdminSetting((prev) => ({ ...prev, totalPages: 0 }));
      });
  };

  useEffect(() => {
    fetchCompanyList();
  }, []);

  useEffect(() => {
    // fetchCompanyList();
    fetchCompanyAdminsWithPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyAdminSetting.currentPage,
    companyAdminSetting.pageSize,
    companyAdminSetting.showArchivedData,
    searchTerm,
    sorts,
  ]);

  const setSearchTermAndGotToFirstPage = (term) => {
    setSearchTerm(term);
    setCompanyAdminSetting((prev) => ({ ...prev, currentPage: 1 }));
  };

  const openSaveModal = () => {
    setError(getDefaultErrorObj(getDefaultCompanyAdminObj()));
    setStaffTemp(getDefaultCompanyAdminObj());
    setIsOpen(true);
  };
  const openEditModal = () => {
    setError(getDefaultErrorObj(getDefaultCompanyAdminObj()));
    setStaffTemp(staff);
    setIsOpen(true);
  };
  const openAuditModal = (companyAdminSid) =>
    setAuditOptions({
      open: true,
      moduleType: Modules.COMPANY_ADMIN,
      sid: companyAdminSid,
    });
  const openChangePasswordModal = () => {
    setChangePasswordOptions({
      open: true,
      type: "reset",
      personSid: staff.personSid,
      name: staff.firstName + " " + staff.lastName,
      email: staff.email,
    });
  };

  const handleChange = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setStaffTemp({
      ...staffTemp,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const onBlur = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const saveOrEditStaff = () => {
    try {
      const fieldsToChk = [
        "salutationSid",
        "firstName",
        "lastName",
        "companySid",
        "email",
      ];
      if (!staffTemp.personSid) {
        fieldsToChk.push("password");
        fieldsToChk.push("passwordReenter");
      }
      if (hasMissingDataOnlyOn(staffTemp, ...fieldsToChk)) {
        alertWarning("Warning! Missing data in required fields.");
      } else if (
        !staffTemp.personSid &&
        staffTemp.password !== staffTemp.passwordReenter
      ) {
        alertWarning("Password mismatch! Please re-enter password.");
      } else if (hasErrorOnlyOn(error, ...fieldsToChk)) {
        alertWarning("Invalid! Check your input data entered.");
      } else {
        if (staffTemp.personSid) {
          // UPDATE
          Axios.put("/companyadmins", staffTemp)
            .then((res) => {
              // fetchCompanyAdminList();
              fetchCompanyAdminsWithPagination();
              setStaff(res.data);
              setIsOpen(false);
              alertSuccess("Successfully updated!");
            })
            .catch((err) => {
              if (err.response) {
                alertError(err.response.message);
              } else {
                alertError("Network Error! Unable to update.");
              }
            });
        } else {
          // SAVE
          Axios.post("/companyadmins", staffTemp)
            .then((res) => {
              // fetchCompanyAdminList();
              fetchCompanyAdminsWithPagination(1);
              setStaff(res.data);
              setIsOpen(false);
              alertSuccess("Successfully saved!");
            })
            .catch((err) => {
              if (err.response) {
                alertError(err.response.message);
              } else {
                alertError("Network Error! Unable to save.");
              }
            });
        }
      }
    } catch (error) {
      alertError(
        `Error! Failed to ${staffTemp.personSid ? "update" : "save"}.`
      );
    }
  };

  const goDelete = () => {
    Axios.delete("/companyadmins/" + staff.personSid)
      .then((res) => {
        // fetchCompanyAdminList();
        fetchCompanyAdminsWithPagination(1);
        setStaff(null);
        alertSuccess("Successfully deleted!");
      })
      .catch((err) => {
        if (err.response) {
          alertError(err.response.message);
        } else {
          alertError("Error! Unable to delete.");
        }
      });
  };

  const confirmDelete = () =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Delete",
      okBtnColor: "secondary",
      onOkClicked: goDelete,
      title: "Delete",
      message: `Are you sure you want to delete "${staff.firstName} ${staff.lastName}"?`,
    }));

  const goToggleArchive = () => {
    const term = staff.active ? "archive" : "unarchive";
    Axios.patch(`/companyadmins/${term}/${staff.personSid}`)
      .then((res) => {
        alertSuccess(`Successfully ${term}d!`);
        fetchCompanyAdminsWithPagination();
        if (!staff.active || companyAdminSetting.showArchivedData) {
          setStaff((prev) => ({ ...prev, active: !prev.active }));
        } else {
          setStaff(null);
        }
      })
      .catch((err) => {
        alertError(getError(err, `Error! Unable to ${term}.`));
      });
  };

  const confirmToggleArchive = () => {
    const term = staff.active ? "archive" : "unarchive";
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: term,
      okBtnColor: staff.active ? "secondary" : "primary",
      onOkClicked: goToggleArchive,
      title: term,
      message: `Are you sure you want to ${term} "${staff.firstName} ${staff.lastName}"?`,
    }));
  };

  return (
    <>
      <CommonPage
        className="CompanyAdminPage"
        setSelectedData={setStaff}
        detailTitle={staff && " "}
        PageSidebar={
          <PageSidebar
            loading={loading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTermAndGotToFirstPage}
            sortFields={SortColumns.COMPANY_ADMIN}
            setSorts={setSorts}
            searchInputPlaceholder="Search Company Admin"
            listName="Company Admins"
            listData={comAdminList.map((l) => ({
              active: l.active,
              id: l.personSid,
              primary: `${l.firstName} ${l.lastName}`,
              secondary:
                companyList.length > 0 &&
                l.companySid &&
                companyList.find((el) => el.companySid === l.companySid)
                  ? companyList.find((el) => el.companySid === l.companySid)
                      .companyName
                  : " ",
              selected: staff && l.personSid === staff.personSid,
              onClick: () =>
                setStaff(comAdminList.find((s) => s.personSid === l.personSid)),
            }))}
            addClicked={openSaveModal}
            module={Modules.COMPANY_ADMIN}
          />
        }
        Buttons={
          <Grid item>
            <PopupMenuButton
              size="small"
              color="default"
              isIconButton
              menuItems={[
                {
                  text: "Create Company Admin",
                  icon: <PersonAddRounded fontSize="small" />,
                  handler: openSaveModal,
                },
                {
                  text: "Reset Password",
                  icon: <VpnKeyRounded fontSize="small" />,
                  handler: openChangePasswordModal,
                  disabled: Boolean(!staff || !staff.active),
                  tooltip:
                    staff &&
                    !staff.active &&
                    "Resetting password for inactive employee is not allowed",
                },
                {
                  text: "View Audit History",
                  icon: <HistoryRounded fontSize="small" />,
                  disabled: !staff,
                  handler: () => staff && openAuditModal(staff.personSid),
                },
                {
                  text: "Edit",
                  icon: <CreateRounded fontSize="small" />,
                  disabled: Boolean(!staff || !staff.active),
                  tooltip:
                    staff &&
                    !staff.active &&
                    "Inactive employees are non-editable",
                  handler: openEditModal,
                },
                {
                  text: staff && !staff.active ? "Unarchive" : "Archive",
                  icon: <ArchiveRounded fontSize="small" />,
                  disabled: !staff,
                  handler: confirmToggleArchive,
                },
                {
                  text: "Delete",
                  icon: <DeleteRounded fontSize="small" />,
                  disabled: !staff,
                  handler: confirmDelete,
                },
              ]}
            />
          </Grid>
        }
      >
        {staff && (
          <StaffProfile
            staff={staff}
            companyList={companyList}
            isCompanyAdmin
          />
        )}
      </CommonPage>
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="sm"
        isOpen={isOpen}
        title={staffTemp.personSid ? "Edit Company Admin" : "New Company Admin"}
        closeBtnTitle="Cancel"
        saveBtnTitle={staffTemp.personSid ? "Update" : "Save"}
        handleClose={() => setIsOpen(false)}
        handleSave={saveOrEditStaff}
      >
        <CompanyAdminForm
          staff={staffTemp}
          handleChange={handleChange}
          error={error}
          setError={setError}
          onBlur={onBlur}
          companyList={companyList}
        />
      </CustomModal>
    </>
  );
};

export default CompanyAdminPage;
