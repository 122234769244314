import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";

const ComplexSelect = ({
  options = [],
  valueKey = "id",
  labelKey = "value",
  fullWidth,
  required,
  value = "",
  name = "",
  className,
  getOptionLabel = (el) => el && el.value,
  onChange = (e, v) => {},
  onBlur = (e) => {},
  error,
  label,
  textFieldProps = {},
  ...others
}) => {
  // CLIENT-REQUEST: show only active options if object has active property and active is true.
  // otherwise show all
  const _options = options?.filter((o) => {
    if (o.hasOwnProperty("active")) {
      return o.active === true;
    }
    return true;
  });

  return (
    <Autocomplete
      {...others}
      // getOptionDisabled={(o) => o.active !== undefined && !o.active}
      className={clsx("ComplexSelect", className)}
      fullWidth={fullWidth}
      options={_options}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={(e, v) => {
        onChange({
          ...e,
          target: {
            ...e.target,
            name,
            value: v,
            validity: {
              ...e.target.validity,
              valid: required && v,
            },
          },
        });
      }}
      renderInput={(params) => (
        <TextField
          {...textFieldProps}
          {...params}
          label={label}
          required={required}
          name={name}
          error={error}
          onBlur={(e) => {
            onBlur({
              ...e,
              target: {
                ...e.target,
                name,
                validity: {
                  ...e.target.validity,
                  valid: required && value,
                },
              },
            });
          }}
        />
      )}
    />
  );
};

export default ComplexSelect;
