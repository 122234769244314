import React, { useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { PopupContext, DataContext, SettingContext } from "../DataContext";
import CustomModal from "./CustomModal";
import QuickTimeEntryForm from "../pages/cases/QuickTimeEntryForm";
import { getDefaultTimeExpObj } from "../model";
import {
  getDefaultErrorObj,
  getError,
  hasMissingDataOnlyOn,
  hasErrorOnlyOn,
} from "../utils/functions";
import { useTheme, useMediaQuery } from "@material-ui/core";
import SlideUp from "./SlideUp";
import Axios from "axios";
import { RateType } from "../utils/constants";

const QteModal = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { openQte, setOpenQte, selectedTimeExp, setSelectedTimeExp } =
    useContext(PopupContext);
  const { alertError, alertSuccess, alertWarning, staffList } =
    useContext(DataContext);
  const { setProcessing, setRefetch } = useContext(SettingContext);

  const [error, setError] = useState(
    getDefaultErrorObj(getDefaultTimeExpObj())
  );
  const [hasSaveAnother, setHasSaveAnother] = useState(false);
  const [customRates, setCustomRates] = useState([]);

  useEffect(() => {
    setError(getDefaultErrorObj(getDefaultTimeExpObj()));
  }, [openQte]);

  const calculateAndSet = (
    qteObj = selectedTimeExp,
    cusRates = customRates
  ) => {
    const qte = { ...qteObj };

    qte.rate = 0;
    qte.rateType = RateType.GLOBAL;
    qte.amount = 0;
    if (qte.staff && qte.staff.id) {
      const stf = staffList.find((el) => el.id === qte.staff.id);
      qte.rate = stf ? stf.rate : 0;
      qte.rateType = RateType.GLOBAL;
      if (qte.caseDto && qte.caseDto.id) {
        const cus =
          cusRates &&
          cusRates.length > 0 &&
          cusRates.find((el) => qte.staff && el.personSid === qte.staff.id);
        if (cus) {
          qte.rate = cus.customRate;
          qte.rateType = RateType.CUSTOM;
        }
      }
    }

    qte.amount =
      qte.rate *
      Number((+qte.billedHours + +qte.billedMinutes / 60.0).toFixed(2));
    qte.amount = +qte.amount.toFixed(2);

    setSelectedTimeExp(qte);
  };

  const fetchCustomRates = (caseSid) => {
    setProcessing(true);
    Axios.get(`/cases/${caseSid}/additional-lawyers`)
      .then((res) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setProcessing(false);
          setCustomRates(res.data);
          calculateAndSet(selectedTimeExp, res.data);
        });
      })
      .catch((err) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setProcessing(false);
          setCustomRates([]);
          calculateAndSet(selectedTimeExp, []);
          alertError(
            "Unable to retrieve custom hourly rate via additional lawyers"
          );
        });
      });
  };

  useEffect(() => {
    if (openQte) {
      if (selectedTimeExp.caseDto && selectedTimeExp.caseDto.id) {
        fetchCustomRates(selectedTimeExp.caseDto.id);
      } else {
        setCustomRates([]);
        calculateAndSet(selectedTimeExp, []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeExp.caseDto]);

  const handleChange = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    const value = event.target.value;
    const newObj = { ...selectedTimeExp };
    newObj[name] = value;

    if (name === "hours") {
      newObj.billedHours = value;
    } else if (name === "minutes") {
      newObj.billedMinutes = value;
    }

    const timeFields = ["hours", "minutes", "billedHours", "billedMinutes"];
    if (timeFields.includes(name)) {
      newObj.amount =
        newObj.rate *
        Number((+newObj.billedHours + +newObj.billedMinutes / 60.0).toFixed(2));
      newObj.amount = +newObj.amount.toFixed(2);
      newObj.netAmount = newObj.amount - newObj.discountAmount;
    }

    if (name === "staff") {
      calculateAndSet(newObj);
    } else {
      setSelectedTimeExp(newObj);
    }

    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const handleChangeByKey = (k, v) => {
    setSelectedTimeExp({ ...selectedTimeExp, [k]: v });
  };

  const onBlur = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const saveOrEditQte = () => {
    try {
      const { hours, minutes, billedHours, billedMinutes, amount, reason } =
        selectedTimeExp;

      let fieldsToChk = [
        "caseDto",
        "staff",
        "type",
        "date",
        "actExpCode",
        "desc",
      ];

      if ((+hours !== +billedHours || +minutes !== +billedMinutes) && !reason) {
        setError({ ...error, reason: true });
        alertWarning(
          "Specify the reason why clocked and billed times are different."
        );
      } else if (hasMissingDataOnlyOn(selectedTimeExp, ...fieldsToChk)) {
        alertWarning("Warning! Missing data in required fields.");
      } else if (hasErrorOnlyOn(error, ...fieldsToChk)) {
        alertWarning("Invalid! Check your input data entered.");
      } else {
        const timeExpToBeSaved = {
          ...selectedTimeExp,
          hours: isNaN(hours) ? 0 : Number(hours),
          minutes: isNaN(minutes) ? 0 : Number(minutes),
          amount: isNaN(amount) ? 0 : Number(amount),
          billedHours: isNaN(billedHours) ? 0 : Number(billedHours),
          billedMinutes: isNaN(billedMinutes) ? 0 : Number(billedMinutes),
        };
        if (selectedTimeExp.id) {
          setProcessing(true);
          Axios.put("/quick-entries", timeExpToBeSaved)
            .then((res) => {
              setOpenQte(false);
              alertSuccess("Successfully updated!");
              setProcessing(false);
              setRefetch((prev) => !prev);
            })
            .catch((err) => {
              alertError(getError(err, "Error! Unable to update."));
              setProcessing(false);
            });
        } else {
          setProcessing(true);
          Axios.post("/quick-entries", timeExpToBeSaved)
            .then((res) => {
              if (hasSaveAnother) {
                const qteObj = {
                  ...timeExpToBeSaved,
                  hours: 0,
                  minutes: 0,
                  billedHours: 0,
                  billedMinutes: 0,
                  amount: 0,
                  desc: "",
                  reason: "",
                };
                setSelectedTimeExp(qteObj);
              } else {
                _handleClose();
              }
              alertSuccess("Successfully saved!");
              setProcessing(false);
              setRefetch((prev) => !prev);
            })
            .catch((err) => {
              alertError(getError(err, "Error! Unable to Save."));
              setProcessing(false);
            });
        }
      }
    } catch (error) {
      alertError(`Error! Failed to ${selectedTimeExp.id ? "update" : "save"}.`);
    }
  };

  const _handleSaveAnother = selectedTimeExp.id
    ? null
    : (isChecked) => setHasSaveAnother(isChecked);

  const _handleClose = () => {
    setOpenQte(false);
    setHasSaveAnother(false);
  };

  return (
    <CustomModal
      TransitionComponent={SlideUp}
      fullScreen={fullScreen}
      maxWidth="md"
      isOpen={openQte}
      title={selectedTimeExp.id ? "Edit Time Entry" : "Quick Time Entry"}
      saveBtnTitle={selectedTimeExp.id ? "Update" : "Save"}
      handleClose={_handleClose}
      handleSave={saveOrEditQte}
      handleSaveAnother={_handleSaveAnother}
      // disableSaveBtn={processing}
      // disableCancelBtn={processing}
      // disableCloseBtn={processing}
    >
      <QuickTimeEntryForm
        data={selectedTimeExp}
        handleChange={handleChange}
        handleChangeByKey={handleChangeByKey}
        onBlur={onBlur}
        error={error}
      />
    </CustomModal>
  );
};

export default QteModal;
