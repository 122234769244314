import { RateType } from "../utils/constants";
import { formatDateDash } from "../utils/dateUtils";

export const getDefaultTimeExpObj = (type = "time") => ({
  actExpCode: "",
  active: true,
  amount: 0,
  billedHours: 0,
  billedMinutes: 0,
  caseDto: null, // CommonDto
  date: formatDateDash(new Date()),
  desc: "",
  discountAmount: 0,
  discountPercentage: 0,
  hours: 0,
  id: null,
  isDiscountable: false,
  isInvoiced: false,
  isTaxable: type === "time", // expense is not taxable by default
  minutes: 0,
  netAmount: 0,
  rate: 0,
  rateType: RateType.GLOBAL,
  reason: "",
  staff: null, // CommonDto
  type,
});

export const getDefaultTimerObj = (start, staff) => {
  const timerObj = getDefaultTimeExpObj();
  let hours = 0,
    minutes = 0;
  const finish = new Date().getTime();

  if (finish > start) {
    const diff = parseInt((finish - start) / 1000);
    minutes = parseInt((diff / 60) % 60);
    hours = parseInt(diff / 3600);
  }
  return { ...timerObj, staff, hours, minutes };
};
