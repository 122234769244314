import { colors, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { ColorValues } from "../../theme/colors";
import { DateFormats } from "../../utils/constants";
import { formatDate } from "../../utils/dateUtils";

const useStyles = makeStyles((theme) => ({
  circle: {
    width: "130px",
    height: "130px",
    borderRadius: "50%",
    borderWidth: "6px",
    borderStyle: "solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&> span": {
      textTransform: "uppercase",
      fontSize: "22px",
      fontWeight: "bold",
      letterSpacing: "1px",
    },
    [theme.breakpoints.up("xs")]: {
      marginLeft: "16px",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "32px",
    },
  },
  open: {
    borderColor: colors[ColorValues.GREEN][400],
    "&> span": {
      color: colors[ColorValues.GREEN][400],
    },
  },
  closed: {
    opacity: 0.7,
    borderColor: theme.palette.text.secondary,
    "&> span": {
      color: theme.palette.text.secondary,
    },
  },
  date: {
    fontWeight: "600",
    fontStyle: "italic",
    whiteSpace: "nowrap",
  },
}));

const CaseHeaderSection = ({ status, openDate, closedDate }) => {
  const classes = useStyles();

  const isOpen = status === "open";
  return (
    <div className="CaseHeaderSection">
      <div className="d-flex align-items-center">
        <div className="p175">
          <div
            className={clsx(
              classes.circle,
              isOpen ? classes.open : classes.closed
            )}
          >
            <span>{isOpen ? "open" : "closed"}</span>
          </div>
        </div>
        <div className="p175">
          <Typography variant="body2" color="textSecondary">
            Opened on:
            <br />
            <span className={classes.date}>
              &nbsp;{formatDate(openDate, DateFormats.MEDIUM)}
            </span>
          </Typography>
          {!isOpen && (
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginTop: "12px" }}
            >
              Closed on:
              <br />
              <span className={classes.date}>
                &nbsp;{formatDate(closedDate, DateFormats.MEDIUM)}
              </span>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseHeaderSection;
