import React from "react";
import TabPanel from "./TabPanel";
import { Typography, List, Grid } from "@material-ui/core";
import clsx from "clsx";
import { useContext } from "react";
import { DataContext } from "../DataContext";
import SimpleBox from "./SimpleBox";
import { DateFormats } from "../utils/constants";
import { formatDate } from "../utils/dateUtils";
import LabelValueDetail from "./LabelValueDetail";
import { formatDecimal, padZero } from "../utils/functions";

const QuickEntryDetail = ({ entry, className, ...others }) => {
  const { actCodes, expCodes } = useContext(DataContext);

  const getActExpCode = (code) => {
    if (expCodes.find((e) => e.id === code)) {
      return expCodes.find((e) => e.id === code).value;
    }
    return actCodes.find((e) => e.id === code).value;
  };

  return (
    <TabPanel className={clsx("DetailsTabPanel", className)} {...others}>
      <List className="fullWidth">
        <Grid container>
          <LabelValueDetail
            label="Case Name"
            value={entry.caseDto && entry.caseDto.value}
          />
          <LabelValueDetail
            label="Staff"
            value={entry.staff && entry.staff.value}
          />
          {entry.type === "time" && (
            <>
              <LabelValueDetail
                label="Clocked Time (Hr:Min)"
                value={`${entry.hours}:${padZero(entry.minutes, 2)}`}
                isNumber
              />

              <LabelValueDetail
                label="Billable Time (Hr:Min)"
                value={`${entry.billedHours}:${padZero(
                  entry.billedMinutes,
                  2
                )}`}
                isNumber
              />

              {entry.reason && entry.reason.trim() && (
                <Grid item xs={12} md={12} className="p175">
                  <SimpleBox>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      style={{
                        display: "block",
                        marginTop: "-16px",
                        marginBottom: "8px",
                        textDecoration: "underline",
                        textDecorationStyle: "dotted",
                        textUnderlineOffset: "5px",
                      }}
                    >
                      Reason of Difference in Clocked and Billed Time
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      display="block"
                    >
                      {entry.reason.trim()}
                    </Typography>
                  </SimpleBox>
                </Grid>
              )}
            </>
          )}
          <LabelValueDetail
            label="Date"
            value={entry.date && formatDate(entry.date, DateFormats.MEDIUM)}
          />
          <LabelValueDetail
            label="Amount ($)"
            value={formatDecimal(entry.amount)}
            isNumber
          />
          <LabelValueDetail
            label={entry.type === "time" ? "Activity Code" : "Expense Code"}
            value={getActExpCode(entry.actExpCode)}
          />
          <LabelValueDetail
            label={entry.type === "time" ? "Service Performed" : "Description"}
            value={entry.desc.trim()}
          />
        </Grid>
      </List>
    </TabPanel>
  );
};

export default QuickEntryDetail;
