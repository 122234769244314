import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
  IconButton,
  withStyles,
  useTheme,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import clsx from "clsx";
import { SettingContext } from "../DataContext";

export const DialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  closeButton: {
    margin: 0,
    padding: theme.spacing(1.25),
    color: theme.palette.text.secondary,
  },
}))((props) => {
  const {
    children,
    classes,
    onClose,
    hideCloseBtn,
    disableCloseBtn,
    ...other
  } = props;
  const { processing } = useContext(SettingContext);
  return (
    <MuiDialogTitle
      disableTypography
      className={clsx(
        classes.root,
        "d-flex justify-content-between align-items-center"
      )}
      {...other}
    >
      <Typography variant="h6" color="primary">
        {children}
      </Typography>
      {!hideCloseBtn && onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          disabled={disableCloseBtn || processing}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5),
    },
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CustomModal = ({
  isOpen,
  title,
  children,
  handleSave,
  saveBtnTitle,
  disableSaveBtn,
  handleSaveAnother,
  saveAnotherTitle,
  handleClose,
  handleCloseBtn,
  closeBtnTitle,
  disableCancelBtn,
  maxWidth,
  fullWidth,
  hideFooter,
  transitionOnAllScreen,
  fullScreen,
  TransitionComponent,
  hideCloseBtn,
  disableCloseBtn,
  noDividers,
  CustomModalHeader,
  CustomModalFooter,
  ...others
}) => {
  const theme = useTheme();
  const { processing } = useContext(SettingContext);
  const [check, setCheck] = useState(false);

  const handleCheck = () => {
    setCheck(!check);
    handleSaveAnother && handleSaveAnother(!check);
  };

  return (
    <Dialog
      TransitionComponent={
        transitionOnAllScreen || fullScreen ? TransitionComponent : undefined
      }
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={() => !processing && handleClose()}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      {...others}
    >
      {(title || !hideCloseBtn) && (
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => !processing && handleClose()}
          hideCloseBtn={hideCloseBtn}
          disableCloseBtn={disableCloseBtn}
        >
          {title}
        </DialogTitle>
      )}
      {CustomModalHeader}
      <DialogContent dividers={!noDividers}>{children}</DialogContent>
      {!hideFooter && (
        <DialogActions>
          {handleSaveAnother && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={handleCheck}
                  name="chkSaveAnother"
                  color="primary"
                />
              }
              label={saveAnotherTitle}
            />
          )}
          {handleClose && (
            <Button
              style={{ marginRight: theme.spacing(1) + "px" }}
              onClick={() => {
                if (!processing) {
                  handleCloseBtn ? handleCloseBtn() : handleClose();
                }
              }}
              color="primary"
              variant="outlined"
              disabled={disableCancelBtn || processing}
              disableElevation
            >
              {closeBtnTitle}
            </Button>
          )}
          {handleSave && (
            <Button
              style={{ marginRight: theme.spacing(2) + "px" }}
              onClick={handleSave}
              color="primary"
              variant="contained"
              disabled={disableSaveBtn || processing}
              disableElevation
            >
              {saveBtnTitle}
            </Button>
          )}
        </DialogActions>
      )}
      {CustomModalFooter}
    </Dialog>
  );
};

CustomModal.defaultProps = {
  closeBtnTitle: "Close",
  saveBtnTitle: "Save",
  fullWidth: true,
  maxWidth: "sm",
  saveAnotherTitle: "Save another",
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  closeBtnTitle: PropTypes.string.isRequired,
  saveBtnTitle: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func,
  handleSaveAnother: PropTypes.func,
};

export default CustomModal;
