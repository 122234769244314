import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import CommonAccordion from "../../../components/CommonAccordion";
import { DataContext } from "../../../DataContext";
import { InvoiceContent } from "./InvoiceContent";
import { InvoiceSummary } from "./InvoiceSummary";

export const ThisWeekAccordion = ({ setRefetch, refetch }) => {
  const { alertError } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = () => {
    setIsLoading(true);
    Axios.get(`/view-invoices/this-week`)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setData([]);
        setIsLoading(false);
        alertError("Failed to load this-week invoices");
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return (
    <CommonAccordion className="ThisWeekAccordion" title="This Week">
      <InvoiceContent
        invoiceData={data}
        isLoading={isLoading}
        setRefetch={setRefetch}
      />
      <InvoiceSummary data={data} isLoading={isLoading} />
    </CommonAccordion>
  );
};
