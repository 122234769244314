import { Skeleton } from "@material-ui/lab";
import React from "react";

export const TableSkeleton = () => {
  return (
    <div className="TableSkeleton">
      <Skeleton
        animation="wave"
        width="20%"
        height={24}
        style={{ marginBottom: "32px" }}
      />
      <Skeleton
        animation="wave"
        width="100%"
        height={24}
        style={{ marginBottom: "24px" }}
      />
      <Skeleton
        animation="wave"
        width="100%"
        height={24}
        style={{ marginBottom: "24px" }}
      />
    </div>
  );
};
