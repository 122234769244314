import {
  DashboardRounded,
  AssignmentIndRounded,
  BusinessCenterRounded,
  AccountBalanceRounded,
  PeopleRounded,
  BusinessRounded,
  AddAlarmRounded,
  AddBoxOutlined,
  ReceiptRounded,
  SettingsRounded,
  HomeRounded,
} from "@material-ui/icons";
import { RoleTypes } from "../utils/constants";

export default [
  {
    to: "/manage-companies",
    Icon: BusinessRounded,
    text: "Companies",
    allowedRoles: [RoleTypes.PORTAL_ADMIN],
  },
  {
    to: "/company-admin",
    Icon: PeopleRounded,
    text: "Company Admin",
    allowedRoles: [RoleTypes.PORTAL_ADMIN],
  },
  {
    to: "/dashboard",
    Icon: DashboardRounded,
    text: "Dashboard",
    allowedRoles: [RoleTypes.MANAGER],
  },
  {
    to: "/home",
    Icon: HomeRounded,
    text: "Home",
    allowedRoles: [RoleTypes.MANAGER, RoleTypes.STAFF, RoleTypes.LAWYER],
  },
  {
    to: "/clients",
    Icon: AssignmentIndRounded,
    text: "Clients",
    allowedRoles: [RoleTypes.MANAGER, RoleTypes.STAFF, RoleTypes.LAWYER],
  },
  {
    to: "/cases",
    Icon: BusinessCenterRounded,
    text: "Cases",
    allowedRoles: [RoleTypes.MANAGER, RoleTypes.STAFF, RoleTypes.LAWYER],
  },
  {
    to: "/quick-time-entries",
    Icon: AddAlarmRounded,
    text: "Time Entries",
    allowedRoles: [RoleTypes.MANAGER, RoleTypes.STAFF, RoleTypes.LAWYER],
  },
  {
    to: "/quick-expense-entries",
    Icon: AddBoxOutlined,
    text: "Expense Entries",
    allowedRoles: [RoleTypes.MANAGER, RoleTypes.STAFF, RoleTypes.LAWYER],
  },
  {
    to: "/all-quick-time-entries",
    Icon: AddAlarmRounded,
    text: "Time Entries (All)",
    allowedRoles: [RoleTypes.MANAGER, RoleTypes.STAFF],
  },
  {
    to: "/all-quick-expense-entries",
    Icon: AddBoxOutlined,
    text: "Expense Entries (All)",
    allowedRoles: [RoleTypes.MANAGER, RoleTypes.STAFF],
  },
  {
    to: "/all-invoices",
    Icon: ReceiptRounded,
    text: "Invoices",
    allowedRoles: [RoleTypes.MANAGER],
  },
  {
    to: "/staff",
    Icon: PeopleRounded,
    text: "Staff",
    allowedRoles: [RoleTypes.MANAGER, RoleTypes.COMPANY_ADMIN],
  },
  {
    to: "/setting",
    Icon: SettingsRounded,
    text: "Setting",
    allowedRoles: [RoleTypes.COMPANY_ADMIN],
  },
  // {
  //   to: "/accounts",
  //   Icon: AccountBalanceRounded,
  //   text: "Client Accounts",
  //   allowedRoles: [RoleTypes.MANAGER],
  // },
  // {
  //   to: "/office-account",
  //   Icon: AccountBalanceRounded,
  //   text: "Office Account",
  //   allowedRoles: [RoleTypes.MANAGER],
  // },
  {
    to: "/debit-credit",
    Icon: AccountBalanceRounded,
    text: "Debit/Credit Notes",
    allowedRoles: [RoleTypes.MANAGER],
  },
  /*
  {
    to: "/accounts",
    Icon: AccountBalanceRounded,
    text: "Accounts"
  },
  {
    to: "/ledger",
    Icon: AccountBalanceWalletRounded,
    text: "Ledger"
  },
  {
    to: "/calendar",
    Icon: EventRounded,
    text: "Calendar"
  },
  {
    to: "/task",
    Icon: BallotRounded,
    text: "Task"
  },
  {
    to: "/vendor",
    Icon: AlternateEmailRounded,
    text: "Vendor"
  },
  {
    to: "/reports-tools",
    Icon: DescriptionRounded,
    text: "Reports/Tools"
  },
  {
    to: "/apps",
    Icon: AppsRounded,
    text: "Apps"
  },
  {
    to: "/settings",
    Icon: SettingsRounded,
    text: "Settings"
  }*/
];
