import { createTheme, colors } from "@material-ui/core";
import { defaultFontFamily } from "./font";
import { ColorValues } from "./colors";

export const recreateMuiTheme = (mode = "light", color = ColorValues.CYAN) =>
  createTheme({
    typography: {
      fontFamily: defaultFontFamily,
    },
    palette: {
      type: mode,
      primary: colors[color],
    },
    overrides: {
      MuiFormHelperText: {
        root: {
          opacity: 0.6,
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: "#000",
          color: "#fafafa",
        },
        arrow: {
          color: "#000",
        },
      },
    },
  });
