import React, { useContext, useState } from "react";
import { Grid, List, useMediaQuery, useTheme } from "@material-ui/core";
import PopupMenuButton from "../../components/PopupMenuButton";
import CustomModal from "../../components/CustomModal";
import SlideUp from "../../components/SlideUp";
import {
  AddRounded,
  CloudUploadRounded,
  LaunchRounded,
} from "@material-ui/icons";
import Axios from "axios";
import fileDownload from "js-file-download";
import { getDefaultTimeExpObj } from "../../model";
import { DataContext, PopupContext, SettingContext } from "../../DataContext";
import SimplePage from "../SimplePage";
import { getError } from "../../utils/functions";
import CommonAccordion from "../../components/CommonAccordion";
import DisplayTimeEntry from "../../components/DisplayTimeEntry";
import DisplayTimeEntryThisWeek from "../../components/DisplayTimeEntryThisWeek";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { formatDateDash } from "../../utils/dateUtils";

const helperTextStyle = { style: { position: "absolute", bottom: "-22px" } };

const TimeEntryPage = ({ location }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { currentUser, alertError, alertSuccess, staffList } =
    useContext(DataContext);
  const { setOpenQte, setSelectedTimeExp } = useContext(PopupContext);
  const { setProcessing } = useContext(SettingContext);
  const [errMsg, setErrMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [toggleValue, setToggleValue] = useState(false);

  const [dateRange, setDateRange] = useState([null, null]);
  const [isExportBoxOpen, setIsExportBoxOpen] = useState(false);

  const all = location.pathname === "/all-quick-time-entries";

  const uploadQteCsvFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", file);

      setProcessing(true);
      Axios.post("/quick-entries/upload_qe_csv?type=time", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          setProcessing(false);
          alertSuccess("Successfully uploaded csv file.");
          setToggleValue(!toggleValue);
        })
        .catch((err) => {
          setProcessing(false);
          if (err.response) {
            if (err.response.status === 400) {
              let msg = "";
              for (const [key, value] of Object.entries(err.response.data)) {
                msg =
                  msg +
                  `row ${key} error stacks: \n \t ${value.map((v, index) =>
                    index > 0 ? `\t ${v} \n` : `${v} \n`
                  )} \n`;
              }
              setIsOpen(true);
              setErrMsg(msg);
            } else {
              alertError(
                err.response.data.message ||
                  err.response.data.error ||
                  "Error! Failed to upload csv file."
              );
            }
          } else {
            alertError(err.message);
          }
        });
    }
  };

  const exportQteCsvFile = () => {
    let link;
    let fileName;
    if (all) {
      link = "/quick-entries/export-quick-entries-for-all-staffs?type=time";
      fileName = "weekly-time-entries-summary-for-all-lawyers.csv";
    } else {
      link = "/quick-entries/export_qe_csv?type=time";
      fileName = "weekly-time-entries-summary.csv";
    }
    setProcessing(true);
    Axios.get(link, { responseType: "arraybuffer" })
      .then((res) => {
        setProcessing(false);
        fileDownload(res.data, fileName, "text/csv");
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, "Unable to generate weekly report"));
      });
  };

  const goExportQteToCsvWithDateRange = () => {
    const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
    if (
      dateRange &&
      dateRange.length > 0 &&
      dateRange[0] &&
      dateRange[1] &&
      regex.test(dateRange[0]) &&
      regex.test(dateRange[1])
    ) {
      let link = `/quick-entries/export-daily-quick-entries?fromDate=${dateRange[0]}&toDate=${dateRange[1]}`;
      let fileName;
      if (all) {
        fileName = "daily-time-entries-summary-for-all-lawyers.csv";
      } else {
        link = link + "&personSid=" + currentUser.personSid;
        fileName = "daily-time-entries-summary.csv";
      }
      setProcessing(true);
      Axios.get(link, { responseType: "arraybuffer" })
        .then((res) => {
          setProcessing(false);
          fileDownload(res.data, fileName, "text/csv");
        })
        .catch((err) => {
          setProcessing(false);
          alertError(getError(err, "Unable to generate daily report"));
        });
    } else {
      alertError("Invalid date range");
    }
  };

  const menuItems = [
    {
      text: "New Time Entry",
      icon: <AddRounded fontSize="small" />,
      handler: () => {
        let rate = currentUser.rate;
        if (!rate) {
          const stf = staffList.find((el) => el.id === currentUser.personSid);
          if (stf) {
            rate = stf.rate ? stf.rate : 0;
          }
        }
        const qteObj = {
          ...getDefaultTimeExpObj(),
          staff: {
            id: currentUser.personSid,
            value: `${currentUser.firstName} ${currentUser.lastName}`,
            active: true,
            rate,
          },
          rate,
        };
        setSelectedTimeExp(qteObj);
        setOpenQte(true);
      },
    },
    {
      text: (
        <>
          <input
            style={{ display: "none" }}
            type="file"
            id="csvFile"
            onChange={uploadQteCsvFile}
            accept=".csv, text/csv"
          />
          <label htmlFor="csvFile">Import from CSV</label>
        </>
      ),
      icon: <CloudUploadRounded fontSize="small" />,
    },
    // {
    //   text: "Import from Excel",
    //   icon: <CloudUploadRounded fontSize="small" />,
    //   disabled: true,
    // },
    {
      text: "Export This Week Data",
      icon: <LaunchRounded fontSize="small" />,
      handler: exportQteCsvFile,
    },
    {
      text: "Export Daily Data",
      icon: <LaunchRounded fontSize="small" />,
      handler: () => setIsExportBoxOpen(true),
    },
    // {
    //   text: "Export As",
    //   icon: <LaunchRounded fontSize="small" />,
    //   disabled: true,
    // },
    // {
    //   text: "Archive",
    //   icon: <ArchiveRounded fontSize="small" />,
    //   disabled: true,
    //   // handler: confirmArchive,
    // },
    // {
    //   text: "Delete",
    //   icon: <DeleteRounded fontSize="small" />,
    //   disabled: true,
    //   // handler: confirmDelete,
    // },
  ];

  return (
    <>
      <SimplePage
        className="TimeEntryPage"
        pageTitle={"Quick Time Entries" + (all ? " for All Lawyers" : "")}
        Buttons={
          <Grid item>
            <PopupMenuButton
              size="small"
              color="default"
              isIconButton
              menuItems={menuItems}
            />
          </Grid>
        }
      >
        <List key={toggleValue}>
          <CommonAccordion title="This Week">
            <DisplayTimeEntryThisWeek all={all} />
          </CommonAccordion>
          <CommonAccordion title="All Time">
            <DisplayTimeEntry
              selectedStaffSid={!all && currentUser.personSid}
              hideStaffCol={!all}
              hideStaffFilter={!all}
            />
          </CommonAccordion>
        </List>
      </SimplePage>
      <CustomModal
        maxWidth="sm"
        isOpen={isExportBoxOpen}
        title="Export Daily Time Entries"
        saveBtnTitle="Export"
        handleSave={goExportQteToCsvWithDateRange}
        disableSaveBtn={
          !dateRange || dateRange.length === 0 || !dateRange[0] || !dateRange[1]
        }
        closeBtnTitle="Cancel"
        handleClose={() => setIsExportBoxOpen(false)}
        hideCloseBtn
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid
            container
            spacing={3}
            style={{
              marginBottom: theme.spacing(1.5),
              alignItems: "flex-end",
            }}
          >
            <Grid item xs={6}>
              <KeyboardDatePicker
                fullWidth
                margin="none"
                placeholder="MM/dd/yyyy"
                format="dd-MMM-yyyy"
                label="From Date"
                value={dateRange[0] ? new Date(dateRange[0]) : null}
                onChange={(d) =>
                  setDateRange([formatDateDash(d), dateRange[1]])
                }
                FormHelperTextProps={helperTextStyle}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                fullWidth
                margin="none"
                placeholder="MM/dd/yyyy"
                format="dd-MMM-yyyy"
                label="To Date"
                value={dateRange[1] ? new Date(dateRange[1]) : null}
                onChange={(d) =>
                  setDateRange([dateRange[0], formatDateDash(d)])
                }
                FormHelperTextProps={helperTextStyle}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </CustomModal>
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="md"
        isOpen={isOpen}
        title="Errors"
        closeBtnTitle="OK"
        handleClose={() => setIsOpen(false)}
      >
        <pre style={{ fontSize: 15 }}>{errMsg}</pre>
      </CustomModal>
    </>
  );
};

export default TimeEntryPage;
