import React from "react";
import SimpleBox from "../../components/SimpleBox";
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Divider,
  // FormHelperText,
} from "@material-ui/core";
import { DateFormats } from "../../utils/constants";
import { formatDecimal, padZero } from "../../utils/functions";
import { formatDate } from "../../utils/dateUtils";
import InvoiceHeaderSection from "./InvoiceHeaderSection";
import SubInvoiceSection from "./SubInvoiceSection";
import { useQuickEntryTableStyles } from "../../theme/quickEntryTableStyles";

const useStyles = makeStyles((theme) => ({
  p175: {
    padding: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.75),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  p125: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

const InvoiceDetail = ({
  invoice,
  selectedCase,
  client,
  selectedSubInv = null,
}) => {
  const classes = useStyles();
  const tblCls = useQuickEntryTableStyles();

  return (
    <SimpleBox className="InvoiceDetail" title="">
      <InvoiceHeaderSection
        client={client}
        selectedCase={selectedCase}
        invoice={invoice}
        subInvoice={selectedSubInv}
      />
      <Divider style={{ margin: "16px 0 12px 0" }} />
      {selectedSubInv && (
        <>
          <SubInvoiceSection
            subInvoice={selectedSubInv}
            mainInvAmt={invoice.totalAmount}
          />
          <Divider style={{ margin: "16px 0 12px 0" }} />
        </>
      )}
      {invoice && (
        <>
          <Grid container>
            {invoice.timeExpenseEntry.length !== 0 && (
              <Grid item xs={12} md={12} className={classes.p175}>
                <TableContainer component={Paper}>
                  <Typography
                    className={classes.p175}
                    variant="body1"
                    color="textSecondary"
                    gutterBottom
                  >
                    Selected Time/Expense Entries
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="body2" color="primary">
                            Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color="primary">
                            Description
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color="primary">
                            Staff
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="primary" noWrap>
                            Billable Time
                            <br />
                            (Hr:Min)
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="primary" noWrap>
                            Discount
                            <br />
                            (%)
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="primary" noWrap>
                            Discount
                            <br />
                            ($)
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="primary" noWrap>
                            Amount
                            <br />
                            ($)
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="primary" noWrap>
                            Net Amount
                            <br />
                            ($)
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={tblCls.tableBody}>
                      {invoice.timeExpenseEntry
                        .sort((a, b) => a.date.localeCompare(b.date))
                        .map((timeExp, i) => (
                          <TableRow key={timeExp.id}>
                            <TableCell className="noWrap">
                              {formatDate(timeExp?.date, DateFormats.MEDIUM)}
                            </TableCell>
                            <TableCell>{timeExp.desc}</TableCell>
                            <TableCell className="noWrap">
                              {timeExp?.staff?.value}
                            </TableCell>
                            <TableCell align="right" className="e360-number">
                              {timeExp.type === "time"
                                ? `${timeExp.billedHours}:${padZero(
                                    timeExp.billedMinutes,
                                    2
                                  )}`
                                : "(Expense)"}
                            </TableCell>
                            <TableCell align="right" className="e360-number">
                              {timeExp && timeExp.discountPercentage
                                ? formatDecimal(timeExp.discountPercentage)
                                : 0}
                            </TableCell>
                            <TableCell align="right" className="e360-number">
                              {timeExp && timeExp.discountAmount
                                ? formatDecimal(timeExp.discountAmount)
                                : 0}
                            </TableCell>
                            <TableCell align="right" className="e360-number">
                              {timeExp && timeExp.amount
                                ? formatDecimal(timeExp.amount)
                                : 0}
                            </TableCell>
                            <TableCell align="right" className="e360-number">
                              {formatDecimal(timeExp.netAmount)}
                            </TableCell>
                          </TableRow>
                        ))}
                      <TableRow key={"xxx"}>
                        <TableCell colSpan={4} />
                        <TableCell align="right" style={{ fontWeight: 600 }}>
                          Total :
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            color="primary"
                            align="right"
                            className="e360-number"
                            style={{ fontWeight: 600 }}
                          >
                            {formatDecimal(invoice.discountAmount)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            color="primary"
                            align="right"
                            className="e360-number"
                            style={{ fontWeight: 600 }}
                          >
                            {formatDecimal(invoice.amountBeforeTaxAndDiscount)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            color="primary"
                            align="right"
                            className="e360-number"
                            style={{ fontWeight: 600 }}
                          >
                            {formatDecimal(
                              invoice.timeExpenseEntry.reduce(
                                (sum, el) => sum + +el.netAmount,
                                0.0
                              )
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent="flex-end" wrap="wrap-reverse">
            {/* <Grid
              container
              item
              xs={12}
              md={6}
              className={classes.p175}
              justify="flex-end"
              direction="column"
              style={{ paddingBottom: 0 }}
            >
              <Grid
                item
                xs={12}
                style={{ flexGrow: "initial", flexBasis: "initial" }}
              >
                <FormHelperText style={{ marginTop: 0, marginBottom: "-8px" }}>
                  {"* Tax applies to "}
                  <span style={{ fontStyle: "italic" }}>Professional Fees</span>
                  {" only"}
                </FormHelperText>
              </Grid>
            </Grid> */}
            <Grid
              item
              xs={12}
              md={6}
              className={classes.p175}
              style={{ paddingBottom: 0 }}
            >
              {/* <Grid container>
            <Grid item xs={7} className={classes.p125}>
              <Typography
                variant="body2"
                color="textSecondary"
                align="right"
                style={{ fontWeight: 600 }}
                noWrap
              >
                Discount (%) :
              </Typography>
            </Grid>
            <Grid item xs={5} className={classes.p125}>
              <Typography
                variant="body2"
                color="primary"
                align="right"
                className="e360-number"
                style={{ fontWeight: 600 }}
                noWrap
              >
                {formatDecimal(invoice.discountPercentage)}
              </Typography>
            </Grid>
          </Grid> */}
              <Grid container>
                <Grid item xs={7} className={classes.p125}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    Discount Amount ($) :
                  </Typography>
                </Grid>
                <Grid item xs={5} className={classes.p125}>
                  <Typography
                    variant="body2"
                    color="primary"
                    align="right"
                    className="e360-number"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    {formatDecimal(invoice.discountAmount)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7} className={classes.p125}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    Tax (%) :
                  </Typography>
                </Grid>
                <Grid item xs={5} className={classes.p125}>
                  <Typography
                    variant="body2"
                    color="primary"
                    align="right"
                    className="e360-number"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    {formatDecimal(invoice.taxPercentage)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7} className={classes.p125}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    Tax Amount ($) :
                  </Typography>
                </Grid>
                <Grid item xs={5} className={classes.p125}>
                  <Typography
                    variant="body2"
                    color="primary"
                    align="right"
                    className="e360-number"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    {formatDecimal(invoice.taxAmount)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={{ margin: "20px 0 12px 0" }} />
              <Grid container>
                <Grid item xs={7} className={classes.p175}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    Total Amount ($) :
                  </Typography>
                </Grid>
                <Grid item xs={5} className={classes.p175}>
                  <Typography
                    variant="body1"
                    color="primary"
                    align="right"
                    className="e360-number"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    {formatDecimal(invoice.totalAmount)}
                  </Typography>
                </Grid>
              </Grid>
              {selectedSubInv && (
                <>
                  <Grid container>
                    <Grid item xs={7} className={classes.p125}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="right"
                        style={{ fontWeight: 600 }}
                        noWrap
                      >
                        Split Percentage (%) :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.p125}>
                      <Typography
                        variant="body2"
                        color="primary"
                        align="right"
                        className="e360-number"
                        style={{ fontWeight: 600 }}
                        noWrap
                      >
                        {formatDecimal(selectedSubInv.percentage)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider style={{ margin: "20px 0 12px 0" }} />
                  <Grid container>
                    <Grid item xs={7} className={classes.p175}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="right"
                        style={{ fontWeight: 600 }}
                        noWrap
                      >
                        Split Amount ($) :
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.p175}>
                      <Typography
                        variant="body1"
                        color="primary"
                        align="right"
                        className="e360-number"
                        style={{ fontWeight: 600 }}
                        noWrap
                      >
                        {formatDecimal(
                          (+invoice.totalAmount * +selectedSubInv.percentage) /
                            100
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </SimpleBox>
  );
};

export default InvoiceDetail;
