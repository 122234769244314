import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DataContext } from "../DataContext";
import handleRefreshToken from "../utils/handleRefreshToken";

const Redirect401 = (props) => {
  const history = useHistory();
  const { alertError } = useContext(DataContext);

  useEffect(() => {
    handleRefreshToken(history, alertError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRefreshToken]);
  return null;
};

export default Redirect401;
