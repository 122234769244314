import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { DateFormats } from "../../utils/constants";
import {
  AssignmentIndRounded,
  BusinessCenterRounded,
  DescriptionRounded,
  HomeRounded,
  TodayRounded,
} from "@material-ui/icons";
import { formatDate } from "../../utils/dateUtils";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  p175: {
    padding: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.75),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
}));

const InvoiceHeaderSection = ({
  className,
  invoice,
  selectedCase,
  client,
  subInvoice,
}) => {
  const classes = useStyles();
  return (
    <>
      {invoice && (
        <Grid container className={clsx("InvoiceHeaderSection", className)}>
          <Grid item xs={12} md={6} className={classes.p175}>
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap
              className="segLabel"
            >
              Invoice Detail
            </Typography>
            <div className="d-flex align-items-center py-5">
              <DescriptionRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />

              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
                style={{ marginRight: "16px" }}
              >
                Invoice No. :
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                noWrap
                style={{ fontWeight: 600, letterSpacing: "1px" }}
              >
                {subInvoice
                  ? subInvoice.subInvoiceNumber
                  : invoice.id
                  ? invoice.invoiceNum === "-"
                    ? "(Auto)"
                    : invoice.invoiceNum
                  : invoice.isRefManual
                  ? invoice.invoiceNum
                    ? "INV" + invoice.invoiceNum
                    : "TBA"
                  : "(Auto)"}
              </Typography>
            </div>
            <div className="d-flex align-items-center py-5">
              <TodayRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />

              <Typography variant="body2" color="textSecondary" noWrap>
                {formatDate(invoice.date, DateFormats.LONG)}
              </Typography>
            </div>
            <div className="d-flex align-items-center py-5">
              <BusinessCenterRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
                style={{ marginRight: "16px" }}
              >
                Case Ref#
              </Typography>
              <Typography variant="body2" color="secondary" noWrap>
                {selectedCase.caseRef}
              </Typography>
            </div>
            <div className="d-flex align-items-center py-5">
              <BusinessCenterRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
                style={{ marginRight: "16px" }}
              >
                Case Name :
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {selectedCase.name}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.p175}>
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap
              className="segLabel"
            >
              Invoice To Client
            </Typography>
            <div className="d-flex align-items-center py-5">
              <AssignmentIndRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />

              <Typography
                variant="body2"
                color="primary"
                noWrap
                style={{ fontWeight: 600 }}
              >
                {selectedCase &&
                  selectedCase.client &&
                  selectedCase.client.value}
              </Typography>
            </div>
            {client && (
              <>
                <div className="d-flex align-items-center py-5">
                  <AssignmentIndRounded
                    fontSize="small"
                    className={classes.textSecondary}
                    style={{ marginRight: "10px" }}
                  />
                  <Typography variant="body2" color="secondary" noWrap>
                    {client.clientId}
                  </Typography>
                </div>
                <div className="d-flex py-5">
                  <HomeRounded
                    fontSize="small"
                    className={classes.textSecondary}
                    style={{ marginRight: "10px" }}
                  />
                  <div>
                    <Typography variant="body2" color="textSecondary">
                      {client.billingAddressOne}
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                      {client.billingCity +
                        ", " +
                        client.billingState +
                        ", " +
                        client.billingCountry?.value.split("-")[1]}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {client.billingPostalCode}
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default InvoiceHeaderSection;
