export const getDefaultCompanyObj = () => ({
  createdBy: "",
  createdAt: "",
  updatedBy: "",
  updatedAt: "",
  companySid: "",
  companyId: null,
  companyName: "",
  taxRegistered: "",
  businessRegistrationNumber: "",
  themeName: "",
  profilePicture: "",
  cpcEntries: [],
  version: 0,
  active: true,
});
