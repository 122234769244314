import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  HistoryRounded,
  CreateRounded,
  DeleteRounded,
} from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PopupMenuButton from "../../components/PopupMenuButton";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function TransactionTable({ transactions }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Invoice Number</TableCell>
            <TableCell>Operator</TableCell>
            <TableCell>Operation</TableCell>
            <TableCell align="right">Amount($)</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.length &&
            transactions.map((row) => (
              <TableRow key={row.name}>
                <TableCell align="center">
                  {row.invoiceNumber && row.invoiceNumber.length !== 0
                    ? row.invoiceNumber
                    : "-"}
                </TableCell>
                <TableCell>{row.createdBy}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="right">
                  <PopupMenuButton
                    size="small"
                    color="default"
                    isIconButton
                    menuItems={[
                      {
                        text: "View Audit History",
                        icon: <HistoryRounded fontSize="small" />,
                        // handler: () => m && openAuditModal(m),
                      },
                      {
                        text: "Edit",
                        icon: <CreateRounded fontSize="small" />,
                        // handler: () => {
                        //   setIsFormOpen(true);
                        //   setMasterCodeType(mapMasterCodeType(m));
                        // },
                      },
                      {
                        text: "Delete",
                        icon: <DeleteRounded fontSize="small" />,
                        // handler: () => {
                        //   confirmDelete(mapMasterCodeType(m));
                        // },
                        // disabled: m.used,
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
