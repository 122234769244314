import { Grid, makeStyles, TablePagination } from "@material-ui/core";
import React from "react";
import TablePaginationSkeleton from "./TablePaginationSkeleton";

const useStyles = makeStyles((theme) => ({
  caption: {
    color: theme.palette.text.secondary,
  },
  selectRoot: {
    color: theme.palette.primary.main,
  },
  select: {
    color: theme.palette.primary.main,
  },
  selectIcon: {
    color: theme.palette.text.secondary,
  },
}));

const CustomTablePagination = ({
  loading,
  totalElements,
  size,
  page,
  handleChangePage,
  handleChangeSize,
}) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="flex-end">
      <Grid item>
        {loading ? (
          <TablePaginationSkeleton />
        ) : (
          <TablePagination
            classes={classes}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
            component="div"
            count={totalElements || 0}
            rowsPerPage={size}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeSize}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CustomTablePagination;
