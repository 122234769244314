import React from "react";
import { Switch, withTheme } from "@material-ui/core";

const ModeSwitch = ({ theme, setMode, ...others }) => {
  const noClose = e => e.stopPropagation();
  const toggleMode = () => {
    const mode = theme.palette.type === "dark" ? "light" : "dark";
    setMode(mode);
    localStorage.setItem("mode", mode);
  };
  return (
    <Switch
      className="ModeSwitch"
      size="small"
      onClick={noClose}
      onChange={toggleMode}
      checked={theme.palette.type === "dark"}
    />
  );
};

export default withTheme(ModeSwitch);
