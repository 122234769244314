import React from "react";
import { Divider, Grid, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  pad: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  skeleton: {
    height: "16px",
    float: "right",
    margin: "12px 0 12px auto",
  },
}));

const LoadingSummary = ({ className }) => {
  const classes = useStyles();

  return (
    <Grid
      className={clsx("LoadingSummary", className)}
      container
      justifyContent="flex-end"
    >
      <Grid item xs={12} md={6} className={classes.pad}>
        <Divider />
        <Grid container alignItems="center" style={{ marginTop: "14px" }}>
          <Grid item xs={7} lg={8}>
            <Skeleton
              animation="wave"
              className={classes.skeleton}
              width="30%"
            />
          </Grid>
          <Grid item xs={5} lg={4}>
            <Skeleton
              animation="wave"
              className={classes.skeleton}
              width="40%"
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={7} lg={8}>
            <Skeleton
              animation="wave"
              className={classes.skeleton}
              width="60%"
            />
          </Grid>
          <Grid item xs={5} lg={4}>
            <Skeleton
              animation="wave"
              className={classes.skeleton}
              width="40%"
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={7} lg={8}>
            <Skeleton
              animation="wave"
              className={classes.skeleton}
              width="45%"
            />
          </Grid>
          <Grid item xs={5} lg={4}>
            <Skeleton
              animation="wave"
              className={classes.skeleton}
              width="25%"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoadingSummary;
