const QTE_LIST_KEY = "qte-list";

const saveQteList = (list) => {
  localStorage.setItem(QTE_LIST_KEY, JSON.stringify(list));
};

const retrieveQteList = () => {
  const str = localStorage.getItem(QTE_LIST_KEY);
  const list = JSON.parse(str);
  if (Array.isArray(list)) return list;
  return [];
};

const deleteQteList = () => {
  localStorage.removeItem(QTE_LIST_KEY);
};

export { saveQteList, retrieveQteList, deleteQteList };
