import React, { useState, useContext } from "react";
import { Grid } from "@material-ui/core";
import PageSidebar from "../../components/PageSidebar";
import PopupMenuButton from "../../components/PopupMenuButton";
import CommonPage from "../CommonPage";

import { DataContext } from "../../DataContext";
import { Modules } from "../../utils/constants";
import TransactionTable from "./TransactionTable";
import CreditNoteTable from "./CreditNoteTable";
import OfficeAccountSummary from "./OfficeAccountSummary";

function createCreditNoteData(client, caseInfo, description, amount) {
  return { client, caseInfo, description, amount };
}

const creditNotes = [
  createCreditNoteData(
    "102",
    "C102",
    "Some Long or Short Description here...",
    200
  ),
  createCreditNoteData(
    "102",
    "C102",
    "Some Long or Short Description here...",
    100
  ),
  createCreditNoteData(
    "102",
    "C102",
    "Some Long or Short Description here...",
    800
  ),
  createCreditNoteData(
    "102",
    "C102",
    "Some Long or Short Description here...",
    900
  ),
  createCreditNoteData(
    "103",
    "C103",
    "Some Long or Short Description here...",
    200
  ),
  createCreditNoteData(
    "103",
    "C103",
    "Some Long or Short Description here...",
    100
  ),
  createCreditNoteData(
    "103",
    "C103",
    "Some Long or Short Description here...",
    800
  ),
  createCreditNoteData(
    "103",
    "C103",
    "Some Long or Short Description here...",
    900
  ),
  createCreditNoteData(
    "101",
    "C104",
    "Some Long or Short Description here...",
    200
  ),
  createCreditNoteData(
    "101",
    "C104",
    "Some Long or Short Description here...",
    100
  ),
  createCreditNoteData(
    "101",
    "C104",
    "Some Long or Short Description here...",
    800
  ),
  createCreditNoteData(
    "101",
    "C104",
    "Some Long or Short Description here...",
    900
  ),
  createCreditNoteData(
    "102",
    "C105",
    "Some Long or Short Description here...",
    200
  ),
  createCreditNoteData(
    "102",
    "C105",
    "Some Long or Short Description here...",
    100
  ),
  createCreditNoteData(
    "102",
    "C105",
    "Some Long or Short Description here...",
    800
  ),
  createCreditNoteData(
    "101",
    "C101",
    "Some Long or Short Description here...",
    900
  ),
];

const OfficeAccountPage = () => {
  const { transactionList, debitCreditList } = useContext(DataContext);
  const [loading] = useState(false);

  const menuItems = [
    // {
    // 	text: "Deposit Money",
    // 	icon: <Money fontSize="small" />,
    // 	// handler: () => {
    // 	//   setIsFormOpen(true);
    // 	//   setMasterCodeType(mapMasterCodeType(m));
    // 	// },
    //   },
    //   {
    // 	text: "Withdraw Money",
    // 	icon: <AccountBalanceWallet fontSize="small" />,
    // 	// handler: () => {
    // 	//   confirmDelete(mapMasterCodeType(m));
    // 	// },
    // 	// disabled: m.used,
    //   },
  ];

  const sideMenuList = [
    {
      title: "Office Account Summary",
      type: "detail",
    },
    {
      title: "Transactions",
      type: "transaction",
    },
    {
      title: "Credit Notes",
      type: "credit",
    },
  ];
  const [sideMenu, setSideMenu] = useState(sideMenuList[0]);

  return (
    <>
      <CommonPage
        className="OfficeAccountPage"
        setSelectedData={setSideMenu}
        detailTitle={sideMenu.title}
        PageSidebar={
          <PageSidebar
            loading={loading}
            // searchTerm={searchTerm}
            // setSearchTerm={setSearchTermAndGotToFirstPage}
            // sortFields={SortColumns.ACCOUNT}
            // setSorts={setSorts}
            // searchInputPlaceholder="Search Client"
            listName="Office Account"
            listData={sideMenuList.map((l) => ({
              active: true,
              primary: `${l.title}`,
              selected: sideMenu && l.title === sideMenu.title,
              onClick: () =>
                setSideMenu(sideMenuList.find((s) => s.title === l.title)),
            }))}
            // addClicked={openSaveModal}
            module={Modules.OFFICE_ACCOUNT}
          />
        }
        Buttons={
          <Grid item>
            <PopupMenuButton
              size="small"
              color="default"
              isIconButton
              menuItems={menuItems}
            />
          </Grid>
        }
      >
        {sideMenu.type === "transaction" && (
          <TransactionTable transactions={transactionList} />
        )}
        {sideMenu.type === "credit" && (
          <CreditNoteTable
            // creditNotes={creditNotes}
            list={debitCreditList.filter((dc) => dc.type === "credit")}
          />
        )}
        {sideMenu.type === "detail" && (
          <OfficeAccountSummary
            transactions={transactionList}
            creditNotes={creditNotes}
          />
        )}
      </CommonPage>
    </>
  );
};

export default OfficeAccountPage;
