import { Grid, Typography } from "@material-ui/core";
import {
  AccountBalanceWallet,
  AddAlarmRounded,
  AddBoxOutlined,
  AddRounded,
  ArchiveRounded,
  Close,
  CreateRounded,
  DeleteRounded,
  HistoryRounded,
} from "@material-ui/icons";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import PageSidebar from "../../components/PageSidebar";
import PopupMenuButton from "../../components/PopupMenuButton";
import TabPane from "../../components/TabPane";
import { DataContext, PopupContext, SettingContext } from "../../DataContext";
import {
  getDefaultCaseObj,
  getDefaultDebitCreditObj,
  getDefaultTimeExpObj,
} from "../../model";
import {
  DateFormats,
  Modules,
  RoleTypes,
  SortColumns,
} from "../../utils/constants";
import { formatDate } from "../../utils/dateUtils";
import { getError } from "../../utils/functions";
import CommonPage from "../CommonPage";
import DebitCreditModal from "./DebitCreditModal";
import {
  AccountTabPanel,
  ChatPanel,
  DetailsTabPanel,
  ExpenseTabPanel,
  InvoicesTabPanel,
  TimeTabPanel,
} from "./panels";

const CasesPage = () => {
  const [openDeposit, setOpenDeposit] = useState(false);
  const [debitCreditType, setDebitCreditType] = useState("debit");
  const {
    alertSuccess,
    alertWarning,
    alertError,
    staffList,
    fetchClientList,
    fetchStaffList,
    fetchCaseList,
    currentUser,
    transactionList,
    debitCreditList,
    ...others
  } = useContext(DataContext);
  const {
    setOpenQte,
    setOpenQee,
    setSelectedTimeExp,
    openDialog,
    setAuditOptions,
    setOpenCase,
  } = useContext(PopupContext);
  const { refetchCase, caseSetting, setCaseSetting, setProcessing } =
    useContext(SettingContext);
  const [caseList, setCaseList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [tabIndex, setTabIndex] = useState(null);

  const [selectedCase, setSelectedCase] = useState(null);
  const [clientInfo, setClientInfo] = useState(null);
  const [sorts, setSorts] = useState([]);
  const [linesOfDebitCredit, setLinesOfDebitCredit] = useState([]);
  const [debitCredit, setDebitCredit] = useState(getDefaultDebitCreditObj());

  const fetchClientInfo = (clientInfoSid) => {
    Axios.get("/clients/" + clientInfoSid)
      .then((res) => setClientInfo(res.data))
      .catch(() => setClientInfo(null));
  };

  const fetchSecretariesInCharge = (caseSid) => {
    Axios.get("/cases/" + caseSid + "/secretaries-in-charge")
      .then((res) => {
        const ind = caseList.findIndex((el) => el.caseSid === caseSid);
        const newList = [...caseList];
        const selCase = { ...newList[ind], secretariesInCharge: res.data };
        newList.splice(ind, 1, selCase);
        setSelectedCase(selCase);
      })
      .catch(() => alertWarning("Failed to load secretaries in charge data"));
  };

  const fetchCaseListWithPagination = (
    page = caseSetting.currentPage,
    size = caseSetting.pageSize,
    showArchivedData = caseSetting.showArchivedData,
    q = searchTerm,
    sort = sorts
  ) => {
    setLoading(true);
    Axios.get(
      `/cases/paginate?page=${page}&size=${size}&showArchivedData=${showArchivedData}${
        q ? "&q=" + q : ""
      }${
        sort.length > 0
          ? "&sort=" + sort.map((s) => s.field + "-" + s.order).join(",")
          : ""
      }`
    )
      .then((res) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setCaseList(res.data.content);
          setCaseSetting((prev) => ({
            ...prev,
            totalPages: res.data.totalPages,
            currentPage: page,
          }));
          setLoading(false);
        });
      })
      .catch((err) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setCaseList([]);
          setCaseSetting((prev) => ({ ...prev, totalPages: 0 }));
          setLoading(false);
        });
      });
  };

  const openAuditModal = (caseSid) =>
    setAuditOptions({
      open: true,
      moduleType: Modules.CASE,
      sid: caseSid,
    });

  useEffect(() => {
    if (!loading && selectedCase) {
      if (caseList.length > 0) {
        const newSelCase = caseList.find(
          (el) => el.caseSid === selectedCase.caseSid
        );
        newSelCase && setSelectedCase(newSelCase);
        newSelCase &&
          newSelCase.clientInfoSid &&
          fetchClientInfo(newSelCase.clientInfoSid);
      } else {
        setClientInfo(null);
        setSelectedCase(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    fetchCaseListWithPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    caseSetting.currentPage,
    caseSetting.pageSize,
    caseSetting.showArchivedData,
    searchTerm,
    sorts,
    refetchCase,
  ]);

  const setSearchTermAndGotToFirstPage = (term) => {
    setSearchTerm(term);
    setCaseSetting((prev) => ({ ...prev, currentPage: 1 }));
  };

  const openSaveModal = () => {
    others.setCaseTemp(getDefaultCaseObj());
    setOpenCase(true);
  };

  const openCreateDebitCreditModal = (debitCredit) => {
    if (debitCredit && debitCredit.id) {
      setDebitCredit(debitCredit);
      setLinesOfDebitCredit(debitCredit.debitCreditLines);
    } else {
      setDebitCredit(getDefaultDebitCreditObj);
      setDebitCredit({ ...debitCreditType });
      setLinesOfDebitCredit([]);
    }
    setOpenDeposit(true);
  };

  const closeCase = () => {
    let today = new Date();
    let credit = {
      type: "credit",
      amount: 3000,
      caseInfo: selectedCase.name + " (Ref#" + selectedCase.caseRef + ")",
      createdBy:
        currentUser && currentUser.firstName + " " + currentUser.lastName,
      date: formatDate(today, DateFormats.MEDIUM),
    };
    debitCreditList.push(credit);
  };

  const openEditModal = () => {
    others.setCaseTemp(selectedCase);
    setOpenCase(true);
  };

  const onChangeTabs = (value) => setTabIndex(value);

  const goDelete = () => {
    setProcessing(true);
    Axios.delete("/cases/" + selectedCase.caseSid)
      .then((res) => {
        setProcessing(false);
        others.setCaseList((prev) =>
          prev.filter((c) => c.id !== selectedCase.caseSid)
        );
        fetchCaseListWithPagination(1);
        setSelectedCase(null);
        setClientInfo(null);
        alertSuccess("Successfully deleted!");
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, "Error! Unable to delete."));
      });
  };

  const goToggleArchive = () => {
    const term = selectedCase.active ? "archive" : "unarchive";
    setProcessing(true);
    Axios.patch(`/cases/${term}/${selectedCase.caseSid}`)
      .then((res) => {
        setProcessing(false);
        others.setCaseList((prev) => {
          const oldInd = prev.findIndex((c) => c.id === selectedCase.caseSid);
          const newCase = prev[oldInd];
          newCase.active = !newCase.active;
          prev.splice(oldInd, 1, newCase);
          return prev;
        });
        alertSuccess(`Successfully ${term}d!`);
        fetchCaseListWithPagination();
        if (!selectedCase.active || caseSetting.showArchivedData) {
          setSelectedCase((prev) => ({ ...prev, active: !prev.active }));
        } else {
          setClientInfo(null);
          setSelectedCase(null);
        }
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, `Error! Unable to ${term}.`));
      });
  };

  const confirmDelete = () =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Delete",
      okBtnColor: "secondary",
      onOkClicked: goDelete,
      title: "Delete",
      message: `Are you sure you want to delete this case Ref#${selectedCase.caseRef}?`,
    }));

  const confirmToggleArchive = () => {
    const term = selectedCase.active ? "archive" : "unarchive";
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: term,
      okBtnColor: selectedCase.active ? "secondary" : "primary",
      onOkClicked: goToggleArchive,
      title: term,
      message: `Are you sure you want to ${term} this case Ref#${selectedCase.caseRef}?`,
    }));
  };

  let tabTitleList = [
    { label: "Details" },
    { label: "Time" },
    { label: "Expense" },
    { label: "Invoices" },
    { label: "Debit/Credit" },
  ];

  if (currentUser.rcModuleUsable) {
    tabTitleList.push({ label: "Chat" });
  }

  return (
    <>
      <CommonPage
        className="CasePage"
        detailTitle={selectedCase && selectedCase.name}
        archived={selectedCase && !selectedCase.active}
        setSelectedData={setSelectedCase}
        PageSidebar={
          <PageSidebar
            loading={loading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTermAndGotToFirstPage}
            sortFields={SortColumns.CASE}
            defaultSortDesc="Sort cases by Ref# in descending order"
            setSorts={setSorts}
            searchInputPlaceholder="Search Case"
            listName="Active Cases"
            listData={caseList.map((c) => {
              return {
                active: c.active,
                id: c.caseSid,
                primary: c.name,
                secondary: (
                  <>
                    <Typography variant="body2">
                      {`Ref#${c.caseRef}` +
                        (c.client ? " (" + c.client.value + ")" : "")}
                    </Typography>
                    {c.createdBy && (
                      <Typography
                        variant="body2"
                        style={{ fontStyle: "italic", opacity: "0.75" }}
                      >
                        created by{" "}
                        <span
                          style={{
                            fontWeight: "600",
                            fontStyle: "normal",
                          }}
                        >
                          {c.createdBy}
                        </span>
                      </Typography>
                    )}
                  </>
                ),
                selected: selectedCase && c.caseSid === selectedCase.caseSid,
                onClick: () => {
                  setSelectedCase(
                    caseList.find((cal) => cal.caseSid === c.caseSid)
                  );
                  c.client && fetchClientInfo(c.client.id);
                  fetchSecretariesInCharge(c.caseSid);
                },
              };
            })}
            addClicked={openSaveModal}
            module={Modules.CASE}
          />
        }
        Buttons={
          <Grid item>
            <PopupMenuButton
              size="small"
              color="default"
              isIconButton
              menuItems={[
                {
                  text: "Create New Case",
                  icon: <AddRounded fontSize="small" />,
                  handler: openSaveModal,
                },
                {
                  text: "Quick Time Entry",
                  icon: <AddAlarmRounded fontSize="small" />,
                  disabled: Boolean(!selectedCase || !selectedCase.active),
                  tooltip:
                    selectedCase &&
                    !selectedCase.active &&
                    "Time entry cannot be further keyed in under archived case",
                  handler: () => {
                    let rate = currentUser.rate;
                    if (!rate) {
                      const stf = staffList.find(
                        (el) => el.id === currentUser.personSid
                      );
                      if (stf) {
                        rate = stf.rate ? stf.rate : 0;
                      }
                    }
                    const qteObj = {
                      ...getDefaultTimeExpObj(),
                      caseDto: {
                        id: selectedCase.caseSid,
                        value: `${selectedCase.name} (Ref#${selectedCase.caseRef})`,
                        active: selectedCase.active,
                      },
                      staff: {
                        id: currentUser.personSid,
                        value: `${currentUser.firstName} ${currentUser.lastName}`,
                        active: true,
                        rate,
                      },
                      rate,
                    };
                    setSelectedTimeExp(qteObj);
                    setOpenQte(true);
                  },
                },
                {
                  text: "Quick Expense Entry",
                  icon: <AddBoxOutlined fontSize="small" />,
                  disabled: Boolean(!selectedCase || !selectedCase.active),
                  tooltip:
                    selectedCase &&
                    !selectedCase.active &&
                    "Cannot create expense entry under archived case",
                  handler: () => {
                    const qeeObj = {
                      ...getDefaultTimeExpObj("exp"),
                      caseDto: {
                        id: selectedCase.caseSid,
                        value: `${selectedCase.name} (Ref#${selectedCase.caseRef})`,
                        active: selectedCase.active,
                      },
                      staff: {
                        id: currentUser.personSid,
                        value: `${currentUser.firstName} ${currentUser.lastName}`,
                        active: true,
                      },
                    };
                    setSelectedTimeExp(qeeObj);
                    setOpenQee(true);
                  },
                },
                {
                  text: "Create Debit Note",
                  icon: <AccountBalanceWallet fontSize="small" />,
                  disabled: !selectedCase || selectedCase.status === "close",
                  handler: () => {
                    setDebitCreditType("debit");
                    openCreateDebitCreditModal();
                  },
                },
                {
                  text: "Create Credit Note",
                  icon: <AccountBalanceWallet fontSize="small" />,
                  // disabled: !selectedCase || selectedCase.status === "open",
                  handler: () => {
                    setDebitCreditType("credit");
                    openCreateDebitCreditModal();
                  },
                },
                {
                  text: "Close Case",
                  icon: <Close fontSize="small" />,
                  disabled: !selectedCase,
                  handler: closeCase,
                },
                currentUser.roleType.roleTypeName === RoleTypes.MANAGER && {
                  text: "View Audit History",
                  icon: <HistoryRounded fontSize="small" />,
                  disabled: !selectedCase,
                  handler: () =>
                    selectedCase && openAuditModal(selectedCase.caseSid),
                },
                {
                  text: "Edit",
                  icon: <CreateRounded fontSize="small" />,
                  disabled: Boolean(!selectedCase || !selectedCase.active),
                  tooltip:
                    selectedCase &&
                    !selectedCase.active &&
                    "Archived items are non-editable",
                  handler: openEditModal,
                },
                {
                  text:
                    selectedCase && !selectedCase.active
                      ? "Unarchive"
                      : "Archive",
                  icon: <ArchiveRounded fontSize="small" />,
                  disabled: !selectedCase,
                  handler: confirmToggleArchive,
                },
                {
                  text: "Delete",
                  icon: <DeleteRounded fontSize="small" />,
                  disabled: !selectedCase,
                  handler: confirmDelete,
                },
              ]}
            />
          </Grid>
        }
      >
        {selectedCase && caseList.length > 0 && (
          <TabPane tabTitles={tabTitleList} onChange={onChangeTabs}>
            <DetailsTabPanel
              data={selectedCase}
              clientInfo={clientInfo}
              tabIndex={tabIndex}
            />
            <TimeTabPanel
              selectedCaseSid={selectedCase.caseSid}
              tabIndex={tabIndex}
            />
            <ExpenseTabPanel
              selectedCaseSid={selectedCase.caseSid}
              tabIndex={tabIndex}
            />
            <InvoicesTabPanel
              selectedCase={selectedCase}
              tabIndex={tabIndex}
              client={clientInfo}
            />
            <AccountTabPanel
              openEditModal={openCreateDebitCreditModal}
              open
              data={selectedCase}
              tabIndex={tabIndex}
            />
            {currentUser.rcModuleUsable ? (
              <ChatPanel
                data={selectedCase}
                clientInfo={clientInfo}
                tabIndex={tabIndex}
              />
            ) : undefined}
          </TabPane>
        )}
      </CommonPage>
      <DebitCreditModal
        debitCreditNote={debitCredit}
        debitCreditLines={linesOfDebitCredit}
        openDeposit={openDeposit}
        setOpenDeposit={setOpenDeposit}
        clientInfo={clientInfo}
        selectedCase={selectedCase}
        type={debitCreditType}
      />
    </>
  );
};

export default CasesPage;
