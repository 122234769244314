/* eslint-disable no-useless-escape */
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const brnRegex1 = /^[0-9]{9}[A-Z]$/;

export const brnRegex2 = /^[0-9]{8}[A-Z]$/;

export const brnRegex3 = /^[A-Z][0-9]{2}[A-Z]{2}[0-9]{4}[A-Z]$/;

export const REFRESH_REQUIRED_MSG = "Token_Refresh_Required";

export const LOGIN_REQUIRED_MSG = "Re_Login_Required";

export const DateFormats = {
  SLASH: "MM/DD/YYYY",
  DASH: "YYYY-MM-DD",
  MEDIUM: "MMM D, YYYY",
  LONG: "dddd, MMMM D, YYYY",
  LONG1: "ddd, MMMM D, YYYY",
  LONG2: "dddd, MMM D, YYYY",
  LONG3: "ddd, MMM D, YYYY",
  LONG4: "MMM DD, YYYY ddd",
  DATETIME: "MMM D, YYYY hh:mm:ss A",
};

export const CONSTANTS = {
  ACCESS_TOKEN: "e360_access_token",
  REFRESH_TOKEN: "e360_refresh_token",
  CURRENT_USER: "e360_current_user",
  MODE: "mode",
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  API_VERSION: process.env.REACT_APP_API_VERSION,
  API_BASE_URL:
    process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_API_VERSION,
  ROCKET_CHAT_ENDPOINT: process.env.REACT_APP_ROCKET_CHAT_ENDPOINT,
  DOCX_SERVER_ENDPOINT: process.env.REACT_APP_DOCX_SERVER_ENDPOINT,
};

export const RoleTypes = {
  PORTAL_ADMIN: "Portal Admin",
  COMPANY_ADMIN: "Company Admin",
  MANAGER: "Manager",
  LAWYER: "Lawyer",
  STAFF: "Staff",
};

export const MasterTypeCats = {
  CASE_CATEGORY: "CASE-CATEGORY-TYPES",
  INVOICE: "INVOICE-TYPES",
  CASE_RATE: "CASE-RATE-TYPES",
  BILLING_TERM: "BILLING-TERM-TYPES",
  SALUTATION: "SALUTATION-TYPES",
  STAFF: "STAFF-TYPES",
  ROLE: "ROLE-TYPES",
  CASE_STATUS: "CASE-STATUS-TYPES",
  PAYMENT_TERM: "PAYMENT-TERM-TYPES",
  ADDRESS: "ADDRESS-TYPES",
  CASE_PERMISSION: "CASE-PERMISSION-TYPES",
  CLIENT: "CLIENT-TYPES",
};

export const MasterCodeCats = {
  COUNTRY: "COUNTRY-CODES",
  ACT: "ACT-CODES",
  EXP: "EXP-CODES",
};

export const PaymentTerms = {
  CONTINGENT_FEE: "Contingent Fee Agreement",
  DUE_UPON_RECEIPT: "Due Upon Receipt",
  DAYS_7: "7 Days",
  DAYS_15: "15 Days",
  DAYS_30: "30 Days",
  DAYS_45: "45 Days",
  DAYS_90: "90 Days",
};

export const Operations = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  ARCHIVE: "ARCHIVE",
  UNARCHIVE: "UNARCHIVE",
  DELETE: "DELETE",
};

export const Modules = {
  STAFF: "staff",
  CASE: "case",
  CLIENT: "client",
  COMPANY: "company",
  COMPANY_ADMIN: "company-admin",
  QTE: "qte",
  QEE: "qee",
  INVOICE: "invoice",
  SETTING: "setting",
  ACCOUNT: "account",
  OFFICE_ACCOUNT: "office-account",
  DEBIT_CREDIT: "debit-credit",
};

export const SortColumns = {
  CLIENT: [
    { field: "clientId", fieldName: "Client ID", asc: "A → Z", desc: "Z → A" },
    {
      field: "firstName",
      fieldName: "First Name",
      asc: "A → Z",
      desc: "Z → A",
    },
    { field: "lastName", fieldName: "Last Name", asc: "A → Z", desc: "Z → A" },
    { field: "fullName", fieldName: "Full Name", asc: "A → Z", desc: "Z → A" },
    {
      field: "companyName",
      fieldName: "Company Name",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "email",
      fieldName: "Email",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "dateOfBirth",
      fieldName: "Age",
      asc: "Oldest First",
      desc: "Youngest First",
    },
    {
      field: "createdDate",
      fieldName: "Created Date",
      asc: "Oldest First",
      desc: "Newest First",
    },
    {
      field: "typeShortDesc",
      fieldName: "Client Type",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "yearlyInterestRate",
      fieldName: "Interest Rate",
      asc: "Ascending",
      desc: "Descending",
    },
    {
      field: "brn",
      fieldName: "Business Registratin No.",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "tax",
      fieldName: "Tax",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "postalCountryCode",
      fieldName: "Country Code",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "postalCountryName",
      fieldName: "Country Name",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "openCaseCount",
      fieldName: "Open Case Count",
      asc: "Ascending",
      desc: "Descending",
    },
    {
      field: "closedCaseCount",
      fieldName: "Closed Case Count",
      asc: "Ascending",
      desc: "Descending",
    },
    {
      field: "updatedAt",
      fieldName: "Last Modified",
      asc: "Recent Last",
      desc: "Recent First",
    },
  ],
  // Cases
  CASE: [
    {
      field: "caseRef",
      fieldName: "Case Ref#",
      asc: "Ascending",
      desc: "Descending",
    },
    {
      field: "caseName",
      fieldName: "Case Name",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "courtCaseNumber",
      fieldName: "Court Case No.",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "caseDescription",
      fieldName: "Case Description",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "caseOpenDate",
      fieldName: "Open Date",
      asc: "Recent Last",
      desc: "Recent First",
    },
    {
      field: "caseCloseDate",
      fieldName: "Close Date",
      asc: "Recent Last",
      desc: "Recent First",
    },
    {
      field: "caseAgreedFee",
      fieldName: "Agreed Fee",
      asc: "Ascending",
      desc: "Descending",
    },
    {
      field: "updatedAt",
      fieldName: "Last Modified",
      asc: "Recent Last",
      desc: "Recent First",
    },
    {
      field: "createdAt",
      fieldName: "Date Created",
      asc: "Oldest First",
      desc: "Newest First",
    },
  ],
  COMPANY: [
    {
      field: "companyName",
      fieldName: "Name",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "businessRegistrationNumber",
      fieldName: "Business Registration Number",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "taxRegistered",
      fieldName: "Tax Registered",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "updatedAt",
      fieldName: "Last Modified",
      asc: "Recent Last",
      desc: "Recent First",
    },
    {
      field: "createdAt",
      fieldName: "Date Created",
      asc: "Oldest First",
      desc: "Newest First",
    },
  ],
  STAFF: [
    {
      field: "contact.firstName",
      fieldName: "First Name",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "contact.lastName",
      fieldName: "Last Name",
      asc: "A → Z",
      desc: "Z → A",
    },
    { field: "contact.email", fieldName: "Email", asc: "A → Z", desc: "Z → A" },
    {
      field: "createdAt",
      fieldName: "Created Date",
      asc: "Oldest First",
      desc: "Newest First",
    },
    {
      field: "updatedAt",
      fieldName: "Last Modified",
      asc: "Recent Last",
      desc: "Recent First",
    },
  ],
  COMPANY_ADMIN: [
    {
      field: "contact.firstName",
      fieldName: "First Name",
      asc: "A → Z",
      desc: "Z → A",
    },
    {
      field: "contact.lastName",
      fieldName: "Last Name",
      asc: "A → Z",
      desc: "Z → A",
    },
    { field: "contact.email", fieldName: "Email", asc: "A → Z", desc: "Z → A" },
    {
      field: "createdAt",
      fieldName: "Created Date",
      asc: "Oldest First",
      desc: "Newest First",
    },
    {
      field: "updatedAt",
      fieldName: "Last Modified",
      asc: "Recent Last",
      desc: "Recent First",
    },
  ],
};

export const RateType = {
  GLOBAL: "global",
  CUSTOM: "custom",
  STATIC: "static",
};

export default CONSTANTS;
