import React from "react";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import clsx from "clsx";

const CommonSelect = ({
  options = [],
  valueKey = "id",
  labelKey = "value",
  fullWidth,
  required,
  value = "",
  name = "",
  className,
  onChange = (e) => {},
  onBlur = (e) => {},
  error,
  label,
  ...others
}) => {
  // CLIENT-REQUEST: show only active options if object has active property and active is true.
  // otherwise show all
  const _options = options?.filter((o) => {
    if (o.hasOwnProperty("active")) {
      return o.active === true;
    }
    return true;
  });

  return (
    <>
      {label && (
        <InputLabel id={name}>
          {label + (required ? (name === "salutation" ? "*" : " *") : "")}
        </InputLabel>
      )}
      <Select
        className={clsx("CommonSelect", className)}
        labelId={name}
        fullWidth={fullWidth}
        required={required}
        value={value || ""}
        name={name}
        error={error}
        onChange={(e) =>
          onChange({
            ...e,
            target: {
              ...e.target,
              validity: {
                ...e.target.validity,
                valid: required && e.target.value,
              },
            },
          })
        }
        onBlur={(e) =>
          onBlur({
            ...e,
            target: {
              ...e.target,
              validity: {
                ...e.target.validity,
                valid: required && e.target.value,
              },
            },
          })
        }
        {...others}
      >
        {_options?.map((o, i) => (
          <MenuItem
            key={i}
            id={o[valueKey]}
            value={o[valueKey]}
            // disabled={o.active !== undefined && !o.active}
          >
            {o[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default CommonSelect;
