import React, { useState, useContext, useEffect } from "react";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import {
  CreateRounded,
  ArchiveRounded,
  DeleteRounded,
  VpnKeyRounded,
  PersonAddRounded,
  HistoryRounded,
  LanguageRounded,
} from "@material-ui/icons";
import PageSidebar from "../../components/PageSidebar";
import PopupMenuButton from "../../components/PopupMenuButton";
import CustomModal from "../../components/CustomModal";
import CommonPage from "../CommonPage";
import StaffForm from "./StaffForm";

import { DataContext, PopupContext, SettingContext } from "../../DataContext";
import { getDefaultStaffObj } from "../../model";
import {
  getDefaultErrorObj,
  getError,
  hasError,
  hasMissingData,
} from "../../utils/functions";
import SlideUp from "../../components/SlideUp";
import Axios from "axios";
import { Modules, SortColumns, RoleTypes } from "../../utils/constants";
import { ChangeGhrModal, StaffProfile } from "./components";

const StaffPage = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    roleTypes,
    alertSuccess,
    alertWarning,
    alertError,
    currentUser,
    staffTypes,
    fetchStaffList,
    ...others
  } = useContext(DataContext);
  const { openDialog, setAuditOptions, setChangePasswordOptions } =
    useContext(PopupContext);
  const { staffSetting, setStaffSetting } = useContext(SettingContext);

  const [isOpen, setIsOpen] = useState(false);
  const [openGhrModal, setOpenGhrModal] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState(null);
  const [staffTemp, setStaffTemp] = useState(getDefaultStaffObj());
  const [error, setError] = useState(getDefaultErrorObj(getDefaultStaffObj()));
  const [searchTerm, setSearchTerm] = useState("");
  const [sorts, setSorts] = useState([]);

  const isSecretary =
    staffTemp.staffType &&
    staffTypes &&
    staffTypes.length > 0 &&
    staffTypes.find((st) => st.id === staffTemp.staffType).value ===
      "Secretary";

  const isSecretary1 =
    staff &&
    staffTypes &&
    staffTypes.length > 0 &&
    staffTypes.find((st) => st.id === staff.staffType).value === "Secretary";

  const fetchStaffListWithPagination = (
    page = staffSetting.currentPage,
    size = staffSetting.pageSize,
    showArchivedData = staffSetting.showArchivedData,
    q = searchTerm,
    sort = sorts
  ) => {
    setLoading(true);
    Axios.get(
      `/staffs/paginate/employees?page=${page}&size=${size}&showArchivedData=${showArchivedData}
      ${q ? "&q=" + q : ""}${
        sort.length > 0
          ? "&sort=" + sort.map((s) => s.field + "-" + s.order).join(",")
          : ""
      }
      `
    )
      .then((res) => {
        setLoading(false);
        setStaffList(res.data.content);
        setStaffSetting((prev) => ({
          ...prev,
          totalPages: res.data.totalPages,
          currentPage: page,
        }));
      })
      .catch((err) => {
        setLoading(false);
        setStaffList([]);
        setStaffSetting((prev) => ({ ...prev, totalPages: 0 }));
      });
  };

  useEffect(() => {
    fetchStaffListWithPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    staffSetting.currentPage,
    staffSetting.pageSize,
    staffSetting.showArchivedData,
    searchTerm,
    sorts,
  ]);

  const setSearchTermAndGotToFirstPage = (term) => {
    setSearchTerm(term);
    setStaffSetting((prev) => ({ ...prev, currentPage: 1 }));
  };

  const openSaveModal = () => {
    setError(getDefaultErrorObj(getDefaultStaffObj()));
    setStaffTemp(getDefaultStaffObj());
    setIsOpen(true);
  };

  const openEditModal = () => {
    setError(getDefaultErrorObj(getDefaultStaffObj()));
    setStaffTemp(staff);
    setIsOpen(true);
  };

  const openAuditModal = (staffSid) =>
    setAuditOptions({
      open: true,
      moduleType: Modules.STAFF,
      sid: staffSid,
    });

  const openChangePasswordModal = () => {
    setChangePasswordOptions({
      open: true,
      type: "reset",
      personSid: staff.personSid,
      name: staff.firstName + " " + staff.lastName,
      email: staff.email,
    });
  };

  const handleChange = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setStaffTemp({
      ...staffTemp,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const onBlur = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const saveOrEditStaff = () => {
    try {
      const fieldsToIgnore = [
        "personSid",
        "active",
        "autoAssignNewCase",
        "phone",
        "lastLogin",
        "initials",
        "companySid",
      ];
      if (staffTemp.personSid) {
        fieldsToIgnore.push("password");
        fieldsToIgnore.push("passwordReenter");
      }
      if (isSecretary) {
        fieldsToIgnore.push("globalHourlyRate");
      }
      if (hasMissingData(staffTemp, ...fieldsToIgnore)) {
        alertWarning("Warning! Missing data in required fields.");
      } else if (
        !staffTemp.personSid &&
        staffTemp.password !== staffTemp.passwordReenter
      ) {
        alertWarning("Password mismatch! Please re-enter password.");
      } else if (hasError(error, ...fieldsToIgnore)) {
        alertWarning("Invalid! Check your input data entered.");
      } else {
        if (staffTemp.personSid) {
          // UPDATE
          Axios.put("/staffs", {
            ...staffTemp,
            companySid: currentUser.company.companySid,
            password: "not null",
          })
            .then((res) => {
              others.setStaffList((prev) => {
                const newList = prev.filter(
                  (st) => st.personSid !== res.data.personSid
                );
                const stfOpt = {
                  id: res.data.personSid,
                  value: res.data.firstName + " " + res.data.lastName,
                  active: res.data.active,
                  rate: res.data.globalHourlyRate,
                };
                return [stfOpt, ...newList];
              });
              fetchStaffListWithPagination();
              setStaff(res.data);
              setIsOpen(false);
              alertSuccess("Successfully updated!");
            })
            .catch((err) =>
              alertError(getError(err, "Error! Failed to update"))
            );
        } else {
          // SAVE
          Axios.post("/staffs", {
            ...staffTemp,
            companySid: currentUser.company.companySid,
            globalHourlyRate: isSecretary ? 0.0 : staffTemp.globalHourlyRate,
          })
            .then((res) => {
              const stfOpt = {
                id: res.data.personSid,
                value: res.data.firstName + " " + res.data.lastName,
                active: res.data.active,
                rate: res.data.globalHourlyRate,
              };
              others.setStaffList((prev) => [stfOpt, ...prev]);
              fetchStaffListWithPagination(1);
              setStaff(res.data);
              setIsOpen(false);
              alertSuccess("Successfully saved!");
            })
            .catch((err) => alertError(getError(err, "Error! Failed to save")));
        }
      }
    } catch (error) {
      alertError(
        `Error! Unable to ${staffTemp.personSid ? "update" : "save"}.`
      );
    }
  };

  const goDelete = () => {
    Axios.delete("/staffs/" + staff.personSid)
      .then((res) => {
        if (res.data) {
          others.setStaffList((prev) =>
            prev.filter((st) => st.clientSid !== staff.clientSid)
          );
          fetchStaffListWithPagination(1);
          setStaff(null);
          alertSuccess("Successfully deleted!");
        } else {
          alertError("Unable to delete due to corresponding cases or clients");
        }
      })
      .catch((err) => alertError(getError(err, "Error! Failed to delete")));
  };

  const goToggleArchive = () => {
    const term = staff.active ? "archive" : "unarchive";
    Axios.patch(`/staffs/${term}/${staff.personSid}`)
      .then((res) => {
        others.setStaffList((prev) => {
          const oldInd = prev.findIndex((c) => c.id === staff.personSid);
          if (oldInd > -1) {
            const newStaff = prev[oldInd];
            newStaff.active = !newStaff.active;
            prev[oldInd] = newStaff;
            return prev;
          }
          return prev;
        });
        alertSuccess(`Successfully ${term}d!`);
        fetchStaffListWithPagination();
        if (!staff.active || staffSetting.showArchivedData) {
          setStaff((prev) => ({ ...prev, active: !prev.active }));
        } else {
          setStaff(null);
        }
      })
      .catch((err) => {
        alertError(getError(err, `Error! Unable to ${term}.`));
      });
  };

  const confirmDelete = () =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Delete",
      okBtnColor: "secondary",
      onOkClicked: goDelete,
      title: "Delete",
      message: `Are you sure you want to delete "${staff.firstName} ${staff.lastName}"?`,
    }));

  const confirmToggleArchive = () => {
    const term = staff.active ? "archive" : "unarchive";
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: term,
      okBtnColor: staff.active ? "secondary" : "primary",
      onOkClicked: goToggleArchive,
      title: term,
      message: `Are you sure you want to ${term} "${staff.firstName} ${staff.lastName}"?`,
    }));
  };

  const menuItems = [
    {
      text: "Create New Staff",
      icon: <PersonAddRounded fontSize="small" />,
      handler: openSaveModal,
    },
    {
      text: "Reset Password",
      icon: <VpnKeyRounded fontSize="small" />,
      disabled: Boolean(!staff || !staff.active),
      tooltip: staff && !staff.active && "Archived items are non-editable",
      handler: openChangePasswordModal,
    },
    !isSecretary1 && {
      text: "Change Global Hourly Rate",
      icon: <LanguageRounded fontSize="small" />,
      disabled: Boolean(!staff || !staff.active),
      tooltip: staff && !staff.active && "Archived items are non-editable",
      handler: () => setOpenGhrModal(true),
    },
    currentUser.roleType.roleTypeName === RoleTypes.MANAGER && {
      text: "View Audit History",
      icon: <HistoryRounded fontSize="small" />,
      disabled: !staff,
      handler: () => staff && openAuditModal(staff.personSid),
    },
    {
      text: "Edit",
      icon: <CreateRounded fontSize="small" />,
      disabled: Boolean(!staff || !staff.active),
      tooltip: staff && !staff.active && "Archived items are non-editable",
      handler: openEditModal,
    },
    {
      text: staff && !staff.active ? "Unarchive" : "Archive",
      icon: <ArchiveRounded fontSize="small" />,
      disabled: !staff,
      handler: confirmToggleArchive,
    },
    {
      text: "Delete",
      icon: <DeleteRounded fontSize="small" />,
      disabled: !staff,
      handler: confirmDelete,
    },
  ];

  return (
    <>
      <CommonPage
        className="StaffPage"
        setSelectedData={setStaff}
        detailTitle={staff && " "}
        PageSidebar={
          <PageSidebar
            loading={loading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTermAndGotToFirstPage}
            sortFields={SortColumns.STAFF}
            setSorts={setSorts}
            searchInputPlaceholder="Search Staff"
            listName="All Staffs"
            listData={staffList.map((l) => ({
              active: l.active,
              id: l.personSid,
              primary: `${l.firstName} ${l.lastName}`,
              secondary:
                l.roleType &&
                roleTypes.length > 0 &&
                roleTypes.find((rt) => rt.id === l.roleType).value,
              selected: staff && l.personSid === staff.personSid,
              onClick: () =>
                setStaff(staffList.find((s) => s.personSid === l.personSid)),
            }))}
            addClicked={openSaveModal}
            module={Modules.STAFF}
          />
        }
        Buttons={
          <Grid item>
            <PopupMenuButton
              size="small"
              color="default"
              isIconButton
              menuItems={menuItems}
            />
          </Grid>
        }
      >
        {staff && <StaffProfile staff={staff} />}
      </CommonPage>
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="sm"
        isOpen={isOpen}
        title={staffTemp.personSid ? "Edit Staff" : "New Staff"}
        closeBtnTitle="Cancel"
        saveBtnTitle={staffTemp.personSid ? "Update" : "Save"}
        handleClose={() => setIsOpen(false)}
        handleSave={saveOrEditStaff}
      >
        <StaffForm
          staff={staffTemp}
          handleChange={handleChange}
          error={error}
          setError={setError}
          onBlur={onBlur}
          isSecretary={isSecretary}
        />
      </CustomModal>
      <ChangeGhrModal
        staff={staff}
        SlideUp={SlideUp}
        fullScreen={fullScreen}
        openGhrModal={openGhrModal}
        handleClose={() => setOpenGhrModal(false)}
      />
    </>
  );
};

export default StaffPage;
