import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import Axios from "axios";

import { DataContext } from "../DataContext";

const LogoutPage = () => {
  const { currentUser } = useContext(DataContext);
  localStorage.clear();

  const logout = () => {
    const headers = {
      "X-Auth-Token": currentUser.rcAuthToken,
      "X-User-Id": currentUser.rcUserId,
    };
    Axios.get("/auth/logout", { headers, withCredentials: true });
  };

  if (currentUser.rcModuleUsable) {
    logout();
  }

  return <Redirect to="/" />;
};

export default LogoutPage;
