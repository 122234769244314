import React from "react";
import clsx from "clsx";
import { Grid, TextField } from "@material-ui/core";

const AddCategoryForm = ({
  className,
  caseCategoryName,
  setCaseCategoryName,
}) => {
  return (
    <form className={clsx("CaseForm", className)}>
      <Grid container>
        <Grid item xs={12} md={12} className="p175">
          <TextField
            label="Case Category Name"
            value={caseCategoryName}
            onChange={(event) => setCaseCategoryName(event.target.value)}
            fullWidth
            required
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default AddCategoryForm;
