import {
  colors,
  Grid,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { HelpRounded, PersonRounded } from "@material-ui/icons";
import clsx from "clsx";
import React, { useContext } from "react";
import SpanForTooltip from "../../../components/SpanForTooltip";
import { DataContext } from "../../../DataContext";
import { getDefaultStaffObj } from "../../../model";
import { ColorValues } from "../../../theme/colors";
import { formatDecimal } from "../../../utils/functions";
import TabPanel from "../../../components/TabPanel";

const StatusBadge = ({ active }) => {
  const color = active ? ColorValues.GREEN : ColorValues.ORANGE;
  const style = {
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "8px",
    padding: "4px 10px",
    fontSize: "12px",
    fontWeight: 600,
    letterSpacing: "1px",
    textTransform: "uppercase",
    cursor: "default",
    whiteSpace: "nowrap",
    color: color && colors[color][500],
    backgroundColor: color && colors[color][500] + "25",
  };
  return (
    <span className="StatusBadge" style={style}>
      <span style={{ fontSize: "20px", lineHeight: "1", marginRight: "4px" }}>
        {"\u2022"}
      </span>
      <span>{active ? "active" : "inactive"}</span>
    </span>
  );
};

const LabelWithHelpIcon = ({ children, tooltip }) => {
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item>{children}</Grid>
      {tooltip && (
        <Grid item style={{ padding: "4px 0 0 6px" }}>
          <Tooltip title={tooltip} arrow>
            <SpanForTooltip>
              <HelpRounded style={{ fontSize: "18px" }} />
            </SpanForTooltip>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  profile: {
    width: "160px",
    height: "160px",
    marginBottom: "24px",
    border: "1px dashed",
    borderRadius: "8px",
    borderColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.action.hover,
    textAlign: "center",
    fontSize: "120px",
    color: theme.palette.primary.main,
    "&>*": {
      fontSize: "inherit",
    },
  },
  tableBody: {
    "&>tr": {
      verticalAlign: "center",
    },
    "&>tr>td.MuiTableCell-body": {
      color: theme.palette.text.secondary,
      whiteSpace: "nowrap",
    },
    "&>tr>td.MuiTableCell-body:last-child": {
      color: theme.palette.primary.main,
      width: "100%",
      paddingLeft: theme.spacing(4),
    },
    "&>tr:last-child>td": {
      borderBottomColor: "transparent",
    },
  },
}));

export const StaffProfile = ({
  className,
  staff = getDefaultStaffObj(),
  isCompanyAdmin,
  companyList = [],
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const { roleTypes, staffTypes, salutationList, currentUser } = useContext(
    DataContext
  );

  const getFullName = () => {
    const sal = salutationList.find((el) => el.id === staff.salutation);
    return (
      (sal ? sal.value + " " : "") + staff.firstName + " " + staff.lastName
    );
  };

  return (
    <TabPanel className="ProfileTabPanel" style={{ overflowX: "hidden" }}>
      <div className={clsx("StaffProfile", className)}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            md={5}
            lg={4}
            alignItems="center"
            direction="column"
            style={{ marginBottom: "24px" }}
          >
            <div className={classes.profile}>
              <PersonRounded />
            </div>
            <Typography variant="h6" color="textSecondary">
              {staff.firstName + " " + staff.lastName}
            </Typography>
            <Typography variant="body2" color="primary">
              <Link href={`mailto:${staff.email}`}>{staff.email}</Link>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={8}
            style={{
              marginBottom: "16px",
              paddingRight: desktop ? "24px" : "0",
            }}
          >
            <Table
              style={
                desktop
                  ? { maxWidth: "520px", marginRight: "16px" }
                  : { maxWidth: "520px", margin: "0 auto" }
              }
            >
              <TableBody className={classes.tableBody}>
                <TableRow>
                  <TableCell>Full Name :</TableCell>
                  <TableCell>{getFullName()}</TableCell>
                </TableRow>
                {isCompanyAdmin && companyList && companyList.length > 0 && (
                  <TableRow>
                    <TableCell>Company :</TableCell>
                    <TableCell>
                      {
                        companyList.find(
                          (com) => com.companySid === staff.companySid
                        )?.companyName
                      }
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <LabelWithHelpIcon
                      tooltip={`Access level in E360 with "Manager" role being the most privileged`}
                    >
                      Role Type :
                    </LabelWithHelpIcon>
                  </TableCell>
                  <TableCell>
                    {isCompanyAdmin
                      ? "Company Admin"
                      : roleTypes.find((t) => t.id === staff.roleType)?.value}
                  </TableCell>
                </TableRow>
                {!isCompanyAdmin && (
                  <TableRow>
                    <TableCell>
                      <LabelWithHelpIcon
                        tooltip={
                          "Actual position under " +
                          currentUser?.company?.companyName
                        }
                      >
                        Staff Type :
                      </LabelWithHelpIcon>
                    </TableCell>
                    <TableCell>
                      {staffTypes.find((t) => t.id === staff.staffType)?.value}
                    </TableCell>
                  </TableRow>
                )}
                {!isCompanyAdmin && (
                  <TableRow>
                    <TableCell>
                      <LabelWithHelpIcon
                        tooltip={
                          staffTypes.find((t) => t.id === staff.staffType)
                            ?.value === "Secretary"
                            ? "Secretary does not key-in time entry and thus has no global hourly rate"
                            : "Changing global hourly rate will not affect the amount on previous time entries that are already keyed in but new entries will use most recently updated rate to calculate amount"
                        }
                      >
                        Global Hourly Rate :
                      </LabelWithHelpIcon>
                    </TableCell>
                    <TableCell className="e360-number">
                      ${formatDecimal(staff.globalHourlyRate)}
                    </TableCell>
                  </TableRow>
                )}
                {staff.phone ? (
                  <TableRow>
                    <TableCell>Phone :</TableCell>
                    <TableCell className="e360-number">
                      <Link href={`tel:${staff.phone}`}>{staff.phone}</Link>
                    </TableCell>
                  </TableRow>
                ) : null}
                <TableRow>
                  <TableCell>Status :</TableCell>
                  <TableCell>
                    <StatusBadge active={staff.active} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
    </TabPanel>
  );
};
