import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import CustomTablePagination from "../../../components/CustomTablePagination";
import CommonAccordion from "../../../components/CommonAccordion";
import { DataContext } from "../../../DataContext";
import { InvoiceContent } from "./InvoiceContent";
import { InvoiceFilter, InvoiceSummary } from "./";

export const AllTimeAccordion = ({ setRefetch, refetch }) => {
  const { alertError } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState(null);

  const [dateRange, setDateRange] = useState([null, null]);
  const [caseFilter, setCaseFilter] = useState(null);
  const [invoiceTypeFilter, setInvoiceTypeFilter] = useState(null);
  const [staffFilter, setStaffFilter] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchAllTimeDataWithPagination = (
    fromDate = dateRange[0],
    toDate = dateRange[1],
    caseSid = caseFilter?.id,
    invoiceTypeSid = invoiceTypeFilter?.id,
    caseOrigLawyerSid = staffFilter?.id,
    page = currentPage,
    size = pageSize
  ) => {
    let link = `/view-invoices/all-time?page=${page}&size=${size}&showArchivedData=true`;
    if (invoiceTypeSid) {
      link +=
        invoiceTypeSid === "draft"
          ? `&isInDraft=true`
          : `&invoiceTypeSid=${invoiceTypeSid}`;
    }
    if (caseSid) link += `&caseSid=${caseSid}`;
    if (caseOrigLawyerSid) link += `&caseOrigLawyerSid=${caseOrigLawyerSid}`;
    if (fromDate) link += `&fromDate=${fromDate}`;
    if (toDate) link += `&toDate=${toDate}`;
    setLoading(true);
    Axios.get(link)
      .then((res) => {
        setLoading(false);
        setPageData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setPageData(null);
        alertError("Failed to load all-time invoices");
      });
  };

  useEffect(() => {
    fetchAllTimeDataWithPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRange,
    caseFilter,
    invoiceTypeFilter,
    staffFilter,
    currentPage,
    pageSize,
    refetch,
  ]);

  const handleChangePage = (e, newPage) => setCurrentPage(newPage + 1);

  const handleChangeSize = (e) => setPageSize(parseInt(e.target.value));

  const isFiltering =
    dateRange[0] ||
    dateRange[0] ||
    caseFilter ||
    invoiceTypeFilter ||
    staffFilter;

  return (
    <CommonAccordion className="AllTimeAccordion" title="All Time">
      <InvoiceFilter
        dateRange={dateRange}
        setDateRange={setDateRange}
        caseFilter={caseFilter}
        setCaseFilter={setCaseFilter}
        invoiceTypeFilter={invoiceTypeFilter}
        setInvoiceTypeFilter={setInvoiceTypeFilter}
        staffFilter={staffFilter}
        setStaffFilter={setStaffFilter}
        isFiltering={isFiltering}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        isLoading={loading}
      />
      {!isFiltering && (
        <CustomTablePagination
          loading={loading}
          totalElements={pageData?.totalElements}
          size={pageSize}
          page={currentPage}
          handleChangePage={handleChangePage}
          handleChangeSize={handleChangeSize}
        />
      )}
      <InvoiceContent
        invoiceData={pageData && pageData.content ? pageData.content : []}
        isLoading={loading}
        setRefetch={setRefetch}
      />
      <InvoiceSummary data={pageData?.content} isLoading={loading} />
    </CommonAccordion>
  );
};
