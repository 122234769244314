import React from "react";
import {
  Divider,
  Grid,
  Typography,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { formatDecimal, padZero } from "../utils/functions";
import LoadingSummary from "./LoadingSummary";

const useStyles = makeStyles((theme) => ({
  pad: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
}));

const QuickEntrySummary = ({ data = [], type = "time", isLoading }) => {
  const classes = useStyles();

  if (isLoading) {
    return <LoadingSummary className="QuickEntrySummary" />;
  }

  if (!data) {
    return null;
  }

  const _data = [...data].filter((el) => el.active);
  if (!_data || _data.length < 1) {
    return null;
  }

  const style = { fontWeight: 600, margin: "14px 8px" };

  let totAmt = 0;
  let totNetAmt = 0;
  let totBillableMins = 0;

  let totInvAmt = 0;
  let totInvNetAmt = 0;
  let totInvMins = 0;
  let totBilledMins = 0;

  let awaitingPayment = 0;
  let receivedPayment = 0;

  _data.forEach((qe) => {
    totAmt += +qe.amount;
    totNetAmt += +qe.netAmount;
    if (qe.isInvoiced && !qe.isInvoiceInDraft) {
      totInvAmt += +qe.amount;
      if (qe.invoiceTypeShort === "unpaid") {
        awaitingPayment += +qe.netAmount;
      }
      if (qe.invoiceTypeShort === "paid") {
        receivedPayment += +qe.netAmount;
      }
    }

    if (type === "time") {
      if (qe.isInvoiced && !qe.isInvoiceInDraft) {
        totInvNetAmt += +qe.netAmount;
      }
      const min = +qe.billedHour * 60 + +qe.billedMinute;
      totBillableMins += min;
      if (qe.isInvoiced && !qe.isInvoiceInDraft) {
        totInvMins += min;
        if (qe.invoiceTypeShort === "paid") {
          totBilledMins += min;
        }
      }
    }
  });

  return (
    <Grid className="QuickEntrySummary" container justifyContent="flex-end">
      {type === "time" && (
        <Grid item xs={12} md={6} className={classes.pad}>
          <Divider />
          <Grid container alignItems="center">
            <Grid item xs={7} lg={8}>
              <Typography
                variant="body2"
                color="textSecondary"
                align="right"
                style={style}
                noWrap
              >
                Total Entries :
              </Typography>
            </Grid>
            <Grid item xs={5} lg={4}>
              <Typography
                variant="body1"
                color="primary"
                align="right"
                className="e360-number"
                style={style}
                noWrap
              >
                {formatDecimal(_data.length, 0)}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container alignItems="center">
            <Grid item xs={7} lg={8}>
              <Typography
                variant="body2"
                color="textSecondary"
                align="right"
                style={style}
                noWrap
              >
                Total Billable Time (Hr:Min) :
              </Typography>
            </Grid>
            <Grid item xs={5} lg={4}>
              <Typography
                variant="body1"
                color="primary"
                align="right"
                className="e360-number"
                style={style}
                noWrap
              >
                {parseInt(totBillableMins / 60) +
                  ":" +
                  padZero(totBillableMins % 60, 2)}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container alignItems="center">
            <Grid item xs={7} lg={8}>
              <Typography
                variant="body2"
                color="textSecondary"
                align="right"
                style={style}
                noWrap
              >
                Total Invoiced Time (Hr:Min) :
              </Typography>
            </Grid>
            <Grid item xs={5} lg={4}>
              <Typography
                variant="body1"
                color="primary"
                align="right"
                className="e360-number"
                style={style}
                noWrap
              >
                {parseInt(totInvMins / 60) + ":" + padZero(totInvMins % 60, 2)}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container alignItems="center">
            <Grid item xs={7} lg={8}>
              <Typography
                variant="body2"
                color="textSecondary"
                align="right"
                style={style}
                noWrap
              >
                Total Billed Time (Hr:Min) :
              </Typography>
            </Grid>
            <Grid item xs={5} lg={4}>
              <Typography
                variant="body1"
                color="primary"
                align="right"
                className="e360-number"
                style={style}
                noWrap
              >
                {parseInt(totBilledMins / 60) +
                  ":" +
                  padZero(totBilledMins % 60, 2)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={6} className={classes.pad}>
        {type === "exp" && (
          <>
            <Divider />
            <Grid container alignItems="center">
              <Grid item xs={7} lg={8}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="right"
                  style={style}
                  noWrap
                >
                  Total Entries :
                </Typography>
              </Grid>
              <Grid item xs={5} lg={4}>
                <Typography
                  variant="body1"
                  color="primary"
                  align="right"
                  className="e360-number"
                  style={style}
                  noWrap
                >
                  {formatDecimal(_data.length, 0)}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        <Divider />
        <Grid container alignItems="center">
          <Grid item xs={7} lg={8}>
            <Typography
              variant="body2"
              color="textSecondary"
              align="right"
              style={style}
              noWrap
            >
              Total Amount ($) :
            </Typography>
          </Grid>
          <Grid item xs={5} lg={4}>
            <Typography
              variant="body1"
              color="primary"
              align="right"
              className="e360-number"
              style={style}
              noWrap
            >
              {formatDecimal(totAmt)}
            </Typography>
          </Grid>
        </Grid>
        {type === "time" && (
          <>
            <Divider />
            <Grid container alignItems="center">
              <Grid item xs={7} lg={8}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="right"
                  style={style}
                  noWrap
                >
                  Total Net Amount ($) :
                </Typography>
              </Grid>
              <Grid item xs={5} lg={4}>
                <Typography
                  variant="body1"
                  color="primary"
                  align="right"
                  className="e360-number"
                  style={style}
                  noWrap
                >
                  {formatDecimal(totNetAmt)}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        <Divider />
        <Grid container alignItems="center">
          <Grid item xs={7} lg={8}>
            <Typography
              variant="body2"
              color="textSecondary"
              align="right"
              style={style}
              noWrap
            >
              Total Invoiced Amount ($) :
            </Typography>
          </Grid>
          <Grid item xs={5} lg={4}>
            <Typography
              variant="body1"
              color="primary"
              align="right"
              className="e360-number"
              style={style}
              noWrap
            >
              {formatDecimal(totInvAmt)}
            </Typography>
          </Grid>
        </Grid>
        {type === "time" && (
          <>
            <Divider />
            <Grid container alignItems="center">
              <Grid item xs={7} lg={8}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="right"
                  style={style}
                  noWrap
                >
                  Total Invoiced Net Amount ($) :
                </Typography>
              </Grid>
              <Grid item xs={5} lg={4}>
                <Typography
                  variant="body1"
                  color="primary"
                  align="right"
                  className="e360-number"
                  style={style}
                  noWrap
                >
                  {formatDecimal(totInvNetAmt)}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
        <Divider />
        <Grid container alignItems="center">
          <Grid item xs={7} lg={8}>
            <Typography
              variant="body2"
              color="textSecondary"
              align="right"
              style={style}
              noWrap
            >
              Awaiting Payment ($) :
            </Typography>
          </Grid>
          <Grid item xs={5} lg={4}>
            <Typography
              variant="body1"
              color="primary"
              align="right"
              className="e360-number"
              style={style}
              noWrap
            >
              {formatDecimal(awaitingPayment)}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container alignItems="center">
          <Grid item xs={7} lg={8}>
            <Typography
              variant="body2"
              color="textSecondary"
              align="right"
              style={style}
              noWrap
            >
              Received Payment ($) :
            </Typography>
          </Grid>
          <Grid item xs={5} lg={4}>
            <Typography
              variant="body1"
              color="primary"
              align="right"
              className="e360-number"
              style={style}
              noWrap
            >
              {formatDecimal(receivedPayment)}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={12}>
            <FormHelperText style={{ textAlign: "right" }}>
              * Archived items are not included in the overall calculation
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuickEntrySummary;
