export const getDefaultCompanyAdminObj = () => ({
  personSid: null,
  companySid: "",
  salutationSid: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  passwordReenter: "",
  phone: "",
  active: true,
});
