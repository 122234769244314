import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import { PlayArrow, Stop } from "@material-ui/icons";
import clsx from "clsx";
import { useContext } from "react";
import { DataContext, PopupContext } from "../DataContext";
import { useState } from "react";
import { useEffect } from "react";
import { calcTimeInterval } from "../utils/functions";

let timer;

const TimerButton = ({ className, ...others }) => {
  const { startTime, setStartTime } = useContext(DataContext);
  const { setOpenTimer } = useContext(PopupContext);

  const [tick, setTick] = useState(0);

  const iconFontSize = { fontSize: "30px" };

  useEffect(() => {
    if (startTime !== 0) {
      timer = setInterval(() => setTick(new Date().getTime()), 1000);
    } else {
      timer && clearInterval(timer);
    }

    return () => timer && clearInterval(timer);
  }, [startTime]);

  const toggleTimer = () => {
    if (startTime !== 0) {
      // open modal to save timer
      setOpenTimer(true);
    } else {
      // start timer and record startTime
      setStartTime(new Date().getTime());
    }
  };

  return (
    <Tooltip arrow title={startTime ? "Stop Timer" : "Start Timer"}>
      <Button
        startIcon={
          startTime ? (
            <Stop style={iconFontSize} />
          ) : (
            <PlayArrow style={iconFontSize} />
          )
        }
        className={clsx("TimerButton", className)}
        onClick={toggleTimer}
        {...others}
      >
        {startTime !== 0 && (
          <span className="timer-value">
            {calcTimeInterval(startTime, tick)}
          </span>
        )}
      </Button>
    </Tooltip>
  );
};

export default TimerButton;
