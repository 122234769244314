import Axios from "axios";
import React, { useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  CreateRounded,
  ArchiveRounded,
  DeleteRounded,
  HistoryRounded,
  VisibilityRounded,
  HelpRounded,
} from "@material-ui/icons";
import clsx from "clsx";
import SpanForTooltip from "./SpanForTooltip";
import CustomModal from "./CustomModal";
import QuickEntryDetail from "./QuickEntryDetail";
import PopupMenuButton from "./PopupMenuButton";
import SlideUp from "./SlideUp";
import { useQuickEntryTableStyles } from "../theme/quickEntryTableStyles";
import { DataContext, PopupContext, SettingContext } from "../DataContext";
import { DateFormats, Modules, RoleTypes } from "../utils/constants";
import { formatDate } from "../utils/dateUtils";
import { formatDecimal, getError, padZero } from "../utils/functions";
import NoDataRow from "./NoDataRow";

const TimeEntryTable = ({
  className,
  data = [],
  hideStaffCol,
  hideCaseCol,
  hideClockedTimeCol,
  isLoading,
}) => {
  const classes = useQuickEntryTableStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { setRefetch } = useContext(SettingContext);
  const [entryDetail, setEntryDetail] = useState(false);
  const { caseList, staffList, currentUser, alertSuccess, alertError } =
    useContext(DataContext);
  const { openDialog, setAuditOptions, setOpenQte, setSelectedTimeExp } =
    useContext(PopupContext);
  const [selectedEntry, setSelectedEntry] = useState(null);

  const goArchive = (el) => {
    Axios.patch("/quick-entries/" + (el.active ? "archive" : "unarchive"), el)
      .then((res) => {
        setRefetch((prev) => !prev);
        alertSuccess(
          "Successfully " + (el.active ? "archived!" : "unarchived!")
        );
      })
      .catch((err) =>
        alertError(
          getError(
            err,
            "Error! Unable to " + (el.active ? "archive." : "unarchive.")
          )
        )
      );
  };

  const goDelete = (el) => {
    Axios.delete("/quick-entries/", { data: el })
      .then((res) => {
        setRefetch((prev) => !prev);
        alertSuccess("Successfully deleted!");
      })
      .catch((err) => {
        alertError(getError(err, "Error! Unable to delete."));
      });
  };

  const confirmDelete = (el) =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Delete",
      okBtnColor: "secondary",
      onOkClicked: () => goDelete(el),
      title: "Delete",
      message: `Are you sure you want to delete this entry?`,
    }));

  const confirmArchive = (el) =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: el.active ? "Archive" : "Unarchive",
      okBtnColor: "secondary",
      onOkClicked: () => goArchive(el),
      title: el.active ? "Archive" : "Unarchive",
      message: `Are you sure you want to ${
        el.active ? "archive" : "unarchive"
      } this entry?`,
    }));

  const mapQeToDetail = (qe) => {
    let qte = {};
    qte.id = qe.entrySid;
    qte.reason = qe.reason;
    qte.active = qe.active;
    qte.type = qe.type;
    qte.isInvoiced = qe.isInvoiced;
    qte.caseDto = caseList.find((ca) => ca.id === qe.caseSid);
    qte.staff = staffList.find((st) => st.id === qe.personSid);
    qte.actExpCode = qe.activityCodeSid;
    qte.desc = qe.servicePerformed;
    qte.billedHours = qe.billedHour;
    qte.hours = qe.hour;
    qte.billedMinutes = qe.billedMinute;
    qte.minutes = qe.minute;
    qte.amount = qe.amount;
    qte.netAmount = qe.netAmount;
    qte.discountAmount = qe.discountAmount;
    qte.date = qe.date;
    qte.rate = qe.rate;
    return qte;
  };

  const openAuditModal = (timeExpense) => {
    setAuditOptions({
      open: true,
      moduleType: Modules.QTE,
      sid: timeExpense.entrySid,
    });
  };

  return (
    <TableContainer className={clsx(className, "TimeEntryTable")}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Service Performed</TableCell>
            {!hideClockedTimeCol && (
              <TableCell align="right">Clocked Time (Hr:Min)</TableCell>
            )}
            <TableCell align="right">Billable Time (Hr:Min)</TableCell>
            {!hideStaffCol && <TableCell>Lawyer</TableCell>}
            {!hideCaseCol && <TableCell>Case</TableCell>}
            <TableCell>Date</TableCell>
            <TableCell>Invoiced In</TableCell>
            <TableCell align="right">
              Rate
              <br />
              ($/hour)
            </TableCell>
            <TableCell align="right">
              Amount
              <br />
              ($)
            </TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {data && data.length > 0 ? (
            data.map((qe) => (
              <TableRow
                key={qe.entrySid}
                hover={qe.active}
                style={{ opacity: qe.active ? 1 : 0.7 }}
              >
                <TableCell>
                  {qe.servicePerformed}
                  {!qe.active && (
                    <Tooltip title="This is an archived item">
                      <SpanForTooltip
                        style={{
                          marginLeft: "6px",
                        }}
                      >
                        <HelpRounded
                          color="disabled"
                          style={{ fontSize: "18px" }}
                        />
                      </SpanForTooltip>
                    </Tooltip>
                  )}
                </TableCell>
                {!hideClockedTimeCol && (
                  <TableCell align="right" className={classes.number}>
                    {`${qe.hour}:${padZero(qe.minute, 2)}`}
                  </TableCell>
                )}
                <TableCell align="right" className={classes.number}>
                  {`${
                    qe.billedHour === null ? qe.hour : qe.billedHour
                  }:${padZero(
                    qe.billedMinute === null ? qe.minute : qe.billedMinute,
                    2
                  )}`}
                </TableCell>
                {!hideStaffCol && (
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    {qe.fullName}
                    <br />
                    {qe.email}
                  </TableCell>
                )}
                {!hideCaseCol && (
                  <TableCell>
                    <Tooltip arrow title={qe.caseName}>
                      <SpanForTooltip
                        className={classes.secondaryColor}
                        style={{ letterSpacing: "1px", fontSize: "0.875rem" }}
                      >
                        Ref#{qe.caseRef}
                      </SpanForTooltip>
                    </Tooltip>
                  </TableCell>
                )}
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  {qe.date && formatDate(qe.date, DateFormats.MEDIUM)}
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  {qe.invoiceSid && (
                    <Tooltip
                      arrow
                      title={
                        qe.isInvoiceInDraft
                          ? "DRAFT"
                          : qe.invoiceTypeLong.toUpperCase()
                      }
                    >
                      <SpanForTooltip className={classes.textSecondary}>
                        {qe.invoiceNumber === "-" ? "(Auto)" : qe.invoiceNumber}
                      </SpanForTooltip>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="right" className={classes.number}>
                  <Tooltip
                    arrow
                    title={
                      qe.customHourlyRate
                        ? "Custom Hourly Rate"
                        : "Global Hourly Rate"
                    }
                  >
                    <SpanForTooltip>{formatDecimal(qe.rate)}</SpanForTooltip>
                  </Tooltip>
                </TableCell>
                <TableCell align="right" className={classes.number}>
                  {formatDecimal(qe.amount)}
                </TableCell>
                <TableCell align="right" className={classes.number}>
                  <PopupMenuButton
                    size="small"
                    color="default"
                    isIconButton
                    menuItems={[
                      {
                        text: "View Details",
                        icon: <VisibilityRounded fontSize="small" />,
                        handler: () => {
                          if (qe) {
                            setSelectedEntry(mapQeToDetail(qe));
                            setEntryDetail(true);
                          }
                        },
                      },
                      currentUser.roleType.roleTypeName ===
                        RoleTypes.MANAGER && {
                        text: "View Audit History",
                        icon: <HistoryRounded fontSize="small" />,
                        handler: () => qe && openAuditModal(qe),
                      },
                      {
                        text: "Edit",
                        icon: <CreateRounded fontSize="small" />,
                        disabled:
                          Boolean(
                            qe.isInvoiced &&
                              !qe.isInvoiceInDraft &&
                              qe.invoiceSid
                          ) || !qe.active,
                        tooltip:
                          qe.isInvoiced &&
                          !qe.isInvoiceInDraft &&
                          qe.invoiceSid &&
                          `This entry is included in the finalized invoice: ${
                            qe.invoiceNumber && qe.invoiceNumber !== "-"
                              ? qe.invoiceNumber
                              : qe.invoiceSid
                          }`,
                        handler: () => {
                          setSelectedTimeExp(mapQeToDetail(qe));
                          setOpenQte(true);
                        },
                      },
                      {
                        text: qe.active ? "Archive" : "Unarchive",
                        icon: <ArchiveRounded fontSize="small" />,
                        disabled: Boolean(qe.isInvoiced && qe.invoiceSid),
                        tooltip:
                          qe.isInvoiced &&
                          qe.invoiceSid &&
                          `This entry is included in the invoice: ${
                            qe.invoiceNumber && qe.invoiceNumber !== "-"
                              ? qe.invoiceNumber
                              : qe.invoiceSid
                          }`,
                        handler: () => confirmArchive(mapQeToDetail(qe)),
                      },
                      {
                        text: "Delete",
                        icon: <DeleteRounded fontSize="small" />,
                        disabled: Boolean(qe.isInvoiced && qe.invoiceSid),
                        tooltip:
                          qe.isInvoiced &&
                          qe.invoiceSid &&
                          `This entry is included in the invoice: ${
                            qe.invoiceNumber && qe.invoiceNumber !== "-"
                              ? qe.invoiceNumber
                              : qe.invoiceSid
                          }`,
                        handler: () => confirmDelete(mapQeToDetail(qe)),
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoDataRow
              colSpan={
                9 -
                (hideStaffCol ? 1 : 0) -
                (hideCaseCol ? 1 : 0) -
                (hideClockedTimeCol ? 1 : 0)
              }
            />
          )}
        </TableBody>
      </Table>
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="lg"
        isOpen={entryDetail}
        title="Quick Time Entry Details"
        handleClose={() => setEntryDetail(false)}
        hideFooter
      >
        <QuickEntryDetail entry={selectedEntry} />
      </CustomModal>
    </TableContainer>
  );
};

export default TimeEntryTable;
