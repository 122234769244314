import { TableCell, TableRow, Typography } from "@material-ui/core";
import React from "react";

const NoDataRow = ({ colSpan = 3, className, message = "No Data" }) => {
  return (
    <TableRow className={className}>
      <TableCell colSpan={colSpan}>
        <Typography color="error" align="center" variant="body2" noWrap>
          {message}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default NoDataRow;
