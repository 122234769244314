import React from "react";
import clsx from "clsx";
import {
  IconButton,
  makeStyles,
  List,
  Divider,
  SwipeableDrawer,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { drawerWidth } from "../config/appConfig";
import routes from "../config/routes";
import MenuItemWithRoute from "./MenuItemWithRoute";
import { useContext } from "react";
import { DataContext } from "../DataContext";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
}));

const AppSidebar = ({ open, handleDrawerOpen, handleDrawerClose }) => {
  const classes = useStyles();
  const { currentUser } = useContext(DataContext);
  return (
    <SwipeableDrawer
      anchor="left"
      variant="temporary"
      classes={{
        paper: clsx(
          "AppSidebar",
          classes.drawerPaper,
          !open && classes.drawerPaperClose
        ),
      }}
      open={open}
      onOpen={handleDrawerOpen}
      onClose={handleDrawerClose}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider />
      <div
        role="presentation"
        onClick={handleDrawerClose}
        onKeyDown={handleDrawerClose}
      >
        <List>
          {currentUser &&
            currentUser.roleType &&
            currentUser.roleType.roleTypeName &&
            routes
              .filter((r) =>
                r.allowedRoles.includes(currentUser.roleType.roleTypeName)
              )
              .map((route, i) => <MenuItemWithRoute key={i} {...route} />)}
        </List>
      </div>
    </SwipeableDrawer>
  );
};

export default AppSidebar;
