import React, { useContext } from "react";
import clsx from "clsx";
import {
  Grid,
  TextField,
  makeStyles,
  Collapse,
  Typography,
  Tooltip,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DataContext } from "../../DataContext";
import CommonSelect from "../../components/CommonSelect";
import { getDefaultTimeExpObj } from "../../model";
import NumberInput from "../../components/NumberInput";
import ComplexSelect from "../../components/ComplexSelect";
import { formatDateDash } from "../../utils/dateUtils";
import { formatDecimal } from "../../utils/functions";
import { RateType } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  p175LessBottom: {
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  lb: {
    marginLeft: theme.spacing(3),
  },
}));

const QuickTimeEntryForm = ({
  disableStaffSelection,
  disableTime,
  disableDate,
  className,
  hideRateAndAmount,
  data = getDefaultTimeExpObj(),
  handleChange = () => {},
  handleChangeByKey = () => {},
  error = {},
  onBlur = () => {},
}) => {
  const classes = useStyles();
  const { caseList, staffList, actCodes, expCodes } = useContext(DataContext);

  const getActExpCodes = (code) => {
    if (expCodes.find((e) => e.id === code)) {
      return [expCodes.find((e) => e.id === code), ...actCodes];
    }
    return actCodes;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form className={clsx("QuickTimeEntryForm", className)}>
        <Grid container>
          <Grid item xs={12} md={6} className="p175">
            <ComplexSelect
              fullWidth
              required
              name="caseDto"
              label="Case"
              options={caseList}
              value={data.caseDto}
              error={error.caseDto}
              onChange={handleChange}
              onBlur={onBlur}
            />
          </Grid>
          <Grid item xs={12} md={6} className="p175">
            <ComplexSelect
              fullWidth
              required
              name="staff"
              label="Staff"
              options={staffList.filter((el) => el.rate !== 0)}
              value={data.staff}
              error={error.staff}
              onChange={handleChange}
              onBlur={onBlur}
              disabled={disableStaffSelection}
            />
          </Grid>
          <Grid item xs={12} md={6} className="p175">
            <KeyboardDatePicker
              fullWidth
              margin="none"
              format="dd-MMM-yyyy"
              label="Date"
              value={new Date(data.date)}
              error={error.date}
              onChange={(d) => handleChangeByKey("date", formatDateDash(d))}
              onBlur={onBlur}
              disabled={disableDate}
              disableFuture
            />
          </Grid>
          <Grid item xs={12} md={6} className="p175">
            <CommonSelect
              fullWidth
              required
              label="Activity Code"
              options={data.id ? getActExpCodes(data.actExpCode) : actCodes}
              name="actExpCode"
              value={data.actExpCode}
              error={error.actExpCode}
              onChange={handleChange}
              onBlur={onBlur}
            />
          </Grid>
          <Grid item xs={12} md={6} className="p175">
            <div className="d-flex align-items-center">
              <NumberInput
                className="flex-grow"
                name="hours"
                label="Clocked Hours"
                type="number"
                value={data.hours}
                error={error.hours}
                onChange={handleChange}
                onBlur={onBlur}
                min={0}
                disabled={disableTime}
              />
              <NumberInput
                className={clsx("flex-grow", classes.lb)}
                name="minutes"
                label="Clocked Minutes"
                value={data.minutes}
                error={error.minutes}
                onChange={handleChange}
                onBlur={onBlur}
                min={0}
                max={59}
                disabled={disableTime}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="p175">
            <div className="d-flex align-items-center">
              <NumberInput
                className="flex-grow"
                name="billedHours"
                label="Billed Hours"
                type="number"
                value={data.billedHours}
                error={error.billedHours}
                onChange={handleChange}
                onBlur={onBlur}
                min={0}
              />
              <NumberInput
                className={clsx("flex-grow", classes.lb)}
                name="billedMinutes"
                label="Billed Minutes"
                value={data.billedMinutes}
                error={error.billedMinutes}
                onChange={handleChange}
                onBlur={onBlur}
                min={0}
                max={59}
              />
            </div>
          </Grid>
        </Grid>
        {/* {data.billedHours === data.hours &&
          data.billedMinutes === data.minutes ? (
            <></>
          ) : (
            <Grid item xs={12} md={12} className="p175">
              <TextField
                label="Reason for Difference in Clocked and Billed Period"
                multiline={true}
                fullWidth
                required
                name="reason"
                value={data.reason}
                error={error.reason}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
          )} */}
        <Collapse
          in={
            parseInt(data.billedHours) !== parseInt(data.hours) ||
            parseInt(data.billedMinutes) !== parseInt(data.minutes)
          }
        >
          <Grid container>
            <Grid item xs={12} md={12} className="p175">
              <TextField
                label="Reason for Difference in Clocked and Billed Period"
                multiline={true}
                fullWidth
                required
                name="reason"
                value={data.reason ? data.reason : ""}
                error={error.reason}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
          </Grid>
        </Collapse>
        <Grid container>
          <Grid item xs={12} md={12} className="p175">
            <TextField
              label="Service Performed"
              multiline={true}
              fullWidth
              required
              name="desc"
              value={data.desc}
              error={error.desc}
              onChange={handleChange}
              onBlur={onBlur}
            />
          </Grid>
        </Grid>
        {!hideRateAndAmount && (
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={6} className="p175">
              <Grid container>
                <Grid item xs={6} className={classes.p175LessBottom}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    Hourly Rate ($) :
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.p175LessBottom}>
                  <Tooltip
                    arrow
                    title={
                      data.rateType === RateType.CUSTOM
                        ? "Custom Hourly Rate from addtional lawyers"
                        : "Staff's Global Hourly Rate"
                    }
                  >
                    <Typography
                      variant="body1"
                      color={error.rate ? "error" : "primary"}
                      align="right"
                      className="e360-number"
                      style={{ fontWeight: 600 }}
                      noWrap
                    >
                      {formatDecimal(data.rate)}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} className={classes.p175LessBottom}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    Amount ($) :
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.p175LessBottom}>
                  <Typography
                    variant="body1"
                    color={error.amount ? "error" : "primary"}
                    align="right"
                    className="e360-number"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    {formatDecimal(data.amount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </form>
    </MuiPickersUtilsProvider>
  );
};

export default QuickTimeEntryForm;
