import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  makeStyles,
  Checkbox,
  Typography,
  TableCell,
  TableRow,
  IconButton,
  TextareaAutosize,
  Grid,
} from "@material-ui/core";
import { RemoveCircleOutlineRounded, EditOutlined } from "@material-ui/icons";

import { DateFormats } from "../../utils/constants";
import { formatDate } from "../../utils/dateUtils";
import {
  formatDecimal,
  padZero,
  getDefaultErrorObj,
} from "../../utils/functions";

import { DataContext } from "../../DataContext";

import { useHover } from "../../hooks/";
import { getDefaultInvoiceObj } from "../../model";
import NumberInput from "../../components/NumberInput";
import SlideUp from "../../components/SlideUp";

const useStyles = makeStyles((theme) => ({
  taxChkbx: { margin: "0 0 0 8px", padding: "4px" },
  editBtn: {
    paddingLeft: 10,
  },
  customTextAreaStyle: {
    width: "100%",
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    border: "1px solid",
    borderColor: theme.palette.divider,
    fontFamily: theme.typography.fontFamily,
    fontSize: "14px",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    outline: "none",
    appearance: "none",
  },
}));

const TimeExpenseEntryItem = ({
  className,
  tee = {},
  index,
  group,
  handleRemove = () => {},
  toggleIsTaxable = () => {},
  toggleIsDiscountable = () => {},
  fetchInvoiceList = () => {},
  invoice = getDefaultInvoiceObj(),
  setInvoice = () => {},
  recalculateAndSet = () => {},
}) => {
  const classes = useStyles();

  const [hoverRef, isHovered] = useHover();

  const [hoverRefTime, isHoveredTime] = useHover();

  const [hoverRefAmount, isHoveredAmount] = useHover();

  const [editDesc, setEditDesc] = useState(false);
  const [editTime, setEditTime] = useState(false);
  const [editAmount, setEditAmount] = useState(false);

  const [slide, setSlide] = useState(false);

  const [editEntryObj, setEditEntryObj] = useState({
    desc: tee.desc,
    hour: tee.billedHours,
    minute: tee.billedMinutes,
    reason: tee.reason,
    amount: tee.amount,
  });

  const [error, setError] = useState(getDefaultErrorObj({ ...editEntryObj }));

  const { alertWarning } = useContext(DataContext);

  const _handleRemove = () => {
    handleRemove && handleRemove(tee.id);
  };

  const _toggleIsTaxable = () => {
    toggleIsTaxable && toggleIsTaxable(tee, index);
  };

  const _toggleIsDiscountable = () => {
    toggleIsDiscountable && toggleIsDiscountable(tee, group);
  };

  const handleChange = (event) => {
    event.persist && event.persist();
    let name = event.target.name;
    let value = event.target.value;
    setEditEntryObj({
      ...editEntryObj,
      [name]: value,
    });
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };
  useEffect(() => {
    if (!slide) {
      updateEntry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide]);

  const updateEntry = () => {
    if (
      editEntryObj.desc.trim().length === 0 ||
      (editEntryObj.hour === 0 && editEntryObj.minute === 0) ||
      ((editEntryObj.hour !== tee.hours ||
        editEntryObj.minute !== tee.minutes) &&
        slide === false &&
        editEntryObj.reason.length === 0) ||
      +editEntryObj.amount <= 0
    ) {
      if (editEntryObj.desc.trim().length === 0) {
        setEditEntryObj({
          ...editEntryObj,
          desc: tee.desc,
        });
        alertWarning(
          `Invalid! ${
            tee.type === "time" ? "Service Performed" : "Description"
          } cannot be empty.`
        );
      } else if (editEntryObj.hour === 0 && editEntryObj.minute === 0) {
        setEditEntryObj({
          ...editEntryObj,
          hour: tee.billedHours,
          minute: tee.billedMinutes,
        });
      } else if (
        (editEntryObj.hour !== tee.hours ||
          editEntryObj.minute !== tee.minutes) &&
        slide === false &&
        editEntryObj.reason.length === 0
      ) {
        setEditEntryObj({
          ...editEntryObj,
          reason: tee.reason,
          hour: tee.billedHours,
          minute: tee.billedMinutes,
        });
        alertWarning(
          "Invalid! Reason cannot be empty if billed and clocked time is not the same."
        );
      } else if (+editEntryObj.amount <= 0) {
        setEditEntryObj({
          ...editEntryObj,
          amount: tee.amount,
          netAmount: tee.netAmount,
        });
        alertWarning("Invalid! Amount must be greater than 0.");
      } else {
        alertWarning("Invalid! Check your input data entered.");
      }
    } else {
      const type = tee.type === "time" ? "time" : "exp";
      tee.billedHours = editEntryObj.hour;
      tee.billedMinutes = editEntryObj.minute;
      tee.reason = editEntryObj.reason;
      tee.desc = editEntryObj.desc;
      tee.amount =
        type === "time"
          ? +tee.rate * +(+tee.billedHours + +tee.billedMinutes / 60).toFixed(2)
          : editEntryObj.amount;
      tee.netAmount = +(tee.amount - +tee.discountAmount).toFixed(2);
      const newInv = { ...invoice };
      const oldInd = newInv.timeExpenseEntry.findIndex(
        (el) => el.id === tee.id
      );
      // const entry = newInv.timeExpenseEntry[oldInd];
      newInv.timeExpenseEntry.splice(oldInd, 1, tee);
      recalculateAndSet(newInv, false);
    }
  };

  return (
    <>
      <TableRow className={clsx("TimeExpenseEntryItem", className)}>
        <TableCell>
          <IconButton
            size="small"
            color="secondary"
            style={{ margin: "0 0 0 8px", padding: "4px" }}
            onClick={_handleRemove}
          >
            <RemoveCircleOutlineRounded fontSize="small" color="error" />
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="textSecondary" noWrap>
            {formatDate(tee?.date, DateFormats.MEDIUM)}
          </Typography>
        </TableCell>
        <TableCell ref={hoverRef}>
          {editDesc ? (
            <TextareaAutosize
              className={classes.customTextAreaStyle}
              name="desc"
              rowsMin={4}
              rowsMax={6}
              value={editEntryObj.desc}
              onChange={handleChange}
              onBlur={() => {
                setEditDesc(false);
                updateEntry();
              }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              {tee.desc}
              <IconButton
                size="small"
                onClick={() => {
                  // setField("desc");
                  // onEditIconClicked();
                  setEditDesc(true);
                }}
                className={classes.editBtn}
                style={{
                  visibility: isHovered ? "visible" : "hidden",
                }}
              >
                <EditOutlined />
              </IconButton>
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="textSecondary">
            {tee?.staff?.value}
          </Typography>
        </TableCell>
        <TableCell align="right" ref={hoverRefTime} width="15%">
          {tee.type === "time" ? (
            editTime ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    className="flex-grow"
                    name="hour"
                    type="number"
                    value={editEntryObj.hour}
                    error={error.hour}
                    onChange={(evt) => {
                      handleChange(evt);
                      tee.hours === evt.target.value &&
                      tee.minutes === editEntryObj.minute
                        ? setSlide(false)
                        : setSlide(true);
                    }}
                    onBlur={() => {
                      setEditTime(false);
                      updateEntry();
                    }}
                    min={0}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NumberInput
                    className={clsx("flex-grow", classes.lb)}
                    name="minute"
                    value={editEntryObj.minute}
                    error={error.minute}
                    onChange={(evt) => {
                      handleChange(evt);
                      parseInt(tee.minutes) === parseInt(evt.target.value) &&
                      parseInt(tee.hours) === parseInt(editEntryObj.hour)
                        ? setSlide(false)
                        : setSlide(true);
                    }}
                    onBlur={() => {
                      setEditTime(false);
                      updateEntry();
                    }}
                    min={0}
                    max={59}
                  />
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant="body2"
                color="textSecondary"
                className="e360-number"
              >
                {`${tee.billedHours}:${padZero(tee.billedMinutes, 2)}`}
                <IconButton
                  size="small"
                  onClick={() => {
                    setEditTime(true);
                    setSlide(
                      tee.billedHours !== tee.hours ||
                        tee.billedMinutes !== tee.minutes
                    );
                  }}
                  onBlur={() => {
                    setSlide(false);
                    setEditTime(false);
                    updateEntry();
                  }}
                  className={classes.editBtn}
                  style={{
                    visibility: isHoveredTime ? "visible" : "hidden",
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Typography>
            )
          ) : (
            <Typography variant="body2" color="textSecondary">
              (Expense)
            </Typography>
          )}
        </TableCell>
        <TableCell align="right" ref={hoverRefAmount}>
          {editAmount ? (
            <NumberInput
              className={clsx("flex-grow", classes.lb)}
              name="amount"
              value={editEntryObj.amount}
              error={error.amount}
              onChange={(evt) => {
                handleChange(evt);
              }}
              onBlur={() => {
                setEditAmount(false);
                updateEntry();
              }}
              min={0}
            />
          ) : (
            <Typography
              variant="body2"
              color="textSecondary"
              className="e360-number"
            >
              {tee && tee.amount ? formatDecimal(tee.amount) : 0}
              {tee.type === "exp" && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setEditAmount(true);
                  }}
                  onBlur={() => {
                    setEditAmount(false);
                    updateEntry();
                  }}
                  className={classes.editBtn}
                  style={{
                    visibility: isHoveredAmount ? "visible" : "hidden",
                  }}
                >
                  <EditOutlined />
                </IconButton>
              )}
            </Typography>
          )}
        </TableCell>
        <TableCell align="right">
          <Typography
            variant="body2"
            color="textSecondary"
            className="e360-number"
          >
            {tee && tee.netAmount ? formatDecimal(tee.netAmount) : 0}
          </Typography>
        </TableCell>
        <TableCell width="5">
          <Checkbox
            color="primary"
            size="small"
            className={classes.taxChkbx}
            onClick={_toggleIsTaxable}
            checked={tee.isTaxable}
            inputProps={{
              "aria-label": "taxable",
            }}
          />
        </TableCell>
        {tee && tee.type === "time" ? (
          <TableCell width="5">
            <Checkbox
              color="primary"
              size="small"
              className={classes.taxChkbx}
              onClick={_toggleIsDiscountable}
              checked={tee.isDiscountable}
              inputProps={{
                "aria-label": "discountable",
              }}
            />
          </TableCell>
        ) : (
          <TableCell width="5"></TableCell>
        )}
      </TableRow>
      {slide && (
        <SlideUp direction="down" in={slide}>
          <TableRow
            item
            xs={12}
            md={12}
            style={{ padding: "14px", height: "0" }}
          >
            <TableCell colSpan={9}>
              <TextareaAutosize
                className={classes.customTextAreaStyle}
                rowsMin={2}
                rowsMax={6}
                name="reason"
                error={error.reason}
                value={editEntryObj.reason}
                onChange={handleChange}
                onBlur={() => {
                  setSlide(false);
                  setEditTime(false);
                }}
              />
            </TableCell>
          </TableRow>
        </SlideUp>
      )}
      {/* <CustomModal
        maxWidth="sm"
        isOpen={openEditModal}
        title={"Update " + tee.type === "time" ? "Time" : "Expense" + " Entry"}
        saveBtnTitle="Update"
        closeBtnTitle="Cancel"
        handleSave={() => updateEntry()}
        handleClose={() => setOpenEditModal(false)}
      >
        {field && field === "desc" && (
          <TextareaAutosize
            className={classes.desc}
            name="desc"
            rowsMin={4}
            rowsMax={6}
            value={editEntryObj.desc}
            onChange={handleChange}
          />
        )}
        {field && field === "time" && (
          <>
            <Grid container>
              <Grid item xs={12} md={6} style={{ padding: "14px" }}>
                <TextField
                  label="Billable Hours"
                  name="hour"
                  value={editEntryObj.hour}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "14px" }}>
                <TextField
                  label="Billable Minutes"
                  name="minute"
                  value={editEntryObj.minute}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {(editEntryObj.hour !== tee.hours ||
                editEntryObj.minute !== tee.minutes) && (
                <Grid item xs={12} md={12} style={{ padding: "14px" }}>
                  <TextField
                    label="Reason of Defference in Billable and Clock Time"
                    name="reason"
                    value={editEntryObj.reason}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </CustomModal> */}
    </>
  );
};

export default TimeExpenseEntryItem;
