import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  ArchiveRounded,
  BackspaceOutlined,
  CancelPresentationRounded,
  CreateRounded,
  DeleteRounded,
  DescriptionRounded,
  HistoryRounded,
  OfflinePinRounded,
  VisibilityRounded,
} from "@material-ui/icons";
import Axios from "axios";
import docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import JSzip from "jszip";
import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { format } from "date-fns";
import invTemplate from "../../../assets/templates/invoice_template.docx";
import subInvTemplate from "../../../assets/templates/sub_invoice_template.docx";
import CustomModal from "../../../components/CustomModal";
import InvoiceTable from "../../../components/InvoiceTable";
import SlideUp from "../../../components/SlideUp";
import {
  DataContext,
  PopupContext,
  SettingContext,
} from "../../../DataContext";
import { getDefaultClientObj, getDefaultInvoiceObj } from "../../../model";
import { Modules, RoleTypes } from "../../../utils/constants";
import { getDefaultErrorObj, getError } from "../../../utils/functions";
import InvoiceDetail from "../../cases/InvoiceDetail";
import InvoiceForm from "../../cases/InvoiceForm";

export const InvoiceContent = ({
  isLoading,
  invoiceData = [],
  setRefetch = (v) => {},
  hidePaymentDueCol = true,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    countryList,
    clientList,
    caseList,
    setTimeExpList,
    invoiceTypes,
    alertSuccess,
    alertWarning,
    alertError,
    currentUser,
    entryListCache,
    setEntryListCache,
  } = useContext(DataContext);

  const { openDialog, setAuditOptions, setOpenSplitInv, setInvToSplit } =
    useContext(PopupContext);
  const { setProcessing } = useContext(SettingContext);

  const [invoiceTemp, setInvoiceTemp] = useState(getDefaultInvoiceObj());
  const [client, setClient] = useState(getDefaultClientObj());
  const [caseInfo, setCaseInfo] = useState({});
  const [isInvoiceFormOpen, setIsInvoiceFormOpen] = useState(false);
  const [isInvoiceDetailOpen, setIsInvoiceDetailOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedSubInv, setSelectedSubInv] = useState(null);
  const [error, setError] = useState(
    getDefaultErrorObj(getDefaultInvoiceObj())
  );

  const fetchQuickEntriesByInvoiceSid = async (inv) => {
    if (inv && inv.id) {
      if (entryListCache[inv.id] && entryListCache[inv.id].length > 0) {
        return entryListCache[inv.id];
      }
      try {
        setProcessing(true);
        const res = await Axios.get(
          "/view-entries/get-by-invoice-sid-from-ite/" + inv.id
        );
        setProcessing(false);
        setEntryListCache((prev) => ({ ...prev, [inv.id]: res.data }));
        return res.data;
      } catch (err) {
        setProcessing(false);
        alertError(
          getError(err, "Fail to load time/expense entries for this invoice")
        );
        return [];
      }
    } else return [];
  };

  const mapClient = (invoice) => {
    client.clientId = invoice.clientId;
    client.billingAddressOne = invoice.billingAddressOne;
    client.billingAddressTwo = invoice.billingAddressTwo;
    client.billingCity = invoice.billingCity;
    client.billingState = invoice.billingState;
    client.billingCountry = countryList.find(
      (c) => c.id === invoice.billingCountrySid
    );
    client.billingPostalCode = invoice.billingPostalCode;
    return client;
  };

  const mapCase = (invoice) => {
    caseInfo.caseRef = invoice.caseRef;
    caseInfo.client = clientList.find((c) => c.id === invoice.clientSid);
    caseInfo.name = invoice.caseName;
    return caseInfo;
  };

  const openInvoiceDetailModal = async (invoice, subInv = null) => {
    const inv = { ...invoice };
    inv.timeExpenseEntry = await fetchQuickEntriesByInvoiceSid(invoice);
    ReactDOM.unstable_batchedUpdates(() => {
      setSelectedInvoice(inv);
      setSelectedSubInv(subInv);
      setClient(mapClient(invoice));
      setCaseInfo(mapCase(invoice));
      setIsInvoiceDetailOpen(true);
    });
  };

  const openEditModal = async (invoice) => {
    const inv = { ...invoice };
    inv.timeExpenseEntry = await fetchQuickEntriesByInvoiceSid(invoice);
    ReactDOM.unstable_batchedUpdates(() => {
      setError(getDefaultErrorObj(getDefaultInvoiceObj()));
      setInvoiceTemp(inv);
      setClient(mapClient(invoice));
      setCaseInfo(mapCase(invoice));
      setIsInvoiceFormOpen(true);
    });
    setTimeout(() => {
      fetchTimeExpList(invoice.caseSid);
    }, 500);
  };

  const openAuditModal = (invoiceSid) =>
    setAuditOptions({
      open: true,
      moduleType: Modules.INVOICE,
      sid: invoiceSid,
    });

  const onBlur = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const saveOrEditInvoice = (invTemp) => {
    try {
      if (invTemp.isRefManual && !invTemp.invoiceNum) {
        setError((prev) => ({ ...prev, invoiceNum: true }));
      }
      if (invTemp.timeExpenseEntry.length === 0) {
        alertWarning("Please select time/expense entry.");
      } else if (invTemp.isRefManual && !invTemp.invoiceNum) {
        alertWarning("Please enter manual invoice number.");
      } else if (isNaN(invTemp.totalAmount)) {
        alertWarning("Invalid numbers!");
      } else if (
        invTemp.amountBeforeTaxAndDiscount === null ||
        invTemp.amountBeforeTaxAndDiscount === 0
      ) {
        alertWarning("Subtotal cannot be $0");
      } else {
        const invType = invoiceTypes.find(
          (e) => e.id === invoiceTemp.invoiceType
        );
        const caseDto = caseList.find((e) => e.id === invoiceTemp.caseSid);
        const invTempToSave = {
          ...invTemp,
          invoiceType: invType,
          caseDto: caseDto,
          discountPercentage: Number(invTemp.discountPercentage),
          discountAmount: Number(invTemp.discountAmount),
          taxPercentage: Number(invTemp.taxPercentage),
          taxAmount: Number(invTemp.taxAmount),
          totalAmount: Number(invTemp.totalAmount),
        };
        if (invTempToSave.id) {
          // UPDATE
          setProcessing(true);
          Axios.put("/invoices", invTempToSave)
            .then((res) => {
              setRefetch((prev) => !prev);
              setIsInvoiceFormOpen(false);
              alertSuccess("Successfully updated!");
              setSelectedInvoice(res.data);
              setProcessing(false);
              setEntryListCache((prev) => ({
                ...prev,
                [res.data.id]: undefined,
              }));
            })
            .catch((err) => {
              alertError(getError(err, "Error! Failed to update"));
              setProcessing(false);
            });
        } else {
          // SAVE
          setProcessing(true);
          Axios.post("/invoices", invTempToSave)
            .then((res) => {
              setRefetch((prev) => !prev);
              setIsInvoiceFormOpen(false);
              alertSuccess("Successfully saved!");
              setSelectedInvoice(res.data);
              setProcessing(false);
            })
            .catch((err) => {
              alertError(getError(err, "Error! Failed to save"));
              setProcessing(false);
            });
        }
      }
    } catch (error) {
      alertError(`Failed to ${invTemp.id ? "update" : "save"}`);
    }
  };

  const clearReferenceNo = () => {
    setInvoiceTemp((prev) => ({
      ...prev,
      invoiceNum: "",
    }));
    setError((prev) => ({ ...prev, invoiceNum: false }));
  };

  const goDelete = (id) => {
    setProcessing(true);
    Axios.delete("/invoices/" + id)
      .then(() => {
        setRefetch((prev) => !prev);
        setSelectedInvoice(null);
        setProcessing(false);
        alertSuccess("Successfully deleted!");
        setEntryListCache((prev) => ({ ...prev, [id]: undefined }));
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, "Error! Unable to delete"));
      });
  };

  const goArchive = (inv) => {
    setProcessing(true);
    Axios.patch(
      "/invoices/" + (inv.active ? "archive/" : "unarchive/") + inv?.id
    )
      .then((res) => {
        setRefetch((prev) => !prev);
        setSelectedInvoice(null);
        setProcessing(false);
        alertSuccess(
          "Successfully " + (inv.active ? "archived!" : "unarchived!")
        );
      })
      .catch((err) => {
        alertError(
          getError(
            err,
            "Error! Unable to " + (inv.active ? "archive." : "unarchive.")
          )
        );
        setProcessing(false);
      });
  };

  const confirmDelete = (id) =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Delete",
      okBtnColor: "secondary",
      onOkClicked: () => goDelete(id),
      title: "Delete",
      message: `Are you sure you want to delete this invoice?`,
    }));

  const confirmArchive = (inv) =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: inv.active ? "Archive" : "Unarchive",
      okBtnColor: "secondary",
      onOkClicked: () => goArchive(inv),
      title: inv.active ? "Archive" : "Unarchive",
      message: `Are you sure you want to ${
        inv.active ? "archive" : "unarchive"
      } this invoice?`,
    }));

  const confirmFinalization = (id) =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Finalize",
      okBtnColor: "primary",
      onOkClicked: () => finalizeInvoice(id),
      title: "Finalization",
      message: `The invoice will not be editable once marked as finalized and will be moved to UNPAID category. Are you sure you want to finalize this invoice?`,
    }));

  const showPartiesToSplit = (inv) => {
    setInvToSplit(inv);
    setOpenSplitInv(true);
  };

  const calculateTaxableSubtotal = (quickEntries, isTaxable) => {
    let subTotal = 0;
    if (quickEntries && quickEntries.length > 0) {
      quickEntries.forEach((qe) => {
        if (qe.isTaxable === isTaxable) subTotal += qe.netAmount;
      });
    }
    return subTotal;
  };

  const getTaxableExpenses = (quickEntries, isTaxable) => {
    let expenses = [];
    if (quickEntries && quickEntries.length > 0) {
      quickEntries.forEach((qe) => {
        if (qe.type === "exp" && qe.isTaxable === isTaxable) expenses.push(qe);
      });
    }
    return expenses;
  };

  const generateInvoice = (invoice, subInv = null) => {
    setProcessing(true);
    Axios.get(
      "/invoices/" +
        invoice.id +
        "/export" +
        (subInv ? `?subInvoiceSid=${subInv.subInvoiceSid}` : "")
    )
      .then((res) => {
        const docData = { ...res.data };
        const sortedServices = docData.services.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

        const mappedServices = sortedServices?.map((s) => ({
          ...s,
          date: format(new Date(s.date), "dd/MM/y"),
        }));

        docData.services = mappedServices;

        fetch(subInv ? subInvTemplate : invTemplate)
          .then((d) => d.arrayBuffer())
          .then((ab) => {
            let zip = new JSzip(ab);
            let doc = new docxtemplater(zip);

            docData.taxableSubtotal = calculateTaxableSubtotal(
              invoice.timeExpenseEntry,
              true
            );
            docData.notTaxableSubtotal = calculateTaxableSubtotal(
              invoice.timeExpenseEntry,
              false
            );
            docData.taxableExpenses = getTaxableExpenses(
              invoice.timeExpenseEntry,
              true
            );
            docData.notTaxableExpenses = getTaxableExpenses(
              invoice.timeExpenseEntry,
              false
            );

            doc.setData(docData);
            doc.render();

            let out = doc.getZip().generate({
              type: "blob",
              mimeType:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            saveAs(
              out,
              `${
                subInv
                  ? subInv.subInvoiceNumber
                  : invoice.invoiceNum === "-"
                  ? "INV-" + invoice.id
                  : invoice.invoiceNum
              }.docx`
            );
          });
        setProcessing(false);
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, "Error! Failed to download"));
      });
  };

  const togglePay = (invoice, payType) => {
    setProcessing(true);
    Axios.post("/invoices/" + invoice.id + "/toggle-pay", { payType })
      .then((res) => {
        setRefetch((prev) => !prev);
        setSelectedInvoice(res.data);
        setProcessing(false);
        alertSuccess(`Successfully marked as ${payType.replace("-", " ")}`);
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, "Error! Unable to update invoice pay type"));
      });
  };

  const togglePayForSubInv = (subInvoiceSid, payType) => {
    setProcessing(true);
    Axios.post("/split-invoices/" + subInvoiceSid + "/toggle-pay", { payType })
      .then((res) => {
        setRefetch((prev) => !prev);
        setProcessing(false);
        alertSuccess(`Successfully marked as ${payType.replace("-", " ")}`);
      })
      .catch((err) => {
        setProcessing(false);
        alertError(
          getError(err, "Error! Unable to update sub invoice pay type")
        );
      });
  };

  const finalizeInvoice = (invoiceSid) => {
    setProcessing(true);
    Axios.post("/invoices/" + invoiceSid + "/finalize-invoice")
      .then((res) => {
        setRefetch((prev) => !prev);
        setSelectedInvoice(res.data);
        setProcessing(false);
        alertSuccess("Successfully finalized!");
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, "Error! Failed to finalize the invoice"));
      });
  };

  const fetchTimeExpList = (caseSid) => {
    Axios.get("/quick-entries/" + caseSid + "?all=false")
      .then((res) => setTimeExpList(res.data))
      .catch(() => setTimeExpList([]));
  };

  const getAllMenuItems = (invoice) => {
    let menuItems = [
      {
        text: "View Invoice",
        icon: <VisibilityRounded fontSize="small" />,
        handler: () => openInvoiceDetailModal(invoice),
      },
      currentUser.roleType.roleTypeName === RoleTypes.MANAGER && {
        text: "View Audit History",
        icon: <HistoryRounded fontSize="small" />,
        handler: () => invoice && openAuditModal(invoice.id),
      },
      invoice.active &&
        invoice.isInDraft && {
          text: "Edit",
          icon: <CreateRounded fontSize="small" />,
          handler: () => openEditModal(invoice),
        },
      !invoice.isInDraft && {
        text: invoice.active ? "Archive" : "Unarchive",
        icon: <ArchiveRounded fontSize="small" />,
        handler: () => confirmArchive(invoice),
      },
      {
        text: "Delete",
        icon: <DeleteRounded fontSize="small" />,
        handler: () => confirmDelete(invoice.id),
      },
      {
        text: "Export Invoice as Word",
        icon: <DescriptionRounded fontSize="small" />,
        handler: () => generateInvoice(invoice),
      },
    ];

    let invoiceType = invoiceTypes.find(
      (el) => el.id === invoice.invoiceType
    )?.value;

    if (invoice.isInDraft) {
      menuItems = [
        ...menuItems,
        ...[
          invoice.active && {
            text: "Finalize Invoice",
            icon: <ArchiveRounded fontSize="small" />,
            handler: () => confirmFinalization(invoice.id),
          },
          invoice.active && {
            text: "Split and Finalize",
            icon: <ArchiveRounded fontSize="small" />,
            handler: () => showPartiesToSplit(invoice),
          },
        ],
      ];
    } else if (invoiceType === "Unpaid") {
      menuItems = [
        ...menuItems,
        invoice.active && {
          text: "Mark as Paid",
          icon: <OfflinePinRounded fontSize="small" />,
          handler: () => togglePay(invoice, "paid"),
        },
        invoice.active && {
          text: "Write Off",
          icon: <CancelPresentationRounded fontSize="small" />,
          handler: () => togglePay(invoice, "written-off"),
        },
      ];
    } else if (invoiceType === "Paid") {
      menuItems = [
        ...menuItems,
        invoice.active && {
          text: "Mark as Unpaid",
          icon: <BackspaceOutlined fontSize="small" />,
          handler: () => togglePay(invoice, "unpaid"),
        },
      ];
    } else {
      menuItems = [
        ...menuItems,
        invoice.active && {
          text: "Mark as Unpaid",
          icon: <BackspaceOutlined fontSize="small" />,
          handler: () => togglePay(invoice, "unpaid"),
        },
        invoice.active && {
          text: "Mark as Paid",
          icon: <OfflinePinRounded fontSize="small" />,
          handler: () => togglePay(invoice, "paid"),
        },
      ];
    }
    return menuItems;
  };

  const getMenuItemsForSubInvoice = (invoice, subInvIndx) => {
    const subInv = invoice.subInvoices[subInvIndx];
    let menuItems = [
      {
        text: "View Invoice",
        icon: <VisibilityRounded fontSize="small" />,
        handler: () => openInvoiceDetailModal(invoice, subInv),
      },
      // !invoice.isInDraft && {
      //   text: invoice.active ? "Archive" : "Unarchive",
      //   icon: <ArchiveRounded fontSize="small" />,
      //   handler: () => confirmArchive(invoice),
      // },
      {
        text: "Export Invoice as Word",
        icon: <DescriptionRounded fontSize="small" />,
        handler: () => generateInvoice(invoice, subInv),
      },
    ];

    let subInvoiceType = invoiceTypes.find(
      (el) => el.id === subInv.subInvoiceTypeSid
    )?.value;

    if (subInvoiceType === "Unpaid") {
      menuItems = [
        ...menuItems,
        subInv.active && {
          text: "Mark as Paid",
          icon: <OfflinePinRounded fontSize="small" />,
          handler: () => togglePayForSubInv(subInv.subInvoiceSid, "paid"),
        },
        subInv.active && {
          text: "Write Off",
          icon: <CancelPresentationRounded fontSize="small" />,
          handler: () =>
            togglePayForSubInv(subInv.subInvoiceSid, "written-off"),
        },
      ];
    } else if (subInvoiceType === "Paid") {
      menuItems = [
        ...menuItems,
        subInv.active && {
          text: "Mark as Unpaid",
          icon: <BackspaceOutlined fontSize="small" />,
          handler: () => togglePayForSubInv(subInv.subInvoiceSid, "unpaid"),
        },
      ];
    } else {
      menuItems = [
        ...menuItems,
        subInv.active && {
          text: "Mark as Unpaid",
          icon: <BackspaceOutlined fontSize="small" />,
          handler: () => togglePayForSubInv(subInv.subInvoiceSid, "unpaid"),
        },
        subInv.active && {
          text: "Mark as Paid",
          icon: <OfflinePinRounded fontSize="small" />,
          handler: () => togglePayForSubInv(subInv.subInvoiceSid, "paid"),
        },
      ];
    }
    return menuItems;
  };

  return (
    <>
      <InvoiceTable
        isLoading={isLoading}
        data={invoiceData}
        getAllMenuItems={getAllMenuItems}
        getMenuItemsForSubInvoice={getMenuItemsForSubInvoice}
        hidePaymentDueCol={hidePaymentDueCol}
      />
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="lg"
        isOpen={isInvoiceFormOpen}
        title={
          invoiceTemp && invoiceTemp.id ? "Edit Invoice" : "Create Invoice"
        }
        saveBtnTitle={invoiceTemp && invoiceTemp.id ? "Update" : "Save"}
        handleClose={() => setIsInvoiceFormOpen(false)}
        handleSave={() => saveOrEditInvoice(invoiceTemp)}
      >
        <InvoiceForm
          selectedCase={caseInfo}
          client={client}
          invoice={invoiceTemp}
          setInvoice={setInvoiceTemp}
          error={error}
          setError={setError}
          onBlur={onBlur}
          clearReferenceNo={clearReferenceNo}
        />
      </CustomModal>

      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="lg"
        isOpen={isInvoiceDetailOpen}
        title="Invoice Detail"
        handleClose={() => setIsInvoiceDetailOpen(false)}
        hideFooter
      >
        <InvoiceDetail
          invoice={selectedInvoice}
          selectedCase={caseInfo}
          client={client}
          selectedSubInv={selectedSubInv}
        />
      </CustomModal>
    </>
  );
};
