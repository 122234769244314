import React from "react";
import clsx from "clsx";
import { Grid, TextField } from "@material-ui/core";
import CommonSelect from "../../components/CommonSelect";
import { useContext } from "react";
import { DataContext } from "../../DataContext";
import { getDefaultCompanyAdminObj } from "../../model";

const CompanyAdminForm = ({
  className,
  staff = getDefaultCompanyAdminObj(),
  handleChange = () => {},
  error = {},
  onBlur = () => {},
  companyList = [],
}) => {
  const { salutationList } = useContext(DataContext);

  return (
    <form className={clsx("CompanyAdminForm", className)}>
      <Grid container>
        <Grid item xs={6} md={4} className="p175">
          <CommonSelect
            options={salutationList}
            label="Salutation"
            fullWidth
            required
            name="salutationSid"
            value={staff.salutationSid}
            onChange={handleChange}
            error={error.salutationSid}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6} className="p175">
          <TextField
            label="First Name"
            fullWidth
            required
            name="firstName"
            value={staff.firstName}
            onChange={handleChange}
            error={error.firstName}
            onBlur={onBlur}
          />
        </Grid>
        <Grid item xs={12} md={6} className="p175">
          <TextField
            className="flex-grow"
            label="Last Name"
            fullWidth
            required
            name="lastName"
            value={staff.lastName}
            onChange={handleChange}
            error={error.lastName}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} className="p175">
          <CommonSelect
            options={companyList}
            valueKey="companySid"
            labelKey="companyName"
            label="Company Name"
            fullWidth
            required
            name="companySid"
            value={staff.companySid || ""}
            onChange={handleChange}
            error={error.companySid}
            onBlur={onBlur}
            readOnly={staff.personSid ? true : false}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className="p175">
          <TextField
            label="Email"
            type="email"
            fullWidth
            required
            name="email"
            value={staff.email}
            onChange={handleChange}
            error={error.email}
            onBlur={onBlur}
            autoComplete="off"
          />
        </Grid>
      </Grid>
      {!staff.personSid && (
        <Grid container>
          <Grid item xs={12} className="p175">
            <TextField
              label="Password"
              fullWidth
              required
              type="password"
              name="password"
              value={staff.password || ""}
              onChange={handleChange}
              error={error.password}
              onBlur={onBlur}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} className="p175">
            <TextField
              label="Re-enter Password"
              fullWidth
              required
              type="password"
              name="passwordReenter"
              value={staff.passwordReenter || ""}
              onChange={handleChange}
              error={
                error.passwordReenter ||
                Boolean(
                  staff.passwordReenter &&
                    staff.password &&
                    staff.passwordReenter !== staff.password
                )
              }
              onBlur={onBlur}
              autoComplete="off"
            />
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default CompanyAdminForm;
