import React from "react";
import clsx from "clsx";
import { Tooltip } from "@material-ui/core";

const CustomBadge = ({ className, children, color, tooltip, ...others }) => {
  return (
    <Tooltip title={tooltip ? tooltip : ""} arrow={true}>
      <span
        className={clsx("MuiBadge-badge", "CustomBadge", className)}
        style={{
          margin: "2px",
          position: "initial",
          backgroundColor: color || "limegreen",
          color: "#fafafa"
        }}
        {...others}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default CustomBadge;
