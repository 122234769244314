import React, { useState, useContext, useEffect } from "react";
import { Grid, useTheme, useMediaQuery, IconButton } from "@material-ui/core";
import PageSidebar from "../../components/PageSidebar";
import CustomModal from "../../components/CustomModal";
import CommonPage from "../CommonPage";
import { DataContext, PopupContext } from "../../DataContext";
import { getDefaultMasterCodeTypeObj } from "../../model";
import {
  getDefaultErrorObj,
  getError,
  hasError,
  hasMissingData,
} from "../../utils/functions";
import SlideUp from "../../components/SlideUp";
import Axios from "axios";
import { Modules } from "../../utils/constants";
import MasterCodeTypeForm from "./components/MasterCodeTypeForm";
import { MasterCodeTypeTable } from "./components/MasterCodeTypeTable";
import { AddRounded } from "@material-ui/icons";

const SettingPage = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState(false);
  const {
    alertSuccess,
    alertWarning,
    alertError,
    masterTypeList,
    fetchMasterTypes,
    masterCodeList,
    fetchMasterCodes,
  } = useContext(DataContext);
  const { openDialog } = useContext(PopupContext);
  const [masterCodeType, setMasterCodeType] = useState(
    getDefaultMasterCodeTypeObj("code")
  );
  const [error, setError] = useState(
    getDefaultErrorObj(getDefaultMasterCodeTypeObj("code"))
  );
  const [existingCategory, setExistingCategory] = useState(true);
  const settingList = [
    {
      title: "Master Codes",
      type: "code",
    },
    {
      title: "Master Types",
      type: "type",
    },
  ];
  const [setting, setSetting] = useState(settingList[0]);

  const [typeCats, setTypeCats] = useState([]);
  const [codeCats, setCodeCats] = useState([]);

  const getCatList = (li) =>
    [...new Set(li.map((el) => el.category))].map((el) => ({
      id: el,
      value: el,
    }));

  useEffect(() => {
    setCodeCats(getCatList(masterCodeList));
    setTypeCats(getCatList(masterTypeList));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterTypeList, masterCodeList]);

  const openSaveModal = (type) => {
    setError(getDefaultErrorObj(getDefaultMasterCodeTypeObj(type)));
    setMasterCodeType(getDefaultMasterCodeTypeObj(type));
    setIsOpen(true);
  };

  const handleChange = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setMasterCodeType({
      ...masterCodeType,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const handleCheckbox = (event) => {
    event.persist && event.persist();
    setExistingCategory(!existingCategory);
  };

  const onBlur = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const saveOrEditMasterCodeType = () => {
    try {
      const fieldsToIgnore = ["active", "id"];
      if (hasMissingData(masterCodeType, ...fieldsToIgnore)) {
        alertWarning("Warning! Missing data in required fields.");
      } else if (hasError(error, ...fieldsToIgnore)) {
        alertWarning("Invalid! Check your input data entered.");
      } else {
        if (masterCodeType.id) {
          // UPDATE
          Axios.put(`/setting/${masterCodeType.type}s`, masterCodeType)
            .then((res) => {
              if (res.data.type === "code") {
                fetchMasterCodes();
              } else {
                fetchMasterTypes();
              }
              setIsOpen(false);
              alertSuccess("Successfully updated!");
            })
            .catch((err) =>
              alertError(getError(err, "Error! Unable to update."))
            );
        } else {
          // SAVE
          Axios.post(`/setting/${masterCodeType.type}s`, masterCodeType)
            .then((res) => {
              if (res.data.type === "code") {
                fetchMasterCodes();
                // setMasterCodeList((prev) => [res.data, ...prev]);
              } else {
                fetchMasterTypes();
                // setMasterTypeList((prev) => [res.data, ...prev]);
              }
              setIsOpen(false);
              alertSuccess("Successfully saved!");
            })
            .catch((err) =>
              alertError(getError(err, "Error! Unable to save."))
            );
        }
      }
    } catch (error) {
      alertError(`Error! Failed to ${masterCodeType.id ? "update" : "save"}.`);
    }
  };

  const goDelete = (masterCodeType) => {
    Axios.delete(`/setting/${masterCodeType.type}s/` + masterCodeType.id)
      .then((res) => {
        if (res.data) {
          setting?.type === "type" ? fetchMasterTypes() : fetchMasterCodes();
          alertSuccess("Successfully deleted!");
        } else {
          alertError("It has been used! Unable to delete.");
        }
      })
      .catch((err) => alertError(getError(err, "Error! Unable to delete.")));
  };

  const confirmDelete = (masterCodeType) =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Delete",
      okBtnColor: "secondary",
      onOkClicked: () => goDelete(masterCodeType),
      title: "Delete",
      message: `Are you sure you want to delete this item?`,
    }));

  return (
    <>
      <CommonPage
        className="SettingPage"
        setSelectedData={setSetting}
        detailTitle={setting?.title}
        PageSidebar={
          <PageSidebar
            listName="Settings"
            listData={settingList.map((l) => ({
              active: true,
              primary: `${l.title}`,
              selected: setting && l.title === setting.title,
              onClick: () =>
                setSetting(settingList.find((s) => s.title === l.title)),
            }))}
            module={Modules.SETTING}
            hideSearchBar
            hidePagination
            hideSettingBtn
          />
        }
        Buttons={
          <Grid item>
            <IconButton
              size="small"
              color="primary"
              style={{ marginRight: "8px" }}
              onClick={() => openSaveModal(setting?.type)}
            >
              <AddRounded />
            </IconButton>
          </Grid>
        }
      >
        <MasterCodeTypeTable
          data={setting?.type === "type" ? masterTypeList : masterCodeList}
          isformOpen={isOpen}
          setIsFormOpen={setIsOpen}
          setMasterCodeType={setMasterCodeType}
          fetchData={
            setting?.type === "type" ? fetchMasterTypes : fetchMasterCodes
          }
          confirmDelete={confirmDelete}
        />
      </CommonPage>
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="sm"
        isOpen={isOpen}
        title={
          masterCodeType.id
            ? "Edit Master " +
              (masterCodeType.type === "code" ? "Code" : "Type")
            : "New Master " + (masterCodeType.type === "code" ? "Code" : "Type")
        }
        closeBtnTitle="Cancel"
        saveBtnTitle={masterCodeType.id ? "Update" : "Save"}
        handleClose={() => setIsOpen(false)}
        handleSave={saveOrEditMasterCodeType}
      >
        <MasterCodeTypeForm
          masterCodeType={masterCodeType}
          handleChange={handleChange}
          handleCheckbox={handleCheckbox}
          existingCategory={existingCategory}
          categories={masterCodeType.type === "code" ? codeCats : typeCats}
          error={error}
          setError={setError}
          onBlur={onBlur}
        />
      </CustomModal>
    </>
  );
};

export default SettingPage;
