import React, { useContext } from "react";
import clsx from "clsx";
import { Grid, TextField } from "@material-ui/core";
import CommonSelect from "../../components/CommonSelect";
import { DataContext } from "../../DataContext";
import { RoleTypes } from "../../utils/constants";

const StaffForm = ({
  className,
  staff = {},
  handleChange = () => {},
  error = {},
  onBlur = () => {},
  isSecretary,
}) => {
  const { roleTypes, staffTypes, salutationList } = useContext(DataContext);

  return (
    <form className={clsx("StaffForm", className)}>
      <Grid container>
        <Grid item xs={6} md={5} className="p175">
          <CommonSelect
            label="Salutation"
            fullWidth
            required
            value={staff.salutation}
            name="salutation"
            options={salutationList}
            onBlur={onBlur}
            onChange={handleChange}
            error={error.salutation}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={6} className="p175">
          <TextField
            label="First Name"
            fullWidth
            required
            name="firstName"
            value={staff.firstName}
            onChange={handleChange}
            error={error.firstName}
            onBlur={onBlur}
          />
        </Grid>
        <Grid item xs={12} md={6} className="p175">
          <TextField
            className="flex-grow"
            label="Last Name"
            fullWidth
            required
            name="lastName"
            value={staff.lastName}
            onChange={handleChange}
            error={error.lastName}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={6} className="p175">
          <CommonSelect
            label="Select Role Type"
            fullWidth
            required
            value={staff.roleType}
            name="roleType"
            options={roleTypes.filter(
              (el) =>
                ![RoleTypes.PORTAL_ADMIN, RoleTypes.COMPANY_ADMIN].includes(
                  el.value
                )
            )}
            onBlur={onBlur}
            onChange={handleChange}
            error={error.roleType}
          />
        </Grid>
        <Grid item xs={12} md={6} className="p175">
          <CommonSelect
            label="Select Staff Type"
            fullWidth
            required
            value={staff.staffType}
            name="staffType"
            options={staffTypes.filter((el) => el.value !== "Client")}
            onBlur={onBlur}
            onChange={handleChange}
            error={error.staffType}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={6} className="p175">
          <TextField
            label="Initials"
            fullWidth
            name="initials"
            value={staff.initials}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </Grid>
        {!staff.personSid && !isSecretary && (
          <Grid item xs={12} md={6} className="p175">
            <TextField
              className="flex-grow"
              label="Global Hourly Rate"
              required
              fullWidth
              name="globalHourlyRate"
              value={isSecretary ? 0.0 : staff.globalHourlyRate}
              onChange={handleChange}
              error={!isSecretary && error.globalHourlyRate}
              onBlur={onBlur}
              disabled={Boolean(isSecretary)}
            />
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12} className="p175">
          <TextField
            label="Email"
            type="email"
            fullWidth
            required
            name="email"
            value={staff.email}
            onChange={handleChange}
            error={error.email}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
      {!staff.personSid && (
        <Grid container>
          <Grid item xs={12} className="p175">
            <TextField
              label="Password"
              fullWidth
              required
              type="password"
              name="password"
              value={staff.password || ""}
              onChange={handleChange}
              error={error.password}
              onBlur={onBlur}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} className="p175">
            <TextField
              label="Re-enter Password"
              fullWidth
              required
              type="password"
              name="passwordReenter"
              value={staff.passwordReenter || ""}
              onChange={handleChange}
              error={
                error.passwordReenter ||
                Boolean(
                  staff.passwordReenter &&
                    staff.password &&
                    staff.passwordReenter !== staff.password
                )
              }
              onBlur={onBlur}
              autoComplete="off"
            />
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12} className="p175">
          <TextField
            label="Phone Number"
            fullWidth
            name="phone"
            value={staff.phone}
            onChange={handleChange}
            error={error.phone}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default StaffForm;
