import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../DataContext";
import routes from "../config/routes";
import CONSTANTS from "../utils/constants";

const PrivateRoute = (props) => {
  const { currentUser } = useContext(DataContext);
  const route = routes.find((r) => r.to === props.path);

  if (!localStorage.getItem(CONSTANTS.ACCESS_TOKEN))
    return <Redirect to="/logout" />;
  else if (
    currentUser.roleType &&
    currentUser.roleType.roleTypeName &&
    route &&
    !route.allowedRoles.includes(currentUser.roleType.roleTypeName)
  ) {
    return <Redirect to="/" />;
  }
  return <Route {...props} />;
};

export default PrivateRoute;
