import { TableCell, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const LoadingRow = ({ colSpan = 8 }) => {
  return (
    <>
      {[...Array(3).keys()].map((k) => (
        <TableRow key={k} className="LoadingRow">
          <TableCell colSpan={2}>
            <Skeleton animation="wave" height={16} width="100%" />
          </TableCell>
          <TableCell colSpan={2}>
            <Skeleton animation="wave" height={16} width="100%" />
          </TableCell>
          {colSpan > 8 && <TableCell />}
          <TableCell colSpan={colSpan - (colSpan > 8 ? 7 : 5)}>
            <Skeleton animation="wave" height={16} width="100%" />
          </TableCell>
          {colSpan > 8 && <TableCell />}
          <TableCell>
            <Skeleton animation="wave" height={16} width="100%" />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default LoadingRow;
