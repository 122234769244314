import React, { useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { PopupContext, DataContext, SettingContext } from "../DataContext";
import CustomModal from "./CustomModal";
import { getDefaultCaseObj } from "../model";
import {
  getDefaultErrorObj,
  getError,
  hasMissingDataOnlyOn,
  hasErrorOnlyOn,
} from "../utils/functions";
import SlideUp from "./SlideUp";
import Axios from "axios";
import CaseForm from "../pages/cases/CaseForm";

const CaseModal = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { openCase, setOpenCase, openDialog } = useContext(PopupContext);
  const {
    alertError,
    alertSuccess,
    alertWarning,
    staffList,
    caseTemp,
    setCaseTemp,
    ...others
  } = useContext(DataContext);
  const { setProcessing, setRefetchCase, setRefetch } =
    useContext(SettingContext);

  const [error, setError] = useState(getDefaultErrorObj(getDefaultCaseObj()));
  const [cusLawyer, setCusLawyer] = useState("");
  const [cusRate, setCusRate] = useState("");
  const [addedLawyers, setAddedLawyers] = useState([]);
  const [clientInfo, setClientInfo] = useState(null);
  const [loadingClient, setLoadingClient] = useState(false);

  const [secretary, setSecretary] = useState("");
  const [addedSecretaries, setAddedSecretaries] = useState([]);

  const fetchClientInfo = (clientInfoSid) => {
    setLoadingClient(true);
    Axios.get("/clients/" + clientInfoSid)
      .then((res) => {
        setClientInfo(res.data);
        setLoadingClient(false);
      })
      .catch(() => {
        setClientInfo(null);
        setLoadingClient(false);
      });
  };

  useEffect(() => {
    if (openCase) {
      ReactDOM.unstable_batchedUpdates(() => {
        if (caseTemp.client && caseTemp.client.id) {
          fetchClientInfo(caseTemp.client.id);
        }
        setCusLawyer("");
        setCusRate("");
        setError(getDefaultErrorObj(getDefaultCaseObj()));
        setAddedLawyers(caseTemp.additionalLawyers || []);
        setSecretary("");
        setAddedSecretaries(caseTemp.secretariesInCharge || []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCase]);

  const goRemoveAdditionalLawyer = (personSid) => {
    setProcessing(true);
    Axios.delete(`/cases/${caseTemp.caseSid}/additional-lawyers/${personSid}`)
      .then((res) => {
        setProcessing(false);
        alertSuccess("Successfully removed!");
        const lawyers = [...addedLawyers].filter(
          (p) => p.personSid !== personSid
        );
        setAddedLawyers(lawyers);
        setProcessing(false);
        setRefetchCase((prev) => !prev);
        setRefetch((prev) => !prev);
      })
      .catch((err) => {
        alertError(getError(err, "Error! Unable to remove additional lawyer."));
        setProcessing(false);
      });
  };

  const goAddOrOverrideAdditionalLawyer = (cus) => {
    setProcessing(true);
    Axios.post(`/cases/${caseTemp.caseSid}/additional-lawyers`, cus)
      .then((res) => {
        setProcessing(false);
        const lwrInd = addedLawyers.findIndex(
          (el) => el.personSid === cus.personSid
        );
        let lawyers = [];

        if (lwrInd >= 0) {
          // Override mode
          alertSuccess("Successfully updated the lawyer's custom hourly rate!");
          lawyers = [...addedLawyers];
          lawyers.splice(lwrInd, 1, cus);
        } else {
          // Add mode
          alertSuccess(
            "Successfully added as additional lawyer with coustom hourly rate!"
          );
          lawyers = [...addedLawyers, cus];
        }

        setAddedLawyers(lawyers);
        setCusLawyer("");
        setCusRate("");
        setProcessing(false);
        setRefetchCase((prev) => !prev);
        setRefetch((prev) => !prev);
      })
      .catch((err) => {
        setProcessing(false);
        alertError(
          getError(err, "Error! Unable to add/override additional lawyer.")
        );
        setProcessing(false);
      });
  };

  const confirmRemoveAdditionalLawyer = (personSid) => {
    const lwyr = staffList.find((el) => el.id === personSid);
    const name = lwyr.value;
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Remove",
      okBtnColor: "secondary",
      onOkClicked: () => goRemoveAdditionalLawyer(personSid),
      title: "Remove Lawyer",
      message: `All of ${name}'s uninvoiced time entries, time entries that are included in draft invoice, and the affected draft invoices under this case will be re-calculated with the lawyer's global hourly rate ($${lwyr.rate}/h).
        Are you sure you want to remove "${name}" from additional lawyers in this case Ref#${caseTemp.caseRef}?`,
    }));
  };

  const confirmAddAdditionalLawyer = (cus) => {
    const name = staffList.find((el) => el.id === cus.personSid).value;
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Add",
      okBtnColor: "primary",
      onOkClicked: () => goAddOrOverrideAdditionalLawyer(cus),
      title: "Add Lawyer",
      message: `All of ${name}'s uninvoiced time entries, time entries that are included in draft invoice, and the affected draft invoices under this case will be re-calculated based on specified custom hourly rate ($${cus.customRate}/h).
        Are you sure you want to add "${name}" as an additional lawyer in this case Ref#${caseTemp.caseRef}?`,
    }));
  };

  const confirmOverrideAdditionalLawyer = (cus) => {
    const name = staffList.find((el) => el.id === cus.personSid).value;
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Override",
      okBtnColor: "primary",
      onOkClicked: () => goAddOrOverrideAdditionalLawyer(cus),
      title: "Override Lawyer",
      message: `All of ${name}'s uninvoiced time entries, time entries that are included in draft invoice, and the affected draft invoices under this case will be re-calculated based on the lawyer's to-be-updated custom hourly rate ($${cus.customRate}/h).
        Are you sure you want to update ${name}'s custom rate in this case Ref#${caseTemp.caseRef}?`,
    }));
  };

  const addLawyer = () => {
    if (cusLawyer && cusRate) {
      const cus = { personSid: cusLawyer, customRate: cusRate };

      const ind = addedLawyers.findIndex((el) => el.personSid === cusLawyer);
      if (caseTemp.caseSid) {
        // Edit mode
        if (ind >= 0) {
          // Override
          confirmOverrideAdditionalLawyer(cus);
        } else {
          // Add
          confirmAddAdditionalLawyer(cus);
        }
      } else {
        // Save mode
        if (ind >= 0) {
          // Override
          const newList = [...addedLawyers];
          newList.splice(ind, 1, cus);
          setAddedLawyers(newList);
        } else {
          // Add
          setAddedLawyers((prev) => [...prev, cus]);
        }

        setCusLawyer("");
        setCusRate("");
      }
    }
  };

  const removeLawyer = (personSid) => {
    if (caseTemp.caseSid) {
      // Edit mode
      confirmRemoveAdditionalLawyer(personSid);
    } else {
      // Save mode
      setAddedLawyers((prev) =>
        [...prev].filter((p) => p.personSid !== personSid)
      );
    }
  };

  const goRemoveSecretary = (personSid) => {
    setProcessing(true);
    Axios.delete(
      `/cases/${caseTemp.caseSid}/secretaries-in-charge/${personSid}`
    )
      .then((res) => {
        setProcessing(false);
        alertSuccess("Successfully removed!");
        setAddedSecretaries((prev) =>
          prev.filter((p) => p.personSid !== personSid)
        );
        setProcessing(false);
        setRefetchCase((prev) => !prev);
        setRefetch((prev) => !prev);
      })
      .catch((err) => {
        alertError(getError(err, "Error! Unable to remove secretary."));
        setProcessing(false);
      });
  };

  const goAddSecretary = (personSid) => {
    setProcessing(true);
    Axios.post(`/cases/${caseTemp.caseSid}/secretaries-in-charge/${personSid}`)
      .then((res) => {
        setProcessing(false);
        alertSuccess("Successfully added secretary in charge!");
        setAddedSecretaries((prev) => [...prev, res.data]);
        setSecretary("");
        setProcessing(false);
        setRefetchCase((prev) => !prev);
        setRefetch((prev) => !prev);
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, "Error! Unable to add secretary in charge."));
        setProcessing(false);
      });
  };

  const confirmRemoveSecretary = (personSid) => {
    const lwyr = staffList.find((el) => el.id === personSid);
    const name = lwyr.value;
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Remove",
      okBtnColor: "secondary",
      onOkClicked: () => goRemoveSecretary(personSid),
      title: "Remove Secretary",
      message: `Are you sure you want to remove "${name}" from secretaries in charge for this case Ref#${caseTemp.caseRef}?`,
    }));
  };

  const confirmAddSecretary = (sec) => {
    const name = staffList.find((el) => el.id === sec.personSid).value;
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Add",
      okBtnColor: "primary",
      onOkClicked: () => goAddSecretary(sec.personSid),
      title: "Add Secretary",
      message: `Are you sure you want to add "${name}" as a secretary in charge for this case Ref#${caseTemp.caseRef}?`,
    }));
  };

  const addSecretary = () => {
    if (secretary) {
      const sec = { personSid: secretary };

      if (caseTemp.caseSid) {
        // Edit mode
        confirmAddSecretary(sec);
      } else {
        // Save mode
        setAddedSecretaries((prev) => [
          ...prev,
          { ...sec, primary: prev.length === 0 },
        ]);
        setSecretary("");
      }
    }
  };

  const removeSecretary = (personSid) => {
    if (caseTemp.caseSid) {
      // Edit mode
      confirmRemoveSecretary(personSid);
    } else {
      // Save mode
      setAddedSecretaries((prev) =>
        [...prev]
          .filter((p) => p.personSid !== personSid)
          .map((p, i) => ({ ...p, primary: i === 0 }))
      );
    }
  };

  const handleChange = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    const val = event.target.value;
    setCaseTemp({
      ...caseTemp,
      [name]: val,
    });
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
    if (
      name === "client" &&
      val &&
      val.id &&
      (!clientInfo || clientInfo.clientSid !== val.id)
    ) {
      fetchClientInfo(val.id);
    }
  };

  const handleChangeByKey = (k, v) => {
    setCaseTemp({
      ...caseTemp,
      [k]: v,
    });
  };

  const clearReferenceNo = () => {
    setCaseTemp({
      ...caseTemp,
      caseRef: null,
    });
  };

  const onBlur = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const saveOrEditCase = () => {
    try {
      let fieldsToChk = ["name", "client", "respAtt"];
      caseTemp.additionalLawyers = [...addedLawyers];
      caseTemp.secretariesInCharge = [...addedSecretaries];

      if (!caseTemp.isPocDefault) {
        fieldsToChk = [
          ...fieldsToChk,
          "firstName",
          "lastName",
          "email",
          "mobile",
          "role",
        ];
      }

      if (caseTemp.status !== "open") {
        fieldsToChk.push("closeDate");
      }

      if (hasMissingDataOnlyOn(caseTemp, ...fieldsToChk)) {
        alertWarning("Warning! Missing data in required fields.");
      } else if (hasErrorOnlyOn(error, ...fieldsToChk)) {
        alertWarning("Invalid! Check your input data entered.");
      } else if (isNaN(caseTemp.agreedFee) || +caseTemp.agreedFee < 0) {
        alertWarning("Invalid agreed fee");
      } else if (
        caseTemp.additionalLawyers.length > 0 &&
        caseTemp.additionalLawyers.find(
          (el) => isNaN(el.customRate) || +el.customRate <= 0
        )
      ) {
        alertWarning("Invalid custom rate in one of additional lawyers");
      } else {
        setProcessing(true);
        if (caseTemp.caseSid) {
          // UPDATE
          Axios.put("/cases", caseTemp)
            .then((res) => {
              others.setCaseList((prev) => {
                const newList = prev.filter((c) => c.id !== res.data.caseSid);
                const caseOpt = {
                  id: res.data.caseSid,
                  value: `${res.data.name} (Ref#${res.data.caseRef})`,
                  active: res.data.active,
                };
                return [caseOpt, ...newList];
              });
              setAddedLawyers([]);
              setAddedSecretaries([]);
              setOpenCase(false);
              alertSuccess("Successfully updated!");
              setProcessing(false);
              setRefetchCase((prev) => !prev);
            })
            .catch((err) => {
              alertError(getError(err, "Error! Unable to update."));
              setProcessing(false);
            });
        } else {
          // SAVE
          Axios.post("/cases", caseTemp)
            .then((res) => {
              const caseOpt = {
                id: res.data.caseSid,
                value: `${res.data.name} (Ref#${res.data.caseRef})`,
                active: res.data.active,
              };
              others.setCaseList((prev) => [caseOpt, ...prev]);
              setOpenCase(false);
              alertSuccess("Successfully saved!");
              setProcessing(false);
              setRefetchCase((prev) => !prev);
            })
            .catch((err) => {
              alertError(getError(err, "Error! Unable to save."));
              setProcessing(false);
            });
        }
      }
    } catch (error) {
      alertError(`Error! Failed to ${caseTemp.caseSid ? "update" : "save"}.`);
    }
  };

  return (
    <CustomModal
      TransitionComponent={SlideUp}
      fullScreen={fullScreen}
      maxWidth="md"
      isOpen={openCase}
      title={caseTemp.caseSid ? "Edit Case" : "New Case"}
      closeBtnTitle="Cancel"
      saveBtnTitle={caseTemp.caseSid ? "Update" : "Save"}
      handleClose={() => setOpenCase(false)}
      handleSave={saveOrEditCase}
    >
      <CaseForm
        data={caseTemp}
        handleChange={handleChange}
        handleChangeByKey={handleChangeByKey}
        clearReferenceNo={clearReferenceNo}
        error={error}
        setError={setError}
        onBlur={onBlur}
        addLawyer={addLawyer}
        removeLawyer={removeLawyer}
        addedLawyers={addedLawyers}
        cusLawyer={cusLawyer}
        setCusLawyer={setCusLawyer}
        cusRate={cusRate}
        setCusRate={setCusRate}
        clientInfo={clientInfo}
        loadingClient={loadingClient}
        addSecretary={addSecretary}
        removeSecretary={removeSecretary}
        addedSecretaries={addedSecretaries}
        secretary={secretary}
        setSecretary={setSecretary}
      />
    </CustomModal>
  );
};

export default CaseModal;
