import React, { useState } from "react";
import { Grid, useTheme, useMediaQuery, Typography } from "@material-ui/core";
import { AccountBalanceWallet } from "@material-ui/icons";
import PageSidebar from "../../components/PageSidebar";
import PopupMenuButton from "../../components/PopupMenuButton";
import CustomModal from "../../components/CustomModal";
import CommonPage from "../CommonPage";

import SlideUp from "../../components/SlideUp";
import { Modules } from "../../utils/constants";
import TransactionTable from "./TransactionTable";
import DepositMoney from "../../components/DepositMoney";
import { transactions } from "./tempAccountData";

function createAccountData(accountNumber, caseInfo, balance) {
  return { accountNumber, caseInfo, balance };
}
const accountList = [
  createAccountData("1001001", "C101", 3100),
  createAccountData("1001002", "C102", 3000),
  createAccountData("1001003", "C103", 3400),
  createAccountData("1001004", "C104", 3800),
  createAccountData("1001005", "C105", 3900),
];

function createCaseData(id, name, caseRef) {
  return { id, name, caseRef };
}

const caseList = [
  createCaseData("C101", "Case A", "20210001"),
  createCaseData("C102", "Case B", "20210002"),
  createCaseData("C103", "Case C", "20210003"),
  createCaseData("C104", "Case D", "20210004"),
  createCaseData("C105", "Case E", "20210005"),
];

const AccountsPage = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDeposit, setOpenDeposit] = useState(false);
  const [deposit, setDeposit] = useState({
    case: "",
    amount: 0,
  });
  const [loading] = useState(false);
  const [account, setAccount] = useState(null);

  const openDepositModal = () => {
    let selectedCase = caseList.find((c) => c.id === account.caseInfo);
    setDeposit({
      ...deposit,
      case:
        selectedCase &&
        selectedCase.name + " (Ref#" + selectedCase.caseRef + ")",
    });
    setOpenDeposit(true);
  };

  const saveDeposit = () => {
    setOpenDeposit(false);
  };

  const menuItems = [
    {
      text: "Deposit Money",
      icon: <AccountBalanceWallet fontSize="small" />,
      disabled: !account,
      handler: openDepositModal,
    },
  ];

  return (
    <>
      <CommonPage
        className="AccountsPage"
        setSelectedData={setAccount}
        detailTitle={account && account.accountNumber}
        PageSidebar={
          <PageSidebar
            loading={loading}
            listName="All Accounts"
            listData={accountList.map((l) => ({
              active: true,
              id: l.accountNumber,
              primary: l.accountNumber,
              secondary: (
                <>
                  <Typography variant="body2">
                    {l.caseInfo &&
                      caseList.find((s) => s.id === l.caseInfo).name +
                        " - Ref# " +
                        caseList.find((s) => s.id === l.caseInfo).caseRef}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ fontStyle: "italic", opacity: "0.75" }}
                  >
                    Balance:{" $"}
                    <span
                      style={{
                        fontWeight: "600",
                        fontStyle: "normal",
                      }}
                    >
                      {l.balance}
                    </span>
                  </Typography>
                </>
              ),
              selected: account && l.accountNumber === account.accountNumber,
              onClick: () =>
                setAccount(
                  accountList.find((s) => s.accountNumber === l.accountNumber)
                ),
            }))}
            module={Modules.ACCOUNT}
          />
        }
        Buttons={
          <Grid item>
            <PopupMenuButton
              size="small"
              color="default"
              isIconButton
              menuItems={menuItems}
            />
          </Grid>
        }
      >
        {account && (
          <TransactionTable
            transactions={transactions.filter(
              (r) => r.caseInfo === account.caseInfo
            )}
          />
        )}
      </CommonPage>
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="xs"
        isOpen={openDeposit}
        title="Deposit Money"
        closeBtnTitle="Cancel"
        saveBtnTitle="Deposit"
        handleClose={() => setOpenDeposit(false)}
        handleSave={saveDeposit}
      >
        <DepositMoney data={deposit} />
      </CustomModal>
    </>
  );
};

export default AccountsPage;
