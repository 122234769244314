import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import SimpleBox from "../../components/SimpleBox";
import {
  Grid,
  TextField,
  makeStyles,
  Checkbox,
  Button,
  Typography,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Divider,
  // FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import AddIcon from "@material-ui/icons/Add";
import DateFnsUtils from "@date-io/date-fns";

import SelectTimeExpenseEntryModal from "./SelectTimeExpenseEntryModal";
import CustomModal from "../../components/CustomModal";
import { formatDecimal } from "../../utils/functions";
import { formatDateDash } from "../../utils/dateUtils";
import { IndeterminateCheckBox, SwapHorizRounded } from "@material-ui/icons";
import InvoiceHeaderSection from "./InvoiceHeaderSection";
import { getDefaultInvoiceObj } from "../../model";

import TimeExpenseEntryItem from "./TimeExpenseEntryItem";
import { DataContext } from "../../DataContext";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  box: {
    marginBottom: theme.spacing(2),
  },
  p175: {
    padding: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.75),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  p175NoTop: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1.75),
    paddingLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      paddingBottom: theme.spacing(1.75),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  p175LessBottom: {
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  fullWidth: {
    width: "100%",
  },
  numInput: {
    textAlign: "right",
    paddingRight: "8px",
    letterSpacing: "1px",
    whiteSpace: "nowrap",
    color: theme.palette.text.secondary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
}));

const InvoiceForm = ({
  className,
  selectedCase = {},
  invoice = getDefaultInvoiceObj(),
  setInvoice = () => {},
  error,
  clearReferenceNo = () => {},
  setError = () => {},
  onBlur = () => {},
  client = {},
  onUpdate = () => {},
  fetchInvoiceList = () => {},
}) => {
  const classes = useStyles();

  const groupByLawyers = (timeExpList, prevTimeListByLawyer) => {
    let timeList = timeExpList.filter((el) => el.type === "time");
    let timeListByLawyer = [];
    timeList.forEach((t) => {
      if (timeListByLawyer[t.staff.id]) {
        timeListByLawyer[t.staff.id]["timeEntry"].push(t);
      } else {
        timeListByLawyer[t.staff.id] = {};
        timeListByLawyer[t.staff.id]["timeEntry"] = [];
        timeListByLawyer[t.staff.id]["timeEntry"].push(t);
        timeListByLawyer[t.staff.id]["discountAmount"] = 0;
        timeListByLawyer[t.staff.id]["discountPercentage"] = 0;
      }
    });
    Object.keys(timeListByLawyer).forEach((t) => {
      timeListByLawyer[t].timeEntry.forEach((qte) => {
        timeListByLawyer[t]["discountAmount"] += +qte.discountAmount;
        if (prevTimeListByLawyer && prevTimeListByLawyer[t]) {
          timeListByLawyer[t]["discountPercentage"] = +prevTimeListByLawyer[t][
            "discountPercentage"
          ];
        } else if (qte.discountPercentage) {
          timeListByLawyer[t]["discountPercentage"] = +qte.discountPercentage;
          return;
        } else {
          timeListByLawyer[t]["discountPercentage"] = 0;
        }
      });
      timeListByLawyer[t].timeEntry = timeListByLawyer[t].timeEntry.sort((a, b) => a.date.localeCompare(b.date));
    });
    return timeListByLawyer;
  };

  const { staffList, alertWarning } = useContext(DataContext);

  const [selectedTempEntries, setSelectedTempEntries] = useState([]);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [timeListByLawyer, setTimeListByLawyer] = useState(
    groupByLawyers(invoice.timeExpenseEntry, null)
  );

  useEffect(() => {
    if (
      invoice &&
      invoice.timeExpenseEntry &&
      invoice.timeExpenseEntry.length > 0
    ) {
      setTimeListByLawyer(groupByLawyers(invoice.timeExpenseEntry, null));
    } else {
      setTimeListByLawyer([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice.timeExpenseEntry]);

  const recalculateAndSet = (newInv, disPercToAmt = false) => {
    let subtotal = 0.0;
    let totTimeAmt = 0.0;
    let totExpAmt = 0.0;
    let taxBase = 0.0;
    let totalNetAmount = 0.0;
    newInv.discountAmount = 0.0;
    newInv.timeExpenseEntry.forEach((el) => {
      newInv.discountAmount += +el.discountAmount;
      const amt = +el.amount;
      subtotal += amt;
      totalNetAmount += +el.netAmount;
      if (el.isTaxable) {
        taxBase += amt;
      }
      if (el.type === "time") {
        totTimeAmt += amt;
      } else {
        totExpAmt += amt;
      }
    });

    const discBase =
      newInv.discountMode === 0
        ? totTimeAmt
        : newInv.discountMode === 1
        ? totExpAmt
        : subtotal;

    if (disPercToAmt) {
      newInv.discountAmount = (+newInv.discountPercentage * discBase) / 100;
    } else {
      newInv.discountPercentage =
        discBase === 0 ? 0 : (+newInv.discountAmount * 100) / discBase;
      newInv.discountPercentage = +(+newInv.discountPercentage).toFixed(2);
    }

    let taxAmt =
      (+newInv.taxPercentage * (taxBase - +newInv.discountAmount)) / 100;
    taxAmt = +taxAmt.toFixed(2);

    newInv.amountBeforeTaxAndDiscount = subtotal;
    newInv.taxAmount = taxAmt;
    newInv.totalAmount = subtotal - newInv.discountAmount + taxAmt;
    newInv.totalNetAmount = totalNetAmount;
    setInvoice(newInv);
    setError((err) => ({
      ...err,
      discountPercentage: isNaN(newInv.discountPercentage),
      discountAmount: isNaN(newInv.discountAmount),
      taxPercentage: isNaN(newInv.taxPercentage),
      taxAmount: isNaN(newInv.taxAmount),
      totalAmount: isNaN(newInv.totalAmount),
      totalNetAmount: isNaN(newInv.totalNetAmount),
    }));
  };

  const handleSelect = () => {
    var all = invoice.id
      ? [...invoice.timeExpenseEntry, ...selectedTempEntries]
      : [...selectedTempEntries];

    invoice.timeExpenseEntry = all;
    setTimeListByLawyer(groupByLawyers(all, timeListByLawyer));

    recalculateAndSet({ ...invoice });
    setOpenSelectModal(false);
  };

  const handleRemove = (entryId) => {
    setTimeListByLawyer(
      groupByLawyers(
        invoice.timeExpenseEntry.filter((el) => el.id !== entryId),
        timeListByLawyer
      )
    );
    recalculateAndSet({
      ...invoice,
      timeExpenseEntry: invoice.timeExpenseEntry.filter(
        (el) => el.id !== entryId
      ),
    });
    setSelectedTempEntries(
      selectedTempEntries.filter((el) => el.id !== entryId)
    );
  };

  const handleChangeInvoiceNum = (event, t) => {
    const val = event.target.value;
    let inv = { ...invoice };
    if (
      (RegExp("[0-9]{" + val.length + "}").test(val) && val.length < 7) ||
      val === ""
    ) {
      inv = {
        ...invoice,
        invoiceNum: val,
      };
    }
    setInvoice(inv);
    setError({
      ...error,
      invoiceNum:
        (event.target.validity && !event.target.validity.valid) ||
        !(
          RegExp("[0-9]{6}").test(inv.invoiceNum) && inv.invoiceNum.length === 6
        ),
    });
  };

  const handleChangeDiscount = (evt, t) => {
    let totalFees = 0;
    let timeList = timeListByLawyer[t];
    let name = evt.target.name;
    let value = evt.target.value;
    let inv = invoice;
    let index = -1;
    if (timeList) {
      timeList.timeEntry.forEach((time) => {
        if (time.isDiscountable) {
          totalFees += time.amount;
        }
      });
      if (name === "discountAmount") {
        timeList.discountAmount = value ? +(+value).toFixed(2) : 0;
        timeList.discountPercentage = (+value / totalFees) * 100;
        if (timeList.discountPercentage > 100) {
          timeList.discountPercentage = 100;
          timeList.discountAmount = totalFees;
          alertWarning("Warning! Maximum discount is 100%");
        }
      } else {
        timeList.discountPercentage = value ? +value : 0;
        timeList.discountAmount = +((+value * totalFees) / 100).toFixed(2);
        if (timeList.discountPercentage > 100) {
          timeList.discountPercentage = 100;
          timeList.discountAmount = totalFees;
          alertWarning("Warning! Maximum discount is 100%");
        }
      }
      timeList.timeEntry.forEach((time) => {
        if (time.isDiscountable) {
          time.discountPercentage = +timeList.discountPercentage;
          time.discountAmount =
            (+time.amount * timeList.discountPercentage) / 100;
          time.discountAmount = +time.discountAmount.toFixed(2);
          time.netAmount = +(+time.amount - time.discountAmount).toFixed(2);
          index = inv.timeExpenseEntry.findIndex((qte) => qte.id === time.id);
          if (index) {
            inv.timeExpenseEntry[index] = time;
            setInvoice(inv);
          }
        }
      });
      setTimeListByLawyer({
        ...timeListByLawyer,
        [t]: timeList,
      });
      recalculateAndSet(inv);
    }
  };

  const handleChangeByKey = (k, v) => {
    setInvoice({
      ...invoice,
      [k]: v,
    });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    recalculateAndSet(
      { ...invoice, [name]: +e.target.value },
      name === "discountPercentage"
    );
  };

  const toggleApplyTaxToAll = (e) => {
    e.persist && e.persist();
    recalculateAndSet({
      ...invoice,
      timeExpenseEntry: invoice.timeExpenseEntry.map((el) => ({
        ...el,
        isTaxable: e.target.checked,
      })),
    });
    setTimeListByLawyer(
      groupByLawyers(
        invoice.timeExpenseEntry.map((el) => ({
          ...el,
          isTaxable: e.target.checked,
        }))
      )
    );
  };

  const toggleApplyDiscountToAll = (e, t) => {
    e.persist && e.persist();
    recalculateAndSet({
      ...invoice,
      timeExpenseEntry: invoice.timeExpenseEntry.map((el) =>
        el.staff && el.staff.id && el.staff.id === t
          ? {
              ...el,
              isDiscountable: e.target.checked,
              discountAmount:
                e.target.checked && timeListByLawyer[t]
                  ? (+timeListByLawyer[t].discountPercentage * el.amount) / 100
                  : 0,
              discountPercentage:
                e.target.checked && timeListByLawyer[t]
                  ? +timeListByLawyer[t].discountPercentage
                  : 0,
              netAmount:
                e.target.checked && timeListByLawyer[t]
                  ? el.amount -
                    (timeListByLawyer[t].discountPercentage * el.amount) / 100
                  : el.amount,
            }
          : { ...el }
      ),
    });
    setTimeListByLawyer(
      groupByLawyers(
        invoice.timeExpenseEntry.map((el) =>
          el.staff && el.staff.id && el.staff.id === t
            ? {
                ...el,
                isDiscountable: e.target.checked,
                discountAmount:
                  e.target.checked && timeListByLawyer[t]
                    ? (+timeListByLawyer[t].discountPercentage * el.amount) /
                      100
                    : 0,
                discountPercentage:
                  e.target.checked && timeListByLawyer[t]
                    ? +timeListByLawyer[t].discountPercentage
                    : 0,
                netAmount:
                  e.target.checked && timeListByLawyer[t]
                    ? el.amount -
                      (+timeListByLawyer[t].discountPercentage * el.amount) /
                        100
                    : el.amount,
              }
            : { ...el }
        ),
        timeListByLawyer
      )
    );
  };

  const toggleIsTaxable = (timeExp) => {
    const newTimeExp = { ...timeExp, isTaxable: !timeExp.isTaxable };
    const newArr = [...invoice.timeExpenseEntry];
    let i = newArr.findIndex((el) => el.id === timeExp.id);
    newArr.splice(i, 1, newTimeExp);
    setTimeListByLawyer((prev) => groupByLawyers(newArr, prev));
    recalculateAndSet({
      ...invoice,
      timeExpenseEntry: newArr,
    });
  };

  const toggleIsDiscountable = (timeExp, t) => {
    let timeList = timeListByLawyer[t];
    const newTimeExp = {
      ...timeExp,
      discountAmount:
        timeList && !timeExp.isDiscountable
          ? (timeExp.amount * +timeList.discountPercentage) / 100
          : 0,
      discountPercentage:
        timeList && !timeExp.isDiscountable ? +timeList.discountPercentage : 0,
      netAmount: !timeExp.isDiscountable
        ? parseFloat(timeExp.amount) -
          parseFloat((timeExp.amount * +timeList.discountPercentage) / 100)
        : timeExp.amount,
      isDiscountable: !timeExp.isDiscountable,
    };
    const newArr = [...invoice.timeExpenseEntry];
    const index = newArr.findIndex((t) => t.id === timeExp.id);
    newArr.splice(index, 1, newTimeExp);
    let i = timeListByLawyer[t].timeEntry.findIndex(
      (time) => time.id === timeExp.id
    );
    let tll = timeListByLawyer[t];
    tll.timeEntry[i] = newTimeExp;
    recalculateAndSet({
      ...invoice,
      timeExpenseEntry: newArr,
    });
    if (tll) {
      let totalFees = 0;
      tll.timeEntry.forEach((time) => {
        if (time.isDiscountable) {
          totalFees += time.amount;
        }
      });
      tll.discountAmount = (+tll.discountPercentage * totalFees) / 100;
    }
    setTimeListByLawyer({
      ...timeListByLawyer,
      [t]: tll,
    });
  };

  const rowCount = invoice.timeExpenseEntry.length;
  const numSelected = invoice.timeExpenseEntry.filter((el) => el.isTaxable)
    .length;

  const getSelectedCount = (t) => {
    if (timeListByLawyer[t]) {
      return timeListByLawyer[t].timeEntry.filter((el) => el.isDiscountable)
        .length;
    }
  };

  const getRowCount = (t) => {
    if (timeListByLawyer[t]) {
      return timeListByLawyer[t].timeEntry.length;
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form className={clsx("InvoiceForm", className)}>
        <SimpleBox className={classes.box} title="">
          {!invoice.id && (
            <>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={classes.p175}
                  style={{ paddingTop: 0 }}
                >
                  <div className={classes.fullWidth}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="manualReferenceNo"
                          color="primary"
                          checked={invoice.isRefManual}
                          onChange={() => {
                            if (invoice.isRefManual) {
                              clearReferenceNo();
                            }
                            const newInv = {
                              ...invoice,
                              isRefManual: !invoice.isRefManual,
                            };
                            setInvoice(newInv);
                          }}
                        />
                      }
                      label="Manual Invoice Number"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end" justifyContent="flex-end">
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.p175}
                  style={{ paddingTop: 0 }}
                >
                  <Collapse in={invoice.isRefManual}>
                    <TextField
                      label="Invoice Number"
                      name="invoiceNum"
                      value={invoice.invoiceNum}
                      onChange={handleChangeInvoiceNum}
                      error={error.invoiceNum}
                      onBlur={onBlur}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">INV</InputAdornment>
                        ),
                      }}
                      helperText="Enter 6 digits only"
                    />
                  </Collapse>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.p175}
                  style={{ paddingTop: 0 }}
                >
                  <KeyboardDatePicker
                    style={{ marginTop: 20 }}
                    margin="none"
                    format="dd-MMM-yyyy"
                    label="Created Date"
                    name="date"
                    value={new Date(invoice.date)}
                    onChange={(v) =>
                      handleChangeByKey("date", formatDateDash(v))
                    }
                    helperText=" "
                    disableFuture
                  />
                </Grid>
              </Grid>
            </>
          )}

          {invoice.id && (
            <Grid
              item
              xs={12}
              md={6}
              className={classes.p175}
              style={{ paddingTop: 0 }}
            >
              <KeyboardDatePicker
                style={{ marginTop: 20 }}
                margin="none"
                format="dd-MMM-yyyy"
                label="Created Date"
                name="date"
                value={new Date(invoice.date)}
                onChange={(v) => handleChangeByKey("date", formatDateDash(v))}
                helperText=" "
              />
            </Grid>
          )}

          <InvoiceHeaderSection
            client={client}
            selectedCase={selectedCase}
            invoice={invoice}
          />

          <Divider style={{ margin: "16px 0 12px 0" }} />

          <Grid container>
            {rowCount > 0 && (
              <Grid item xs={12} md={12} className={classes.p175}>
                <TableContainer className={classes.paper} component={Paper}>
                  <Typography
                    className={classes.p175}
                    variant="body1"
                    color="textSecondary"
                    gutterBottom
                  >
                    Selected Time/Expense Entries
                  </Typography>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <Typography variant="body2" color="primary">
                            Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color="primary">
                            Description
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color="primary">
                            Staff
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="primary" noWrap>
                            Billable Time
                            <br />
                            (Hr:Min)
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="primary" noWrap>
                            Amount
                            <br />
                            ($)
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="primary" noWrap>
                            Net Amount
                            <br />
                            ($)
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color="primary">
                            Taxable
                          </Typography>
                          <Checkbox
                            color="primary"
                            size="small"
                            indeterminate={
                              numSelected > 0 && numSelected < rowCount
                            }
                            indeterminateIcon={
                              <IndeterminateCheckBox color="primary" />
                            }
                            checked={rowCount > 0 && numSelected === rowCount}
                            onClick={toggleApplyTaxToAll}
                            inputProps={{
                              "aria-label": "apply tax to all entries",
                            }}
                            style={{ marginLeft: "8px", padding: "0 4px" }}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2" color="primary">
                            Discountable
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(timeListByLawyer).map((t, i) => (
                        <Fragment key={i}>
                          <TableRow>
                            <TableCell colSpan={4}>
                              <Typography variant="body1" color="textSecondary">
                                Time Entries of{" "}
                                <span style={{ color: "#00bcd4" }}>
                                  {staffList.find((s) => s.id === t) &&
                                    staffList.find((s) => s.id === t).value}
                                </span>
                              </Typography>
                            </TableCell>
                            <TableCell colSpan={2}>
                              <TextField
                                label="Discount ($)"
                                value={
                                  +(+timeListByLawyer[t]
                                    .discountAmount).toFixed(2)
                                }
                                onChange={(v) => handleChangeDiscount(v, t)}
                                onBlur={onBlur}
                                type="number"
                                inputProps={{
                                  name: "discountAmount",
                                  className: classes.numInput,
                                  pattern: "^[1-9]d*$",
                                }}
                                disabled={
                                  !timeListByLawyer[t].timeEntry.find(
                                    (time) => time.isDiscountable === true
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell colSpan={2}>
                              <TextField
                                label="Discount (%)"
                                value={
                                  +(+timeListByLawyer[t]
                                    .discountPercentage).toFixed(2)
                                }
                                onChange={(v) => handleChangeDiscount(v, t)}
                                onBlur={onBlur}
                                type="number"
                                inputProps={{
                                  name: "discountPercentage",
                                  className: classes.numInput,
                                  min: 0,
                                  max: 100,
                                  pattern: "^[1-9]d*$",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                color="primary"
                                size="small"
                                indeterminate={
                                  getSelectedCount(t) > 0 &&
                                  getSelectedCount(t) < getRowCount(t)
                                }
                                indeterminateIcon={
                                  <IndeterminateCheckBox color="primary" />
                                }
                                checked={
                                  getRowCount(t) > 0 &&
                                  getSelectedCount(t) === getRowCount(t)
                                }
                                onClick={(evt) =>
                                  toggleApplyDiscountToAll(evt, t)
                                }
                                inputProps={{
                                  "aria-label": "apply tax to all entries",
                                }}
                                style={{ marginLeft: "8px", padding: "0 4px" }}
                              />
                            </TableCell>
                          </TableRow>
                          {timeListByLawyer[t].timeEntry.map((time, i) => (
                            <TimeExpenseEntryItem
                              key={i + "|" + t}
                              tee={time}
                              index={i}
                              group={t}
                              handleRemove={handleRemove}
                              toggleIsTaxable={toggleIsTaxable}
                              toggleIsDiscountable={toggleIsDiscountable}
                              fetchInvoiceList={fetchInvoiceList}
                              invoice={invoice}
                              setInvoice={setInvoice}
                              recalculateAndSet={recalculateAndSet}
                            />
                          ))}
                        </Fragment>
                      ))}
                      {invoice.timeExpenseEntry.filter(
                        (el) => el.type !== "time"
                      ).length > 0 && (
                        <TableRow>
                          <TableCell colSpan={9}>
                            <Typography variant="body1" color="textSecondary">
                              Expenses
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      {invoice.timeExpenseEntry
                        .filter((el) => el.type !== "time").sort((a, b) => a.date.localeCompare(b.date))
                        .map((timeExp, i) => (
                          <TimeExpenseEntryItem
                            key={timeExp.id}
                            tee={timeExp}
                            index={i}
                            handleRemove={handleRemove}
                            toggleIsTaxable={toggleIsTaxable}
                            fetchInvoiceList={fetchInvoiceList}
                            invoice={invoice}
                            setInvoice={setInvoice}
                            recalculateAndSet={recalculateAndSet}
                          />
                        ))}
                      <TableRow>
                        <TableCell colSpan={4} />
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontWeight: 600 }}
                          >
                            Total :
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            color="primary"
                            align="right"
                            className="e360-number"
                            style={{ fontWeight: 600 }}
                          >
                            {formatDecimal(invoice.amountBeforeTaxAndDiscount)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            <Grid item xs={12} md={6} className={classes.p175}>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setOpenSelectModal(true)}
              >
                Add Time/Expense
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className={classes.p175}
              style={{ paddingBottom: 0 }}
            >
              <Grid container justifyContent="flex-end" alignItems="flex-end">
                <Grid item xs={4} className={classes.p175LessBottom}>
                  <TextField
                    label="Tax (%)"
                    onChange={handleChange}
                    inputProps={{
                      name: "taxPercentage",
                      className: classes.numInput,
                    }}
                    value={invoice.taxPercentage}
                    error={error.taxPercentage}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} className={classes.p175LessBottom}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    style={{ marginTop: "8px" }}
                  >
                    <SwapHorizRounded />
                  </Typography>
                </Grid>
                <Grid item xs={5} md={4} className={classes.p175LessBottom}>
                  <TextField
                    label="Tax ($)"
                    inputProps={{
                      name: "taxAmount",
                      className: classes.numInput,
                    }}
                    value={invoice.taxAmount}
                    error={error.taxAmount}
                    disabled
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Divider style={{ margin: "24px 0 12px 0" }} />
              <Grid container>
                <Grid item xs={6} className={classes.p175}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    Gross Amount ($) :
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.p175}>
                  <Typography
                    variant="body1"
                    color={
                      error.amountBeforeTaxAndDiscount ? "error" : "primary"
                    }
                    align="right"
                    className="e360-number"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    {formatDecimal(invoice.amountBeforeTaxAndDiscount)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} className={classes.p175}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    Total Discount Amount ($) :
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.p175}>
                  <Typography
                    variant="body1"
                    color={error.discountAmount ? "error" : "primary"}
                    align="right"
                    className="e360-number"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    {formatDecimal(invoice.discountAmount)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} className={classes.p175}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    Total Tax Amount ($) :
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.p175}>
                  <Typography
                    variant="body1"
                    color={error.taxAmount ? "error" : "primary"}
                    align="right"
                    className="e360-number"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    {formatDecimal(invoice.taxAmount)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} className={classes.p175}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    Total Amount ($) :
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.p175}>
                  <Typography
                    variant="body1"
                    color={error.totalAmount ? "error" : "primary"}
                    align="right"
                    className="e360-number"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    {formatDecimal(invoice.totalAmount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SimpleBox>

        <CustomModal
          maxWidth="md"
          isOpen={openSelectModal}
          title="Select Time/Expense Entries"
          saveBtnTitle="Select"
          closeBtnTitle="Cancel"
          handleSave={handleSelect}
          handleClose={() => setOpenSelectModal(false)}
        >
          <SelectTimeExpenseEntryModal
            selectedTempEntries={selectedTempEntries}
            setSelectedTempEntries={setSelectedTempEntries}
          />
        </CustomModal>
      </form>
    </MuiPickersUtilsProvider>
  );
};

export default InvoiceForm;
