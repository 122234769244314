import React from "react";
import TabPanel from "../../../components/TabPanel";
import { Typography, Divider, List, ListItem, Grid } from "@material-ui/core";
import clsx from "clsx";
import CommonAccordion from "../../../components/CommonAccordion";
import SimpleBox from "../../../components/SimpleBox";
import { formatDate } from "../../../utils/dateUtils";
import { DateFormats } from "../../../utils/constants";

const DetailsTabPanel = ({ client, className, ...others }) => {
  return (
    <TabPanel className={clsx("DetailsTabPanel", className)} {...others}>
      <List className="fullWidth">
        <CommonAccordion title="Info">
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Client Id
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {client.clientId}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Name
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {(client.salutation ? client.salutation.value + " " : "") +
                      client.firstName +
                      " " +
                      client.lastName}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            {client.isClientCompany ? (
              <>
                <Grid item xs={12} md={6} className="p175">
                  <ListItem>
                    <div className="d-flex justify-content-between align-items-center fullWidth">
                      <Typography variant="body1" color="textSecondary">
                        Company Name
                      </Typography>
                      <Typography variant="body1" color="primary">
                        {client.companyName}
                      </Typography>
                    </div>
                  </ListItem>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6} className="p175">
                  <ListItem>
                    <div className="d-flex justify-content-between align-items-center fullWidth">
                      <Typography variant="body1" color="textSecondary">
                        Business Registration Number
                      </Typography>
                      <Typography variant="body1" color="primary">
                        {client.businessRegistrationNumber}
                      </Typography>
                    </div>
                  </ListItem>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6} className="p175">
                  <ListItem>
                    <div className="d-flex justify-content-between align-items-center fullWidth">
                      <Typography variant="body1" color="textSecondary">
                        Tax Registered
                      </Typography>
                      <Typography variant="body1" color="primary">
                        {client.taxRegistered}
                      </Typography>
                    </div>
                  </ListItem>
                  <Divider />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6} className="p175">
                  <ListItem>
                    <div className="d-flex justify-content-between align-items-center fullWidth">
                      <Typography variant="body1" color="textSecondary">
                        ID Card No.
                      </Typography>
                      <Typography variant="body1" color="primary">
                        {client.idCardNumber}
                      </Typography>
                    </div>
                  </ListItem>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6} className="p175">
                  <ListItem>
                    <div className="d-flex justify-content-between align-items-center fullWidth">
                      <Typography variant="body1" color="textSecondary">
                        Date Of Birth
                      </Typography>
                      <Typography variant="body1" color="primary">
                        {formatDate(client.dateOfBirth, DateFormats.MEDIUM)}
                      </Typography>
                    </div>
                  </ListItem>
                  <Divider />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Email
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.email}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Mobile
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.mobile}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Phone
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.phone}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Fax
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.fax}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Created By
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.createdBy ? client.createdBy.value : ""}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Referred By
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.referredBy ? client.referredBy.value : ""}
                  </Typography>
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Date Created
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {formatDate(client.clientCreatedDate, DateFormats.MEDIUM)}
                  </Typography>
                </div>
              </ListItem>
            </Grid>
            {client.note && client.note.trim() && (
              <Grid item xs={12} md={12} className="p175">
                <ListItem>
                  <div className="justify-content-between align-items-center fullWidth">
                    <SimpleBox>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className="descTitle"
                        display="block"
                      >
                        Notes
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="p175"
                        display="block"
                        style={{ paddingTop: 0 }}
                      >
                        {client.note.trim()}
                      </Typography>
                    </SimpleBox>
                  </div>
                </ListItem>
              </Grid>
            )}
          </Grid>
        </CommonAccordion>
        <CommonAccordion title="Postal Address">
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Address 1
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.postalAddressOne}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Address 2
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.postalAddressTwo}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    City
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.postalCity}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    State
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.postalState}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Country
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.postalCountry
                      ? client.postalCountry.value.slice(5)
                      : ""}
                  </Typography>
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Postal Code
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.postalPostalCode}
                  </Typography>
                </div>
              </ListItem>
            </Grid>
          </Grid>
        </CommonAccordion>
        <CommonAccordion title="Billing Address">
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Address 1
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.billingAddressOne}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Address 2
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.billingAddressTwo}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    City
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.billingCity}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    State
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.billingState}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Country
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.billingCountry
                      ? client.billingCountry.value.slice(5)
                      : ""}
                  </Typography>
                </div>
              </ListItem>
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Postal Code
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.billingPostalCode}
                  </Typography>
                </div>
              </ListItem>
            </Grid>
          </Grid>
        </CommonAccordion>
        <CommonAccordion title="Payment Settings">
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Payment Term
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.paymentTerm && client.paymentTerm.value
                      ? client.paymentTerm.value
                      : "None Selected"}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Billing Term
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.billingTerm && client.billingTerm.value
                      ? client.billingTerm.value
                      : "None Selected"}
                  </Typography>
                </div>
              </ListItem>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ListItem>
                <div className="d-flex justify-content-between align-items-center fullWidth">
                  <Typography variant="body1" color="textSecondary">
                    Yearly Interest Rate
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {client.yearlyInterestRate + "%"}
                  </Typography>
                </div>
              </ListItem>
            </Grid>
          </Grid>
        </CommonAccordion>
      </List>
    </TabPanel>
  );
};

export default DetailsTabPanel;
