import React, { useState } from "react";
import clsx from "clsx";
import {
  Grid,
  TextField,
  makeStyles,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Collapse,
  Button,
  TextareaAutosize,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CloudUploadRounded } from "@material-ui/icons";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CommonAccordion from "../../components/CommonAccordion";
import { useContext } from "react";
import { DataContext } from "../../DataContext";
import ComplexSelect from "../../components/ComplexSelect";
import { formatDateDash } from "../../utils/dateUtils";
import SlideUp from "../../components/SlideUp";
import CustomModal from "../../components/CustomModal";
import ConflictTable from "./ConflictTable";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  lb: {
    marginLeft: theme.spacing(3),
  },
  input: {
    display: "none",
  },
  notes: {
    width: "100%",
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    border: "1px solid",
    borderColor: theme.palette.divider,
    fontFamily: theme.typography.fontFamily,
    fontSize: "14px",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    outline: "none",
    appearance: "none",
  },
  idRoot: {
    fontSize: theme.typography.body2,
    "& span": {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.body1,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: "1px",
      paddingLeft: "8px",
    },
    "& span.disabled": {
      color: theme.palette.action.disabled,
    },
  },
}));

const ClientForm = ({
  className,
  data,
  error,
  handleChange = () => {},
  handleChangeByKey = () => {},
  setError = () => {},
  onBlur = () => {},
  isBRNRequired = false,
  isTaxRegisteredRequired = false,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const {
    staffList,
    countryList,
    salutationList,
    paymentTermList,
    billingTermList,
    alertError,
  } = useContext(DataContext);
  const [showConflict, setShowConflict] = useState(false);
  const [conflictList, setConflictList] = useState([]);

  const openConflictTable = () => {
    Axios.post("/clients/conflict", data)
      .then((res) => {
        if (res.data) {
          setConflictList(res.data);
          setShowConflict(true);
        }
      })
      .catch((err) => {
        alertError("Error! Cannot fetch conflict list!");
        setConflictList([]);
        setShowConflict(false);
      });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form className={clsx("ClientForm", className)}>
        <CommonAccordion title="Info">
          <div className="d-flex justify-content-center">
            <ToggleButtonGroup
              value={data.clientType}
              exclusive
              onChange={(_, v) =>
                v && !data.id && handleChangeByKey("clientType", v)
              }
              size="small"
            >
              <ToggleButton value="company">Company</ToggleButton>
              <ToggleButton value="person">Person</ToggleButton>
            </ToggleButtonGroup>
          </div>
          {data.clientType === "company" ? (
            <div>
              <Grid container>
                <Grid item xs={12} md={6} className="p175">
                  <TextField
                    label="Company Name"
                    fullWidth
                    required
                    name="companyName"
                    value={data.companyName}
                    error={error.companyName}
                    onChange={handleChange}
                    onBlur={onBlur}
                  />
                </Grid>
                <Grid item xs={12} md={6} className="p175">
                  <TextField
                    label="Tax Registered"
                    fullWidth
                    required={isTaxRegisteredRequired}
                    value={data.taxRegistered}
                    name="taxRegistered"
                    onChange={handleChange}
                    error={error.taxRegistered}
                    onBlur={onBlur}
                  />
                </Grid>
                <Grid item xs={12} md={6} className="p175">
                  <TextField
                    label="Business Registration Number"
                    helperText={
                      isBRNRequired ? (
                        <>
                          <Typography variant="caption" component="p">
                            9 digits and 1 capital letter (eg. 123456789A).
                          </Typography>
                          <Typography variant="caption" component="p">
                            Or 8 digits and 1 capital letter (eg. 12345678A).
                          </Typography>
                          <Typography variant="caption" component="p">
                            Or Full year, 4 digits and 1 capital letter (eg.
                            20005678A).
                          </Typography>
                          <Typography variant="caption" component="span">
                            Or 1 capital letter, 2 digit year, 2 capital
                            letters, 4 digits and 1 capital letter (eg.
                            S09LL0001B).
                          </Typography>
                        </>
                      ) : (
                        <> </>
                      )
                    }
                    fullWidth
                    required={isBRNRequired}
                    error={error.businessRegistrationNumber}
                    value={data.businessRegistrationNumber}
                    name="businessRegistrationNumber"
                    onChange={handleChange}
                    onBlur={onBlur}
                  />
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <Grid container alignItems="flex-end">
                <Grid item xs={12} md={6} className="p175">
                  <TextField
                    label="ID Card Number"
                    name="idCardNumber"
                    value={data.idCardNumber}
                    onChange={handleChange}
                    error={error.idCardNumber}
                    onBlur={onBlur}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} className="p175">
                  <input
                    className={classes.input}
                    id="upload-id-card-button"
                    multiple
                    type="file"
                  />
                  <label htmlFor="upload-id-card-button">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<CloudUploadRounded />}
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6} className="p175">
                  <KeyboardDatePicker
                    required
                    fullWidth
                    margin="normal"
                    format="dd-MMM-yyyy"
                    label="Date of Birth"
                    name="dateOfBirth"
                    value={data.dateOfBirth}
                    onChange={(v) =>
                      handleChangeByKey("dateOfBirth", formatDateDash(v))
                    }
                    error={error.dateOfBirth}
                    onBlur={onBlur}
                    disableFuture
                  />
                </Grid>
              </Grid>
            </div>
          )}
          <Divider style={{ margin: "16px 0" }} />
          <div className="p175">
            <Typography className={classes.idRoot} color="textSecondary" noWrap>
              Client ID :
              <span className={clsx(!data.clientId && "disabled")}>
                {data.clientId || "N/A"}
              </span>
            </Typography>
          </div>
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <div className="d-flex">
                <div style={{ minWidth: "32%" }}>
                  <ComplexSelect
                    label="Salutation"
                    required
                    name="salutation"
                    value={data.salutation}
                    defaultValue={null}
                    error={error.salutation}
                    options={salutationList}
                    onChange={handleChange}
                    onBlur={onBlur}
                  />
                </div>
                <TextField
                  label="First Name"
                  className={clsx("flex-grow", classes.lb)}
                  required
                  name="firstName"
                  value={data.firstName}
                  error={error.firstName}
                  onChange={handleChange}
                  onBlur={onBlur}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <div className="d-flex align-items-end">
                <TextField
                  className="flex-grow"
                  label="Last Name"
                  required
                  name="lastName"
                  value={data.lastName}
                  error={error.lastName}
                  onChange={handleChange}
                  onBlur={onBlur}
                />
                <Button
                  color="primary"
                  className={classes.lb}
                  onClick={openConflictTable}
                >
                  Conflict
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <TextField
                label="Fax"
                fullWidth
                name="fax"
                value={data.fax}
                error={error.fax}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <div className="d-flex align-items-end">
                <TextField
                  className="flex-grow"
                  label="Email"
                  required
                  type="email"
                  name="email"
                  value={data.email}
                  error={error.email}
                  onChange={handleChange}
                  onBlur={onBlur}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <TextField
                label="Mobile"
                fullWidth
                required
                name="mobile"
                value={data.mobile}
                error={error.mobile}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <TextField
                label="Phone"
                fullWidth
                name="phone"
                value={data.phone}
                error={error.phone}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <ComplexSelect
                options={staffList}
                required
                fullWidth
                label="Created By"
                name="createdBy"
                defaultValue={null}
                value={data.createdBy}
                error={error.createdBy}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ComplexSelect
                options={staffList}
                required
                fullWidth
                label="Referred By"
                name="referredBy"
                value={data.referredBy}
                defaultValue={null}
                error={error.referredBy}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <KeyboardDatePicker
                required
                fullWidth
                margin="normal"
                format="dd-MMM-yyyy"
                label="Date Created"
                name="clientCreatedDate"
                value={data.clientCreatedDate}
                error={error.clientCreatedDate}
                onChange={(v) =>
                  handleChangeByKey("clientCreatedDate", formatDateDash(v))
                }
                onBlur={onBlur}
                disableFuture
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className="p175">
              <InputLabel id="lblNotes" htmlFor="note">
                Notes :
              </InputLabel>
              <TextareaAutosize
                id="note"
                name="note"
                minRows={3}
                className={classes.notes}
                value={data.note}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </CommonAccordion>
        <CommonAccordion title="Postal Address">
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <TextField
                label="Address 1"
                fullWidth
                required
                name="postalAddressOne"
                value={data.postalAddressOne}
                error={error.postalAddressOne}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <TextField
                label="Address 2"
                fullWidth
                name="postalAddressTwo"
                value={data.postalAddressTwo}
                error={error.postalAddressTwo}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <TextField
                label="City"
                fullWidth
                required
                name="postalCity"
                value={data.postalCity}
                error={error.postalCity}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <TextField
                label="State"
                fullWidth
                required
                name="postalState"
                value={data.postalState}
                error={error.postalState}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <TextField
                label="Postal Code"
                fullWidth
                required
                name="postalPostalCode"
                value={data.postalPostalCode}
                error={error.postalPostalCode}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ComplexSelect
                options={countryList}
                name="postalCountry"
                label="Country"
                fullWidth
                required
                value={data.postalCountry}
                defaultValue={null}
                error={error.postalCountry}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
          </Grid>
        </CommonAccordion>
        <CommonAccordion title="Billing Address">
          <FormControlLabel
            className="p175"
            control={
              <Checkbox
                name="isBillingSameAsPostal"
                color="primary"
                checked={data.isBillingSameAsPostal}
                onChange={() =>
                  handleChangeByKey(
                    "isBillingSameAsPostal",
                    !data.isBillingSameAsPostal
                  )
                }
              />
            }
            label="Billing Address is same as above"
          />
          <Collapse in={!data.isBillingSameAsPostal}>
            <Grid container>
              <Grid item xs={12} md={6} className="p175">
                <TextField
                  label="Address 1"
                  fullWidth
                  required
                  name="billingAddressOne"
                  value={data.billingAddressOne}
                  error={error.billingAddressOne}
                  onChange={handleChange}
                  onBlur={onBlur}
                />
              </Grid>
              <Grid item xs={12} md={6} className="p175">
                <TextField
                  label="Address 2"
                  fullWidth
                  name="billingAddressTwo"
                  value={data.billingAddressTwo}
                  error={error.billingAddressTwo}
                  onChange={handleChange}
                  onBlur={onBlur}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} className="p175">
                <TextField
                  label="City"
                  fullWidth
                  required
                  name="billingCity"
                  value={data.billingCity}
                  error={error.billingCity}
                  onChange={handleChange}
                  onBlur={onBlur}
                />
              </Grid>
              <Grid item xs={12} md={6} className="p175">
                <TextField
                  label="State"
                  fullWidth
                  required
                  name="billingState"
                  value={data.billingState}
                  error={error.billingState}
                  onChange={handleChange}
                  onBlur={onBlur}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} className="p175">
                <TextField
                  label="Postal Code"
                  fullWidth
                  required
                  name="billingPostalCode"
                  value={data.billingPostalCode}
                  error={error.billingPostalCode}
                  onChange={handleChange}
                  onBlur={onBlur}
                />
              </Grid>
              <Grid item xs={12} md={6} className="p175">
                <ComplexSelect
                  options={countryList}
                  name="billingCountry"
                  label="Country"
                  fullWidth
                  required
                  value={data.billingCountry}
                  error={error.billingCountry}
                  onChange={handleChange}
                  onBlur={onBlur}
                />
              </Grid>
            </Grid>
          </Collapse>
        </CommonAccordion>
        <CommonAccordion title="Payment Settings">
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <ComplexSelect
                options={paymentTermList}
                label="Select Payment Terms"
                fullWidth
                name="paymentTerm"
                value={data.paymentTerm}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ComplexSelect
                options={billingTermList}
                label="Select Billing Terms"
                fullWidth
                name="billingTerm"
                value={data.billingTerm}
                defaultValue={null}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <TextField
                label="Yearly Interest Rate"
                fullWidth
                type="number"
                name="yearlyInterestRate"
                value={data.yearlyInterestRate}
                error={error.yearlyInterestRate}
                onChange={handleChange}
                onBlur={onBlur}
              />
            </Grid>
          </Grid>
        </CommonAccordion>
      </form>
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="md"
        isOpen={showConflict}
        title={"Conflict Check"}
        closeBtnTitle="Close"
        handleClose={() => setShowConflict(false)}
      >
        <ConflictTable conflictList={conflictList} />
      </CustomModal>
    </MuiPickersUtilsProvider>
  );
};

export default ClientForm;
