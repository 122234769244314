import React from "react";
import {
  getCurrentUserDefObj,
  getDefAuditOptObj,
  getDefaultCaseObj,
} from "./model";
import { getDefaultConfigObj } from "./config/appConfig";
import { getDefaultTimeExpObj } from "./model";

export const DataContext = React.createContext({
  fetchAllData: () => {},
  fetchMasterTypes: () => {},
  fetchMasterCodes: () => {},
  fetchStaffList: () => {},
  fetchClientList: () => {},
  fetchCaseList: () => {},
  startTime: 0,
  setStartTime: () => {},
  alertSuccess: () => {},
  alertWarning: () => {},
  alertError: () => {},
  alertInfo: () => {},
  staffList: [],
  clientList: [],
  caseList: [],
  caseCatList: [],
  invoiceList: [],
  timeExpList: [],
  actCodes: [],
  expCodes: [],
  masterCodeList: [],
  masterTypeList: [],
  typeCategoryList: [],
  codeCategoryList: [],
  setStaffList: () => {},
  setClientList: () => {},
  setCaseList: () => {},
  setCaseCatList: () => {},
  setInvoiceList: () => {},
  setTimeExpList: () => {},
  setActCodes: () => {},
  setExpCodes: () => {},
  setMasterTypeList: () => {},
  setMasterCodeList: () => {},
  currentUser: getCurrentUserDefObj(),
  setCurrentUser: () => {},
  countryList: [],
  setCountryList: () => {},
  salutationList: [],
  setSalutationList: () => {},
  staffTypes: [],
  setStaffTypes: () => {},
  roleTypes: [],
  setRoleTypes: () => {},
  rateList: [],
  setRateList: () => {},
  statusList: [],
  setStatusList: () => {},
  permissionList: [],
  setPermissionList: () => {},
  invoiceTypes: [],
  setInvoiceTypes: () => {},
  debitCreditList: [],
  setDebitCreditList: () => {},
  transactionList: [],
  setTransactionList: () => {},
  depositTransactionList: [],
  setDepositTransactionList: () => {},
  caseTemp: getDefaultCaseObj(),
  setCaseTemp: () => {},
  entryListCache: {},
  setEntryListCache: () => {},
});

export const PopupContext = React.createContext({
  openCase: false,
  setOpenCase: () => {},
  openTimer: false,
  setOpenTimer: () => {},
  openQte: false,
  setOpenQte: () => {},
  openQee: false,
  setOpenQee: () => {},
  openBulkQte: false,
  setOpenBulkQte: () => {},
  openSplitInv: false,
  setOpenSplitInv: () => {},
  invToSplit: null,
  setInvToSplit: () => {},
  selectedTimeExp: getDefaultTimeExpObj,
  setSelectedTimeExp: () => {},
  auditOptions: getDefAuditOptObj(),
  setAuditOptions: () => {},
  dialogOptions: {
    open: false,
    title: "",
    message: "",
    Icon: null,
    cancelBtnText: "Cancel",
    okBtnText: "Ok",
    cancelBtnColor: "default",
    okBtnColor: "primary",
    onOkClicked: () => {},
    onCancelClicked: () => {},
  },
  openDialog: () => {},
  changePasswordOptions: {
    open: false,
    type: "change",
    personSid: "",
    name: "",
    email: "",
  },
  setChangePasswordOptions: () => {},
});

export const SettingContext = React.createContext({
  staffSetting: getDefaultConfigObj(),
  setStaffSetting: () => {},
  clientSetting: getDefaultConfigObj(),
  setClientSetting: () => {},
  caseSetting: getDefaultConfigObj(),
  setCaseSetting: () => {},
  companySetting: getDefaultConfigObj(),
  setCompanySetting: () => {},
  companyAdminSetting: getDefaultConfigObj(),
  setCompanyAdminSetting: () => {},
  qteSelfSetting: getDefaultConfigObj(),
  setQteSelfSetting: () => {},
  qteAllSetting: getDefaultConfigObj(),
  setQteAllSetting: () => {},
  qeeSelfSetting: getDefaultConfigObj(),
  setQeeSelfSetting: () => {},
  qeeAllSetting: getDefaultConfigObj(),
  setQeeAllSetting: () => {},
  invoiceSetting: getDefaultConfigObj(),
  setInvoiceSetting: () => {},
  refetch: false,
  setRefetch: (prev) => !prev,
  refetchCase: false,
  setRefetchCase: (prev) => !prev,
  processing: false,
  setProcessing: (prev) => !prev,
});
