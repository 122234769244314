import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  ArchiveRounded,
  CreateRounded,
  DeleteRounded,
  DescriptionRounded,
  HistoryRounded,
  VisibilityRounded,
} from "@material-ui/icons";
import docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import JSzip from "jszip";
import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import creditNoteTemplate from "../../assets/templates/credit_note_template.docx";
import debitNoteTemplate from "../../assets/templates/debit_note_template.docx";
import CustomModal from "../../components/CustomModal";
import DebitCreditDetail from "../../components/DebitCreditDetail";
import DebitCreditTable from "../../components/DebitCreditTable";
import SlideUp from "../../components/SlideUp";
import { DataContext } from "../../DataContext";
import { RoleTypes } from "../../utils/constants";

const DebitCreditPage = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { debitCreditList, currentUser } = useContext(DataContext);

  const [isDebitCreditDetailOpen, setIsDebitCreditDetailOpen] = useState(false);
  const [selectedDebitCredit, setSelectedDebitCredit] = useState(null);

  const openDebitCreditDetailModal = async (debitCredit) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSelectedDebitCredit(debitCredit);
      setIsDebitCreditDetailOpen(true);
    });
  };

  const generateDebitNote = (debitCredit) => {
    let debitCreditNoteTemplate =
      debitCredit.type === "debit" ? debitNoteTemplate : creditNoteTemplate;
    fetch(debitCreditNoteTemplate)
      .then((d) => d.arrayBuffer())
      .then((ab) => {
        let zip = new JSzip(ab);
        let doc = new docxtemplater(zip);

        doc.setData(debitCredit);
        doc.render();

        let out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        debitCredit.type === "debit"
          ? saveAs(out, `${"DB-" + debitCredit.debitCreditNoteNumber}.docx`)
          : saveAs(out, `${"CD-" + debitCredit.debitCreditNoteNumber}.docx`);
      });
  };

  const getAllMenuItems = (debitCredit) => {
    let menuItems = [
      {
        text: `View ${debitCredit.type === "debit" ? "Debit" : "Credit"} Note`,
        icon: <VisibilityRounded fontSize="small" />,
        handler: () => openDebitCreditDetailModal(debitCredit),
      },
      currentUser.roleType.roleTypeName === RoleTypes.MANAGER && {
        text: "View Audit History",
        icon: <HistoryRounded fontSize="small" />,
        disabled: !debitCredit,
      },
      {
        text: "Edit",
        icon: <CreateRounded fontSize="small" />,
      },
      {
        text: debitCredit && !debitCredit.active ? "Unarchive" : "Archive",
        icon: <ArchiveRounded fontSize="small" />,
        disabled: !debitCredit,
      },
      {
        text: "Delete",
        icon: <DeleteRounded fontSize="small" />,
        disabled: !debitCredit,
      },
      {
        text: "Export as Word",
        icon: <DescriptionRounded fontSize="small" />,
        handler: () => generateDebitNote(debitCredit),
      },
    ];
    return menuItems;
  };

  return (
    <>
      <DebitCreditTable
        data={debitCreditList}
        getAllMenuItems={getAllMenuItems}
      />
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="md"
        isOpen={isDebitCreditDetailOpen}
        title={`${
          selectedDebitCredit?.type === "debit" ? "Debit" : "Credit"
        } Detail`}
        handleClose={() => setIsDebitCreditDetailOpen(false)}
        hideFooter
      >
        <DebitCreditDetail debitCredit={selectedDebitCredit} />
      </CustomModal>
    </>
  );
};

export default DebitCreditPage;
