import React from "react";
import clsx from "clsx";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import { ArrowBackIosRounded, MoreVertRounded } from "@material-ui/icons";

export const useStyles = makeStyles(() => ({
  limitHeight: {
    height: "100%",
    maxHeight: "100%",
  },
  pattern: {
    position: "relative",
    "&::after": {
      position: "absolute",
      pointerEvents: "none",
      top: 0,
      left: 0,
      right: 0,
      height: "56px",
      content: "''",
      background:
        "repeating-linear-gradient( -45deg, rgba(255, 152, 0, 0.16), rgba(255, 152, 0, 0.16) 3.5px, rgba(140, 143, 145, 0.05) 3.5px, rgba(140, 143, 145, 0.05) 16px)",
    },
  },
}));

const CommonPage = ({
  className,
  detailTitle,
  PageSidebar,
  Buttons,
  children,
  setSelectedData,
  archived,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const leftArrowClicked = () => {
    !desktop && setSelectedData && setSelectedData(null);
  };

  return (
    <div
      className={clsx("CommonPage", classes.limitHeight, className)}
      style={{ position: "relative" }}
    >
      <Grid container spacing={desktop ? 1 : 0} className={classes.limitHeight}>
        {(desktop || !detailTitle) && (
          <Grid item xs={12} md={3} className={classes.limitHeight}>
            <Paper
              elevation={0}
              className={clsx(classes.limitHeight, "resp-rounded")}
              style={{
                padding: "16px 12px",
              }}
            >
              {PageSidebar}
            </Paper>
          </Grid>
        )}
        {(desktop || detailTitle) && (
          <Grid item xs={12} md={9}>
            <Paper
              elevation={0}
              style={{ padding: "16px 12px" }}
              className={clsx("resp-rounded", archived && classes.pattern)}
            >
              {/*rgba(255, 152, 0, 0.145)*/}
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid
                  container
                  item
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  {!desktop && (
                    <Grid item>
                      <IconButton size="small" onClick={leftArrowClicked}>
                        <ArrowBackIosRounded />
                      </IconButton>
                    </Grid>
                  )}
                  <Grid item style={desktop ? { paddingLeft: "12px" } : {}}>
                    <Typography variant="h6" color="textSecondary">
                      {detailTitle}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}
                  xs={5}
                  wrap="nowrap"
                >
                  {Buttons || (
                    <Grid item>
                      <IconButton size="small">
                        <MoreVertRounded />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <div
                style={{
                  // maxHeight: "calc(100% - 176px)",
                  maxHeight: "100%",
                  overflowY: "auto",
                }}
              >
                {children}
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default CommonPage;
