import React, { useEffect, useMemo, useState } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";

import {
  CssBaseline,
  makeStyles,
  Slide,
  Snackbar,
  ThemeProvider,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AllModals from "./components/AllModals";
import AppHeader from "./components/AppHeader";
import AppSidebar from "./components/AppSidebar";
import { DataContext, PopupContext, SettingContext } from "./DataContext";
import CONSTANTS, {
  MasterCodeCats,
  MasterTypeCats,
  RoleTypes,
} from "./utils/constants";
import { groupBy } from "./utils/functions";

import {
  getDefAuditOptObj,
  getDefaultCaseObj,
  getDefaultTimeExpObj,
} from "./model";
import { getCurrentUserDefObj } from "./model/currentUser";

import Axios from "axios";
import PrivateRoute from "./components/PrivateRoute";
import { getDefaultConfigObj } from "./config/appConfig";
import Redirect401 from "./config/Redirect401";
import { recreateMuiTheme } from "./theme/recreateMuiTheme";

import LoadingBar from "./components/LoadingBar";
import AccountsPage from "./pages/acounts/AccountsPage";
import CasesPage from "./pages/cases/CasesPage";
import ClientsPage from "./pages/clients/ClientsPage";
import CompaniesPage from "./pages/companies/CompaniesPage";
import CompanyAdminPage from "./pages/company-admin/CompanyAdminPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import DebitCreditPage from "./pages/debit-credit/DebitCreditPage";
import HomePage from "./pages/home/HomePage";
import InvoicePage from "./pages/invoices/InvoicePage";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import NotFoundPage from "./pages/NotFoundPage";
import OfficeAccountPage from "./pages/office-account/OfficeAccountPage";
import ExpenseEntryPage from "./pages/qee/ExpenseEntryPage";
import TimeEntryPage from "./pages/qte/TimeEntryPage";
import SettingPage from "./pages/settings/SettingPage";
import StaffPage from "./pages/staff/StaffPage";

const SlideFromTopToBottom = (props) => <Slide {...props} direction="down" />;

const useStyles = makeStyles((theme) => ({
  content: {
    position: "fixed",
    top: "80px",
    right: "16px",
    bottom: "16px",
    left: "16px",
    zIndex: 1000,
    maxWidth: "calc(100vw - 32px)",
    maxHeight: "calc(100vh - 96px)",
    [theme.breakpoints.down("md")]: {
      top: "64px",
      right: "0",
      bottom: "0",
      left: "0",
      maxWidth: "100vw",
      maxHeight: "calc(100vh - 64px)",
      "& .resp-rounded": {
        borderRadius: "0",
      },
    },
    display: "flex",
    flexDirection: "column",
    "&>*": {
      flex: "1 1 auto",
    },
    transition: "all 250ms ease-out",
  },
}));

const App = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(localStorage.getItem("mode") || "light");
  const customTheme = useMemo(() => recreateMuiTheme(mode), [mode]);

  const [startTime, setStartTime] = useState(0);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [warningMsg, setWarningMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [infoMsg, setInfoMsg] = useState("");

  const [staffList, setStaffList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [caseList, setCaseList] = useState([]);
  const [caseCatList, setCaseCatList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [expCodes, setExpCodes] = useState([]);
  const [actCodes, setActCodes] = useState([]);
  const [masterCodeList, setMasterCodeList] = useState([]);
  const [masterTypeList, setMasterTypeList] = useState([]);
  const [timeExpList, setTimeExpList] = useState([]);
  const [currentUser, setCurrentUser] = useState(
    (localStorage.getItem(CONSTANTS.CURRENT_USER) &&
      JSON.parse(localStorage.getItem(CONSTANTS.CURRENT_USER))) ||
      getCurrentUserDefObj()
  );
  const [countryList, setCountryList] = useState([]);
  const [salutationList, setSalutationList] = useState([]);
  const [roleTypes, setRoleTypes] = useState([]);
  const [staffTypes, setStaffTypes] = useState([]);

  const [rateList, setRateList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);

  const [clientType, setClientType] = useState([]);
  const [paymentTermList, setPaymentTermList] = useState([]);
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [billingTermList, setBillingTermList] = useState([]);
  const [typeCategoryList, setTypeCategoryList] = useState([]);
  const [codeCategoryList, setCodeCategoryList] = useState([]);
  const [entryListCache, setEntryListCache] = useState({});

  const [staffSetting, setStaffSetting] = useState(getDefaultConfigObj());
  const [caseSetting, setCaseSetting] = useState(getDefaultConfigObj());
  const [clientSetting, setClientSetting] = useState(getDefaultConfigObj());
  const [companySetting, setCompanySetting] = useState(getDefaultConfigObj());
  const [companyAdminSetting, setCompanyAdminSetting] = useState(
    getDefaultConfigObj()
  );
  const [qteSelfSetting, setQteSelfSetting] = useState(getDefaultConfigObj());
  const [qteAllSetting, setQteAllSetting] = useState(getDefaultConfigObj());
  const [qeeSelfSetting, setQeeSelfSetting] = useState(getDefaultConfigObj());
  const [qeeAllSetting, setQeeAllSetting] = useState(getDefaultConfigObj());
  const [invoiceSetting, setInvoiceSetting] = useState(getDefaultConfigObj());
  const [refetch, setRefetch] = useState(false);
  const [refetchCase, setRefetchCase] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [caseTemp, setCaseTemp] = useState(getDefaultCaseObj());
  const [openCase, setOpenCase] = useState(false);
  const [openTimer, setOpenTimer] = useState(false);
  const [openQte, setOpenQte] = useState(false);
  const [openQee, setOpenQee] = useState(false);
  const [openBulkQte, setOpenBulkQte] = useState(false);
  const [openSplitInv, setOpenSplitInv] = useState(false);
  const [invToSplit, setInvToSplit] = useState(null);
  const [selectedTimeExp, setSelectedTimeExp] = useState(getDefaultTimeExpObj);
  const [auditOptions, setAuditOptions] = useState(getDefAuditOptObj());
  const [dialogOptions, openDialog] = useState({
    open: false,
    title: "",
    message: "",
    cancelBtnText: "Cancel",
    okBtnText: "Ok",
    cancelBtnColor: "default",
    okBtnColor: "primary",
    onOkClicked: () => {},
    onCancelClicked: () => {},
  });
  const [changePasswordOptions, setChangePasswordOptions] = useState({
    open: false,
    type: "change",
    personSid: "",
    name: "",
    email: "",
  });
  const [debitCreditList, setDebitCreditList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [depositTransactionList, setDepositTransactionList] = useState([]);

  const fetchClientList = () => {
    if (
      currentUser.roleType &&
      currentUser.roleType.roleTypeName !== RoleTypes.PORTAL_ADMIN &&
      currentUser.roleType.roleTypeName !== RoleTypes.COMPANY_ADMIN
    ) {
      Axios.get("/clients/all")
        .then((res) =>
          setClientList(
            res.data
              .sort((a, b) =>
                a.value > b.value ? 1 : a.value < b.value ? -1 : 0
              )
              ?.filter((c) => c?.active === true)
          )
        )
        .catch((err) => setClientList([]));
    }
  };

  const fetchStaffList = () => {
    if (
      currentUser.roleType &&
      currentUser.roleType.roleTypeName !== RoleTypes.PORTAL_ADMIN
    ) {
      Axios.get("/staffs/employees")
        .then((res) =>
          setStaffList(
            res.data
              .sort((a, b) =>
                a.value > b.value ? 1 : a.value < b.value ? -1 : 0
              )
              ?.filter((s) => s?.active === true)
          )
        )
        .catch((err) => setStaffList([]));
    }
  };

  const fetchCaseList = () => {
    if (
      currentUser.roleType &&
      currentUser.roleType.roleTypeName !== RoleTypes.PORTAL_ADMIN &&
      currentUser.roleType.roleTypeName !== RoleTypes.COMPANY_ADMIN
    ) {
      Axios.get("/cases/all")
        .then((res) => setCaseList(res.data))
        .catch((err) => setCaseList([]));
    }
  };

  const fetchDebitCreditList = () => {
    Axios.get("/debit-credit/all")
      .then((res) => setDebitCreditList(res.data))
      .catch((err) => setDebitCreditList([]));
  };

  const fetchMasterTypes = () => {
    Axios.get("/setting/types/all")
      .then((res) => {
        const types = groupBy(res.data, "category");

        setRoleTypes(
          (types[MasterTypeCats.ROLE] || []).map((el) => ({
            id: el.masterTypeSid,
            value: el.typeLongDescription,
          }))
        );

        setStaffTypes(
          (types[MasterTypeCats.STAFF] || []).map((el) => ({
            id: el.masterTypeSid,
            value: el.typeShortDescription,
          }))
        );

        setSalutationList(
          (types[MasterTypeCats.SALUTATION] || []).map((el) => ({
            id: el.masterTypeSid,
            value: el.typeShortDescription,
          }))
        );

        setCaseCatList(
          (types[MasterTypeCats.CASE_CATEGORY] || [])
            .map((el) => ({
              id: el.masterTypeSid,
              value: el.typeShortDescription,
            }))
            .sort((a, b) =>
              a.value > b.value ? 1 : a.value < b.value ? -1 : 0
            )
        );

        setStatusList(
          (types[MasterTypeCats.CASE_STATUS] || []).map((el) => ({
            id: el.typeShortDescription,
            value: el.typeLongDescription,
          }))
        );

        setPermissionList(
          (types[MasterTypeCats.CASE_PERMISSION] || []).map((el) => ({
            id: el.typeShortDescription,
            value: el.typeLongDescription,
          }))
        );

        setRateList(
          (types[MasterTypeCats.CASE_RATE] || []).map((el) => ({
            id: el.typeShortDescription,
            value: el.typeLongDescription,
          }))
        );

        setClientType(
          (types[MasterTypeCats.CLIENT] || []).map((el) => ({
            id: el.masterTypeSid,
            value: el.typeLongDescription,
          }))
        );

        const list = types[MasterTypeCats.PAYMENT_TERM] || [];
        const numList = list
          .filter((el) => !isNaN(+el.typeShortDescription))
          .sort((a, b) =>
            +a.typeShortDescription > +b.typeShortDescription
              ? 1
              : +a.typeShortDescription < +b.typeShortDescription
              ? -1
              : 0
          );
        const strList = list.filter((el) => isNaN(+el.typeShortDescription));
        setPaymentTermList(
          [...numList, ...strList].map((el) => ({
            id: el.masterTypeSid,
            value: el.typeLongDescription,
          }))
        );

        setBillingTermList(
          (types[MasterTypeCats.BILLING_TERM] || []).map((el) => ({
            id: el.masterTypeSid,
            value: el.typeLongDescription,
          }))
        );
        setInvoiceTypes(
          (types[MasterTypeCats.INVOICE] || []).map((el) => ({
            id: el.masterTypeSid,
            value: el.typeLongDescription,
            secondaryValue: el.typeShortDescription,
          }))
        );

        setTypeCategoryList(getMasterCategories(res.data));
        setMasterTypeList(res.data);
      })
      .catch((err) => {
        setRoleTypes([]);
        setStaffTypes([]);
        setSalutationList([]);
        setCaseCatList([]);
        setStatusList([]);
        setPermissionList([]);
        setRateList([]);
        setPaymentTermList([]);
        setBillingTermList([]);
        setInvoiceTypes([]);
        setTypeCategoryList([]);
      });
  };

  const fetchMasterCodes = () => {
    Axios.get("/setting/codes/all")
      .then((res) => {
        const codes = groupBy(res.data, "category");

        setActCodes(
          (codes[MasterCodeCats.ACT] || [])
            .map((el) => ({
              id: el.masterCodeSid,
              value: `${el.typeShortDescription} - ${el.typeLongDescription}`,
            }))
            .sort((a, b) =>
              a.value > b.value ? 1 : a.value < b.value ? -1 : 0
            )
        );

        setExpCodes(
          (codes[MasterCodeCats.EXP] || [])
            .map((el) => ({
              id: el.masterCodeSid,
              value: `${el.typeShortDescription} - ${el.typeLongDescription}`,
            }))
            .sort((a, b) =>
              a.value > b.value ? 1 : a.value < b.value ? -1 : 0
            )
        );

        setCountryList(
          (codes[MasterCodeCats.COUNTRY] || [])
            .sort((a, b) =>
              a.typeLongDescription > b.typeLongDescription
                ? 1
                : a.typeLongDescription < b.typeLongDescription
                ? -1
                : 0
            )
            .map((el) => ({
              id: el.masterCodeSid,
              value:
                el.typeShortDescription.toUpperCase() +
                " - " +
                el.typeLongDescription,
            }))
        );
        setCodeCategoryList(getMasterCategories(res.data));
        setMasterCodeList(res.data);
      })
      .catch((err) => {
        setActCodes([]);
        setExpCodes([]);
        setCountryList([]);
        setCodeCategoryList([]);
        setMasterCodeList([]);
        console.error(err);
      });
  };

  const fetchAllData = () => {
    if (
      localStorage.getItem(CONSTANTS.ACCESS_TOKEN) &&
      currentUser &&
      currentUser.accessToken
    ) {
      fetchMasterTypes();
      fetchMasterCodes();
      fetchStaffList();
      fetchClientList();
      fetchCaseList();
      fetchDebitCreditList();
    }
  };

  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const alertSuccess = (msg) => {
    setShowSuccess(true);
    setSuccessMsg(msg);
  };

  const alertWarning = (msg) => {
    setShowWarning(true);
    setWarningMsg(msg);
  };

  const alertError = (msg) => {
    setShowError(true);
    setErrorMsg(msg);
  };

  const alertInfo = (msg) => {
    setShowInfo(true);
    setInfoMsg(msg);
  };

  const getMasterCategories = (list) => {
    let categories = [];
    let id = 1;
    list.forEach((el) => {
      let category = categories.find((l) => l.value === el.category);
      if (category === undefined) {
        el &&
          categories.push({
            id: id,
            value: el.category,
          });
        id++;
      }
    });
    return categories;
  };

  const closeSuccessAlert = () => setShowSuccess(false);
  const closeWarningAlert = () => setShowWarning(false);
  const closeErrorAlert = () => setShowError(false);
  const closeInfoAlert = () => setShowInfo(false);

  return (
    <DataContext.Provider
      value={{
        caseTemp,
        setCaseTemp,
        startTime,
        setStartTime,
        staffList,
        setStaffList,
        clientList,
        setClientList,
        caseList,
        setCaseList,
        caseCatList,
        setCaseCatList,
        invoiceList,
        setInvoiceList,
        actCodes,
        setActCodes,
        expCodes,
        setExpCodes,
        timeExpList,
        setTimeExpList,
        alertSuccess,
        alertWarning,
        alertError,
        alertInfo,
        currentUser,
        setCurrentUser,
        countryList,
        setCountryList,
        masterCodeList,
        setMasterCodeList,
        fetchAllData,
        fetchMasterTypes,
        fetchMasterCodes,
        fetchClientList,
        fetchStaffList,
        fetchCaseList,
        salutationList,
        setSalutationList,
        roleTypes,
        setRoleTypes,
        staffTypes,
        setStaffTypes,
        rateList,
        setRateList,
        statusList,
        setStatusList,
        permissionList,
        setPermissionList,
        clientType,
        setClientType,
        paymentTermList,
        setPaymentTermList,
        billingTermList,
        setBillingTermList,
        invoiceTypes,
        setInvoiceTypes,
        masterTypeList,
        setMasterTypeList,
        typeCategoryList,
        setTypeCategoryList,
        codeCategoryList,
        setCodeCategoryList,
        debitCreditList,
        setDebitCreditList,
        transactionList,
        setTransactionList,
        depositTransactionList,
        setDepositTransactionList,
        entryListCache,
        setEntryListCache,
      }}
    >
      <SettingContext.Provider
        value={{
          staffSetting,
          setStaffSetting,
          clientSetting,
          setClientSetting,
          caseSetting,
          setCaseSetting,
          companySetting,
          setCompanySetting,
          companyAdminSetting,
          setCompanyAdminSetting,
          qteSelfSetting,
          setQteSelfSetting,
          qteAllSetting,
          setQteAllSetting,
          qeeSelfSetting,
          setQeeSelfSetting,
          qeeAllSetting,
          setQeeAllSetting,
          invoiceSetting,
          setInvoiceSetting,
          refetch,
          setRefetch,
          refetchCase,
          setRefetchCase,
          processing,
          setProcessing,
        }}
      >
        <PopupContext.Provider
          value={{
            changePasswordOptions,
            setChangePasswordOptions,
            openCase,
            setOpenCase,
            openTimer,
            setOpenTimer,
            openQte,
            setOpenQte,
            openQee,
            setOpenQee,
            openBulkQte,
            setOpenBulkQte,
            openSplitInv,
            setOpenSplitInv,
            invToSplit,
            setInvToSplit,
            selectedTimeExp,
            setSelectedTimeExp,
            auditOptions,
            setAuditOptions,
            dialogOptions,
            openDialog,
          }}
        >
          <ThemeProvider theme={customTheme}>
            <Router>
              <Redirect401 />
              <div className="App">
                <CssBaseline />
                {processing && <LoadingBar />}
                <AppHeader
                  open={open}
                  handleDrawerOpen={handleDrawerOpen}
                  setMode={setMode}
                />
                <AppSidebar
                  open={open}
                  handleDrawerOpen={handleDrawerOpen}
                  handleDrawerClose={handleDrawerClose}
                />
                <main className={classes.content}>
                  <Switch>
                    <Route exact path="/" component={LoginPage} />
                    <Route exact path="/logout" component={LogoutPage} />
                    <PrivateRoute
                      exact
                      path="/manage-companies"
                      component={CompaniesPage}
                    />
                    <PrivateRoute
                      exact
                      path="/company-admin"
                      component={CompanyAdminPage}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={DashboardPage}
                    />
                    <PrivateRoute exact path="/home" component={HomePage} />
                    <PrivateRoute
                      exact
                      path="/clients"
                      component={ClientsPage}
                    />
                    <PrivateRoute exact path="/cases" component={CasesPage} />
                    <PrivateRoute exact path="/staff" component={StaffPage} />
                    <PrivateRoute
                      exact
                      path="/accounts"
                      component={AccountsPage}
                    />
                    <PrivateRoute
                      exact
                      path="/office-account"
                      component={OfficeAccountPage}
                    />
                    <PrivateRoute
                      exact
                      path="/debit-credit"
                      component={DebitCreditPage}
                    />
                    <PrivateRoute
                      exact
                      path="/quick-time-entries"
                      component={TimeEntryPage}
                    />
                    <PrivateRoute
                      exact
                      path="/quick-expense-entries"
                      component={ExpenseEntryPage}
                    />
                    <PrivateRoute
                      exact
                      path="/all-quick-time-entries"
                      component={TimeEntryPage}
                    />
                    <PrivateRoute
                      exact
                      path="/all-quick-expense-entries"
                      component={ExpenseEntryPage}
                    />
                    <PrivateRoute
                      exact
                      path="/all-invoices"
                      component={InvoicePage}
                    />
                    <PrivateRoute
                      exact
                      path="/setting"
                      component={SettingPage}
                    />
                    <PrivateRoute path="*" component={NotFoundPage} />
                  </Switch>
                </main>
                <AllModals />
              </div>
            </Router>
            <Snackbar
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              TransitionComponent={SlideFromTopToBottom}
              open={showSuccess}
              autoHideDuration={3000}
              onClose={closeSuccessAlert}
            >
              <Alert onClose={closeSuccessAlert} severity="success">
                {successMsg}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              TransitionComponent={SlideFromTopToBottom}
              open={showWarning}
              autoHideDuration={3000}
              onClose={closeWarningAlert}
            >
              <Alert onClose={closeWarningAlert} severity="warning">
                {warningMsg}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              TransitionComponent={SlideFromTopToBottom}
              open={showError}
              autoHideDuration={3000}
              onClose={closeErrorAlert}
            >
              <Alert onClose={closeErrorAlert} severity="error">
                {errorMsg}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              TransitionComponent={SlideFromTopToBottom}
              open={showInfo}
              autoHideDuration={3000}
              onClose={closeInfoAlert}
            >
              <Alert onClose={closeInfoAlert} severity="info">
                {infoMsg}
              </Alert>
            </Snackbar>
          </ThemeProvider>
        </PopupContext.Provider>
      </SettingContext.Provider>
    </DataContext.Provider>
  );
};

export default App;
