import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from "@material-ui/core";
// import { HelpRounded } from "@material-ui/icons";
import { formatDate } from "../utils/dateUtils";
import { DateFormats } from "../utils/constants";
// import { formatDecimal } from "../utils/functions";
import PopupMenuButton from "./PopupMenuButton";
import SpanForTooltip from "./SpanForTooltip";
import { useQuickEntryTableStyles } from "../theme/quickEntryTableStyles";
import NoDataRow from "./NoDataRow";
import LoadingRow from "./LoadingRow";

// const InvStatusBadge = ({ children }) => {
//   if (!children) return null;
//   let color = "";
//   switch (children.toUpperCase()) {
//     case "DRAFT":
//       color = ColorValues.GREY;
//       break;
//     case "UNPAID":
//       color = ColorValues.ORANGE;
//       break;
//     case "PAID":
//       color = ColorValues.GREEN;
//       break;
//     case "WRITTEN OFF":
//       color = ColorValues.RED;
//       break;
//     default:
//       break;
//   }
//   const style = {
//     display: "inline-block",
//     borderRadius: "8px",
//     padding: "4px 8px",
//     fontSize: "12px",
//     fontWeight: 600,
//     letterSpacing: "1px",
//     textTransform: "uppercase",
//     cursor: "default",
//     whiteSpace: "nowrap",
//     color: color && colors[color][500],
//     backgroundColor: color && colors[color][500] + "25",
//   };
//   return (
//     <span className="InvStatusBadge" style={style}>
//       {children}
//     </span>
//   );
// };

const DebitCreditTable = ({ isLoading, data, getAllMenuItems, type }) => {
  const theme = useTheme();
  const classes = useQuickEntryTableStyles();
  //   const { invoiceTypes, clientList, staffList } = useContext(DataContext);

  return (
    <TableContainer className="InvoiceTable">
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Debit/Credit No.</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Client</TableCell>
            <TableCell>Case</TableCell>
            <TableCell align="right">Amount ($)</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {isLoading ? (
            <LoadingRow colSpan={7} />
          ) : data && data.length > 0 ? (
            data.map((debitCredit) => (
              <TableRow
                key={debitCredit.id}
                hover={debitCredit.active}
                style={{ opacity: debitCredit.active ? 1 : 0.7 }}
              >
                <TableCell>
                  {debitCredit.type === "credit"
                    ? "CD" + debitCredit.debitCreditNumber
                    : "DB" + debitCredit.debitCreditNumber}
                </TableCell>
                <TableCell>
                  {formatDate(debitCredit.date, DateFormats.MEDIUM)}
                </TableCell>
                <TableCell>{debitCredit.createdBy}</TableCell>
                <TableCell>
                  {debitCredit &&
                  debitCredit.companyName &&
                  debitCredit.companyName.length
                    ? debitCredit.companyName
                    : debitCredit.clientName}
                </TableCell>
                <TableCell>
                  <Tooltip arrow title={debitCredit.caseName}>
                    <SpanForTooltip
                      style={{
                        letterSpacing: "1px",
                        fontSize: "0.875rem",
                        color: theme.palette.secondary.main,
                      }}
                    >
                      Ref#{debitCredit.caseRef}
                    </SpanForTooltip>
                  </Tooltip>
                </TableCell>
                <TableCell align="right">{debitCredit.totalAmount}</TableCell>
                <TableCell align="right">
                  <PopupMenuButton
                    size="small"
                    color="default"
                    isIconButton
                    menuItems={getAllMenuItems(debitCredit)}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoDataRow colSpan={7} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DebitCreditTable;
