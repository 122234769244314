import React from "react";
import { TextField } from "@material-ui/core";
import clsx from "clsx";

const NumberInput = ({ className, min, max, onChange, ...others }) => {
  const onChangeWithFilter = e => {
    const val = e.target.value;
    if (onChange) {
      if (isNaN(val)) {
        e = {
          ...e,
          target: {
            ...e.target,
            validity: {
              ...e.target.validity,
              valid: false
            }
          }
        };
      }
      if ((min || min === 0) && +val < +min) {
        e = {
          ...e,
          target: {
            ...e.target,
            value: +val < +min ? +min : +val,
            validity: {
              ...e.target.validity,
              valid: false
            }
          }
        };
      }
      if ((max || max === 0) && +val > +max) {
        e = {
          ...e,
          target: {
            ...e.target,
            value: +val > +max ? +max : +val,
            validity: {
              ...e.target.validity,
              valid: false
            }
          }
        };
      }
      onChange(e);
    }
  };
  return (
    <TextField
      className={clsx("NumberInput", className)}
      type="number"
      {...others}
      onChange={onChangeWithFilter}
    />
  );
};

export default NumberInput;
