import React from "react";
import clsx from "clsx";
import { Card, CardContent, Typography, CardHeader } from "@material-ui/core";

const SimpleBox = ({ className, title, children }) => {
  return (
    <Card className={clsx("SimpleBox", className)} variant="outlined">
      {title && (
        <CardHeader
          title={
            <Typography variant="body1" color="primary">
              {title}
            </Typography>
          }
          style={{ paddingBottom: "0" }}
        />
      )}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default SimpleBox;
