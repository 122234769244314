export const getCurrentUserDefObj = () => ({
  personSid: null,
  userSid: null,
  firstName: "",
  lastName: "",
  email: "",
  rate: 0,
  roleType: {
    roleTypeName: "",
    roleTypeSid: null,
  },
  staffType: {
    staffTypeName: "",
    staffTypeSid: "null",
  },
  company: {
    taxRegistered: "",
    businessRegistrationNumber: "",
    companyName: "",
    companySid: "",
  },
  accessToken: null,
  rcAuthToken: null,
  rcUserId: null,
  rcModuleUsable: false,
});
