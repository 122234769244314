export const getDefaultStaffObj = () => ({
  personSid: null,
  salutation: "",
  roleType: "",
  staffType: "",
  firstName: "",
  lastName: "",
  initials: "",
  globalHourlyRate: "",
  email: "",
  password: "",
  passwordReenter: "",
  phone: "",
  active: true,
  autoAssignNewCase: false,
  lastLogin: null,
});
