import {
  Divider,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

/**
 * Grid item that contains label/value nature mostly used in detail tab panel. This component must be wrapped under `<Grid container></Grid>` component.
 *
 * Example:
 * ```
 * <Grid container>
 *   <LabelValueDetail label="Name" value="John Doe" />
 *   <LabelValueDetail label="age" value="24" valueColor="secondary" />
 * </Grid>
 * ```
 * @param {className} className of Grid item component
 * @param {style} style object for Grid component
 * @param {label} label name that is wrapped under <code>Typography</code> component
 * @param {value} value that is wrapped under <code>Typography</code> component
 */
const LabelValueDetail = ({
  className,
  style,
  label,
  value,
  labelColor = "textSecondary",
  valueColor = "primary",
  labelStyle,
  valueStyle,
  labelClassName,
  valueClassName,
  hideUnderline,
  isNumber,
  md = 6,
  isCol,
  justifyClassName = "justify-content-between",
  alignClassName = "align-items-center",
}) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const styleObj = style || {};
  styleObj.padding = desktop ? "14px" : "14px 0";

  if (!desktop && !value) return null;

  const valueStyleObj = isNumber
    ? { whiteSpace: "nowrap", letterSpacing: "1px" }
    : {};
  valueStyle && Object.assign(valueStyleObj, valueStyle);
  Object.assign(valueStyleObj, { paddingBottom: "8px" });

  return (
    <Grid
      item
      xs={12}
      md={md}
      className={clsx("LabelValueDetail", className)}
      style={styleObj}
    >
      <div
        className={clsx(
          "d-flex flex-wrap fullWidth",
          justifyClassName,
          alignClassName,
          isCol && "flex-col"
        )}
        style={{ padding: desktop ? "8px 16px 0 16px" : "8px 8px 0 8px" }}
      >
        <Typography
          className={clsx(labelClassName)}
          variant="body1"
          color={labelColor}
          style={{ ...(labelStyle || {}), paddingBottom: "8px" }}
        >
          {label}
        </Typography>
        <Typography
          className={clsx(valueClassName)}
          variant="body1"
          color={valueColor}
          style={valueStyleObj}
        >
          {value}
        </Typography>
      </div>
      {!hideUnderline && <Divider />}
    </Grid>
  );
};

export default LabelValueDetail;
