import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { PopupContext, DataContext, SettingContext } from "../DataContext";
import CustomModal from "./CustomModal";
import { getDefaultInvoicePartyObj } from "../model";
import { formatDecimal, getError } from "../utils/functions";
import { useTheme, useMediaQuery, Button } from "@material-ui/core";
import SlideUp from "./SlideUp";
import Axios from "axios";
import InvoicePartyForm from "./InvoicePartyForm";
import { AddCircleRounded } from "@material-ui/icons";

const SplitInvoiceModal = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { openSplitInv, setOpenSplitInv, invToSplit } =
    useContext(PopupContext);
  const { alertError, alertSuccess, alertWarning } = useContext(DataContext);
  const { setProcessing, setRefetch } = useContext(SettingContext);

  const [list, setList] = useState([
    getDefaultInvoicePartyObj(),
    getDefaultInvoicePartyObj(),
  ]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (openSplitInv && list.length === 0) {
      setList([getDefaultInvoicePartyObj(), getDefaultInvoicePartyObj()]);
      setErrors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSplitInv]);

  const goSplitAndFinalize = (entries = list) => {
    try {
      entries.forEach((el, i) => {
        if (!el.companyName || !el.companyName.trim()) {
          throw new Error("Specify Company Name at item #" + (i + 1));
        } else if (isNaN(el.percentage)) {
          throw new Error("Invalid Percentage value at item #" + (i + 1));
        } else if (+el.percentage <= 0 || +el.percentage >= 100) {
          throw new Error(
            "Invalid Percentage value at item #" +
              (i + 1) +
              " (Range: min=0.01, max=99.99)"
          );
        } else if (!el.percentageShare || !el.percentageShare.trim()) {
          throw new Error(
            "Select type of Percentage Share at item #" + (i + 1)
          );
        } else if (!el.address1 || !el.address1.trim()) {
          throw new Error("Specify Address Line 1 at item #" + (i + 1));
        } else if (!el.address2 || !el.address2.trim()) {
          throw new Error("Specify Address Line 2 at item #" + (i + 1));
        }
      });
      if (entries && entries.length >= 2) {
        setProcessing(true);
        Axios.post(
          `/split-invoices/cases/${invToSplit?.caseSid}/invoices/${invToSplit?.id}`,
          entries
        )
          .then((res) => {
            ReactDOM.unstable_batchedUpdates(() => {
              setProcessing(false);
              setOpenSplitInv(false);
              setErrors([]);
              alertSuccess(
                "Successfully split and finalize the invoice between " +
                  entries.length +
                  " parties"
              );
              setRefetch((prev) => !prev);
            });
          })
          .catch((err) => {
            ReactDOM.unstable_batchedUpdates(() => {
              setProcessing(false);
              alertError(
                getError(err, "Error! Unable to split and finalize the invoice")
              );
            });
          });
      } else {
        alertWarning("Splitting invoice requires at least 2 parties");
      }
    } catch (error) {
      setProcessing(false);
      alertWarning(error.message);
    }
  };

  const handleSave = () => {
    if (errors.length > 0) {
      alertWarning("Please resolve all the errors");
    } else {
      goSplitAndFinalize();
    }
  };

  const handleChange = (i, k, v) => {
    const newList = [...list];
    newList.splice(i, 1, { ...newList[i], [k]: v });
    setList(newList);
  };

  const removeFromList = (ind) => {
    const newList = [...list];
    newList.splice(ind, 1);
    const newErrors = [...errors];
    newErrors.splice(ind, 1);
    ReactDOM.unstable_batchedUpdates(() => {
      setList(newList);
      setErrors(newErrors);
    });
  };

  const disableOkBtn =
    list.length < 2 ||
    list.reduce((tot, el) => (tot += +el.percentage), 0) !== 100;

  return (
    <CustomModal
      TransitionComponent={SlideUp}
      fullScreen={fullScreen}
      maxWidth="sm"
      isOpen={openSplitInv}
      title={`Split Invoice \u2022 $${formatDecimal(invToSplit?.totalAmount)}`}
      saveBtnTitle="Split and Finalize"
      closeBtnTitle="Close"
      handleClose={() => setOpenSplitInv(false)}
      handleSave={handleSave}
      disableSaveBtn={disableOkBtn}
    >
      <div>
        {list &&
          list.length > 0 &&
          list.map((el, i) => (
            <InvoicePartyForm
              key={i}
              data={el}
              ind={i}
              removeFromList={removeFromList}
              handleChange={handleChange}
            />
          ))}

        <Button
          size="small"
          color="primary"
          startIcon={<AddCircleRounded />}
          style={{
            paddingLeft: "16px",
            paddingRight: "16px",
            marginTop: "16px",
          }}
          onClick={() => setList([...list, getDefaultInvoicePartyObj()])}
        >
          Add
        </Button>
      </div>
    </CustomModal>
  );
};

export default SplitInvoiceModal;
