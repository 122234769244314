import React from "react";
import {
  Divider,
  Grid,
  Typography,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { formatDecimal } from "../../../utils/functions";
import LoadingSummary from "../../../components/LoadingSummary";

const useStyles = makeStyles((theme) => ({
  pad: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
}));

const InvoiceSummary = ({ data = [], isLoading }) => {
  const classes = useStyles();

  if (isLoading) {
    return <LoadingSummary className="InvoiceSummary" />;
  }

  if (!data) {
    return null;
  }

  const _data = [...data].filter((el) => el.active);
  if (!_data || _data.length < 1) {
    return null;
  }

  const style = { fontWeight: 600, margin: "14px 8px" };

  let totAmt = 0;
  let totDiscAmt = 0;

  _data.forEach((inv) => {
    totAmt += +inv.totalAmount;
    totDiscAmt += +inv.discountAmount;
  });

  return (
    <Grid className="InvoiceSummary" container justifyContent="flex-end">
      <Grid item xs={12} md={7} lg={6} className={classes.pad}>
        <Divider />
        <Grid container alignItems="center">
          <Grid item xs={7} lg={8}>
            <Typography
              variant="body2"
              color="textSecondary"
              align="right"
              style={style}
              noWrap
            >
              Total Invoices :
            </Typography>
          </Grid>
          <Grid item xs={5} lg={4}>
            <Typography
              variant="body1"
              color="primary"
              align="right"
              className="e360-number"
              style={style}
              noWrap
            >
              {formatDecimal(_data.length, 0)}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container alignItems="center">
          <Grid item xs={7} lg={8}>
            <Typography
              variant="body2"
              color="textSecondary"
              align="right"
              style={style}
              noWrap
            >
              Total Discount ($) :
            </Typography>
          </Grid>
          <Grid item xs={5} lg={4}>
            <Typography
              variant="body1"
              color="primary"
              align="right"
              className="e360-number"
              style={style}
              noWrap
            >
              {formatDecimal(totDiscAmt)}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container alignItems="center">
          <Grid item xs={7} lg={8}>
            <Typography
              variant="body2"
              color="textSecondary"
              align="right"
              style={style}
              noWrap
            >
              Total Amount ($) :
            </Typography>
          </Grid>
          <Grid item xs={5} lg={4}>
            <Typography
              variant="body1"
              color="primary"
              align="right"
              className="e360-number"
              style={style}
              noWrap
            >
              {formatDecimal(totAmt)}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={12}>
            <FormHelperText style={{ textAlign: "right" }}>
              * Archived items are not included in the overall calculation
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { InvoiceSummary };
