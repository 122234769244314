import React, { useContext, useEffect, useState } from "react";
import { PopupContext } from "../DataContext";
import {
  Dialog,
  useTheme,
  useMediaQuery,
  DialogContent,
  Button,
  DialogTitle,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import AuditHistory from "./AuditHistory";
import SlideUp from "./SlideUp";
import { getDefAuditOptObj } from "../model";
import { getDefaultConfigObj } from "../config/appConfig";
import Axios from "axios";
import { HistoryRounded } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { useStylesForPaginationItem } from "./PageSidebar";

const AuditHistoryPopup = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classesForItem = useStylesForPaginationItem();
  const { auditOptions, setAuditOptions } = useContext(PopupContext);

  const [loading, setLoading] = useState(false);
  const [auditList, setAuditList] = useState([]);
  const [paginationOpt, setPaginationOpt] = useState({
    ...getDefaultConfigObj(),
    showArchivedData: true,
  });

  const fetchAuditList = (
    moduleType = auditOptions.moduleType,
    sid = auditOptions.sid,
    page = paginationOpt.currentPage,
    size = paginationOpt.pageSize,
    showArchivedData = paginationOpt.showArchivedData
  ) => {
    setLoading(true);
    Axios.get(
      `/audits/${moduleType}/${sid}?page=${page}&size=${size}&showArchivedData=${showArchivedData}`
    )
      .then((res) => {
        setAuditList(res.data.content);
        setPaginationOpt((prev) => ({
          ...prev,
          totalPages: res.data.totalPages,
          currentPage: page,
        }));
        setLoading(false);
      })
      .catch((err) => {
        setAuditList([]);
        setPaginationOpt((prev) => ({ ...prev, totalPages: 0 }));
        setLoading(false);
      });
  };

  useEffect(() => {
    if (auditOptions.open && auditOptions.moduleType && auditOptions.sid) {
      fetchAuditList(
        auditOptions.moduleType,
        auditOptions.sid,
        paginationOpt.currentPage,
        paginationOpt.pageSize
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auditOptions.open,
    auditOptions.moduleType,
    auditOptions.sid,
    paginationOpt.currentPage,
    paginationOpt.pageSize,
  ]);

  const goHide = () => {
    setAuditOptions(getDefAuditOptObj());
    setPaginationOpt((prev) => ({ ...prev, currentPage: 1 }));
  };

  return (
    <Dialog
      className="AuditHistoryPopup"
      maxWidth="sm"
      TransitionComponent={SlideUp}
      fullScreen={fullScreen}
      open={auditOptions.open}
      onClose={goHide}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle disableTypography style={{ padding: "16px 16px 0 16px" }}>
        <div className="d-flex justify-content-between align-items-center">
          <Typography
            className="d-flex align-items-center"
            variant="body2"
            style={{
              fontWeight: 600,
              letterSpacing: "1px",
              textTransform: "uppercase",
            }}
            color="textSecondary"
          >
            <HistoryRounded style={{ marginRight: "12px" }} />
            <span>History</span>
          </Typography>
          <Button color="secondary" size="small" onClick={goHide}>
            Close
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "8px",
          }}
        >
          <div>
            <Pagination
              className={classesForItem.root}
              color="primary"
              size="small"
              count={paginationOpt.totalPages}
              showFirstButton
              showLastButton
              page={paginationOpt.currentPage}
              onChange={(e, v) =>
                setPaginationOpt((prev) => ({ ...prev, currentPage: v }))
              }
            />
          </div>
        </div>
        <Divider style={{ marginTop: "8px" }} />
      </DialogTitle>
      <DialogContent
        style={
          fullScreen
            ? { padding: "0 2px 16px 2px" }
            : {
                padding: "0 16px 16px 16px",
                minWidth: theme.breakpoints.values.sm,
              }
        }
      >
        <Paper elevation={0}>
          <div style={{ padding: "0 16px 16px 16px", height: "613px" }}>
            <AuditHistory loading={loading} historyList={auditList} />
          </div>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default AuditHistoryPopup;
