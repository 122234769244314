import { Skeleton } from "@material-ui/lab";
import React from "react";

const TablePaginationSkeleton = () => {
  return (
    <div
      className="TablePaginationSkeleton d-flex align-items-center justify-content-end"
      style={{
        height: "52px",
      }}
    >
      <Skeleton width={110} height={36} animation="wave" />
      <Skeleton
        width={50}
        height={36}
        animation="wave"
        style={{ margin: "0 8px" }}
      />
      <Skeleton
        width={100}
        height={36}
        animation="wave"
        style={{ margin: "0 24px" }}
      />
      <Skeleton width={50} height={36} animation="wave" />
    </div>
  );
};

export default TablePaginationSkeleton;
