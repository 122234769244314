import React, { useContext, useState } from "react";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  InputBase,
  makeStyles,
  IconButton,
  Typography,
  Divider,
  Switch,
  InputAdornment,
  Dialog,
  Button,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  RadioGroup,
  Radio,
  useTheme,
} from "@material-ui/core";
import { DialogActions, DialogContent, DialogTitle } from "./CustomModal";
import {
  // FilterListRounded,
  AddCircleRounded,
  SettingsRounded,
  SearchRounded,
  CloseRounded,
} from "@material-ui/icons";
import CustomBadge from "./CustomBadge";
import { Pagination, Skeleton } from "@material-ui/lab";
import { SettingContext } from "../DataContext";
import { Modules } from "../utils/constants";
import PopupMenuButton from "./PopupMenuButton";
import CommonSelect from "./CommonSelect";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  pageSideBar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    maxHeight: "100%",
    "&>.MuiList-root": {
      flex: "1 1 auto",
      overflowY: "auto",
      overflowX: "hidden",
    },
  },
}));

const useStylesForInput = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
    borderRadius: "2rem",
    border: "1px solid",
    borderColor: theme.palette.text.secondary,
    width: "100%",
    overflow: "hidden",
  },
  input: {
    padding: "0",
  },
}));

export const useStylesForPaginationItem = makeStyles((theme) => ({
  root: {
    "& .MuiPaginationItem-root": {
      color: theme.palette.text.secondary,
    },
  },
}));

const getContextKeyByModule = (module) => {
  switch (module) {
    case Modules.CASE:
      return ["caseSetting", "setCaseSetting"];
    case Modules.STAFF:
      return ["staffSetting", "setStaffSetting"];
    case Modules.CLIENT:
      return ["clientSetting", "setClientSetting"];
    case Modules.COMPANY:
      return ["companySetting", "setCompanySetting"];
    case Modules.COMPANY_ADMIN:
      return ["companyAdminSetting", "setCompanyAdminSetting"];

    default:
      return ["caseSetting", "setCaseSetting"];
  }
};

const PageSidebar = ({
  listName,
  listData,
  searchInputPlaceholder = "Search...",
  filterClicked = () => {},
  addClicked = () => {},
  module,
  loading,
  searchTerm,
  setSearchTerm,
  disabledSearch,
  sortFields,
  defaultSortDesc,
  setSorts,
  hideSearchBar,
  hidePagination,
  hideSettingBtn,
}) => {
  const classes = useStyles();
  const classesForInput = useStylesForInput();
  const classesForItem = useStylesForPaginationItem();
  const theme = useTheme();
  const [settingKey, setSettingKey] = getContextKeyByModule(module);
  const setting = useContext(SettingContext)[settingKey];
  const setSetting = useContext(SettingContext)[setSettingKey];

  const [searchTermInternal, setSearchTermInternal] = useState("");
  const [searching, setSearching] = useState(false);

  const [openSort, setOpenSort] = useState(false);
  const [isDefaultSort, setIsDefaultSort] = useState(true);

  const [field1, setField1] = useState("none");
  const [order1, setOrder1] = useState("asc");
  const [field2, setField2] = useState("none");
  const [order2, setOrder2] = useState("asc");
  const [field3, setField3] = useState("none");
  const [order3, setOrder3] = useState("asc");

  const goSearch = () => {
    if (setSearchTerm) {
      setSearchTerm(searchTermInternal);
      setSearching(true);
    }
  };

  const applySort = () => {
    if (setSorts) {
      if (isDefaultSort) {
        setSorts([]);
      } else {
        let ordersTemp = [
          {
            field: field1,
            order: order1,
          },
        ];
        field2 !== "none" && ordersTemp.push({ field: field2, order: order2 });
        field2 !== "none" &&
          field3 !== "none" &&
          ordersTemp.push({ field: field3, order: order3 });
        setSorts(ordersTemp);
      }
    }
    setOpenSort(false);
  };

  const openSortModal = () => setOpenSort(true);
  const closeSortModal = () => setOpenSort(false);

  return (
    <div className={clsx("PageSidebar", classes.pageSideBar)}>
      {!hideSearchBar && (
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
        >
          <div style={{ flexGrow: "1" }}>
            <InputBase
              disabled={disabledSearch}
              value={searchTermInternal}
              onChange={(e) => {
                setSearchTermInternal(e.target.value);
                setSearching(false);
                if (!e.target.value && setSearchTerm) {
                  setSearchTerm("");
                  setSearching(false);
                }
              }}
              onKeyUp={(e) => e.key === "Enter" && goSearch()}
              classes={classesForInput}
              placeholder={searchInputPlaceholder}
              endAdornment={
                <InputAdornment position="end">
                  {searching ? (
                    <IconButton
                      disabled={
                        loading ||
                        !searchTermInternal ||
                        !searchTermInternal.trim()
                      }
                      onClick={() => {
                        setSearchTerm && setSearchTerm("");
                        setSearchTermInternal("");
                        setSearching(false);
                      }}
                    >
                      <CloseRounded fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton
                      disabled={
                        loading ||
                        !searchTermInternal ||
                        !searchTermInternal.trim()
                      }
                      onClick={goSearch}
                    >
                      <SearchRounded fontSize="small" />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </div>
          {/* <IconButton
          color="primary"
          onClick={filterClicked}
          size="small"
          style={{ marginLeft: "8px" }}
        >
          <FilterListRounded />
        </IconButton> */}
          <IconButton
            color="primary"
            onClick={addClicked}
            size="small"
            style={{ marginLeft: "8px" }}
          >
            <AddCircleRounded />
          </IconButton>
        </div>
      )}

      {!hidePagination && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "8px",
          }}
        >
          <div>
            <Pagination
              className={classesForItem.root}
              color="primary"
              size="small"
              count={setting.totalPages}
              showFirstButton
              showLastButton
              page={setting.currentPage}
              onChange={(e, v) =>
                setSetting((prev) => ({ ...prev, currentPage: v }))
              }
            />
          </div>
        </div>
      )}
      {hidePagination && hideSearchBar ? null : (
        <Divider style={{ marginTop: "8px" }} />
      )}
      <div className="d-flex align-items-center justify-content-between">
        <ListSubheader component="div">{listName}</ListSubheader>
        {!hideSettingBtn && (
          <PopupMenuButton
            size="small"
            color="default"
            isIconButton
            buttonIcon={
              <SettingsRounded
                fontSize="small"
                htmlColor={theme.palette.text.secondary}
              />
            }
            menuItems={[
              {
                text: "Show Archived Data",
                // icon: <VisibilityRounded fontSize="small" />,
                extra: (
                  <div
                    style={{
                      marginTop: "2px",
                      marginLeft: "20px",
                    }}
                  >
                    <Switch
                      size="small"
                      checked={setting.showArchivedData}
                      onClick={(e) => e.stopPropagation()}
                      onChange={() =>
                        setSetting((prev) => ({
                          ...prev,
                          showArchivedData: !setting.showArchivedData,
                        }))
                      }
                    />
                  </div>
                ),
              },
              sortFields && {
                text: "Sort By",
                handler: openSortModal,
              },
            ]}
          />
        )}
      </div>
      {loading ? (
        <List dense>
          {[...Array(3).keys()].map((i) => (
            <ListItem
              key={i}
              style={{
                borderBottomColor: "transparent",
                marginTop: "8px",
                marginBottom: "16px",
              }}
            >
              <div style={{ flexGrow: 1 }}>
                <Skeleton
                  animation="wave"
                  width="40%"
                  height={12}
                  style={{ marginBottom: "6px" }}
                />
                <Skeleton animation="wave" width="75%" height={12} />
              </div>
            </ListItem>
          ))}
        </List>
      ) : listData && listData.length ? (
        <List>
          {listData.map((li, i) => (
            <ListItem
              key={li.id ? li.id : i}
              button
              selected={li.selected}
              onClick={li.onClick}
              style={
                !li.active
                  ? {
                      opacity: 0.5,
                    }
                  : {}
              }
            >
              <ListItemText
                primary={li.primary}
                secondary={li.secondary}
                primaryTypographyProps={{ color: "primary" }}
                secondaryTypographyProps={{ component: "div" }}
              />
              {li.badges && li.badges.length && li.badges.length > 0 && (
                <div
                  className="d-flex justify-content-end align-items-center fullHeight fullWidth"
                  style={{
                    position: "absolute",
                    paddingRight: "24px",
                  }}
                >
                  {li.badges.map((b, i) => (
                    <CustomBadge key={i} color={b.color} tooltip={b.tooltip}>
                      {b.value}
                    </CustomBadge>
                  ))}
                </div>
              )}
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body2" align="center" color="error">
          No Data
        </Typography>
      )}
      <Dialog
        className="sort-modal"
        maxWidth="sm"
        open={openSort}
        onClose={closeSortModal}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={closeSortModal}
          hideCloseBtn={false}
        >
          {`Sort ${
            listName && listName.startsWith("Active")
              ? listName.substring(listName.indexOf(" ") + 1)
              : listName
          }`}
        </DialogTitle>
        <DialogContent dividers={true}>
          <FormControlLabel
            control={
              <Checkbox
                name="isDefaultSort"
                color="primary"
                checked={isDefaultSort}
                onChange={() => setIsDefaultSort(!isDefaultSort)}
              />
            }
            label="Default Sort"
          />
          <FormHelperText>{defaultSortDesc}</FormHelperText>
          <Divider style={{ margin: "16px 0" }} />
          {sortFields && (
            <div>
              <div
                className="d-flex align-itmes-center"
                style={{ margin: "8px 0" }}
              >
                <div style={{ width: "200px", paddingRight: "16px" }}>
                  <CommonSelect
                    disabled={isDefaultSort}
                    options={[
                      {
                        field: "none",
                        fieldName: "None",
                        active: false,
                      },
                      ...sortFields,
                    ]}
                    valueKey="field"
                    labelKey="fieldName"
                    fullWidth
                    value={field1}
                    onChange={(e) => setField1(e.target.value)}
                  />
                </div>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="order"
                    name="order1"
                    value={order1}
                    onChange={(e) => setOrder1(e.target.value)}
                  >
                    <FormControlLabel
                      disabled={isDefaultSort || field1 === "none"}
                      value="asc"
                      control={<Radio color="primary" />}
                      label={
                        field1 === "none"
                          ? "Ascending"
                          : sortFields.find((f) => f.field === field1).asc
                      }
                    />
                    <FormControlLabel
                      disabled={isDefaultSort || field1 === "none"}
                      value="desc"
                      control={<Radio color="primary" />}
                      label={
                        field1 === "none"
                          ? "Descending"
                          : sortFields.find((f) => f.field === field1).desc
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div
                className="d-flex align-itmes-center"
                style={{ margin: "8px 0" }}
              >
                <div style={{ width: "200px", paddingRight: "16px" }}>
                  <CommonSelect
                    disabled={isDefaultSort || field1 === "none"}
                    options={[
                      {
                        field: "none",
                        fieldName: "None",
                      },
                      ...sortFields.map((f) =>
                        f.field !== field1 ? f : { ...f, active: false }
                      ),
                    ]}
                    valueKey="field"
                    labelKey="fieldName"
                    fullWidth
                    value={field2}
                    onChange={(e) => setField2(e.target.value)}
                  />
                </div>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="order"
                    name="order2"
                    value={order2}
                    onChange={(e) => setOrder2(e.target.value)}
                  >
                    <FormControlLabel
                      disabled={
                        isDefaultSort || field1 === "none" || field2 === "none"
                      }
                      value="asc"
                      control={<Radio color="primary" />}
                      label={
                        field2 === "none"
                          ? "Ascending"
                          : sortFields.find((f) => f.field === field2).asc
                      }
                    />
                    <FormControlLabel
                      disabled={
                        isDefaultSort || field1 === "none" || field2 === "none"
                      }
                      value="desc"
                      control={<Radio color="primary" />}
                      label={
                        field2 === "none"
                          ? "Descending"
                          : sortFields.find((f) => f.field === field2).desc
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div
                className="d-flex align-itmes-center"
                style={{ margin: "8px 0" }}
              >
                <div style={{ width: "200px", paddingRight: "16px" }}>
                  <CommonSelect
                    disabled={
                      isDefaultSort || field2 === "none" || field1 === "none"
                    }
                    options={[
                      {
                        field: "none",
                        fieldName: "None",
                      },
                      ...sortFields.map((f) =>
                        f.field !== field1 && f.field !== field2
                          ? f
                          : { ...f, active: false }
                      ),
                    ]}
                    valueKey="field"
                    labelKey="fieldName"
                    fullWidth
                    value={field3}
                    onChange={(e) => setField3(e.target.value)}
                  />
                </div>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="order"
                    name="order3"
                    value={order3}
                    onChange={(e) => setOrder3(e.target.value)}
                  >
                    <FormControlLabel
                      disabled={
                        isDefaultSort ||
                        field2 === "none" ||
                        field1 === "none" ||
                        field3 === "none"
                      }
                      value="asc"
                      control={<Radio color="primary" />}
                      label={
                        field3 === "none"
                          ? "Ascending"
                          : sortFields.find((f) => f.field === field3).asc
                      }
                    />
                    <FormControlLabel
                      disabled={
                        isDefaultSort ||
                        field2 === "none" ||
                        field1 === "none" ||
                        field3 === "none"
                      }
                      value="desc"
                      control={<Radio color="primary" />}
                      label={
                        field3 === "none"
                          ? "Descending"
                          : sortFields.find((f) => f.field === field3).desc
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            style={{ marginRight: "8px" }}
            onClick={closeSortModal}
            color="primary"
            variant="outlined"
            disableElevation
          >
            Cancel
          </Button>
          <Button
            style={{ marginRight: "16px" }}
            onClick={applySort}
            color="primary"
            variant="contained"
            disableElevation
            disabled={!isDefaultSort && field1 === "none"}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PageSidebar;
