import React from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import clsx from "clsx";

const CommonAccordion = ({
  className,
  title,
  children,
  defaultExpanded = true,
  expandIcon = <ExpandMoreRounded color="primary" />,
  ...others
}) => {
  return (
    <Accordion
      className={clsx("CommonAccordion", className)}
      defaultExpanded={defaultExpanded}
      {...others}
    >
      <AccordionSummary expandIcon={expandIcon}>
        <Typography color="primary" variant="body1">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CommonAccordion;
