import React from "react";
import {
  Grid,
  TextField,
  makeStyles,
  Typography,
  FormControlLabel,
  Checkbox,
  Collapse,
  colors,
} from "@material-ui/core";
import { getDefaultStaffObj } from "../../../model";
import { formatDecimal } from "../../../utils/functions";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { formatDate, formatDateDash } from "../../../utils/dateUtils";
import DateFnsUtils from "@date-io/date-fns";
import { ColorValues } from "../../../theme/colors";
import { DateFormats } from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  ghrText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    letterSpacing: "1px",
  },
  bold: { fontWeight: 600 },
  dateBadge: {
    display: "inline-block",
    position: "relative",
    borderRadius: "3px",
    padding: "4px 8px",
    fontSize: "12px",
    fontWeight: 400,
    letterSpacing: "1px",
    cursor: "default",
    whiteSpace: "nowrap",
    color: colors[ColorValues.GREY][500],
    backgroundColor: colors[ColorValues.GREY][500] + "25",
    "&:hover": {
      backgroundColor: colors[ColorValues.GREY][500] + "35",
    },
  },
}));

const helperTextStyle = { style: { position: "absolute", bottom: "-22px" } };

export const ChangeGhrForm = ({
  staff = getDefaultStaffObj(),
  newRate,
  setNewRate,
  doNotUpdatePrevQtes,
  setDoNotUpdatePrevQtes,
  dateRange,
  setDateRange,
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form className="ChangeGhrForm">
        <Grid container>
          <Grid item xs={12} className="p175">
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginBottom: "16px" }}
            >
              Current Global Hourly Rate:{" "}
              <span className={classes.ghrText}>
                ${formatDecimal(staff.globalHourlyRate)}
              </span>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              This takes effect once the global hourly rate of{" "}
              <span
                className={classes.bold}
              >{`${staff.firstName} ${staff.lastName} (${staff.email})`}</span>{" "}
              has been successfully changed. All upcoming time entries will use
              this newly updated rate. You can also choose the way this affects
              previous time entries as well.
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} className="p175">
            <TextField
              label="New Global Hourly Rate"
              fullWidth
              required
              name="newRate"
              value={newRate}
              onChange={(e) => setNewRate(e.target.value)}
              error={Boolean(newRate && (isNaN(+newRate) || +newRate <= 0))}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} className="p175">
            <div className={classes.fullWidth}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="doNotUpdatePrevQtes"
                    color="primary"
                    checked={doNotUpdatePrevQtes}
                    onChange={() => setDoNotUpdatePrevQtes((prev) => !prev)}
                  />
                }
                label={
                  <Typography variant="body2" color="textSecondary">
                    Do Not Update Previous Time Entries
                  </Typography>
                }
              />
            </div>
          </Grid>
        </Grid>

        <Collapse in={!doNotUpdatePrevQtes}>
          <Grid container>
            <Grid
              item
              xs={12}
              className="p175"
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <Typography variant="body2" color="textSecondary">
                All of{" "}
                <span
                  className={classes.bold}
                >{`${staff.firstName} ${staff.lastName}`}</span>
                's previous time entries and their respective draft invoices
                within selected date range will be re-calculated. Leave blank to
                make changes to all previous entries.
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} className="p175">
              <KeyboardDatePicker
                fullWidth
                margin="none"
                placeholder="MM/dd/yyyy"
                format="dd-MMM-yyyy"
                label="From Date"
                value={dateRange[0] ? new Date(dateRange[0]) : null}
                onChange={(d) =>
                  setDateRange([formatDateDash(d), dateRange[1]])
                }
                FormHelperTextProps={helperTextStyle}
                disableFuture
              />
            </Grid>
            <Grid item xs={6} className="p175">
              <KeyboardDatePicker
                fullWidth
                margin="none"
                placeholder="MM/dd/yyyy"
                format="dd-MMM-yyyy"
                label="To Date"
                value={dateRange[1] ? new Date(dateRange[1]) : null}
                onChange={(d) =>
                  setDateRange([dateRange[0], formatDateDash(d)])
                }
                FormHelperTextProps={helperTextStyle}
                disableFuture
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} sm={3} className="p175">
              <Typography variant="body2" color="textSecondary" noWrap>
                Affect within:
              </Typography>
            </Grid>
            <Grid item xs={8} sm={9} className="p175">
              {!dateRange[0] && !dateRange[1] ? (
                <div className={classes.dateBadge}>All Time</div>
              ) : (
                <div className={classes.dateBadge}>
                  {dateRange[0]
                    ? formatDate(dateRange[0], DateFormats.MEDIUM)
                    : "Oldest"}{" "}
                  -{" "}
                  {dateRange[1]
                    ? formatDate(dateRange[1], DateFormats.MEDIUM)
                    : "Newest"}
                </div>
              )}
            </Grid>
          </Grid>
        </Collapse>
      </form>
    </MuiPickersUtilsProvider>
  );
};
