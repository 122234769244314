import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { PopupContext, DataContext, SettingContext } from "../DataContext";
import CustomModal from "./CustomModal";
import { getDefaultTimeExpObj } from "../model";
import { getError } from "../utils/functions";
import { useTheme, useMediaQuery, Button } from "@material-ui/core";
import SlideUp from "./SlideUp";
import Axios from "axios";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import QteFormLite from "./QteFormLite";
import { AddCircleRounded, CloseRounded } from "@material-ui/icons";
import { LOGIN_REQUIRED_MSG, RoleTypes } from "../utils/constants";
import {
  deleteQteList,
  retrieveQteList,
  saveQteList,
} from "../utils/bulkQteUtils";

const getDefTimeObj = (staff) => ({ ...getDefaultTimeExpObj(), staff });

const BulkQteModal = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { openBulkQte, setOpenBulkQte } = useContext(PopupContext);
  const { alertError, alertSuccess, alertWarning, currentUser } =
    useContext(DataContext);
  const { setProcessing, setRefetch } = useContext(SettingContext);

  const isStf = currentUser.roleType.roleTypeName === RoleTypes.STAFF;
  const stf = {
    id: currentUser.personSid,
    value: currentUser.firstName + " " + currentUser.lastName,
  };

  const [list, setList] = useState(() => retrieveQteList());
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (openBulkQte && list.length === 0) {
      setList([getDefTimeObj(stf)]);
      setErrors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBulkQte]);

  const goSaveBulkQte = (entries = list) => {
    try {
      entries.forEach((el, i) => {
        if (isNaN(el.hours)) {
          throw new Error("Invalid clocked hour value at line: " + (i + 1));
        } else if (isNaN(el.minutes)) {
          throw new Error("Invalid clocked minute value at line: " + (i + 1));
        } else if (isNaN(el.billedHours)) {
          throw new Error("Invalid billable hour value at line: " + (i + 1));
        } else if (isNaN(el.billedMinutes)) {
          throw new Error("Invalid billable minute value at line: " + (i + 1));
        }
      });
      if (entries && entries.length > 0) {
        setProcessing(true);
        Axios.post("/quick-entries/save-many", entries)
          .then((res) => {
            let errTmp = [];
            if (isNew) {
              errTmp = res.data;
            } else {
              const newErrs = [...res.data];
              errors.forEach((el, i) => {
                if (el && newErrs.length > 0) {
                  errTmp.push(newErrs.shift());
                } else {
                  errTmp.push(el);
                }
              });
            }
            ReactDOM.unstable_batchedUpdates(() => {
              setErrors(errTmp);
              alertSuccess("Successfully processed bulk saving time entries");
              setProcessing(false);
              setRefetch((prev) => !prev);
              deleteQteList();
            });
          })
          .catch((err) => {
            ReactDOM.unstable_batchedUpdates(() => {
              const errorMsg = getError(err, "Error! Unable to save");
              alertError(errorMsg);
              setOpenBulkQte(false);
              setProcessing(false);
              if (errorMsg === LOGIN_REQUIRED_MSG) {
                if (entries.length >= 1) {
                  saveQteList(entries);
                }
              }
            });
          });
      } else {
        alertWarning("No time entry to save");
      }
    } catch (error) {
      alertWarning(error.message);
    }
  };

  const handleSave = () => {
    if (errors.length > 0) {
      const failCount = errors.filter((el) => el).length;
      if (failCount === 0) {
        // Done
        ReactDOM.unstable_batchedUpdates(() => {
          setOpenBulkQte(false);
          setList([getDefTimeObj(stf)]);
          setErrors([]);
        });
      } else {
        // Retry failures
        const failedEntries = [];
        errors.forEach((el, i) => {
          if (el) {
            failedEntries.push(list[i]);
          }
        });
        goSaveBulkQte(failedEntries);
      }
    } else {
      // Save All (new)
      goSaveBulkQte();
    }
  };

  const handleChange = (i, k, v) => {
    const newList = [...list];
    newList.splice(i, 1, { ...newList[i], [k]: v });
    setList(newList);
  };

  const handleChangeObj = (i, li) => {
    const newList = [...list];
    newList.splice(i, 1, li);
    setList(newList);
  };

  const removeFromList = (ind) => {
    const newList = [...list];
    newList.splice(ind, 1);
    const newErrors = [...errors];
    newErrors.splice(ind, 1);
    ReactDOM.unstable_batchedUpdates(() => {
      setList(newList);
      setErrors(newErrors);
    });
  };

  const clearSuccessFromList = () => {
    const newList = [];
    const newErrors = [];
    errors.forEach((el, i) => {
      if (el) {
        newList.push(list[i]);
        newErrors.push(errors[i]);
      }
    });
    ReactDOM.unstable_batchedUpdates(() => {
      setList(newList);
      setErrors(newErrors);
    });
  };

  const isNew = errors.length === 0;
  const failCount = errors.filter((el) => el).length;
  const successCount = errors.length - failCount;

  const isClose = isNew || successCount === 0 || successCount === errors.length;

  return (
    <CustomModal
      TransitionComponent={SlideUp}
      fullScreen={fullScreen}
      maxWidth="lg"
      isOpen={openBulkQte}
      title="Bulk Key-in Time Entry"
      saveBtnTitle={
        isNew
          ? `Save All (${list.length})`
          : failCount === 0
          ? "Done"
          : `Retry Failures (${failCount})`
      }
      closeBtnTitle={isClose ? "Close" : `Clear Success (${successCount})`}
      handleClose={() => setOpenBulkQte(false)}
      handleCloseBtn={() =>
        isClose ? setOpenBulkQte(false) : clearSuccessFromList()
      }
      handleSave={handleSave}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div style={{ minHeight: "50vh" }}>
          {list &&
            list.length > 0 &&
            list.map((el, i) => (
              <QteFormLite
                key={i}
                data={el}
                ind={i}
                disableStaffSelection={isStf}
                removeFromList={removeFromList}
                handleChange={handleChange}
                handleChangeObj={handleChangeObj}
                error={errors[i]}
              />
            ))}

          <Button
            size="small"
            color="primary"
            startIcon={<AddCircleRounded />}
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              marginTop: "16px",
            }}
            disabled={!isNew}
            onClick={() =>
              setList(
                list.length > 0
                  ? [
                      ...list,
                      {
                        ...list[list.length - 1],
                        hours: 0,
                        minutes: 0,
                        billedHours: 0,
                        billedMinutes: 0,
                        desc: "",
                        reason: "",
                      },
                    ]
                  : [getDefTimeObj(stf)]
              )
            }
          >
            Add
          </Button>
          <Button
            size="small"
            color="secondary"
            startIcon={<CloseRounded />}
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              marginTop: "16px",
              marginLeft: "8px",
            }}
            disabled={list.length === 0}
            onClick={() => {
              setList([]);
              setErrors([]);
            }}
          >
            Clear All
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    </CustomModal>
  );
};

export default BulkQteModal;
