import React, { useState } from "react";
import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { PieChart, Pie } from "recharts";

import staffPieUtils from "../../../utils/staffPieUtils";
import CommonSelect from "../../../components/CommonSelect";

const useStyles = makeStyles((theme) => ({
  perfCard: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
    margin: `4px ${theme.spacing(2)}px`,
    textAlign: "center",
  },
}));

export const StaffPerformance = ({ staffPerformance, period, setPeriod }) => {
  const [activeIndex1, setActiveIndex1] = useState(0);
  const [activeIndex2, setActiveIndex2] = useState(0);
  const classes = useStyles();

  const amountPieData = [
    {
      name: "Remaining Total",
      value: parseFloat(
        staffPerformance.totalBillableAmount -
          staffPerformance.staffBillableAmount
      ),
      type: "amount",
    },
    {
      name: "Staff",
      value: parseFloat(staffPerformance.staffBillableAmount),
      type: "amount",
    },
  ];

  const hoursPieData = [
    {
      name: "Remaining Total",
      value: parseFloat(
        staffPerformance.totalBillableHours -
          staffPerformance.staffBillableHours
      ),
      type: "hour",
    },
    {
      name: "Staff",
      value: parseFloat(staffPerformance.staffBillableHours),
      type: "hour",
    },
  ];

  const handleChange = (event) => {
    setPeriod(event.target.value);
  };

  const timeSpanOpt = [
    { id: "today", value: "Today" },
    { id: "this-week", value: "This Week" },
    { id: "this-month", value: "This Month" },
    { id: "this-year", value: "This Year" },
  ];

  return (
    <div className="StaffPerformance">
      <Typography variant="h6" color="primary" align="center">
        Performance
      </Typography>
      <div
        className="d-flex justify-content-end"
        style={{ padding: "8px 16px 24px 16px" }}
      >
        <CommonSelect
          options={timeSpanOpt}
          value={period}
          onChange={handleChange}
        />
      </div>
      <Grid container justifyContent="center">
        <Grid item xs={6} sm={5} md={4}>
          <Paper className={classes.perfCard} elevation={3}>
            <Typography variant="h5" color="primary">
              {staffPerformance.responsibleCases}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Cases
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Primary Responsible
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={5} md={4}>
          <Paper className={classes.perfCard} elevation={3}>
            <Typography variant="h5" color="primary">
              {staffPerformance.totalUnbilledHours}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Hours
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Unbilled
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container style={{ padding: "16px" }}>
        <Grid item xl={6} md={6} sm={12}>
          {parseFloat(staffPerformance.staffBillableHours) > 0 ? (
            <>
              <PieChart width={360} height={400}>
                <Pie
                  activeIndex={activeIndex1}
                  activeShape={staffPieUtils}
                  data={hoursPieData}
                  cx={200}
                  cy={200}
                  innerRadius={65}
                  outerRadius={85}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={(data, index) => setActiveIndex1(index)}
                />
              </PieChart>
              <Typography
                variant="body2"
                align="center"
                color="textSecondary"
                style={{ padding: 16 }}
              >
                Hours (Remaining Total/Staff)
              </Typography>
            </>
          ) : (
            <Paper style={{ padding: "180px 150px" }}>
              <Typography>No hour has been billed</Typography>
            </Paper>
          )}
        </Grid>
        <Grid item xl={6} md={6} sm={12}>
          {parseFloat(staffPerformance.staffBillableHours) > 0 ? (
            <>
              <PieChart width={360} height={400}>
                <Pie
                  activeIndex={activeIndex2}
                  activeShape={staffPieUtils}
                  data={amountPieData}
                  cx={200}
                  cy={200}
                  innerRadius={65}
                  outerRadius={85}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={(data, index) => setActiveIndex2(index)}
                />
              </PieChart>
              <Typography
                variant="body2"
                align="center"
                color="textSecondary"
                style={{ padding: 16 }}
              >
                Amount (Remaining Total/Staff)
              </Typography>
            </>
          ) : (
            <Paper style={{ padding: "180px 150px" }}>
              <Typography>No hour has been billed</Typography>
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
