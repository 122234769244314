import moment from "moment";
import { DateFormats } from "./constants";

export const formatDate = (date, format = DateFormats.SLASH) =>
  date ? moment(date).format(format) : "";

export const formatDateDash = (date) =>
  date ? moment(date).format(DateFormats.DASH) : "";

export const parseDate = (str, format = DateFormats.SLASH) =>
  moment(str, format).toDate();

export const parseDateDash = (str) => moment(str, DateFormats.DASH).toDate();
