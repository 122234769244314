import React, { createRef, useEffect, useState, useContext } from "react";
import { Typography, makeStyles, Button, Grid } from "@material-ui/core";
import Axios from "axios";

import TabPanel from "../../../components/TabPanel";
import CONSTANTS from "../../../utils/constants";
import { getDefaultCaseObj } from "../../../model";

import { DataContext } from "../../../DataContext";

const useStyles = makeStyles((theme) => ({
  iframeContainer: {
    height: "calc(100vh - 250px)",
    position: "relative",
  },
  h100: { height: "100%" },
}));

const ChatPanel = ({ data = getDefaultCaseObj(), clientInfo, ...others }) => {
  const classes = useStyles();
  let iframeRef = createRef();

  const { currentUser } = useContext(DataContext);

  let [channelInfo, setChannelInfo] = useState({
    channelExisted: false,
    hasPermissionToEnter: false,
    hasError: true,
  });
  let [creatingChannel, setCreatingChannel] = useState(false);

  const createChannel = () => {
    setCreatingChannel(true);
    const channelName = `caseRef-${data.caseRef}-caseSid-${data.caseSid}`;

    const respAttPersonSid = data.respAtt.id;
    const origAttPersonSid = data.origAtt.id;
    const clientInfoSid = data.client.id;

    let secretaryPersonSids = [];
    data.secretariesInCharge.forEach((i) =>
      secretaryPersonSids.push(i.personSid)
    );

    let additionalLawyerPersonSids = [];
    data.additionalLawyers.forEach((i) =>
      additionalLawyerPersonSids.push(i.personSid)
    );

    const headers = {
      "X-Auth-Token": currentUser.rcAuthToken,
      "X-User-Id": currentUser.rcUserId,
    };

    const body = {
      channelName,
      respAttPersonSid,
      origAttPersonSid,
      clientInfoSid,
      secretaryPersonSids,
      additionalLawyerPersonSids,
    };

    Axios.post("/communications/channels.create", body, { headers }).then(
      (res) => {
        if (res.data) {
          setCreatingChannel(false);
          const isCurrentUserRespAtt =
            data?.respAtt?.id === currentUser.personSid;
          setChannelInfo({
            ...channelInfo,
            channelExisted: true,
            hasPermissionToEnter: isCurrentUserRespAtt
              ? true
              : channelInfo.hasPermissionToEnter,
          });
        }
      }
    );
  };

  useEffect(() => {
    if (others.tabIndex === 5) {
      const channelName = `caseRef-${data.caseRef}-caseSid-${data.caseSid}`;
      const headers = {
        "X-Auth-Token": currentUser.rcAuthToken,
        "X-User-Id": currentUser.rcUserId,
      };
      Axios.get("/communications/channels.exist?channelName=" + channelName, {
        headers,
      }).then((res) => setChannelInfo(res.data));
    }
  }, [
    data.caseRef,
    data.caseSid,
    others.tabIndex,
    currentUser.rcAuthToken,
    currentUser.rcUserId,
  ]);

  useEffect(() => {
    if (others.tabIndex === 5 && channelInfo.hasPermissionToEnter) {
      iframeRef.current.contentWindow.postMessage(
        {
          event: "login-with-token",
          loginToken: currentUser.rcAuthToken,
        },
        "*"
      );
    }
  }, [
    iframeRef,
    others.tabIndex,
    channelInfo.hasPermissionToEnter,
    currentUser.rcAuthToken,
  ]);

  const channelName = `caseRef-${data.caseRef}-caseSid-${data.caseSid}`;
  const canCreateChannel = data?.respAtt?.id === currentUser.personSid;

  return (
    <TabPanel className="ChatPanel">
      {others.tabIndex === 5 ? (
        <div className={classes.iframeContainer}>
          {channelInfo.channelExisted ? (
            <>
              {channelInfo.hasPermissionToEnter ? (
                <iframe
                  ref={iframeRef}
                  title="RC IFrame"
                  width="100%"
                  height="100%"
                  src={`${CONSTANTS.ROCKET_CHAT_ENDPOINT}/group/${channelName}?layout=embedded`}
                  frameBorder="0"
                  target="_parent"
                />
              ) : (
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.h100}
                >
                  <Typography variant="body1" color="textSecondary">
                    You don't have permission to join this channel.
                  </Typography>
                </Grid>
              )}
            </>
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.h100}
            >
              <Typography variant="body1" color="textSecondary">
                There is no chat room in this case.
              </Typography>
              {canCreateChannel ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={createChannel}
                >
                  {creatingChannel ? "creating ...." : "create a new channel"}
                </Button>
              ) : null}
            </Grid>
          )}
        </div>
      ) : null}
    </TabPanel>
  );
};

export { ChatPanel };
