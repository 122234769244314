import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import {
  AssessmentRounded,
  DescriptionRounded,
  HomeRounded,
  LocationCityRounded,
  PersonRounded,
  VerticalSplitRounded,
} from "@material-ui/icons";
import clsx from "clsx";
import { formatDecimal } from "../../utils/functions";

const useStyles = makeStyles((theme) => ({
  p175: {
    padding: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.75),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
}));

const SubInvoiceSection = ({ className, subInvoice, mainInvAmt }) => {
  const classes = useStyles();
  return (
    <>
      {subInvoice && (
        <Grid container className={clsx("SubInvoiceSection", className)}>
          <Grid item xs={12} md={6} className={classes.p175}>
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap
              className="segLabel"
            >
              Invoice Split Party Info
            </Typography>
            <div className="d-flex align-items-center py-5">
              <LocationCityRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />

              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
                style={{ marginRight: "16px" }}
              >
                Company Name :
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {subInvoice.companyName}
              </Typography>
            </div>
            {subInvoice.attentionTo && (
              <div className="d-flex align-items-center py-5">
                <PersonRounded
                  fontSize="small"
                  className={classes.textSecondary}
                  style={{ marginRight: "10px" }}
                />

                <Typography
                  variant="body2"
                  color="textSecondary"
                  noWrap
                  style={{ marginRight: "16px" }}
                >
                  Attn To :
                </Typography>
                <Typography variant="body2" color="textSecondary" noWrap>
                  {subInvoice.attentionTo}
                </Typography>
              </div>
            )}
            <div className="d-flex py-5">
              <HomeRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />
              <div>
                <Typography variant="body2" color="textSecondary">
                  {subInvoice.address1}
                </Typography>
                {subInvoice.address2 && (
                  <Typography variant="body2" color="textSecondary">
                    {subInvoice.address2}
                  </Typography>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.p175}>
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap
              className="segLabel"
            >
              &nbsp;
            </Typography>
            <div className="d-flex align-items-center py-5">
              <AssessmentRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />

              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
                style={{ marginRight: "16px" }}
              >
                Percentage Share Type :
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
                style={{ fontWeight: 600, letterSpacing: "1px" }}
              >
                {subInvoice.percentageShare}
              </Typography>
            </div>
            <div className="d-flex align-items-center py-5">
              <VerticalSplitRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />

              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
                style={{ marginRight: "16px" }}
              >
                Share Percentage :
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                noWrap
                style={{ fontWeight: 600, letterSpacing: "1px" }}
              >
                {formatDecimal(subInvoice.percentage)}%
              </Typography>
            </div>
            {mainInvAmt && !isNaN(mainInvAmt) && (
              <div className="d-flex align-items-center py-5">
                <DescriptionRounded
                  fontSize="small"
                  className={classes.textSecondary}
                  style={{ marginRight: "10px" }}
                />

                <Typography
                  variant="body2"
                  color="textSecondary"
                  noWrap
                  style={{ marginRight: "16px" }}
                >
                  Split Amount :
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  noWrap
                  style={{ fontWeight: 600, letterSpacing: "1px" }}
                >
                  ${formatDecimal((+mainInvAmt * +subInvoice.percentage) / 100)}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SubInvoiceSection;
