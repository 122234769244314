import { formatDateDash } from "../utils/dateUtils";

export const getDefaultInvoiceObj = () => ({
  id: null,
  invoiceNum: "",
  invoiceType: { id: null, value: "" },
  caseDto: { id: null, value: "" },
  date: formatDateDash(new Date()),
  timeExpenseEntry: [],
  totalAmount: 0,
  amountBeforeTaxAndDiscount: 0.0,
  discountAmount: 0,
  discountPercentage: 0,
  taxAmount: 0,
  totalNetAmount: 0,
  taxPercentage: 0,
  active: true,
  isInDraft: true,
  isRefManual: true,
  discountMode: 0,
});
