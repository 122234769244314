import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DataContext, SettingContext } from "../DataContext";
import QuickEntrySummary from "./QuickEntrySummary";
import TimeEntryTable from "./TimeEntryTable";

const DisplayTimeEntryThisWeek = ({ all }) => {
  const { alertError } = useContext(DataContext);
  const { refetch } = useContext(SettingContext);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = (allEmp = false) => {
    setIsLoading(true);
    Axios.get(
      `/view-entries/time/${
        allEmp ? "all-employees" : "current-employee"
      }/this-week`
    )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setData([]);
        setIsLoading(false);
        alertError("Failed to load this-week time entries");
      });
  };

  useEffect(() => {
    fetchData(all);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all, refetch]);

  return (
    <>
      <TimeEntryTable isLoading={isLoading} data={data} hideStaffCol={!all} />
      <QuickEntrySummary isLoading={isLoading} data={data} type="time" />
    </>
  );
};

export default DisplayTimeEntryThisWeek;
