import React from "react";
import { Typography, Grid, Paper, makeStyles, useTheme} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    textAlign: "center",
    boxShadow: theme.shadows[3],
  },
}));

const OfficeAccountSummary = ({ transactions, creditNotes }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid className={classes.root} container spacing={3} justify="center">
    <Grid item xs={6} lg={4}>
      <Paper classes={classes}>
        <Typography
          variant="h5"
          color="primary"
        >
          $10500
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Balance
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={6} lg={4}>
      <Paper classes={classes}>
        <Typography variant="h5" style={{ color: theme.palette.success.main }}>
          $10500
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Total Received
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={6} lg={4}>
      <Paper classes={classes}>
        <Typography variant="h5" style={{ color: "red" }}>
          $3500
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Total Credit
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={6} lg={4}>
      <Paper classes={classes}>
        <Typography variant="h5" color="primary">
          {transactions.length}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Total Transactions
        </Typography>
      </Paper>
    </Grid>
    <Grid item xs={6} lg={4}>
      <Paper classes={classes}>
        <Typography variant="h5" color="primary">
        {creditNotes.length}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Total Credit Notes
        </Typography>
      </Paper>
    </Grid>
  </Grid>
  );
};

export default OfficeAccountSummary;
