import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
import { Grid, Typography, TextField, Button, Box } from "@material-ui/core";
import CONSTANTS, { RoleTypes } from "../utils/constants";
import { DataContext } from "../DataContext";
import Axios from "axios";

const LoginPage = () => {
  const { alertError, currentUser, setCurrentUser } = useContext(DataContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  if (localStorage.getItem(CONSTANTS.ACCESS_TOKEN) && currentUser.accessToken) {
    switch (currentUser.roleType.roleTypeName) {
      case RoleTypes.PORTAL_ADMIN:
        return <Redirect to="/manage-companies" />;
      case RoleTypes.COMPANY_ADMIN:
        return <Redirect to="/staff" />;
      case RoleTypes.MANAGER:
        // return <Redirect to="/dashboard" />;
        return <Redirect to="/cases" />;
      default:
        return <Redirect to="/cases" />;
    }
  }

  const goLogin = (e) => {
    e.preventDefault();
    setUsernameError(false);
    setPasswordError(false);
    if (username && username.trim() && password) {
      setIsProcessing(true);
      Axios.defaults.headers["Authorization"] = undefined;
      Axios.post(
        "/auth/login",
        { email: username.trim(), password },
        { withCredentials: true }
      )
        .then((res) => {
          localStorage.setItem(CONSTANTS.ACCESS_TOKEN, res.data.accessToken);
          localStorage.setItem(CONSTANTS.REFRESH_TOKEN, res.data.refreshToken);
          localStorage.setItem(
            CONSTANTS.CURRENT_USER,
            JSON.stringify(res.data)
          );
          Axios.defaults.headers[
            "Authorization"
          ] = `Bearer ${res.data.accessToken}`;
          setIsProcessing(false);
          setCurrentUser(res.data);
        })
        .catch((err) => {
          setIsProcessing(false);
          alertError("Login Failed!");
        });
    } else {
      if (!(username && username.trim())) setUsernameError(true);
      if (!password) setPasswordError(true);
    }
  };

  return (
    <Grid
      container
      className="LoginPage"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={10} md={8} lg={4}>
        <Box p={6} mx={3} className="form-box">
          <form
            className="d-flex flex-col align-items-center"
            onSubmit={goLogin}
          >
            <Typography variant="h5" color="primary">
              E360
            </Typography>
            <TextField
              label="Email"
              name="username"
              type="email"
              value={username}
              error={usernameError}
              onChange={(e) => {
                setUsernameError(false);
                setUsername(e.target.value);
              }}
              fullWidth
              required
              style={{ margin: "16px 0" }}
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              value={password}
              error={passwordError}
              onChange={(e) => {
                setPasswordError(false);
                setPassword(e.target.value);
              }}
              fullWidth
              required
              style={{ margin: "8px 0" }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              disabled={isProcessing}
              style={{ marginTop: "32px", marginBottom: "4px" }}
            >
              {isProcessing ? "Processing..." : "Login"}
            </Button>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
