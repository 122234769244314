import Axios from "axios";
import CONSTANTS, {
  REFRESH_REQUIRED_MSG,
  LOGIN_REQUIRED_MSG,
} from "./constants";

export default function handleRefreshToken(history, alertError) {
  let isAlreadyRefreshing = false;
  // This is the list of waiting requests that will retry after the JWT refresh complete
  let subscribers = [];

  Axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const statusCode = error?.response?.status;
      const msg = error?.response?.data?.message;

      if (statusCode === 401 && msg === REFRESH_REQUIRED_MSG) {
        isAlreadyRefreshing = false;
        return resetTokenAndReattemptRequest(error);
      }

      if (statusCode === 401 && msg === LOGIN_REQUIRED_MSG) {
        const pathName = history?.location?.pathname;
        isAlreadyRefreshing = false;

        if (pathName !== "/") {
          alertError("Session Time Out.");
          history.push("/logout");
        }
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );

  const resetTokenAndReattemptRequest = async (error) => {
    try {
      const refreshToken = localStorage.getItem(CONSTANTS.REFRESH_TOKEN);
      if (!refreshToken) {
        // We can't refresh, throw the error anyway
        return Promise.reject(error);
      }

      let config = error?.response?.config;

      const retryOriginalRequest = new Promise((resolve, _) => {
        const cb = (access_token) => {
          config.headers.Authorization = "Bearer " + access_token;
          Axios.defaults.headers.Authorization = "Bearer " + access_token;
          resolve(Axios(config));
        };
        addSubscriber(cb);
      });

      if (!isAlreadyRefreshing) {
        isAlreadyRefreshing = true;
        const resp = await Axios.post("/auth/refresh-token", {
          refreshToken,
        });

        if (!resp.data) {
          return Promise.reject(error);
        }

        const newAccessToken = resp.data;
        localStorage.setItem(CONSTANTS.ACCESS_TOKEN, newAccessToken);
        isAlreadyRefreshing = false;
        onAccessTokenFetched(newAccessToken);
      }

      return retryOriginalRequest;
    } catch (err) {
      isAlreadyRefreshing = false;
      return Promise.reject(err);
    }
  };

  const onAccessTokenFetched = (access_token) => {
    subscribers.forEach((cb) => cb(access_token));
    subscribers = [];
  };

  const addSubscriber = (cb) => {
    subscribers.push(cb);
  };
}
