import React, { useState } from "react";
import clsx from "clsx";
import { Tabs, Tab } from "@material-ui/core";

const TabPane = ({
  tabTitles = [],
  defaultOpened = 0,
  children,
  className,
  tabsClassName,
  tabClassName,
  onChange = () => {},
}) => {
  const [value, setValue] = useState(defaultOpened);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };
  return (
    tabTitles &&
    tabTitles.length > 0 && (
      <div className={clsx("TabPane", className)}>
        <Tabs
          className={clsx("Tabs", tabsClassName)}
          style={{ marginTop: "8px" }}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="tabs"
        >
          {tabTitles.map((tab, index) => (
            <Tab key={index} {...tab} className={clsx("Tab", tabClassName)} />
          ))}
        </Tabs>
        <div
          style={{
            maxHeight: "calc(100vh - 224px)",
            overflowY: "auto",
          }}
        >
          {React.Children.map(children, (child, index) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { value, index });
            }
            return child;
          })}
        </div>
      </div>
    )
  );
};

export default TabPane;
