import React, { useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  ArrowDropDown,
  DeleteRounded,
  MoreVertRounded,
} from "@material-ui/icons";
import clsx from "clsx";

const PopupMenuButton = ({
  isIconButton,
  buttonIcon = <MoreVertRounded />,
  className,
  menuItems = [],
  children,
  variant = "outlined",
  color = "primary",
  endIcon = <ArrowDropDown />,
  ...others
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const menuItemClicked = (handler) => {
    handleClose();
    handler && handler();
  };
  return (
    <>
      {isIconButton ? (
        <IconButton
          className={clsx("PopupMenuButton", className)}
          color={color}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpen}
          {...others}
        >
          {buttonIcon}
        </IconButton>
      ) : (
        <Button
          className={clsx("PopupMenuButton", className)}
          variant={variant}
          endIcon={endIcon}
          color={color}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleOpen}
          {...others}
        >
          {children || "More Actions"}
        </Button>
      )}

      {menuItems && menuItems.length > 0 && (
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {menuItems
            .filter((x) => x)
            .map((item, i) => (
              <Tooltip
                key={i}
                title={item.tooltip || ""}
                arrow
                placement={item.tooltipPlacement || "left"}
              >
                <span key={i}>
                  <MenuItem
                    key={i}
                    onClick={() => menuItemClicked(item.handler)}
                    disabled={item.disabled}
                  >
                    {item.icon && (
                      <ListItemIcon
                        style={{ marginRight: "12px", minWidth: "initial" }}
                      >
                        {item.text &&
                        typeof item.text == "string" &&
                        item.text.toUpperCase() === "DELETE" ? (
                          <DeleteRounded fontSize="small" color="secondary" />
                        ) : (
                          item.icon
                        )}
                      </ListItemIcon>
                    )}
                    <Typography
                      variant="body2"
                      noWrap
                      color={
                        item.text &&
                        typeof item.text == "string" &&
                        item.text.toUpperCase() === "DELETE"
                          ? "secondary"
                          : "initial"
                      }
                    >
                      {item.text}
                    </Typography>
                    {item.extra}
                  </MenuItem>
                </span>
              </Tooltip>
            ))}
        </Menu>
      )}
    </>
  );
};

export default PopupMenuButton;
