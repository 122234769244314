import { Card, CardContent, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import React from "react";

export const StatCardSkeleton = ({ className }) => {
  return (
    <Paper
      className={clsx("StatCardSkeleton", className)}
      style={{ padding: "16px", margin: "16px" }}
    >
      <Card style={{ boxShadow: "none" }}>
        <Skeleton
          animation="wave"
          height={20}
          style={{ marginBottom: "32px" }}
          width="80%"
        />
        <CardContent>
          <Skeleton
            animation="wave"
            height={12}
            style={{ marginBottom: "16px" }}
            width="60%"
          />
          <Skeleton animation="wave" height={12} />
        </CardContent>
      </Card>
    </Paper>
  );
};
