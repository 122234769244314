import React, { useState, useContext } from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  makeStyles,
  Badge,
  withStyles,
  Avatar,
  MenuItem,
  Menu,
  Divider,
  ListItemIcon,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import {
  MenuRounded,
  AccountCircleRounded,
  Brightness4Rounded,
  ExitToAppRounded,
  AlarmAddRounded,
  VpnKeyRounded,
  LibraryAddRounded,
  PostAddRounded,
} from "@material-ui/icons";
import { drawerWidth } from "../config/appConfig";
import ModeSwitch from "./ModeSwitch";
import { PopupContext, DataContext } from "../DataContext";
import { withRouter, useHistory } from "react-router-dom";
import TimerButton from "./TimerButton";
import { RoleTypes } from "../utils/constants";
import { getDefaultCaseObj, getDefaultTimeExpObj } from "../model";

// #319795
const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.common.white}`,
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 12, // keep right padding when drawer closed
  },
  appBar: {
    position: "fixed",
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 8,
  },
  menuButtonHidden: {
    display: "none",
  },
  menuItemIcon: {
    marginRight: "12px",
    minWidth: "initial",
  },
  title: {
    flexGrow: 1,
  },
  iconBtn: {
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.9,
    },
  },
}));

const AppHeader = ({ open, handleDrawerOpen, setMode, location }) => {
  const {
    setOpenQte,
    setOpenBulkQte,
    setOpenCase,
    setChangePasswordOptions,
    setSelectedTimeExp,
    openDialog,
  } = useContext(PopupContext);
  const { currentUser, staffList, setCaseTemp } = useContext(DataContext);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (location.pathname === "/" || location.pathname === "/login") return null;

  const openChangePasswordModal = () => {
    handleClose();
    setChangePasswordOptions({
      open: true,
      type: "change",
    });
  };

  const confirmLogout = () =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Logout",
      okBtnColor: "secondary",
      onOkClicked: () => history.push("/logout"),
      title: "Logout",
      message: "Are you sure you want to logout?",
    }));

  return (
    <AppBar
      position="absolute"
      className={clsx("AppHeader", classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuRounded />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          E360
        </Typography>
        {[RoleTypes.MANAGER, RoleTypes.STAFF, RoleTypes.LAWYER].includes(
          currentUser.roleType.roleTypeName
        ) && (
          <>
            <TimerButton className={classes.iconBtn} />
            <Tooltip arrow title="Create New Case">
              <IconButton
                size="small"
                className={classes.iconBtn}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={() => {
                  setCaseTemp(getDefaultCaseObj());
                  setOpenCase(true);
                }}
              >
                <PostAddRounded />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Quick Time Entry">
              <IconButton
                size="small"
                className={classes.iconBtn}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={() => {
                  let rate = currentUser.rate;
                  if (!rate) {
                    const stf = staffList.find(
                      (el) => el.id === currentUser.personSid
                    );
                    if (stf) {
                      rate = stf.rate ? stf.rate : 0;
                    }
                  }
                  const qteObj = {
                    ...getDefaultTimeExpObj(),
                    staff: {
                      id: currentUser.personSid,
                      value: `${currentUser.firstName} ${currentUser.lastName}`,
                      active: true,
                      rate,
                    },
                    rate,
                  };
                  setSelectedTimeExp(qteObj);
                  setOpenQte(true);
                }}
              >
                <AlarmAddRounded />
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Bulk key-in time entries">
              <IconButton
                size="small"
                className={classes.iconBtn}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={() => setOpenBulkQte(true)}
              >
                <LibraryAddRounded />
              </IconButton>
            </Tooltip>
          </>
        )}

        <Tooltip arrow title="Profile and Settings">
          <IconButton
            className={classes.iconBtn}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            style={{ marginLeft: "8px" }}
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                style={{
                  backgroundColor: theme.palette.grey[300],
                  color: theme.palette.success.main,
                  textTransform: "uppercase",
                }}
              >
                {currentUser.firstName[0] + currentUser.lastName[0]}
              </Avatar>
            </StyledBadge>
          </IconButton>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ marginTop: "40px" }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon className={classes.menuItemIcon}>
              <AccountCircleRounded />
            </ListItemIcon>
            <div>
              <Typography variant="caption" color="textSecondary" noWrap>
                Signed in as :
              </Typography>
              <Typography variant="body2" color="primary" noWrap>
                {currentUser.email}
              </Typography>
            </div>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon className={classes.menuItemIcon}>
              <Brightness4Rounded fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2" noWrap style={{ marginRight: "16px" }}>
              Dark Mode
            </Typography>
            <ModeSwitch setMode={setMode} />
          </MenuItem>
          <Divider />
          <MenuItem onClick={openChangePasswordModal}>
            <ListItemIcon className={classes.menuItemIcon}>
              <VpnKeyRounded fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2" noWrap>
              Change Password
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleClose();
              confirmLogout();
            }}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <ExitToAppRounded fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2" noWrap>
              Logout
            </Typography>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(AppHeader);
