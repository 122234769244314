import React from "react";
import { Typography, Divider, List, ListItem } from "@material-ui/core";
const CompanyDetail = ({ company }) => {
  return (
    <List className="fullWidth" style={{ padding: "16px" }}>
      <ListItem>
        <div className="d-flex justify-content-between align-items-center fullWidth">
          <Typography variant="body1" color="textSecondary">
            Company Name
          </Typography>
          <Typography variant="body1" color="primary">
            {company.companyName}
          </Typography>
        </div>
      </ListItem>
      <Divider />
      <ListItem>
        <div className="d-flex justify-content-between align-items-center fullWidth">
          <Typography variant="body1" color="textSecondary">
            Business Registration Number
          </Typography>
          <Typography variant="body1" color="primary">
            {company.businessRegistrationNumber}
          </Typography>
        </div>
      </ListItem>
      <Divider />
      <ListItem>
        <div className="d-flex justify-content-between align-items-center fullWidth">
          <Typography variant="body1" color="textSecondary">
            Tax
          </Typography>
          <Typography variant="body1" color="primary">
            {company.taxRegistered}
          </Typography>
        </div>
      </ListItem>
      <Divider />
    </List>
  );
};

export default CompanyDetail;
