import React, { useContext, useState } from "react";
import { Grid, List, useMediaQuery, useTheme } from "@material-ui/core";
import PopupMenuButton from "../../components/PopupMenuButton";
import CustomModal from "../../components/CustomModal";
import SlideUp from "../../components/SlideUp";
import {
  AddRounded,
  CloudUploadRounded,
  LaunchRounded,
} from "@material-ui/icons";
import Axios from "axios";
import FD from "js-file-download";
import { getDefaultTimeExpObj } from "../../model";
import { DataContext, PopupContext } from "../../DataContext";
import SimplePage from "../SimplePage";
import { getError } from "../../utils/functions";
import CommonAccordion from "../../components/CommonAccordion";
import DisplayExpenseEntry from "../../components/DisplayExpenseEntry";
import DisplayExpenseEntryThisWeek from "../../components/DisplayExpenseEntryThisWeek";

const ExpenseEntryPage = ({ location }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { currentUser, alertError, alertSuccess } = useContext(DataContext);
  const { setOpenQee, setSelectedTimeExp } = useContext(PopupContext);
  const [errMsg, setErrMsg] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [toggleValue, setToggleValue] = useState(false);

  const all = location.pathname === "/all-quick-expense-entries";

  const uploadQeeCsvFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", file);

      Axios.post("/quick-entries/upload_qe_csv?type=exp", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          alertSuccess("Successfully uploaded csv file.");
          setToggleValue(!toggleValue);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 400) {
              let msg = "";
              for (const [key, value] of Object.entries(err.response.data)) {
                msg =
                  msg +
                  `row ${key} error stacks: \n \t ${value.map((v, index) =>
                    index > 0 ? `\t ${v} \n` : `${v} \n`
                  )} \n`;
              }
              setIsOpen(true);
              setErrMsg(msg);
            } else {
              alertError(
                err.response.data.message ||
                  err.response.data.error ||
                  "Error! Failed to upload csv file."
              );
            }
          } else {
            alertError(err.message);
          }
        });
    }
  };

  const exportQeeCsvFile = () => {
    Axios.get("/quick-entries/export_qe_csv?type=exp", {
      responseType: "arraybuffer",
    })
      .then((res) => {
        FD(res.data, "weekly_qee_summation.csv", "text/csv");
      })
      .catch((err) => alertError(getError(err)));
  };

  const goOpenQeeModal = () => {
    const qteObj = {
      ...getDefaultTimeExpObj("exp"),
      staff: {
        id: currentUser.personSid,
        value: `${currentUser.firstName} ${currentUser.lastName}`,
        active: true,
      },
    };
    setSelectedTimeExp(qteObj);
    setOpenQee(true);
  };

  const menuItems = [
    {
      text: "New Expense Entry",
      icon: <AddRounded fontSize="small" />,
      handler: goOpenQeeModal,
    },
    {
      text: (
        <>
          <input
            style={{ display: "none" }}
            type="file"
            id="csvFile"
            onChange={uploadQeeCsvFile}
            accept=".csv, text/csv"
          />
          <label htmlFor="csvFile">Import from CSV</label>
        </>
      ),
      icon: <CloudUploadRounded fontSize="small" />,
    },
    // {
    //   text: "Import from Excel",
    //   icon: <CloudUploadRounded fontSize="small" />,
    //   disabled: true,
    // },
    !all && {
      text: "Export This Week Data",
      icon: <LaunchRounded fontSize="small" />,
      handler: exportQeeCsvFile,
    },
    // {
    //   text: "Export As",
    //   icon: <LaunchRounded fontSize="small" />,
    //   disabled: true,
    // },
    // {
    //   text: "Archive",
    //   icon: <ArchiveRounded fontSize="small" />,
    //   disabled: true,
    //   // handler: confirmArchive,
    // },
    // {
    //   text: "Delete",
    //   icon: <DeleteRounded fontSize="small" />,
    //   disabled: true,
    //   // handler: confirmDelete,
    // },
  ];

  return (
    <>
      <SimplePage
        className="ExpenseEntryPage"
        pageTitle={"Quick Expense Entries" + (all ? " for All Lawyers" : "")}
        Buttons={
          <Grid item>
            <PopupMenuButton
              size="small"
              color="default"
              isIconButton
              menuItems={menuItems}
            />
          </Grid>
        }
      >
        <List key={toggleValue}>
          <CommonAccordion title="This Week">
            <DisplayExpenseEntryThisWeek all={all} />
          </CommonAccordion>
          <CommonAccordion title="All Time">
            <DisplayExpenseEntry
              selectedStaffSid={!all && currentUser.personSid}
              hideStaffCol={!all}
              hideStaffFilter={!all}
            />
          </CommonAccordion>
        </List>
      </SimplePage>
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="md"
        isOpen={isOpen}
        title="Errors"
        closeBtnTitle="OK"
        handleClose={() => setIsOpen(false)}
      >
        <pre style={{ fontSize: 15 }}>{errMsg}</pre>
      </CustomModal>
    </>
  );
};

export default ExpenseEntryPage;
