import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ArchiveRounded,
  BackspaceOutlined,
  CancelPresentationRounded,
  CreateRounded,
  DeleteRounded,
  DescriptionRounded,
  HistoryRounded,
  OfflinePinRounded,
  Receipt,
  VisibilityRounded,
} from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Axios from "axios";
import docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import JSzip from "jszip";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { format } from "date-fns";
import invTemplate from "../../../assets/templates/invoice_template.docx";
import newInvTemplate from "../../../assets/templates/invoice_template_with_professional_fee_description_groups.docx";
import subInvTemplate from "../../../assets/templates/sub_invoice_template.docx";
import CustomModal from "../../../components/CustomModal";
import InvoiceTable from "../../../components/InvoiceTable";
import SlideUp from "../../../components/SlideUp";
import TabPanel from "../../../components/TabPanel";
import {
  DataContext,
  PopupContext,
  SettingContext,
} from "../../../DataContext";
import { getDefaultInvoiceObj, getDefaultTransactionObj } from "../../../model";
import { DateFormats, Modules, RoleTypes } from "../../../utils/constants";
import { formatDate } from "../../../utils/dateUtils";
import {
  formatDecimal,
  getDefaultErrorObj,
  getError,
} from "../../../utils/functions";
import { InvoiceSummary } from "../../invoices/components/InvoiceSummary";
import InvoiceDetail from "../InvoiceDetail";
import InvoiceForm from "../InvoiceForm";

const InvoicesTabPanel = ({ selectedCase, client, ...others }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    caseList,
    invoiceList,
    invoiceTypes,
    setInvoiceList,
    setTimeExpList,
    alertSuccess,
    alertWarning,
    alertError,
    currentUser,
    transactionList,
    setTransactionList,
    entryListCache,
    setEntryListCache,
    setDebitCreditList,
  } = useContext(DataContext);

  const { openDialog, setAuditOptions, setOpenSplitInv, setInvToSplit } =
    useContext(PopupContext);
  const { setProcessing } = useContext(SettingContext);

  const [isLoading, setIsLoading] = useState(false);
  const [invType, setInvType] = useState("draft");
  const [invoiceTemp, setInvoiceTemp] = useState(getDefaultInvoiceObj());
  const [isInvoiceFormOpen, setIsInvoiceFormOpen] = useState(false);
  const [isInvoiceDetailOpen, setIsInvoiceDetailOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedSubInv, setSelectedSubInv] = useState(null);
  const [error, setError] = useState(
    getDefaultErrorObj(getDefaultInvoiceObj())
  );

  const [selectedInvoiceTemplate, setSelectedInvoiceTemplate] =
    useState(newInvTemplate);
  const [openChooseExportTemplate, setOpenChooseExportTemplate] =
    useState(false);
  const [templateRadioValue, setTemplateRadioValue] = useState("new");

  const groupByLawyers = (services) => {
    let timeList = services;
    let timeListByLawyer = [];
    timeList.forEach((t) => {
      const _hours = isNaN(t.hours) ? 0 : t.hours;
      if (timeListByLawyer[t.staffId]) {
        timeListByLawyer[t.staffId]["timeEntry"].push(t);
        timeListByLawyer[t.staffId]["subTotal"] += parseFloat(
          t.amount.replace(",", "")
        );
        timeListByLawyer[t.staffId]["totalHours"] += parseFloat(_hours);
      } else {
        timeListByLawyer[t.staffId] = {};
        timeListByLawyer[t.staffId]["timeEntry"] = [];
        timeListByLawyer[t.staffId]["timeEntry"].push(t);
        timeListByLawyer[t.staffId]["subTotal"] = parseFloat(
          t.amount.replace(",", "")
        );
        timeListByLawyer[t.staffId]["totalHours"] = parseFloat(_hours);
        timeListByLawyer[t.staffId]["name"] = t.staffName;
      }
    });
    Object.keys(timeListByLawyer).forEach((t) => {
      timeListByLawyer[t].timeEntry = timeListByLawyer[t].timeEntry.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
    });
    return timeListByLawyer;
  };

  const fetchInvoiceList = async (
    caseSid = selectedCase.caseSid,
    fmt = invType
  ) => {
    try {
      let link = `/view-invoices/all-time?page=1&size=1000&showArchivedData=true&caseSid=${caseSid}`;
      if (fmt === "draft") {
        link += `&isInDraft=true`;
      } else {
        link += `&invoiceTypeSid=${
          invoiceTypes.find((t) => t.secondaryValue === fmt).id
        }`;
      }
      setIsLoading(true);
      const res = await Axios.get(link);
      setInvoiceList(res.data.content);
      setIsLoading(false);
    } catch (err) {
      setInvoiceList([]);
      setIsLoading(false);
    }
  };

  const fetchTimeExpList = (caseSid) => {
    Axios.get("/quick-entries/" + caseSid + "?all=false")
      .then((res) => {
        setTimeExpList(res.data);
      })
      .catch(() => setTimeExpList([]));
  };

  const fetchQuickEntriesByInvoiceSid = async (inv) => {
    if (inv && inv.id) {
      if (entryListCache[inv.id] && entryListCache[inv.id].length > 0) {
        return entryListCache[inv.id];
      }
      try {
        setProcessing(true);
        const res = await Axios.get(
          "/view-entries/get-by-invoice-sid-from-ite/" + inv.id
        );
        setProcessing(false);
        setEntryListCache((prev) => ({ ...prev, [inv.id]: res.data }));
        return res.data;
      } catch (err) {
        setProcessing(false);
        alertError(
          getError(err, "Fail to load time/expense entries for this invoice")
        );
        return [];
      }
    } else return [];
  };

  useEffect(() => {
    if (isInvoiceFormOpen) {
      fetchTimeExpList(selectedCase.caseSid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvoiceFormOpen]);

  useEffect(() => {
    if (others.tabIndex === 3) {
      fetchInvoiceList(selectedCase.caseSid, invType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [others.tabIndex, selectedCase.caseSid, invType]);

  const openInvoiceDetailModal = async (invoice, subInv = null) => {
    const inv = { ...invoice };
    inv.timeExpenseEntry = await fetchQuickEntriesByInvoiceSid(invoice);
    ReactDOM.unstable_batchedUpdates(() => {
      setSelectedInvoice(inv);
      setSelectedSubInv(subInv);
      setIsInvoiceDetailOpen(true);
    });
  };

  const openCreateInvoiceModal = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setError(getDefaultErrorObj(getDefaultInvoiceObj()));
      setInvoiceTemp({
        ...getDefaultInvoiceObj(),
        caseDto: { id: selectedCase.caseSid },
      });
      setIsInvoiceFormOpen(true);
    });
  };

  const openEditModal = async (invoice) => {
    const inv = { ...invoice };
    inv.caseDto = { id: selectedCase.caseSid };
    inv.timeExpenseEntry = await fetchQuickEntriesByInvoiceSid(invoice);
    ReactDOM.unstable_batchedUpdates(() => {
      setError(getDefaultErrorObj(getDefaultInvoiceObj()));
      setInvoiceTemp(inv);
      setIsInvoiceFormOpen(true);
    });
  };

  const openAuditModal = (invoiceSid) =>
    setAuditOptions({
      open: true,
      moduleType: Modules.INVOICE,
      sid: invoiceSid,
    });

  const onBlur = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const openChooseExportTemplateModal = (invoice) => {
    setOpenChooseExportTemplate(true);
    setSelectedInvoice(invoice);
  };

  const handleChangeInvType = (e, v) => v && setInvType(v);

  const handleChangeRadio = (evt) => {
    setTemplateRadioValue(evt.target.value);
    evt.target.value === "old"
      ? setSelectedInvoiceTemplate(invTemplate)
      : setSelectedInvoiceTemplate(newInvTemplate);
  };

  const saveOrEditInvoice = (invTemp) => {
    try {
      if (invTemp.isRefManual && !invTemp.invoiceNum) {
        setError((prev) => ({ ...prev, invoiceNum: true }));
      }
      if (invTemp.timeExpenseEntry.length === 0) {
        alertWarning("Please select time/expense entry.");
      } else if (invTemp.isRefManual && !invTemp.invoiceNum) {
        alertWarning("Please enter manual invoice number.");
      } else if (isNaN(invTemp.totalAmount)) {
        alertWarning("Invalid numbers!");
      } else if (
        invTemp.amountBeforeTaxAndDiscount === null ||
        invTemp.amountBeforeTaxAndDiscount === 0
      ) {
        alertWarning("Subtotal cannot be $0");
      } else {
        const invTempToSave = {
          ...invTemp,
          discountPercentage: Number(invTemp.discountPercentage),
          discountAmount: Number(invTemp.discountAmount),
          taxPercentage: Number(invTemp.taxPercentage),
          taxAmount: Number(invTemp.taxAmount),
          totalAmount: Number(invTemp.totalAmount),
          totalNetAmount: Number(invTemp.totalNetAmount),
        };
        if (invTempToSave.id) {
          const inv = {
            ...invTempToSave,
            invoiceType: invoiceTypes.find((e) => e.id === invTemp.invoiceType),
            caseDto: caseList.find((e) => e.id === invTemp.caseSid),
          };
          // UPDATE
          setProcessing(true);
          Axios.put("/invoices", inv)
            .then((res) => {
              fetchInvoiceList(selectedCase.caseSid, invType);
              setIsInvoiceFormOpen(false);
              alertSuccess("Successfully updated!");
              setSelectedInvoice(res.data);
              setProcessing(false);
              setEntryListCache((prev) => ({
                ...prev,
                [res.data.id]: undefined,
              }));
            })
            .catch((err) => {
              alertError(getError(err, "Error! Failed to update"));
              setProcessing(false);
            });
        } else {
          // SAVE
          setProcessing(true);
          Axios.post("/invoices", invTempToSave)
            .then((res) => {
              fetchInvoiceList(selectedCase.caseSid, invType);
              setIsInvoiceFormOpen(false);
              alertSuccess("Successfully saved!");
              setSelectedInvoice(res.data);
              setProcessing(false);
            })
            .catch((err) => {
              alertError(getError(err, "Error! Failed to save"));
              setProcessing(false);
            });
        }
      }
    } catch (error) {
      alertError(
        `Failed to ${
          invTemp.id ? "update" : "save"
        }. Check console log for detail.`
      );
    }
  };

  const clearReferenceNo = () => {
    setInvoiceTemp((prev) => ({
      ...prev,
      invoiceNum: "",
    }));
    setError((prev) => ({ ...prev, invoiceNum: false }));
  };

  const goDelete = (id) => {
    setProcessing(true);
    Axios.delete("/invoices/" + id)
      .then(() => {
        fetchInvoiceList(selectedCase.caseSid, invType);
        setSelectedInvoice(null);
        setProcessing(false);
        alertSuccess("Successfully deleted!");
        setEntryListCache((prev) => ({ ...prev, [id]: undefined }));
      })
      .catch((err) => {
        alertError(getError(err, "Error! Unable to delete"));
        setProcessing(false);
      });
  };

  const goArchive = (inv) => {
    setProcessing(true);
    Axios.patch(
      "/invoices/" + (inv.active ? "archive/" : "unarchive/") + inv?.id
    )
      .then((res) => {
        fetchInvoiceList(selectedCase.caseSid, invType);
        setSelectedInvoice(null);
        setProcessing(false);
        alertSuccess(
          "Successfully " + (inv.active ? "archived!" : "unarchived!")
        );
      })
      .catch((err) => {
        setProcessing(false);
        alertError(
          getError(
            err,
            "Error! Unable to " + (inv.active ? "archive." : "unarchive.")
          )
        );
      });
  };

  const confirmDelete = (id) =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Delete",
      okBtnColor: "secondary",
      onOkClicked: () => goDelete(id),
      title: "Delete",
      message: `Are you sure you want to delete this invoice?`,
    }));

  const confirmArchive = (inv) =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: inv.active ? "Archive" : "Unarchive",
      okBtnColor: "secondary",
      onOkClicked: () => goArchive(inv),
      title: inv.active ? "Archive" : "Unarchive",
      message: `Are you sure you want to ${
        inv.active ? "archive" : "unarchive"
      } this invoice?`,
    }));

  const confirmFinalization = (id) =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Finalize",
      okBtnColor: "primary",
      onOkClicked: () => finalizeInvoice(id),
      title: "Finalization",
      message: `The invoice will not be editable once marked as finalized and will be moved to UNPAID category. Are you sure you want to finalize this invoice?`,
    }));

  const showPartiesToSplit = (inv) => {
    setInvToSplit(inv);
    setOpenSplitInv(true);
  };

  const calculateTaxableSubtotal = (quickEntries, isTaxable) => {
    let subTotal = 0;
    if (quickEntries && quickEntries.length > 0) {
      quickEntries.forEach((qe) => {
        if (qe.isTaxable === isTaxable) subTotal += qe.netAmount;
      });
    }
    return subTotal;
  };

  const getTaxableExpenses = (quickEntries, isTaxable) => {
    let expenses = [];
    if (quickEntries && quickEntries.length > 0) {
      quickEntries.forEach((qe) => {
        if (qe.type === "exp" && qe.isTaxable === isTaxable) expenses.push(qe);
      });
    }
    return expenses;
  };

  const generateInvoice = (invoice, subInv = null) => {
    setProcessing(true);
    Axios.get(
      "/invoices/" +
        invoice.id +
        "/export" +
        (subInv ? `?subInvoiceSid=${subInv.subInvoiceSid}` : "")
    )
      .then((res) => {
        const docData = { ...res.data };
        const sortedServices = docData.services.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

        const mappedServices = sortedServices?.map((s) => ({
          ...s,
          date: format(new Date(s.date), "dd/MM/y"),
        }));

        docData.services = mappedServices;
        let timeEntryGroups = groupByLawyers(docData.services);

        fetch(subInv ? subInvTemplate : selectedInvoiceTemplate)
          .then((d) => d.arrayBuffer())
          .then((ab) => {
            let zip = new JSzip(ab);
            let doc = new docxtemplater(zip);

            docData.taxableSubtotal = calculateTaxableSubtotal(
              invoice.timeExpenseEntry,
              true
            );
            docData.notTaxableSubtotal = calculateTaxableSubtotal(
              invoice.timeExpenseEntry,
              false
            );
            docData.taxableExpenses = getTaxableExpenses(
              invoice.timeExpenseEntry,
              true
            );
            docData.notTaxableExpenses = getTaxableExpenses(
              invoice.timeExpenseEntry,
              false
            );

            docData.timeEntryGroups = [];
            docData.grandTotalHours = 0;
            Object.keys(timeEntryGroups).forEach((teg) => {
              docData.timeEntryGroups.push(timeEntryGroups[teg]);
              if (!isNaN(timeEntryGroups[teg].totalHours)) {
                docData.grandTotalHours += parseFloat(
                  timeEntryGroups[teg].totalHours
                );
              }
            });

            docData.grandTotalHours = formatDecimal(docData.grandTotalHours);
            docData.timeEntryGroups.forEach((teg) => {
              teg.subTotal = formatDecimal(teg.subTotal);
              teg.totalHours = formatDecimal(teg.totalHours);
            });

            doc.setData(docData);
            doc.render();

            let out = doc.getZip().generate({
              type: "blob",
              mimeType:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });

            const fileName = `${
              subInv
                ? subInv.subInvoiceNumber
                : invoice.invoiceNum === "-"
                ? "INV-" + invoice.id
                : invoice.invoiceNum
            }.docx`;

            saveAs(out, fileName);
          });
        setProcessing(false);
        setOpenChooseExportTemplate(false);
        alertSuccess(`Sucessfully exported invoice no. ${invoice.invoiceNum}!`);
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, "Error! Failed to download"));
      });
  };

  const payWithDeposit = (invoice) => {
    let inv = invoiceList.find((i) => i.id === invoice.id);
    let typeId = invoiceTypes.find((t) => t.value === "Paid")
      ? invoiceTypes.find((t) => t.value === "Paid").id
      : "";
    const type = {
      id: typeId,
      value: "paid",
      active: true,
    };
    inv.invoiceType = type;
    setInvoiceList(invoiceList.filter((i) => i.id !== invoice.id).concat(inv));
    let transaction = getDefaultTransactionObj();
    let today = new Date();
    transaction = {
      ...transaction,
      amount:
        invoice.amountBeforeTaxAndDiscount -
        invoice.discountAmount +
        invoice.taxAmount,
      invoiceNumber: invoice.invoiceNum,
      caseInfo: selectedCase.name + " (Ref#" + selectedCase.caseRef + ")",
      createdBy:
        currentUser && currentUser.firstName + " " + currentUser.lastName,
      date: formatDate(today, DateFormats.MEDIUM),
      type: "Paid with Deposit",
    };
    setTransactionList(transactionList.concat(transaction));
  };

  const fetchDebitCreditList = () => {
    Axios.get("/debit-credit/all").then((res) => {
      setDebitCreditList(res.data);
    });
  };

  const togglePay = (invoice, payType) => {
    setProcessing(true);
    Axios.post("/invoices/" + invoice.id + "/toggle-pay", { payType })
      .then((res) => {
        fetchInvoiceList(selectedCase.caseSid, invType);
        fetchDebitCreditList();
        setSelectedInvoice(res.data);
        if (payType === "paid") {
          let transaction = getDefaultTransactionObj();
          let today = new Date();
          transaction = {
            ...transaction,
            amount:
              invoice.amountBeforeTaxAndDiscount -
              invoice.discountAmount +
              invoice.taxAmount,
            invoiceNumber: invoice.invoiceNum,
            caseInfo: selectedCase.name + " (Ref#" + selectedCase.caseRef + ")",
            createdBy:
              currentUser && currentUser.firstName + " " + currentUser.lastName,
            date: formatDate(today, DateFormats.MEDIUM),
            type: "Paid",
          };
          setTransactionList(transactionList.concat(transaction));
        } else {
          setTransactionList(
            transactionList.filter(
              (t) => t.invoiceNumber !== invoice.invoiceNum
            )
          );
        }
        setProcessing(false);
        alertSuccess(`Successfully marked as ${payType.replace("-", " ")}`);
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, "Error! Unable to update invoice pay type"));
      });
  };

  const togglePayForSubInv = (subInvoiceSid, payType) => {
    setProcessing(true);
    Axios.post("/split-invoices/" + subInvoiceSid + "/toggle-pay", { payType })
      .then((res) => {
        fetchInvoiceList(selectedCase.caseSid, invType);
        setProcessing(false);
        alertSuccess(`Successfully marked as ${payType.replace("-", " ")}`);
      })
      .catch((err) => {
        setProcessing(false);
        alertError(
          getError(err, "Error! Unable to update sub invoice pay type")
        );
      });
  };

  const finalizeInvoice = (invoiceSid) => {
    setProcessing(true);
    Axios.post("/invoices/" + invoiceSid + "/finalize-invoice")
      .then((res) => {
        fetchInvoiceList(selectedCase.caseSid, invType);
        setSelectedInvoice(res.data);
        setProcessing(false);
        alertSuccess("Successfully finalized!");
      })
      .catch((err) => {
        setProcessing(false);
        alertError(getError(err, "Error! Failed to finalize the invoice"));
      });
  };

  const getAllMenuItems = (invoice) => {
    let menuItems = [
      {
        text: "View Invoice",
        icon: <VisibilityRounded fontSize="small" />,
        handler: () => openInvoiceDetailModal(invoice),
      },
      currentUser.roleType.roleTypeName === RoleTypes.MANAGER && {
        text: "View Audit History",
        icon: <HistoryRounded fontSize="small" />,
        handler: () => invoice && openAuditModal(invoice.id),
      },
      invoice.active &&
        invType === "draft" && {
          text: "Edit",
          icon: <CreateRounded fontSize="small" />,
          handler: () => openEditModal(invoice),
        },
      invType !== "draft" && {
        text: invoice.active ? "Archive" : "Unarchive",
        icon: <ArchiveRounded fontSize="small" />,
        handler: () => confirmArchive(invoice),
      },
      {
        text: "Delete",
        icon: <DeleteRounded fontSize="small" />,
        handler: () => confirmDelete(invoice.id),
      },
      {
        text: "Export Invoice as Word",
        icon: <DescriptionRounded fontSize="small" />,
        handler: () => openChooseExportTemplateModal(invoice),
      },
      // {
      //   text: "Export Invoice as Pdf",
      //   icon: <DescriptionRounded fontSize="small" />,
      //   handler: () => generateInvoice(invoice, null, true),
      // },
    ];

    if (invType === "draft") {
      menuItems = [
        ...menuItems,
        ...[
          invoice.active && {
            text: "Finalize Invoice",
            icon: <ArchiveRounded fontSize="small" />,
            handler: () => confirmFinalization(invoice.id),
          },
          invoice.active && {
            text: "Split and Finalize",
            icon: <ArchiveRounded fontSize="small" />,
            handler: () => showPartiesToSplit(invoice),
          },
        ],
      ];
    } else if (invType === "unpaid") {
      menuItems = [
        ...menuItems,
        invoice.active && {
          text: "Mark as Paid",
          icon: <OfflinePinRounded fontSize="small" />,
          handler: () => togglePay(invoice, "paid"),
        },
        {
          text: "Pay with Deposit",
          icon: <OfflinePinRounded fontSize="small" />,
          handler: () => payWithDeposit(invoice),
        },
        invoice.active && {
          text: "Write Off",
          icon: <CancelPresentationRounded fontSize="small" />,
          handler: () => togglePay(invoice, "written-off"),
        },
      ];
    } else if (invType === "paid") {
      menuItems = [
        ...menuItems,
        invoice.active && {
          text: "Mark as Unpaid",
          icon: <BackspaceOutlined fontSize="small" />,
          handler: () => togglePay(invoice, "unpaid"),
        },
      ];
    } else {
      // for written off
      menuItems = [
        ...menuItems,
        invoice.active && {
          text: "Mark as Unpaid",
          icon: <BackspaceOutlined fontSize="small" />,
          handler: () => togglePay(invoice, "unpaid"),
        },
        invoice.active && {
          text: "Mark as Paid",
          icon: <OfflinePinRounded fontSize="small" />,
          handler: () => togglePay(invoice, "paid"),
        },
      ];
    }
    return menuItems;
  };

  const getMenuItemsForSubInvoice = (invoice, subInvIndx) => {
    const subInv = invoice.subInvoices[subInvIndx];
    let menuItems = [
      {
        text: "View Invoice",
        icon: <VisibilityRounded fontSize="small" />,
        handler: () => openInvoiceDetailModal(invoice, subInv),
      },
      // !invoice.isInDraft && {
      //   text: invoice.active ? "Archive" : "Unarchive",
      //   icon: <ArchiveRounded fontSize="small" />,
      //   handler: () => confirmArchive(invoice),
      // },
      {
        text: "Export Invoice as Word",
        icon: <DescriptionRounded fontSize="small" />,
        handler: () => generateInvoice(invoice, subInv),
      },
      // {
      //   text: "Export Invoice as Pdf",
      //   icon: <DescriptionRounded fontSize="small" />,
      //   handler: () => generateInvoice(invoice, subInv, true),
      // },
    ];

    let subInvoiceType = invoiceTypes.find(
      (el) => el.id === subInv.subInvoiceTypeSid
    )?.value;

    if (subInvoiceType === "Unpaid") {
      menuItems = [
        ...menuItems,
        subInv.active && {
          text: "Mark as Paid",
          icon: <OfflinePinRounded fontSize="small" />,
          handler: () => togglePayForSubInv(subInv.subInvoiceSid, "paid"),
        },
        subInv.active && {
          text: "Write Off",
          icon: <CancelPresentationRounded fontSize="small" />,
          handler: () =>
            togglePayForSubInv(subInv.subInvoiceSid, "written-off"),
        },
      ];
    } else if (subInvoiceType === "Paid") {
      menuItems = [
        ...menuItems,
        subInv.active && {
          text: "Mark as Unpaid",
          icon: <BackspaceOutlined fontSize="small" />,
          handler: () => togglePayForSubInv(subInv.subInvoiceSid, "unpaid"),
        },
      ];
    } else {
      menuItems = [
        ...menuItems,
        subInv.active && {
          text: "Mark as Unpaid",
          icon: <BackspaceOutlined fontSize="small" />,
          handler: () => togglePayForSubInv(subInv.subInvoiceSid, "unpaid"),
        },
        subInv.active && {
          text: "Mark as Paid",
          icon: <OfflinePinRounded fontSize="small" />,
          handler: () => togglePayForSubInv(subInv.subInvoiceSid, "paid"),
        },
      ];
    }
    return menuItems;
  };

  return (
    <TabPanel className="InvoicesTabPanel" {...others}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ flexWrap: "wrap-reverse", marginBottom: "8px" }}
      >
        <Grid item className="py-6">
          <ToggleButtonGroup
            value={invType}
            onChange={handleChangeInvType}
            exclusive
            size="small"
          >
            <ToggleButton value="draft">Draft</ToggleButton>
            <ToggleButton value="unpaid">Unpaid</ToggleButton>
            <ToggleButton value="paid">Fully Paid</ToggleButton>
            <ToggleButton value="written-off">Written Off</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item className="py-6" style={{ flexGrow: 1 }}>
          <Grid container direction="row" justifyContent="flex-end">
            <Button
              onClick={openCreateInvoiceModal}
              variant="contained"
              color="primary"
              startIcon={<Receipt />}
              disabled={!selectedCase.active}
            >
              Create Invoice
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <InvoiceTable
        isLoading={isLoading}
        data={invoiceList}
        getAllMenuItems={getAllMenuItems}
        getMenuItemsForSubInvoice={getMenuItemsForSubInvoice}
        selectedCase={selectedCase}
        client={client}
        hideCaseCol
        hidePaymentDueCol
      />
      <InvoiceSummary isLoading={isLoading} data={invoiceList} />
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="lg"
        isOpen={isInvoiceFormOpen}
        title={
          invoiceTemp && invoiceTemp.id ? "Edit Invoice" : "Create Invoice"
        }
        saveBtnTitle={invoiceTemp && invoiceTemp.id ? "Update" : "Save"}
        handleClose={() => setIsInvoiceFormOpen(false)}
        handleSave={() => saveOrEditInvoice(invoiceTemp)}
      >
        <InvoiceForm
          selectedCase={selectedCase}
          client={client}
          invoice={invoiceTemp}
          setInvoice={setInvoiceTemp}
          error={error}
          setError={setError}
          onBlur={onBlur}
          clearReferenceNo={clearReferenceNo}
          fetchInvoiceList={fetchInvoiceList}
        />
      </CustomModal>

      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="lg"
        isOpen={isInvoiceDetailOpen}
        title="Invoice Detail"
        handleClose={() => setIsInvoiceDetailOpen(false)}
        hideFooter
      >
        <InvoiceDetail
          invoice={selectedInvoice}
          selectedCase={selectedCase}
          client={client}
          selectedSubInv={selectedSubInv}
        />
      </CustomModal>

      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="sm"
        isOpen={openChooseExportTemplate}
        title="Choose Invoice Template"
        handleClose={() => setOpenChooseExportTemplate(false)}
        handleSave={() => generateInvoice(selectedInvoice)}
      >
        <FormControl>
          <RadioGroup
            aria-labelledby="choose-invoice-template"
            name="choose-invoice-template"
            value={templateRadioValue}
            onChange={handleChangeRadio}
          >
            <FormControlLabel
              value="new"
              control={<Radio />}
              label="Template with professional fee grouped by lawyers"
            />
            <FormControlLabel
              value="old"
              control={<Radio />}
              label="Template without grouping professional fees"
            />
          </RadioGroup>
        </FormControl>
      </CustomModal>
    </TabPanel>
  );
};

export { InvoicesTabPanel };
