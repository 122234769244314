import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import clsx from "clsx";
import { DataContext, SettingContext } from "../DataContext";
import QuickEntryFilter from "./QuickEntryFilter";
import { getError } from "../utils/functions";
import CustomTablePagination from "./CustomTablePagination";
import ExpenseEntryTable from "./ExpenseEntryTable";
import QuickEntrySummary from "./QuickEntrySummary";

const DisplayExpenseEntry = ({
  className,
  selectedCaseSid,
  selectedStaffSid,
  tabIndex,
  hideCaseCol,
  hideStaffCol,
  hideStaffFilter,
}) => {
  const allOpt = { id: "all", value: "All" };
  const { alertError } = useContext(DataContext);
  const { refetch } = useContext(SettingContext);

  const [loading, setLoading] = useState(false);
  const [expenseEntryData, setExpenseEntryData] = useState(null);

  const [staffFilter, setStaffFilter] = useState(allOpt);
  const [dateRange, setDateRange] = useState([null, null]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchQuickEntryList = (
    caseSid = selectedCaseSid,
    personSid = staffFilter?.id,
    fromDate = dateRange[0],
    toDate = dateRange[1],
    page = currentPage,
    size = pageSize
  ) => {
    let link = `/view-entries?type=exp&showArchivedData=true&caseSid=${
      caseSid || "all"
    }&personSid=${personSid || "all"}&page=${page || 1}&size=${size || 10}`;
    if (fromDate) {
      link += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      link += `&toDate=${toDate}`;
    }
    setLoading(true);
    Axios.get(link)
      .then((res) => {
        setExpenseEntryData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        alertError(getError(err));
        setExpenseEntryData(null);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!tabIndex || tabIndex === 2) {
      fetchQuickEntryList(
        selectedCaseSid,
        selectedStaffSid || staffFilter?.id,
        dateRange[0],
        dateRange[1],
        currentPage,
        pageSize
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tabIndex,
    selectedCaseSid,
    selectedStaffSid,
    staffFilter,
    dateRange,
    currentPage,
    pageSize,
    refetch,
  ]);

  const handleChangePage = (e, newPage) => setCurrentPage(newPage + 1);

  const handleChangeSize = (e) => setPageSize(parseInt(e.target.value));

  const isFiltering =
    dateRange[0] || dateRange[1] || (staffFilter && staffFilter.id !== "all");

  return (
    <div className={clsx("DisplayExpenseEntry", className)}>
      <QuickEntryFilter
        staffFilter={staffFilter}
        setStaffFilter={setStaffFilter}
        dateRange={dateRange}
        setDateRange={setDateRange}
        hideStaffFilter={hideStaffFilter}
        isFiltering={isFiltering}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        personSid={selectedStaffSid}
        caseSid={selectedCaseSid}
        type="exp"
        isLoading={loading}
      />
      {!isFiltering && (
        <CustomTablePagination
          loading={loading}
          totalElements={expenseEntryData?.totalElements}
          size={pageSize}
          page={currentPage}
          handleChangePage={handleChangePage}
          handleChangeSize={handleChangeSize}
        />
      )}
      <ExpenseEntryTable
        isLoading={loading}
        data={expenseEntryData?.content}
        hideCaseCol={hideCaseCol}
        hideStaffCol={hideStaffCol}
      />
      <QuickEntrySummary
        isLoading={loading}
        data={expenseEntryData?.content}
        type="exp"
      />
    </div>
  );
};

export default DisplayExpenseEntry;
