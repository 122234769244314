import React, { useContext } from "react";
import clsx from "clsx";
import { Grid, TextField } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DataContext } from "../../DataContext";
import CommonSelect from "../../components/CommonSelect";
import { getDefaultTimeExpObj } from "../../model";
import NumberInput from "../../components/NumberInput";
import ComplexSelect from "../../components/ComplexSelect";
import { formatDateDash } from "../../utils/dateUtils";

const QuickExpenseEntryForm = ({
  disableStaffSelection,
  className,
  data = getDefaultTimeExpObj(),
  handleChange = () => {},
  handleChangeByKey = () => {},
  error = {},
  onBlur = () => {},
}) => {
  const { caseList, staffList, expCodes } = useContext(DataContext);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form className={clsx("QuickExpenseEntryForm", className)}>
        <Grid container>
          <Grid item xs={12} md={6} className="p175">
            <ComplexSelect
              fullWidth
              required
              name="caseDto"
              label="Case"
              options={caseList}
              value={data.caseDto}
              error={error.caseDto}
              onChange={handleChange}
              onBlur={onBlur}
            />
          </Grid>
          <Grid item xs={12} md={6} className="p175">
            <ComplexSelect
              fullWidth
              required
              name="staff"
              label="Staff"
              options={staffList.filter((el) => el.rate !== 0)}
              value={data.staff}
              error={error.staff}
              onChange={handleChange}
              onBlur={onBlur}
              disabled={disableStaffSelection}
            />
          </Grid>
          <Grid item xs={12} md={6} className="p175">
            <KeyboardDatePicker
              fullWidth
              margin="none"
              format="dd-MMM-yyyy"
              label="Date"
              value={new Date(data.date)}
              error={error.date}
              onChange={(d) => handleChangeByKey("date", formatDateDash(d))}
              onBlur={onBlur}
              disableFuture
            />
          </Grid>
          <Grid item xs={12} md={6} className="p175">
            <NumberInput
              required
              fullWidth
              name="amount"
              label="Amount($)"
              value={data.amount}
              error={error.amount}
              onChange={handleChange}
              onBlur={onBlur}
              min={0}
            />
          </Grid>
          <Grid item xs={12} md={6} className="p175">
            <CommonSelect
              fullWidth
              required
              label="Expense Code"
              options={expCodes}
              name="actExpCode"
              value={data.actExpCode}
              error={error.actExpCode}
              onChange={handleChange}
              onBlur={onBlur}
            />
          </Grid>
          <Grid item xs={12} md={12} className="p175">
            <TextField
              label="Description"
              multiline={true}
              fullWidth
              required
              name="desc"
              value={data.desc}
              error={error.desc}
              onChange={handleChange}
              onBlur={onBlur}
            />
          </Grid>
        </Grid>
      </form>
    </MuiPickersUtilsProvider>
  );
};

export default QuickExpenseEntryForm;
