import { useMediaQuery, useTheme } from "@material-ui/core";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import CustomModal from "../../components/CustomModal";
import DepositMoney from "../../components/DepositMoney";
import SlideUp from "../../components/SlideUp";
import { DataContext } from "../../DataContext";
import { getDefaultDebitCreditObj } from "../../model";

const DebitCreditModal = ({
  selectedCase,
  openDeposit,
  setOpenDeposit,
  openRefund,
  setOpenRefund,
  type,
  debitCreditNote,
  debitCreditLines,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [linesOfDebitCredit, setLinesOfDebitCredit] =
    useState(debitCreditLines);
  let defaultLineOfDebitCredit = {
    value: "",
    id: "",
    active: true,
    description: "",
    amount: 0,
    invoiceSid: "",
  };
  const [lineOfDebitCredit, setLineOfDebitCredit] = useState(
    defaultLineOfDebitCredit
  );
  const [debitCredit, setDebitCredit] = useState(debitCreditNote);

  const [edit] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [invoiceSidList, setInvoiceSidList] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const { setDebitCreditList, alertSuccess, alertError } =
    useContext(DataContext);

  const fetchListOfInvoiceSidUsedInCreditNotesByCase = () => {
    try {
      Axios.get(`/debit-credit/${selectedCase.caseSid}/invoiceSids`).then(
        (res) => {
          setInvoiceSidList(res.data);
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDebitCreditList = () => {
    Axios.get("/debit-credit/all").then((res) => {
      setDebitCreditList(res.data);
    });
  };

  useEffect(() => {
    if (openDeposit) {
      setSelectedInvoice(null);
    }
  }, [openDeposit]);

  useEffect(() => {
    if (selectedCase) {
      fetchListOfInvoiceSidUsedInCreditNotesByCase();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCase]);

  useEffect(() => {
    if (
      debitCreditNote &&
      debitCreditNote.caseSid &&
      debitCreditNote.caseSid.length
    ) {
      setDebitCredit(debitCreditNote);
      setLinesOfDebitCredit(debitCreditLines);
    } else {
      clearDebitCreditForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debitCreditNote, debitCreditLines]);

  const addLineOfCreditDebit = () => {
    if (
      lineOfDebitCredit &&
      parseFloat(lineOfDebitCredit.amount) > 0 &&
      lineOfDebitCredit.description.length
    ) {
      linesOfDebitCredit.push(lineOfDebitCredit);
    }

    setLineOfDebitCredit(defaultLineOfDebitCredit);
    setSelectedInvoice(null);
    linesOfDebitCredit.length
      ? setDisableSaveBtn(false)
      : setDisableSaveBtn(true);
  };

  const editLineOfDebitCredit = (i, t) => {
    setLineOfDebitCredit(t);
    removeLineOfDebitCredit(i);
  };

  const removeLineOfDebitCredit = (i) => {
    let lines = [...linesOfDebitCredit];
    lines.splice(i, 1);
    setLinesOfDebitCredit(lines);
    lines.length ? setDisableSaveBtn(false) : setDisableSaveBtn(true);
  };

  const handleChangeLine = (v) => {
    if (v.target.name === "amount" && debitCredit.type === "credit") {
      let value = v.target.value
        ? parseFloat(v.target.value) <= parseFloat(selectedInvoice.amount)
          ? v.target.value
          : selectedInvoice.amount
        : 0;
      setLineOfDebitCredit({
        ...lineOfDebitCredit,
        [v.target.name]: value,
      });
    } else {
      setLineOfDebitCredit({
        ...lineOfDebitCredit,
        [v.target.name]: v.target.value,
      });
    }
  };

  const handleChangeInv = (evt) => {
    setLineOfDebitCredit(evt.target.value);
    setSelectedInvoice(evt.target.value);
  };

  const handleChange = (v) => {
    setDebitCredit({
      ...debitCredit,
      [v.target.name]: v.target.value,
    });
  };

  const clearDebitCreditForm = () => {
    setLineOfDebitCredit(defaultLineOfDebitCredit);
    setLinesOfDebitCredit([]);
    setDebitCredit({
      ...getDefaultDebitCreditObj(),
      type: type ? type : "debit",
      caseName: selectedCase ? selectedCase.name : "",
      caseRef: selectedCase ? selectedCase.caseRef : "",
      caseSid: selectedCase ? selectedCase.caseSid : "",
    });
  };

  const saveDeposit = () => {
    setOpenDeposit(false);
    let subTotal = 0;

    linesOfDebitCredit.forEach((el) => {
      subTotal += parseFloat(el.amount);
    });
    debitCredit.debitCreditLines = linesOfDebitCredit;
    debitCredit.subTotal = subTotal;
    if (debitCredit.id) {
      Axios.put("/debit-credit", debitCredit)
        .then((res) => {
          fetchDebitCreditList();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Axios.post("/debit-credit", debitCredit)
        .then((res) => {
          fetchDebitCreditList();
          alertSuccess("Successfully saved!");
        })
        .catch((err) => {
          alertError(err);
          console.log(err);
        });
    }
  };

  return (
    <CustomModal
      TransitionComponent={SlideUp}
      fullScreen={fullScreen}
      maxWidth="md"
      isOpen={openDeposit}
      title={type === "credit" ? "Credit Note" : "Debit Note"}
      closeBtnTitle="Cancel"
      saveBtnTitle="Save"
      handleClose={() => setOpenDeposit(false)}
      disableSaveBtn={disableSaveBtn}
      handleSave={saveDeposit}
    >
      <DepositMoney
        data={debitCredit}
        openDeposit={openDeposit}
        handleChange={handleChange}
        handleChangeLine={handleChangeLine}
        handleChangeInv={handleChangeInv}
        removeLineOfDebitCredit={removeLineOfDebitCredit}
        editLineOfDebitCredit={editLineOfDebitCredit}
        addLineOfCreditDebit={addLineOfCreditDebit}
        linesOfDebitCredit={linesOfDebitCredit}
        lineOfDebitCredit={lineOfDebitCredit}
        invoiceSidList={invoiceSidList}
        selectedInvoice={selectedInvoice}
        edit={edit}
      />
    </CustomModal>
  );
};

export default DebitCreditModal;
