import React from "react";
import {
  Checkbox,
  Typography,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core/";

import { formatDate } from "../../utils/dateUtils";
import { DateFormats } from "../../utils/constants";
import { formatDecimal, padZero } from "../../utils/functions";
import { IndeterminateCheckBox } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    boxShadow: "none",
  },
  cardBody: {
    "&:last-child": {
      paddingBottom: "0 !important",
    },
    paddingBottom: 0,
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: theme.palette.action.hover,
  },
  typography: {
    margin: "0 0 15px 0",
  },
  billableLabel: {
    margin: "20px 10px 20px 0",
  },
  billableAmount: {
    margin: "10px 0 0 40px",
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function EntryTable({
  entryType = "time",
  data = [],
  onRowSelect = () => {},
  selectedRows = [],
  setSelectedRows,
}) {
  const classes = useStyles();

  const handleToogle = (eachTimeExp) => (e) => {
    e.persist && e.persist();
    onRowSelect && onRowSelect(eachTimeExp);
  };

  const handleSelectAllClick = (e) => {
    e.persist && e.persist();
    if (e.target.checked) {
      setSelectedRows &&
        setSelectedRows([
          ...data,
          ...selectedRows.filter((el) => el.type !== entryType),
        ]);
    } else {
      setSelectedRows &&
        setSelectedRows(selectedRows.filter((el) => el.type !== entryType));
    }
  };

  const rowCount = data.length;
  const numSelected = selectedRows.filter((el) => el.type === entryType).length;

  return (
    <TableContainer className={classes.paper} component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                color="primary"
                size="small"
                id={entryType + "ChkAll"}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                indeterminateIcon={<IndeterminateCheckBox color="primary" />}
                checked={rowCount > 0 && numSelected === rowCount}
                onClick={handleSelectAllClick}
                inputProps={{ "aria-label": "select all entries" }}
                style={{ margin: "0 0 0 8px", padding: "4px" }}
              />
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="primary">
                Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="primary">
                {entryType === "time" ? "Service Performed" : "Description"}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="primary">
                Staff
              </Typography>
            </TableCell>
            {entryType === "time" && (
              <TableCell align="right">
                <Typography variant="body2" color="primary">
                  Time
                </Typography>
              </TableCell>
            )}
            <TableCell align="right">
              <Typography variant="body2" color="primary" noWrap>
                Amount ($)
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length > 0 ? (
            data.map((timeExp, i) => (
              <TableRow key={timeExp.id}>
                <TableCell>
                  <Checkbox
                    color="primary"
                    onChange={handleToogle(timeExp)}
                    checked={selectedRows.includes(timeExp)}
                    size="small"
                    style={{ margin: "0 0 0 8px", padding: "4px" }}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="textSecondary" noWrap>
                    {formatDate(timeExp?.date, DateFormats.MEDIUM)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">
                    {timeExp?.desc}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">
                    {timeExp?.staff?.value}
                  </Typography>
                </TableCell>
                {entryType === "time" && (
                  <TableCell align="right">
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className="e360-number"
                    >
                      {`${timeExp.billedHours}:${padZero(
                        timeExp.billedMinutes,
                        2
                      )}`}
                    </Typography>
                  </TableCell>
                )}
                <TableCell align="right">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className="e360-number"
                  >
                    {timeExp && timeExp.amount
                      ? formatDecimal(timeExp.amount)
                      : 0}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={entryType === "time" ? 6 : 5}>
                <Typography
                  variant="body2"
                  align="center"
                  color="error"
                  style={{ padding: "4px 0" }}
                >
                  No Data
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
