import React from "react";
import TabPanel from "../../../components/TabPanel";
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import SimpleBox from "../../../components/SimpleBox";
import LabelValueSummary from "../../../components/LabelValueSummary";
import Axios from "axios";
import { useContext, useState, useEffect } from "react";
import { DataContext } from "../../../DataContext";
import { getDefaultClientSummaryObj } from "../../../model";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
    textAlign: "center",
    boxShadow: theme.shadows[3],
  },
}));

const SummaryTabPanel = ({ client, ...others }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [clientSummary, setClientSummary] = useState(
    getDefaultClientSummaryObj
  );
  const { alertError } = useContext(DataContext);
  const getClientSummary = (clientSid) => {
    Axios.get("/clients/summary/" + clientSid)
      .then((res) => {
        setClientSummary(res.data);
      })
      .catch((err) => {
        if (err.response) {
          alertError(err.response.message);
        } else {
          alertError("Error! Unable to Delete.");
        }
      });
  };
  useEffect(() => {
    getClientSummary(client.clientSid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client.clientSid]);

  const data = {
    feeExpense: [
      {
        label: "Billable Fee Entered",
        value: "$" + clientSummary.totalBillableFee,
      },
      {
        label: "Non-Billable Fee Entered",
        value: "$" + clientSummary.totalNonBillableFee,
      },
      {
        label: "Total Expenses Entered",
        value: "$" + clientSummary.totalExpense,
      },
      {
        label: "Billable Time Entered",
        value:
          clientSummary.totalBillableHour +
          " h " +
          clientSummary.totalBillableMinute +
          " m",
      },
      {
        label: "Non-Billable Time Entered",
        value:
          clientSummary.totalNonBillableHour +
          " h " +
          clientSummary.totalNonBillableMinute +
          " m",
      },
    ],
    billedInvoices: [
      { label: "Total Fee Billed", value: "$" + clientSummary.totalBilledFee },
      {
        label: "Total Expenses Billed",
        value: "$" + clientSummary.totalBilledExpense,
      },
      {
        label: "Total Invoiced To Client",
        value: "$" + clientSummary.totalInvoiced,
      },
      {
        label: "Total Paid By Client",
        value: "$" + clientSummary.totalReceived,
      },
      { label: "Total Late Charges Paid By Client", value: "$0.00" },
      { label: "Written Off", value: "$" + clientSummary.totalWrittenOff },
    ],
    billingInfo: [
      { label: "Client ID", value: client.clientId },
      {
        label: "Billing Term",
        value:
          client.billingTerm && client.billingTerm.value
            ? client.billingTerm.value
            : "None",
      },
      {
        label: "Payment Term",
        value:
          client.paymentTerm && client.paymentTerm.value
            ? client.paymentTerm.value
            : "None",
      },
    ],
    contactInfo: [
      { label: "Phone", value: client.phone },
      { label: "Email", value: client.email },
      {
        label: "Billing Address",
        value:
          client.billingAddressOne +
          ", " +
          client.billingCity +
          ", " +
          client.billingState +
          ", " +
          (client.billingCountry ? client.billingCountry.value.slice(5) : ""),
      },
      {
        label: "Postal Address",
        value:
          client.postalAddressOne +
          ", " +
          client.postalCity +
          ", " +
          client.postalState +
          ", " +
          (client.postalCountry ? client.postalCountry.value.slice(5) : ""),
      },
    ],
  };
  return (
    <TabPanel
      className="SummaryTabPanel"
      {...others}
      style={{ overflowX: "hidden" }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={6} lg={3}>
          <Paper classes={classes}>
            <Typography
              variant="h5"
              style={{ color: theme.palette.success.main }}
            >
              {client.openedCases}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Opened Cases
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Paper classes={classes}>
            <Typography variant="h5" color="textSecondary">
              {client.closedCases}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Closed Cases
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Paper classes={classes}>
            <Typography variant="h5" color="primary">
              ${clientSummary.awaitingPayment}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Awaiting Payment
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Paper classes={classes}>
            <Typography variant="h5" color="primary">
              ${clientSummary.totalInvoiced}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Total Invoiced
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Paper classes={classes}>
            <Typography variant="h5" color="primary">
              ${clientSummary.totalReceived}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Total Received
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Paper classes={classes}>
            <Typography variant="h5" color="primary">
              ${clientSummary.totalWrittenOff}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Total Written Off
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Paper classes={classes}>
            <Typography variant="h5" color="primary">
              ${clientSummary.totalUnbilled}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Total Unbilled
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <div style={{ height: "24px" }} />

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} lg={6}>
          <SimpleBox title="Fee/Expense">
            {data.feeExpense.map((fe, i) => (
              <LabelValueSummary key={i} {...fe} />
            ))}
          </SimpleBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SimpleBox title="Billed/Invoices">
            {data.billedInvoices.map((fe, i) => (
              <LabelValueSummary key={i} {...fe} />
            ))}
          </SimpleBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SimpleBox title="Billing Info">
            <LabelValueSummary
              label="Client ID"
              value={client.clientId}
              valueColor={theme.palette.secondary.main}
            />
            <LabelValueSummary
              label="Payment Term"
              value={
                client.paymentTerm && client.paymentTerm.value
                  ? client.paymentTerm.value
                  : "None Selected"
              }
            />
            <LabelValueSummary
              label="Billing Term"
              value={
                client.billingTerm && client.billingTerm.value
                  ? client.billingTerm.value
                  : "None Selected"
              }
            />
          </SimpleBox>
        </Grid>
        <Grid item xs={12} lg={6}>
          <SimpleBox title="Contact Info">
            {data.contactInfo.map((fe, i) => (
              <LabelValueSummary key={i} {...fe} />
            ))}
          </SimpleBox>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default SummaryTabPanel;
