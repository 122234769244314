import React from "react";
import clsx from "clsx";
import { Grid, TextField } from "@material-ui/core";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CommonSelect from "../../../components/CommonSelect";

const MasterCodeTypeForm = ({
  className,
  handleChange,
  masterCodeType,
  existingCategory,
  handleCheckbox,
  categories,
}) => {
  return (
    <form className={clsx("MasterCodeTypeForm", className)}>
      <Grid container>
        <Grid item xs={12} md={12} className="p175">
          <TextField
            label="Short Description"
            name="typeShortDescription"
            value={masterCodeType.typeShortDescription}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} md={12} className="p175">
          <TextField
            label="Long Description"
            name="typeLongDescription"
            value={masterCodeType.typeLongDescription}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} md={12} className="p175">
          <FormControlLabel
            control={
              <Checkbox
                checked={existingCategory}
                onChange={handleCheckbox}
                name="chkSaveAnother"
                color="primary"
              />
            }
            label="Select from existing Category"
          />
        </Grid>
        <Grid item xs={12} md={12} className="p175">
          {existingCategory ? (
            <CommonSelect
              options={categories}
              label="Select Category"
              name="category"
              fullWidth
              required
              value={masterCodeType.category}
              onChange={handleChange}
            />
          ) : (
            <TextField
              label="Case Category Name"
              name="category"
              value={masterCodeType.category}
              onChange={handleChange}
              fullWidth
              required
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default MasterCodeTypeForm;
