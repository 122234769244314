function createTransactionData(
  client,
  caseInfo,
  invoiceNumber,
  operation,
  amount,
  createdBy,
  date
) {
  return {
    client,
    caseInfo,
    invoiceNumber,
    operation,
    amount,
    createdBy,
    date,
  };
}

export const transactions = [
  createTransactionData(
    "102",
    "C102",
    "",
    "deposit",
    5000,
    "John Doe",
    "Apr 15, 2021"
  ),
  createTransactionData(
    "102",
    "C102",
    "INV210001",
    "payment",
    200,
    "John Doe",
    "Apr 15, 2021"
  ),
  createTransactionData(
    "102",
    "C102",
    "INV210006",
    "payment",
    100,
    "John Doe",
    "Apr 15, 2021"
  ),
  createTransactionData(
    "102",
    "C102",
    "INV210007",
    "payment",
    800,
    "John Doe",
    "Apr 15, 2021"
  ),
  createTransactionData(
    "102",
    "C102",
    "INV210013",
    "payment",
    900,
    "John Doe",
    "Apr 15, 2021"
  ),
  createTransactionData(
    "103",
    "C103",
    "",
    "deposit",
    5000,
    "John Doe",
    "Apr 15, 2021"
  ),
  createTransactionData(
    "103",
    "C103",
    "INV210004",
    "payment",
    200,
    "John Doe",
    "Apr 15, 2021"
  ),
  createTransactionData(
    "103",
    "C103",
    "INV210005",
    "payment",
    100,
    "John Doe",
    "Apr 15, 2021"
  ),
  createTransactionData(
    "103",
    "C103",
    "INV210009",
    "payment",
    800,
    "John Doe",
    "Apr 15, 2021"
  ),
  createTransactionData(
    "103",
    "C103",
    "INV210012",
    "payment",
    500,
    "John Doe",
    "Apr 16, 2021"
  ),
  createTransactionData(
    "101",
    "C104",
    "",
    "deposit",
    5000,
    "John Doe",
    "Apr 16, 2021"
  ),
  createTransactionData(
    "101",
    "C104",
    "INV210008",
    "payment",
    200,
    "John Doe",
    "Apr 16, 2021"
  ),
  createTransactionData(
    "101",
    "C104",
    "INV210010",
    "payment",
    100,
    "John Doe",
    "Apr 16, 2021"
  ),
  createTransactionData(
    "101",
    "C104",
    "INV210014",
    "payment",
    800,
    "John Doe",
    "Apr 16, 2021"
  ),
  createTransactionData(
    "101",
    "C104",
    "INV210015",
    "payment",
    100,
    "John Doe",
    "Apr 16, 2021"
  ),
  createTransactionData(
    "102",
    "C105",
    "",
    "deposit",
    5000,
    "John Doe",
    "Apr 15, 2021"
  ),
  createTransactionData(
    "102",
    "C105",
    "INV210003",
    "payment",
    200,
    "John Doe",
    "Apr 16, 2021"
  ),
  createTransactionData(
    "102",
    "C105",
    "INV210011",
    "payment",
    100,
    "John Doe",
    "Apr 16, 2021"
  ),
  createTransactionData(
    "102",
    "C105",
    "INV210016",
    "payment",
    800,
    "John Doe",
    "Apr 16, 2021"
  ),
  createTransactionData(
    "101",
    "C101",
    "",
    "deposit",
    4000,
    "John Doe",
    "Apr 16, 2021"
  ),
  createTransactionData(
    "101",
    "C101",
    "INV210002",
    "payment",
    900,
    "John Doe",
    "Apr 16, 2021"
  ),
];
