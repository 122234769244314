import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Grid, TextField, makeStyles, Button } from "@material-ui/core";
import { EditRounded, AddRounded } from "@material-ui/icons";
import LinesOfDebitCreditTable from "../pages/debit-credit/components/LinesOfDebitCreditTable";
import ComplexSelect from "./ComplexSelect";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  p175: {
    padding: theme.spacing(1.75),
  },
}));

const DepositMoney = ({
  className,
  data,
  openDeposit,
  error,
  handleChange = () => {},
  handleChangeLine = () => {},
  handleChangeInv = () => {},
  addLineOfCreditDebit = () => {},
  addLineOfCredit = () => {},
  removeLineOfCredit = () => {},
  editLineOfDebitCredit = (i) => {},
  removeLineOfDebitCredit = (i) => {},
  linesOfDebitCredit,
  lineOfDebitCredit,
  selectedInvoice,
  invoiceSidList,
  setError = () => {},
  onBlur = () => {},
  edit = false,
}) => {
  const classes = useStyles();
  const [invoiceList, setInvoiceList] = useState([]);

  const fetchInvoiceList = async (
    caseSid = data.caseSid,
    lines = linesOfDebitCredit,
    invSidList = invoiceSidList
  ) => {
    try {
      if (caseSid && lines) {
        let link = `/invoices/${caseSid}/?filter=final`;
        const res = await Axios.get(link);
        const invList = res.data;
        const filteredInvList = invList
          .filter(
            (el) =>
              !(invSidList || []).includes(el.id) &&
              !lines.find((li) => li.id === el.id)
          )
          .map((inv) => ({
            value: inv.invoiceNum,
            id: inv.id,
            active: inv.active,
            description: inv.invoiceNum,
            amount: inv.totalAmount,
            invoiceSid: inv.id,
          }));
        setInvoiceList(filteredInvList);
      } else {
        setInvoiceList([]);
      }
    } catch (err) {
      console.log(err);
      setInvoiceList([]);
    }
  };

  useEffect(() => {
    fetchInvoiceList(data.caseSid, linesOfDebitCredit, invoiceSidList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.caseSid, linesOfDebitCredit, invoiceSidList]);

  return (
    <form className={clsx("CompanyForm", className)}>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.p175}>
          <TextField
            label="Case"
            fullWidth
            name="case"
            value={data.caseName + " (Ref#" + data.caseRef + ")"}
            disabled
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.p175}>
          <TextField
            label={
              data.type === "debit" ? "Debit Note Number" : "Credit Note Number"
            }
            fullWidth
            name="debitCreditNoteNumber"
            value="Auto Generated"
            disabled
            onChange={handleChange}
          />
        </Grid>
        {data.type === "debit" ? (
          <Grid item xs={12} md={6} className={classes.p175}>
            <TextField
              label="Description"
              fullWidth
              name="description"
              value={lineOfDebitCredit?.description || ""}
              onChange={handleChangeLine}
            />
          </Grid>
        ) : (
          <Grid item xs={12} md={6} className={classes.p175}>
            <ComplexSelect
              options={invoiceList}
              fullWidth
              label="Invoice"
              name="invoice"
              onChange={handleChangeInv}
              value={selectedInvoice}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} className={classes.p175}>
          <TextField
            type="number"
            inputProps={{ min: 0 }}
            label="Amount($)"
            fullWidth
            name="amount"
            value={lineOfDebitCredit?.amount || ""}
            onChange={(e) => {
              const sanitizedValue = e.target.value?.replace(/[^0-9]/g, "");
              const _e = { ...e };
              _e.target.value = sanitizedValue;
              return handleChangeLine(_e);
            }}
          />
        </Grid>
        {data.type === "credit" ? (
          <Grid container>
            <Grid item xs={12} md={6} className={classes.p175}>
              <TextField
                label="Description"
                fullWidth
                name="description"
                value={lineOfDebitCredit?.description || ""}
                onChange={handleChangeLine}
              />
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12} md={6} className="p175">
          <Button
            size="small"
            color="primary"
            variant="contained"
            startIcon={edit ? <EditRounded /> : <AddRounded />}
            disableElevation
            style={{ paddingRight: "16px" }}
            onClick={edit ? editLineOfDebitCredit : addLineOfCreditDebit}
            disabled={
              data.amount <= 0 ||
              (data.description && data.description.length <= 0)
            }
          >
            {edit ? "Edit " : "Add "}
            {(data.type === "debit" ? "Debit" : "Credit") + " Line"}
          </Button>
        </Grid>
        {linesOfDebitCredit && linesOfDebitCredit.length > 0 && (
          <LinesOfDebitCreditTable
            type={data.type}
            linesOfDebitCredit={linesOfDebitCredit}
            removeLineOfDebitCredit={removeLineOfDebitCredit}
            editLineOfDebitCredit={editLineOfDebitCredit}
          />
        )}
      </Grid>
    </form>
  );
};

export default DepositMoney;
