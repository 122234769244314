export const getDefAuditObj = () => ({
  operation: "",
  operatorName: "",
  operatorEmail: "",
  domainType: "",
  domainName: "",
  timestamp: "",
});

export const getDefAuditOptObj = () => ({
  open: false,
  moduleType: "",
  sid: "",
});
