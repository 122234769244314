import {
  Card,
  CardContent,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    boxShadow: "none",
  },
  cardBody: {
    "&:last-child": {
      paddingBottom: "0 !important",
    },
    paddingBottom: 0,
  },
  typography: {
    margin: "0 0 15px 0",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  number: {
    letterSpacing: "1px",
  },
}));

export const StatCard = ({ className, label, value, desc }) => {
  const classes = useStyles();
  return (
    <Paper className={clsx("StatCard", className, classes.paper)}>
      <Card className={classes.card}>
        <Typography className={classes.typography} variant="body1" gutterBottom>
          {label}
        </Typography>

        <CardContent className={classes.cardBody}>
          <Typography
            className={clsx(classes.typography, classes.number)}
            variant="body2"
            gutterBottom
          >
            {value}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={100}
            color="primary"
            style={{ marginBottom: "10px" }}
          />
          <Typography
            className={clsx(classes.typography, classes.number)}
            variant="body2"
            gutterBottom
          >
            {desc}
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};
