import axios from "axios";
import moment from "moment";
import React, { useState, useContext } from "react";
import ReactDOM from "react-dom";
import CustomModal from "../../../components/CustomModal";
import { DataContext, SettingContext } from "../../../DataContext";
import { getError } from "../../../utils/functions";
import { ChangeGhrForm } from "./";

export const ChangeGhrModal = ({
  SlideUp,
  fullScreen,
  openGhrModal,
  handleClose,
  staff,
}) => {
  const { alertWarning, alertError, alertSuccess } = useContext(DataContext);
  const { setProcessing } = useContext(SettingContext);

  const [newRate, setNewRate] = useState("");
  const [doNotUpdatePrevQtes, setDoNotUpdatePrevQtes] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);

  const goChangeGlobalHourlyRate = () => {
    // change-global-hourly-rate/{personSid}/{newRate}?fromDate, toDate, doNotUpdatePrevQtes
    if (!newRate || !newRate.trim()) {
      alertWarning("Please enter new global hourly rate");
    } else if (
      isNaN(Number(newRate.trim())) ||
      Number((+newRate.trim()).toFixed(2)) <= 0
    ) {
      alertWarning("Invalid global hourly rate");
    } else if (
      !doNotUpdatePrevQtes &&
      (dateRange[0] === "Invalid date" || dateRange[1] === "Invalid date")
    ) {
      alertWarning("Invalid date range selection");
    } else if (
      !doNotUpdatePrevQtes &&
      (moment(dateRange[0]).isAfter(moment.now()) ||
        moment(dateRange[1]).isAfter(moment.now()))
    ) {
      alertWarning("Future dates are not allowed to select");
    } else {
      let link = `staffs/change-global-hourly-rate/${staff.personSid}/${newRate}?doNotUpdatePrevQtes=${doNotUpdatePrevQtes}`;
      if (!doNotUpdatePrevQtes) {
        if (dateRange[0]) {
          link += `&fromDate=${dateRange[0]}`;
        }
        if (dateRange[1]) {
          link += `&toDate=${dateRange[1]}`;
        }
      }
      setProcessing(true);
      axios
        .patch(link)
        .then((res) => {
          setProcessing(false);
          if (res.data.success) {
            ReactDOM.unstable_batchedUpdates(() => {
              alertSuccess(res.data.message);
              setNewRate("");
              setDoNotUpdatePrevQtes(true);
              setDateRange([null, null]);
              handleClose();
            });
          } else {
            alertError("Unable to change global hourly rate");
          }
        })
        .catch((err) => {
          alertError(
            getError(err, "Error! Failed to change global hourly rate")
          );
          setProcessing(false);
        });
    }
  };

  return (
    <CustomModal
      TransitionComponent={SlideUp}
      fullScreen={fullScreen}
      maxWidth="sm"
      isOpen={openGhrModal}
      title="Change Global Hourly Rate"
      closeBtnTitle="Cancel"
      saveBtnTitle="Change"
      handleClose={handleClose}
      handleSave={goChangeGlobalHourlyRate}
    >
      <ChangeGhrForm
        staff={staff}
        newRate={newRate}
        setNewRate={setNewRate}
        doNotUpdatePrevQtes={doNotUpdatePrevQtes}
        setDoNotUpdatePrevQtes={setDoNotUpdatePrevQtes}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
    </CustomModal>
  );
};
