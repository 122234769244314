import React, { useContext, useState } from "react";
import { PopupContext, DataContext } from "../DataContext";
import {
  Dialog,
  useTheme,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Grid,
} from "@material-ui/core";
import SlideUp from "./SlideUp";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { getError } from "../utils/functions";

const ChangePasswordModal = () => {
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { changePasswordOptions, setChangePasswordOptions } = useContext(
    PopupContext
  );
  const { currentUser, alertError, alertSuccess, alertWarning } = useContext(
    DataContext
  );
  const { open, type } = changePasswordOptions;

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordReenter, setNewPasswordReenter] = useState("");

  const goChange = () => {
    try {
      if (type === "change" && !currentPassword) {
        alertWarning("Warning! Enter your current password");
      } else if (!(newPassword && newPasswordReenter)) {
        alertWarning("Warning! Enter and confirm your new password");
      } else if (newPassword !== newPasswordReenter) {
        alertWarning("Password mismatch! Re-enter your new password");
      } else {
        if (type === "change") {
          Axios.post("/setting/change-password", {
            email: currentUser.email,
            currentPassword,
            newPassword,
            newPasswordReenter,
          })
            .then((res) => {
              closeModal();
              alertSuccess(res.data.message);
              history.push("/logout");
            })
            .catch((err) =>
              alertError(getError(err, "Error! Failed to change password"))
            );
        } else {
          Axios.post("/setting/reset-password", {
            personSid: changePasswordOptions.personSid,
            newPassword,
            newPasswordReenter,
          })
            .then((res) => {
              closeModal();
              alertSuccess(res.data.message);
            })
            .catch((err) =>
              alertError(getError(err, "Error! Failed to reset password"))
            );
        }
      }
    } catch (err) {
      alertError(
        getError(
          err,
          `Error processing to ${
            type === "change" ? "change" : "reset"
          } password`
        )
      );
    }
  };

  const closeModal = () => {
    setCurrentPassword("");
    setNewPassword("");
    setNewPasswordReenter("");
    setChangePasswordOptions((prev) => ({ ...prev, open: false }));
  };

  return (
    <Dialog
      maxWidth="sm"
      TransitionComponent={fullScreen ? SlideUp : undefined}
      fullScreen={fullScreen}
      open={open}
      onClose={closeModal}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography variant="h6" component="span" color="primary">
          {type === "change" ? "Change Password" : "Reset Password"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form className="ChangePasswordForm" style={{ paddingBottom: "12px" }}>
          <Grid container>
            {type === "change" ? (
              <>
                <Grid item xs={12} style={{ padding: "14px" }}>
                  <Typography variant="body2" color="textSecondary">
                    Changing password for&nbsp;
                    <span style={{ fontWeight: 600 }}>{currentUser.email}</span>
                    . You'll be asked to login again after successfully changing
                    password.
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: "14px" }}>
                  <TextField
                    label="Current Password"
                    fullWidth
                    required
                    type="password"
                    name="currentPassowrd"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    autoComplete="off"
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12} style={{ padding: "14px" }}>
                <Typography variant="body2" color="textSecondary">
                  Resetting password for "{changePasswordOptions.name}" (
                  <span style={{ fontWeight: 600 }}>
                    {changePasswordOptions.email}
                  </span>
                  )
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} style={{ padding: "14px" }}>
              <TextField
                label="New Password"
                fullWidth
                required
                type="password"
                name="newPassowrd"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} style={{ padding: "14px" }}>
              <TextField
                label="Re-enter New Password"
                fullWidth
                required
                type="password"
                name="newPassowrdReenter"
                value={newPasswordReenter}
                onChange={(e) => setNewPasswordReenter(e.target.value)}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeModal}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 500 }}
          >
            Cancel
          </Typography>
        </Button>
        <Button
          onClick={goChange}
          color="primary"
          autoFocus
          style={{ fontWeight: 600, marginRight: "22px" }}
        >
          {type === "change" ? "Change" : "Reset"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;
