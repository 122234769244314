import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { DateFormats } from "../../utils/constants";
import {
  AssignmentIndRounded,
  BusinessCenterRounded,
  DescriptionRounded,
  HomeRounded,
  TodayRounded,
} from "@material-ui/icons";
import { formatDate } from "../../utils/dateUtils";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  p175: {
    padding: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.75),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
}));

const DebitCreditHeaderSection = ({ className, debitCredit }) => {
  const classes = useStyles();
  return (
    <>
      {debitCredit && (
        <Grid container className={clsx("DebitCreditHeaderSection", className)}>
          <Grid item xs={12} md={6} className={classes.p175}>
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap
              className="segLabel"
            >
              {debitCredit?.type === "debit" ? "Debit Detail" : "Credit Detail"}
            </Typography>
            <div className="d-flex align-items-center py-5">
              <DescriptionRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />

              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
                style={{ marginRight: "16px" }}
              >
                {`${debitCredit?.type === "debit" ? "Debit" : "Credit"} No. :`}
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                noWrap
                style={{ fontWeight: 600, letterSpacing: "1px" }}
              >
                {debitCredit?.type === "debit"
                  ? "DB" + debitCredit.debitCreditNumber
                  : "CD" + debitCredit.debitCreditNumber}
              </Typography>
            </div>
            <div className="d-flex align-items-center py-5">
              <TodayRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />

              <Typography variant="body2" color="textSecondary" noWrap>
                {formatDate(debitCredit.date, DateFormats.LONG)}
              </Typography>
            </div>
            <div className="d-flex align-items-center py-5">
              <BusinessCenterRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
                style={{ marginRight: "16px" }}
              >
                Case Ref#
              </Typography>
              <Typography variant="body2" color="secondary" noWrap>
                {debitCredit.caseRef}
              </Typography>
            </div>
            <div className="d-flex align-items-center py-5">
              <BusinessCenterRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                noWrap
                style={{ marginRight: "16px" }}
              >
                Case Name :
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {debitCredit.caseName}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.p175}>
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap
              className="segLabel"
            >
              To Client
            </Typography>
            <div className="d-flex align-items-center py-5">
              <AssignmentIndRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />

              <Typography
                variant="body2"
                color="primary"
                noWrap
                style={{ fontWeight: 600 }}
              >
                {debitCredit &&
                debitCredit.companyName &&
                debitCredit.companyName.length
                  ? debitCredit.companyName
                  : debitCredit.clientName}
              </Typography>
            </div>
            <div className="d-flex align-items-center py-5">
              <AssignmentIndRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />
              <Typography variant="body2" color="secondary" noWrap>
                {debitCredit.clientId}
              </Typography>
            </div>
            <div className="d-flex py-5">
              <HomeRounded
                fontSize="small"
                className={classes.textSecondary}
                style={{ marginRight: "10px" }}
              />
              <div>
                <Typography variant="body2" color="textSecondary">
                  {debitCredit.billingAddressOne}
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  {debitCredit.billingCity +
                    ", " +
                    debitCredit.billingState +
                    ", " +
                    debitCredit.billingCountryName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {debitCredit.billingPostalCode}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DebitCreditHeaderSection;
