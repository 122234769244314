import { formatDateDash } from "../utils/dateUtils";

export const getDefaultCaseObj = () => ({
  caseSid: null,
  caseRef: "",
  name: "",
  client: null, // CommonDto
  courtCaseNo: "",
  caseCat: "",
  respAtt: null, // CommonDto
  origAtt: null, // CommonDto,
  permission: "all",
  isBillable: true,
  desc: "",
  status: "open",
  openDate: formatDateDash(new Date()),
  closeDate: null,
  rate: "hourly",
  permittedLawyers: [],
  additionalLawyers: [],
  secretariesInCharge: [],
  agreedFee: "",
  isPocDefault: true,
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  role: "",
  active: true,
});
