import React, { useContext } from "react";
import {
  Grid,
  TextField,
  Collapse,
  InputBase,
  InputLabel,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { DataContext } from "../DataContext";
import { getDefaultTimeExpObj } from "../model";
import { formatDateDash } from "../utils/dateUtils";
import CommonSelect from "./CommonSelect";
import ComplexSelect from "./ComplexSelect";
import clsx from "clsx";
import { CloseRounded } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  qteFormLite: {
    borderBottom: "1px solid " + theme.palette.divider,
    paddingTop: "8px",
    transition: "all 120ms 40ms ease-out",
  },
  bgDefault: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: theme.palette.divider.replace("0.12)", "0.03)"),
    },
  },
  bgSuccess: {
    backgroundColor: theme.palette.success.main + "09",
    "&:hover": {
      backgroundColor: theme.palette.success.main + "12",
    },
  },
  bgError: {
    backgroundColor: theme.palette.error.main + "06",
    "&:hover": {
      backgroundColor: theme.palette.error.main + "10",
    },
  },
  timeLabel: {
    fontSize: "0.75rem",
    whiteSpace: "nowrap",
    marginBottom: "8px",
  },
  timeWrapper: {
    display: "flex",
    alignItems: "center",
    "&> .MuiInputBase-root": {
      backgroundColor: theme.palette.background.default,
      borderRadius: "3px",
    },
    "&> .MuiInputBase-root > input": {
      textAlign: "right",
      color: theme.palette.primary.main,
      paddingLeft: "8px",
      paddingRight: "8px",
      letterSpacing: "1px",
      fontWeight: "600",
      borderRadius: "3px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.divider.replace("0.12)", "0.08)"),
      transition: "all 200ms",
    },
    "&> .MuiInputBase-root > input:focus": {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.main + "75",
    },
  },
  alertSuccess: {
    backgroundColor: "transparent",
    color: theme.palette.success.main,
  },
  alertError: {
    backgroundColor: "transparent",
    color: theme.palette.error.main,
  },
}));

const QteFormLite = ({
  disableStaffSelection,
  ind,
  data = getDefaultTimeExpObj(),
  error,
  removeFromList,
  handleChange = () => {},
  handleChangeObj = () => {},
}) => {
  const classes = useStyles();
  const { caseList, staffList, actCodes } = useContext(DataContext);

  const _handleChange = (e) => handleChange(ind, e.target.name, e.target.value);
  const _handleChangeByKey = (k, v) => handleChange(ind, k, v);

  return (
    <form
      className={clsx(
        "QteFormLite",
        classes.qteFormLite,
        error === false
          ? classes.bgSuccess
          : error
          ? classes.bgError
          : classes.bgDefault
      )}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="p175"
      >
        <Grid item>
          <Typography variant="h6" color="textSecondary">
            {ind + 1}.
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            size="small"
            color="secondary"
            onClick={() => removeFromList(ind)}
          >
            <CloseRounded fontSize="small" color="error" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} md={3} className="p175">
          <ComplexSelect
            fullWidth
            required
            name="caseDto"
            label="Case"
            options={caseList}
            value={data.caseDto}
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={6} md={3} className="p175">
          <ComplexSelect
            fullWidth
            required
            name="staff"
            label="Staff"
            options={staffList.filter((el) => el.rate !== 0)}
            value={data.staff}
            onChange={_handleChange}
            disabled={disableStaffSelection}
          />
        </Grid>
        <Grid item xs={6} md={3} className="p175">
          <KeyboardDatePicker
            fullWidth
            margin="none"
            format="dd-MMM-yyyy"
            label="Date"
            value={new Date(data.date)}
            onChange={(d) => _handleChangeByKey("date", formatDateDash(d))}
            disableFuture
          />
        </Grid>
        <Grid item xs={6} md={3} className="p175">
          <CommonSelect
            fullWidth
            required
            label="Activity Code"
            options={actCodes}
            name="actExpCode"
            value={data.actExpCode}
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={6} md={3} className="p175">
          <InputLabel className={classes.timeLabel}>Clocked Time</InputLabel>
          <div className={classes.timeWrapper}>
            <InputBase
              className="flex-grow"
              name="hours"
              placeholder="hh"
              type="number"
              value={data.hours}
              onChange={(e) => {
                const sanitizedValue = e.target.value?.replace(/[^0-9]/g, "");
                return handleChangeObj(ind, {
                  ...data,
                  hours: sanitizedValue,
                  billedHours: sanitizedValue,
                });
              }}
            />
            <Typography
              variant="h6"
              component="span"
              color="textSecondary"
              align="center"
              style={{ minWidth: "18px" }}
            >
              :
            </Typography>
            <InputBase
              className="flex-grow"
              name="minutes"
              placeholder="mm"
              type="number"
              value={data.minutes}
              onChange={(e) => {
                const sanitizedValue = e.target.value?.replace(/[^0-9]/g, "");
                return handleChangeObj(ind, {
                  ...data,
                  minutes: sanitizedValue,
                  billedMinutes: sanitizedValue,
                });
              }}
            />
          </div>
        </Grid>
        <Grid item xs={6} md={3} className="p175">
          <InputLabel className={classes.timeLabel}>Billable Time</InputLabel>
          <div className={classes.timeWrapper}>
            <InputBase
              className="flex-grow"
              name="billedHours"
              placeholder="hh"
              type="number"
              value={data.billedHours}
              onChange={(e) => {
                const sanitizedValue = e.target.value?.replace(/[^0-9]/g, "");
                const _e = { ...e };
                _e.target.value = sanitizedValue;
                return _handleChange(_e);
              }}
            />
            <Typography
              variant="h6"
              component="span"
              color="textSecondary"
              align="center"
              style={{ minWidth: "18px" }}
            >
              :
            </Typography>
            <InputBase
              className="flex-grow"
              name="billedMinutes"
              placeholder="mm"
              type="number"
              value={data.billedMinutes}
              onChange={(e) => {
                const sanitizedValue = e.target.value?.replace(/[^0-9]/g, "");
                const _e = { ...e };
                _e.target.value = sanitizedValue;
                return _handleChange(_e);
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="p175">
          <TextField
            label="Service Performed"
            multiline={true}
            fullWidth
            required
            name="desc"
            value={data.desc}
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6} className="p175">
          <Collapse
            in={
              parseInt(data.billedHours) !== parseInt(data.hours) ||
              parseInt(data.billedMinutes) !== parseInt(data.minutes)
            }
          >
            <TextField
              label="Reason for Difference in Clocked and Billed Period"
              multiline={true}
              fullWidth
              required
              name="reason"
              value={data.reason ? data.reason : ""}
              onChange={_handleChange}
            />
          </Collapse>
        </Grid>
      </Grid>
      {error === false && (
        <Grid container>
          <Grid item xs={12} md={6} className="p175">
            <Alert severity="success" className={classes.alertSuccess}>
              Saved successfully
            </Alert>
          </Grid>
        </Grid>
      )}
      {error && (
        <Grid container>
          <Grid item xs={12} md={6} className="p175">
            <Alert severity="error" className={classes.alertError}>
              {error}
            </Alert>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default QteFormLite;
