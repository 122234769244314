import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

const MenuItemWithRoute = ({ location, to, Icon, text }) => {
  const history = useHistory();
  const routeTo = () => history.push(to);
  return (
    <div onClick={routeTo}>
      <ListItem
        button
        style={{ paddingTop: "6px", paddingBottom: "6px" }}
        selected={
          location.pathname.startsWith(to) ||
          (location.pathname === "/" && to === "/dashboard")
        }
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </div>
  );
};

export default withRouter(MenuItemWithRoute);
