import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  ButtonBase,
  colors,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { DataContext } from "../../../DataContext";
import ComplexSelect from "../../../components/ComplexSelect";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { formatDate, formatDateDash } from "../../../utils/dateUtils";
import DateFnsUtils from "@date-io/date-fns";
import {
  CloseRounded,
  HighlightOffRounded,
  SearchRounded,
  TuneRounded,
} from "@material-ui/icons";
import { ColorValues } from "../../../theme/colors";
import { DateFormats } from "../../../utils/constants";

const useStyles = makeStyles((theme) => {
  let color = ColorValues.GREY;
  return {
    filterItemBadge: {
      display: "inline-block",
      position: "relative",
      borderRadius: "3px",
      padding: "4px 8px",
      margin: "8px 0 8px 16px",
      fontSize: "12px",
      fontWeight: 400,
      letterSpacing: "1px",
      cursor: "default",
      whiteSpace: "nowrap",
      color: color && colors[color][500],
      backgroundColor: color && colors[color][500] + "25",
      "&:hover": {
        backgroundColor: color && colors[color][500] + "35",
      },
      "&:hover>button": {
        display: "inline-block",
      },
    },
    badgeCloseBtn: {
      display: "none",
      position: "absolute",
      right: "-8px",
      top: "-8px",
      margin: 0,
      padding: 0,
      zIndex: 3,
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      backgroundColor: "transparent",
      color: theme.palette.text.secondary,
    },
  };
});

const FilterItemBadge = ({ children, onClose = () => {} }) => {
  const classes = useStyles();
  return (
    <div className={classes.filterItemBadge}>
      {children}
      <ButtonBase
        className={classes.badgeCloseBtn}
        disableRipple
        disableTouchRipple
        onClick={onClose}
      >
        <HighlightOffRounded style={{ fontSize: "18px" }} />
      </ButtonBase>
    </div>
  );
};

const helperTextStyle = { style: { position: "absolute", bottom: "-22px" } };

const InvoiceFilter = ({
  dateRange,
  setDateRange,
  caseFilter,
  setCaseFilter,
  invoiceTypeFilter,
  setInvoiceTypeFilter,
  staffFilter,
  setStaffFilter,
  isFiltering,
  setCurrentPage,
  setPageSize,
  isLoading,
}) => {
  const { caseList, staffList, invoiceTypes, alertError } =
    useContext(DataContext);
  const theme = useTheme();

  const [_staffFilter, _setStaffFilter] = useState(staffFilter);
  const [_caseFilter, _setCaseFilter] = useState(caseFilter);
  const [_invoiceTypeFilter, _setInvoiceTypeFilter] =
    useState(invoiceTypeFilter);
  const [_dateRange, _setDateRange] = useState(dateRange);

  const _invoiceTypes = [
    { id: "draft", value: "Draft", secondaryValue: "draft" },
    ...invoiceTypes,
  ];

  const goSearchByFilter = () => {
    if (_dateRange[0] !== "Invalid date" && _dateRange[1] !== "Invalid date") {
      setDateRange(_dateRange);
      setCaseFilter(_caseFilter);
      _setCaseFilter(_caseFilter);

      setInvoiceTypeFilter(_invoiceTypeFilter);
      _setInvoiceTypeFilter(_invoiceTypeFilter);

      setStaffFilter(_staffFilter);
      _setStaffFilter(_staffFilter);
      if (
        _dateRange[0] ||
        _dateRange[1] ||
        _staffFilter ||
        _caseFilter ||
        _invoiceTypeFilter
      ) {
        setCurrentPage && setCurrentPage(1);
        setPageSize && setPageSize(10000);
      } else {
        setPageSize && setPageSize(10);
      }
    } else {
      alertError("Invalid date range selection");
    }
  };

  const clearFilters = (e) => {
    e.preventDefault();
    _setDateRange([null, null]);
    setDateRange([null, null]);

    setCaseFilter(null);
    _setCaseFilter(null);

    setInvoiceTypeFilter(null);
    _setInvoiceTypeFilter(null);

    _setStaffFilter(null);
    setStaffFilter(null);
    setCurrentPage && setCurrentPage(1);
    setPageSize && setPageSize(10);
  };

  const regex = /(Ref#\d{8})/g;
  let extractedString = "";
  if (caseFilter?.value) {
    const opt = caseFilter.value.match(regex);
    const str = Array.isArray(opt) && opt[0] ? opt[0] : caseFilter.value;
    extractedString = str;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box
        className="InvoiceFilter"
        style={{
          borderRadius: "5px",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.divider,
          padding: theme.spacing(2),
          paddingBottom: theme.spacing(1),
          marginBottom: theme.spacing(1.5),
        }}
      >
        <Grid
          container
          spacing={3}
          style={{
            marginBottom: theme.spacing(1.5),
            alignItems: "flex-end",
          }}
        >
          <Grid item xs={6} md={2}>
            <KeyboardDatePicker
              fullWidth
              margin="none"
              placeholder="MM/dd/yyyy"
              format="dd-MMM-yyyy"
              label="From Date"
              value={_dateRange[0] ? new Date(_dateRange[0]) : null}
              onChange={(d) =>
                _setDateRange([formatDateDash(d), _dateRange[1]])
              }
              FormHelperTextProps={helperTextStyle}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <KeyboardDatePicker
              fullWidth
              margin="none"
              placeholder="MM/dd/yyyy"
              format="dd-MMM-yyyy"
              label="To Date"
              value={_dateRange[1] ? new Date(_dateRange[1]) : null}
              onChange={(d) =>
                _setDateRange([_dateRange[0], formatDateDash(d)])
              }
              FormHelperTextProps={helperTextStyle}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <ComplexSelect
              fullWidth
              label="Case"
              options={caseList}
              value={_caseFilter}
              onChange={(e) => _setCaseFilter(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <ComplexSelect
              fullWidth
              label="Invoice Type"
              options={_invoiceTypes}
              value={_invoiceTypeFilter}
              onChange={(e) => _setInvoiceTypeFilter(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <ComplexSelect
              fullWidth
              label="Originating Lawyer"
              options={staffList}
              value={_staffFilter}
              onChange={(e) => _setStaffFilter(e.target.value)}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={goSearchByFilter}
              startIcon={<SearchRounded />}
              disabled={isLoading}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        {isFiltering && (
          <div className="d-flex flex-wrap align-items-center">
            <Typography
              className="d-flex align-items-center"
              variant="body2"
              color="textSecondary"
              style={{ marginLeft: "8px" }}
            >
              <TuneRounded fontSize="small" style={{ marginRight: "4px" }} />
              <span>Filters:</span>
            </Typography>
            {(dateRange[0] || dateRange[1]) && (
              <FilterItemBadge
                onClose={() => {
                  _setDateRange([null, null]);
                  setDateRange([null, null]);
                }}
              >
                {dateRange[0]
                  ? formatDate(dateRange[0], DateFormats.MEDIUM)
                  : "Oldest"}{" "}
                -{" "}
                {dateRange[1]
                  ? formatDate(dateRange[1], DateFormats.MEDIUM)
                  : "Newest"}
              </FilterItemBadge>
            )}
            {caseFilter && (
              <FilterItemBadge
                onClose={() => {
                  _setCaseFilter(null);
                  setCaseFilter(null);
                }}
              >
                {extractedString}
              </FilterItemBadge>
            )}
            {invoiceTypeFilter && (
              <FilterItemBadge
                onClose={() => {
                  _setInvoiceTypeFilter(null);
                  setInvoiceTypeFilter(null);
                }}
              >
                {invoiceTypeFilter.value}
              </FilterItemBadge>
            )}
            {staffFilter && (
              <FilterItemBadge
                onClose={() => {
                  _setStaffFilter(null);
                  setStaffFilter(null);
                }}
              >
                {staffFilter.value}
              </FilterItemBadge>
            )}
            <Button
              className="clear-filter-btn"
              color="secondary"
              size="small"
              onClick={clearFilters}
              startIcon={<CloseRounded fontSize="small" />}
            >
              Clear
            </Button>
          </div>
        )}
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export { InvoiceFilter };
