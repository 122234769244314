import React, { useContext, useState, useEffect } from "react";
import { Badge } from "@material-ui/core";

import TabPane from "../../components/TabPane";
import TabPanel from "../../components/TabPanel";
import EntryTable from "./EntryTable";
import QuickEntryFilter from "../../components/QuickEntryFilter";
import { DataContext } from "../../DataContext";

const SelectTimeExpenseEntryModal = ({
  selectedTempEntries,
  setSelectedTempEntries,
}) => {
  const allOpt = { id: "all", value: "All" };
  const { timeExpList } = useContext(DataContext);

  const _timeEntry = Array.isArray(timeExpList)
    ? timeExpList.filter((e) => e.type === "time")
    : [];
  const [timeEntry, setTimeEntry] = useState(_timeEntry);

  const _expEntry = Array.isArray(timeExpList)
    ? timeExpList.filter((e) => e.type === "exp")
    : [];
  const [expEntry, setExpEntry] = useState(_expEntry);

  const [staffFilter1, setStaffFilter1] = useState(allOpt);
  const [dateRange1, setDateRange1] = useState([null, null]);

  const [staffFilter2, setStaffFilter2] = useState(allOpt);
  const [dateRange2, setDateRange2] = useState([null, null]);

  const onSearch = (type = "time", dateRange, staffFilter) => {
    const entry = type === "time" ? _timeEntry : _expEntry;
    const setEntry = type === "time" ? setTimeEntry : setExpEntry;

    const staffId = staffFilter?.id;
    const fromTime = dateRange[0] ? new Date(dateRange[0]).getTime() : null;
    const toTime = dateRange[1] ? new Date(dateRange[1]).getTime() : null;

    const filteredEntry = entry.filter((e) => {
      let q = true;
      const time = new Date(e.date).getTime();

      if (fromTime && !toTime) {
        q = time === fromTime;
      }
      if (!fromTime && toTime) {
        q = time === toTime;
      }
      if (fromTime && toTime) {
        q = time >= fromTime && time <= toTime;
      }
      if (staffId !== "all") {
        q = q && e?.staff?.id === staffId;
      }

      return q;
    });

    setEntry(filteredEntry);
  };

  useEffect(() => {
    onSearch("time", dateRange1, staffFilter1);
    // eslint-disable-next-line
  }, [dateRange1, staffFilter1]);

  useEffect(() => {
    onSearch("exp", dateRange2, staffFilter2);
    // eslint-disable-next-line
  }, [dateRange2, staffFilter2]);

  const _onRowSelect = (entry) => {
    const sr = selectedTempEntries.map((item) => item.id).indexOf(entry.id);
    const all = [...selectedTempEntries];

    if (sr === -1) {
      all.push(entry);
    } else {
      all.splice(sr, 1);
    }

    setSelectedTempEntries(all);
  };

  const retrieveBadgeCount = (type) => {
    return selectedTempEntries.filter((sr) => sr.type === type).length;
  };

  const isFiltering1 =
    dateRange1[0] ||
    dateRange1[1] ||
    (staffFilter1 && staffFilter1.id !== "all");

  const isFiltering2 =
    dateRange2[0] ||
    dateRange2[1] ||
    (staffFilter2 && staffFilter2.id !== "all");

  return (
    <div className="SelectTimeExpenseEntryModal">
      <TabPane
        tabTitles={[
          {
            label: (
              <Badge badgeContent={retrieveBadgeCount("time")} color="primary">
                Time Entry
              </Badge>
            ),
          },
          {
            label: (
              <Badge badgeContent={retrieveBadgeCount("exp")} color="primary">
                Expense Entry
              </Badge>
            ),
          },
        ]}
      >
        <TabPanel>
          <QuickEntryFilter
            staffFilter={staffFilter1}
            setStaffFilter={setStaffFilter1}
            dateRange={dateRange1}
            setDateRange={setDateRange1}
            hideStaffFilter={false}
            isFiltering={isFiltering1}
            personSid={null}
            caseSid={null}
            type="time"
            isLoading={false}
            hideExportBtn={true}
          />
          <EntryTable
            entryType="time"
            data={timeEntry}
            onRowSelect={_onRowSelect}
            selectedRows={selectedTempEntries}
            setSelectedRows={setSelectedTempEntries}
          />
        </TabPanel>
        <TabPanel>
          <QuickEntryFilter
            staffFilter={staffFilter2}
            setStaffFilter={setStaffFilter2}
            dateRange={dateRange2}
            setDateRange={setDateRange2}
            hideStaffFilter={false}
            isFiltering={isFiltering2}
            personSid={null}
            caseSid={null}
            type="exp"
            isLoading={false}
          />
          <EntryTable
            entryType="exp"
            data={expEntry}
            onRowSelect={_onRowSelect}
            selectedRows={selectedTempEntries}
            setSelectedRows={setSelectedTempEntries}
          />
        </TabPanel>
      </TabPane>
    </div>
  );
};
export default SelectTimeExpenseEntryModal;
