import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core/";
import { TimerRounded, MonetizationOnRounded } from "@material-ui/icons";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import CommonSelect from "../../components/CommonSelect";
import {
  StatCard,
  TableSkeleton,
  StatCardSkeleton,
} from "../dashboard/components";
import Axios from "axios";
import { formatDecimal, padZero } from "../../utils/functions";
import { formatDate } from "../../utils/dateUtils";
import { DateFormats } from "../../utils/constants";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useQuickEntryTableStyles } from "../../theme/quickEntryTableStyles";
import { DataContext } from "../../DataContext";
import NoDataRow from "../../components/NoDataRow";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: theme.palette.action.hover,
  },
  typography: {
    margin: "0 0 15px 0",
  },
  billableLabel: {
    margin: "20px 10px 20px 0",
  },
  billableAmount: {
    margin: "10px 0 0 40px",
  },
  paper: {
    padding: theme.spacing(2),
  },
  number: {
    letterSpacing: "1px",
  },
  pm: {
    margin: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
}));

const timeSpanOptions = [
  { id: "today", value: "Today" },
  { id: "this-week", value: "This Week" },
  { id: "this-month", value: "This Month" },
];

const invoiceTypeOptions = [
  { id: "unpaid", value: "Unpaid" },
  { id: "paid", value: "Paid" },
  { id: "written-off", value: "Written Off" },
];

const HomePage = () => {
  const classes = useStyles();
  const tableStyles = useQuickEntryTableStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { clientList } = useContext(DataContext);

  const [timeSpan, setTimeSpan] = useState("today");
  const [invoiceType, setInvoiceType] = useState("unpaid");

  const [loadingStat, setLoadingStat] = useState(false);
  const [statList, setStatList] = useState([]);

  const [loadingBillableData, setLoadingBillableData] = useState(false);
  const [billableData, setBillableData] = useState(null);

  const [loadingMonthlyQte, setLoadingMonthlyQte] = useState(false);
  const [monthlyTimeEntries, setMonthlyTimeEntries] = useState([]);

  const [loadUninvoicedEntries, setLoadUninvoicedEntries] = useState(false);
  const [uninvoicedEntries, seUninvoicedEntries] = useState([]);

  const [loadingTop10Qte, setLoadingTop10Qte] = useState(false);
  const [top10Qtes, setTop10Qtes] = useState([]);

  const [loadingInactiveCases, setLoadingInactiveCases] = useState(false);
  const [inactiveCases, setInactiveCases] = useState([]);

  const fetchStatList = () => {
    setLoadingStat(true);
    Axios.get("/home/totals")
      .then((res) => {
        setStatList(res.data);
        setLoadingStat(false);
      })
      .catch((err) => {
        setStatList([]);
        setLoadingStat(false);
      });
  };

  const fetchMonthlyTimeEntries = () => {
    setLoadingMonthlyQte(true);
    Axios.get("/home/monthly-billable-data")
      .then((res) => {
        setMonthlyTimeEntries(res.data.reverse());
        setLoadingMonthlyQte(false);
      })
      .catch((err) => {
        setMonthlyTimeEntries([]);
        setLoadingMonthlyQte(false);
      });
  };

  const fetchUninvoicedEntriesByClientCase = () => {
    setLoadUninvoicedEntries(true);
    Axios.get("/home/uninvoiced-qte-client-case")
      .then((res) => {
        seUninvoicedEntries(res.data);
        setLoadUninvoicedEntries(false);
      })
      .catch((err) => {
        seUninvoicedEntries([]);
        setLoadUninvoicedEntries(false);
      });
  };

  useEffect(() => {
    fetchStatList();
    fetchMonthlyTimeEntries();
    fetchUninvoicedEntriesByClientCase();
  }, []);

  const fetchBillableData = (timeSpan) => {
    setLoadingBillableData(true);
    Axios.get("/home/billable/" + timeSpan)
      .then((res) => {
        setBillableData(res.data);
        setLoadingBillableData(false);
      })
      .catch((err) => {
        setBillableData([]);
        setLoadingBillableData(false);
      });
  };

  useEffect(() => {
    fetchBillableData(timeSpan);
  }, [timeSpan]);

  const fetchTop10Qtes = (invoiceType) => {
    setLoadingTop10Qte(true);
    Axios.get("/home/qte-by-invoice-type/" + invoiceType)
      .then((res) => {
        setTop10Qtes(res.data);
        setLoadingTop10Qte(false);
      })
      .catch((err) => {
        setTop10Qtes([]);
        setLoadingTop10Qte(false);
      });
  };

  useEffect(() => {
    fetchTop10Qtes(invoiceType);
  }, [invoiceType]);

  const fetchInactiveCases = () => {
    setLoadingInactiveCases(true);
    Axios.get("/home/inactive-cases")
      .then((res) => {
        setInactiveCases(res.data);
        setLoadingInactiveCases(false);
      })
      .catch((err) => {
        setInactiveCases([]);
        setLoadingInactiveCases(false);
      });
  };

  useEffect(() => {
    fetchInactiveCases();
  }, []);

  return (
    <div
      className="HomePage"
      style={{ position: "relative", overflow: "hidden auto", width: "100%" }}
    >
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="center"
        alignItems="flex-start"
      >
        {loadingStat
          ? [...Array(smallScreen ? 2 : 5).keys()].map((i) => (
              <Grid item xs={6} sm={4} md={2} key={i}>
                <StatCardSkeleton />
              </Grid>
            ))
          : statList && statList.length > 0
          ? statList.map((stat) => (
              <Grid item xs={6} sm={4} md={2} key={stat.label}>
                <StatCard
                  label={stat.label}
                  value={"$" + formatDecimal(stat.currentYearValue)}
                  desc={"(" + stat.currentYear + ")"}
                />
              </Grid>
            ))
          : null}
      </Grid>
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={10} md={4}>
          <Paper
            className={classes.paper}
            style={{
              minHeight: "243px",
              margin: "16px",
            }}
          >
            <div
              className="d-flex justify-content-end"
              style={{ minHeight: "50px" }}
            >
              <div style={{ width: "40%" }}>
                {loadingBillableData ? (
                  <Skeleton animation="wave" width="100%" height={40} />
                ) : (
                  <CommonSelect
                    label="Time Span"
                    fullWidth
                    options={timeSpanOptions}
                    value={timeSpan}
                    onChange={(e) => setTimeSpan(e.target.value)}
                  />
                )}
              </div>
            </div>

            <List>
              <ListItem>
                <ListItemIcon>
                  {loadingBillableData ? (
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      width={40}
                      height={40}
                    />
                  ) : (
                    <TimerRounded color="primary" />
                  )}
                </ListItemIcon>
                {loadingBillableData ? (
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton
                      animation="wave"
                      width="60%"
                      height={12}
                      style={{ marginBottom: "6px" }}
                    />
                    <Skeleton animation="wave" width="40%" height={10} />
                  </div>
                ) : (
                  <ListItemText
                    secondaryTypographyProps={{
                      className: classes.number,
                    }}
                    primary="Billable Hours"
                    secondary={
                      billableData &&
                      formatDecimal(billableData.billableHour) + " h"
                    }
                  />
                )}
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {loadingBillableData ? (
                    <Skeleton
                      animation="wave"
                      variant="circle"
                      width={40}
                      height={40}
                    />
                  ) : (
                    <MonetizationOnRounded color="primary" />
                  )}
                </ListItemIcon>
                {loadingBillableData ? (
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton
                      animation="wave"
                      width="60%"
                      height={12}
                      style={{ marginBottom: "6px" }}
                    />
                    <Skeleton animation="wave" width="40%" height={10} />
                  </div>
                ) : (
                  <ListItemText
                    secondaryTypographyProps={{
                      className: classes.number,
                    }}
                    primary="Billable Amount"
                    secondary={
                      billableData &&
                      "$" + formatDecimal(billableData.billableAmount)
                    }
                  />
                )}
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Paper
            className={classes.paper}
            style={{
              minHeight: "243px",
              margin: "16px",
            }}
          >
            {loadingMonthlyQte ? (
              <Skeleton
                animation="wave"
                height={16}
                style={{ marginBottom: "16px" }}
                width="30%"
              />
            ) : (
              <Typography
                className={classes.typography}
                variant="body1"
                gutterBottom
              >
                Billable Time Entries
              </Typography>
            )}
            {loadingMonthlyQte ? (
              <div
                className="d-flex justify-content-center align-items-end"
                style={{ minHeight: "120px" }}
              >
                <Skeleton
                  component="div"
                  animation="wave"
                  height={60}
                  width={24}
                  style={{
                    marginLeft: "16px",
                    marginRight: "16px",
                  }}
                />
                <Skeleton
                  component="div"
                  animation="wave"
                  height={100}
                  width={24}
                  style={{
                    marginLeft: "16px",
                    marginRight: "16px",
                  }}
                />
                <Skeleton
                  component="div"
                  animation="wave"
                  height={80}
                  width={24}
                  style={{
                    marginLeft: "16px",
                    marginRight: "16px",
                  }}
                />
              </div>
            ) : monthlyTimeEntries && monthlyTimeEntries.length > 0 ? (
              <div style={{ overflowY: "hidden", overflowX: "auto" }}>
                <ResponsiveContainer
                  className={classes.paper}
                  width={550}
                  height={300}
                >
                  <BarChart
                    data={monthlyTimeEntries.map((el) => ({
                      ...el,
                      "Billable Amount": +el.billableAmount.toFixed(2),
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 25,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="label" />
                    <YAxis dataKey="Billable Amount" />
                    <Tooltip
                      contentStyle={{ color: theme.palette.secondary.main }}
                    />
                    <Legend />
                    <Bar
                      dataKey="Billable Amount"
                      label="Billable Amount"
                      fill={theme.palette.primary.main}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : null}
          </Paper>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={clsx(classes.paper, classes.pm)}>
              {loadUninvoicedEntries ? (
                <TableSkeleton />
              ) : (
                <>
                  <Typography
                    className={classes.typography}
                    variant="h6"
                    gutterBottom
                  >
                    Uninvoiced Time/Expense Summary by Case &nbsp;
                    <span
                      style={{
                        color: theme.palette.action.disabled,
                        fontSize: "90%",
                      }}
                    >
                      ({uninvoicedEntries.length})
                    </span>
                  </Typography>
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead className={tableStyles.tableHead}>
                        <TableRow>
                          <TableCell>Case Name</TableCell>
                          <TableCell>Case Ref</TableCell>
                          <TableCell>Client</TableCell>
                          <TableCell align="right">
                            Billable Hours (h)
                          </TableCell>
                          <TableCell align="right">
                            Billable Amount ($)
                          </TableCell>
                          <TableCell align="right">Net Amount ($)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={tableStyles.tableBody}>
                        {uninvoicedEntries && uninvoicedEntries.length > 0 ? (
                          uninvoicedEntries.map((el) => (
                            <TableRow key={el.caseRef} hover>
                              <TableCell>{el.caseName}</TableCell>
                              <TableCell>
                                <Typography
                                  noWrap
                                  color="secondary"
                                  style={{
                                    letterSpacing: "1px",
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  Ref#{el.caseRef}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {
                                  clientList.find(
                                    (cl) => cl.id === el.clientSid
                                  )?.value
                                }
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.number}
                              >
                                {el.totalBillableMinutes
                                  ? formatDecimal(el.totalBillableMinutes / 60)
                                  : "-"}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.number}
                              >
                                {formatDecimal(el.totalBillableAmount)}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.number}
                              >
                                {formatDecimal(el.totalNetAmount)}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <NoDataRow colSpan={6} />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: "16px" }}>
            <Paper className={clsx(classes.paper, classes.pm)}>
              <div
                className="d-flex justify-content-end"
                style={{ minHeight: "50px", marginBottom: "16px" }}
              >
                <div style={{ width: "170px" }}>
                  {loadingTop10Qte ? (
                    <Skeleton animation="wave" width="100%" height={40} />
                  ) : (
                    <CommonSelect
                      label="Invoice Type"
                      fullWidth
                      options={invoiceTypeOptions}
                      value={invoiceType}
                      onChange={(e) => setInvoiceType(e.target.value)}
                    />
                  )}
                </div>
              </div>
              {loadingTop10Qte ? (
                <TableSkeleton />
              ) : (
                <>
                  <Typography
                    className={classes.typography}
                    variant="h6"
                    gutterBottom
                  >
                    10 Recent Time/Expense by Invoice Type &nbsp;
                    <span
                      style={{
                        color: theme.palette.action.disabled,
                        fontSize: "90%",
                      }}
                    >
                      ({top10Qtes.length})
                    </span>
                  </Typography>
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead className={tableStyles.tableHead}>
                        <TableRow>
                          <TableCell>Service Performed</TableCell>
                          <TableCell>Billable Time (Hr:Min)</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell align="right">Rate ($/hour)</TableCell>
                          <TableCell align="right">Amount ($)</TableCell>
                          <TableCell align="right">Net Amount ($)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={tableStyles.tableBody}>
                        {top10Qtes && top10Qtes.length > 0 ? (
                          top10Qtes.map((el) => (
                            <TableRow key={el.servicePerformed} hover>
                              <TableCell>{el.servicePerformed}</TableCell>
                              <TableCell>
                                {el.type === "exp"
                                  ? `(Expense)`
                                  : `${
                                      el.billedHour === null
                                        ? el.hour
                                        : el.billedHour
                                    }:${padZero(
                                      el.billedMinute === null
                                        ? el.minute
                                        : el.billedMinute,
                                      2
                                    )}`}
                              </TableCell>
                              <TableCell>
                                {el.date &&
                                  formatDate(el.date, DateFormats.MEDIUM)}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.number}
                              >
                                {el.type === "exp"
                                  ? `(Expense)`
                                  : formatDecimal(el.rate)}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.number}
                              >
                                {formatDecimal(el.amount)}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.number}
                              >
                                {formatDecimal(el.netAmount)}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <NoDataRow colSpan={6} />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={clsx(classes.paper, classes.pm)}>
              {loadingInactiveCases ? (
                <TableSkeleton />
              ) : (
                <>
                  <Typography
                    className={classes.typography}
                    variant="h6"
                    gutterBottom
                  >
                    Inactive Cases &nbsp;
                    <span
                      style={{
                        color: theme.palette.action.disabled,
                        fontSize: "90%",
                      }}
                    >
                      ({inactiveCases.length})
                    </span>
                  </Typography>
                  <TableContainer>
                    <Table className={classes.table}>
                      <TableHead className={tableStyles.tableHead}>
                        <TableRow>
                          <TableCell>Case Name</TableCell>
                          <TableCell>Case Ref</TableCell>
                          <TableCell>Client</TableCell>
                          <TableCell align="right">Created Date</TableCell>
                          <TableCell align="right">Last Modified</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={tableStyles.tableBody}>
                        {inactiveCases && inactiveCases.length > 0 ? (
                          inactiveCases.map((el) => (
                            <TableRow key={el.caseRef} hover>
                              <TableCell>{el.caseName}</TableCell>
                              <TableCell>
                                <Typography
                                  noWrap
                                  color="secondary"
                                  style={{
                                    letterSpacing: "1px",
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  Ref#{el.caseRef}
                                </Typography>
                              </TableCell>
                              <TableCell>{el.clientName}</TableCell>
                              <TableCell align="right">
                                {el.createdAt &&
                                  formatDate(el.createdAt, DateFormats.MEDIUM)}
                              </TableCell>
                              <TableCell align="right">
                                {el.updatedAt &&
                                  formatDate(el.updatedAt, DateFormats.MEDIUM)}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <NoDataRow colSpan={6} />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
