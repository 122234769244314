import React, { Fragment } from "react";
import {
  Typography,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core/";
import { EditRounded, CloseRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    boxShadow: "none",
  },
  cardBody: {
    "&:last-child": {
      paddingBottom: "0 !important",
    },
    paddingBottom: 0,
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: theme.palette.action.hover,
  },
  typography: {
    margin: "0 0 15px 0",
  },
  billableLabel: {
    margin: "20px 10px 20px 0",
  },
  billableAmount: {
    margin: "10px 0 0 40px",
  },
  paper: {
    padding: theme.spacing(2),
  },
  p175: {
    padding: theme.spacing(1.75),
  },
}));

export default function LinesOfDebitCreditTable({
  type,
  linesOfDebitCredit,
  editLineOfDebitCredit,
  removeLineOfDebitCredit,
}) {
  const classes = useStyles();
  return (
    <TableContainer className={classes.paper} component={Paper}>
      <Typography
        className={classes.p175}
        variant="body1"
        color="textSecondary"
        gutterBottom
      >
        Lines of Debit / Credit
      </Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <Typography variant="body2" color="primary">
                Description
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="body2" color="primary" noWrap>
                Amount($)
              </Typography>
            </TableCell>
            {type === "debit" && (
              <TableCell align="right">
                <Typography variant="body2" color="primary">
                  Edit
                </Typography>
              </TableCell>
            )}
            <TableCell align="right">
              <Typography variant="body2" color="primary">
                Remove
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {linesOfDebitCredit.map((t, i) => (
            <Fragment key={i}>
              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">
                    {i + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">
                    {t.description}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                  >
                    {t.amount}
                  </Typography>
                </TableCell>
                {type === "debit" && (
                  <TableCell align="right">
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      size="small"
                      style={{ marginRight: "4px" }}
                      onClick={() => {
                        editLineOfDebitCredit(i, t);
                      }}
                    >
                      <EditRounded color="primary" fontSize="small" />
                    </IconButton>
                  </TableCell>
                )}
                <TableCell align="right">
                  <IconButton
                    edge="end"
                    aria-label="remove"
                    size="small"
                    onClick={() => removeLineOfDebitCredit(i)}
                  >
                    <CloseRounded color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
