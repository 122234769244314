import React from "react";
import { Grid, TextField, InputBase, InputLabel, Typography, makeStyles, IconButton } from "@material-ui/core";
import { getDefaultInvoicePartyObj } from "../model";
import CommonSelect from "./CommonSelect";
import clsx from "clsx";
import { CloseRounded } from "@material-ui/icons";

const percentageShareOptions = [{ id: "LPI", value: "LPI" }];

const useStyles = makeStyles((theme) => ({
  invPartyForm: {
    borderBottom: "1px solid " + theme.palette.divider,
    paddingTop: "8px",
    transition: "all 120ms 40ms ease-out",
  },
  bgDefault: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: theme.palette.divider.replace("0.12)", "0.03)"),
    },
  },
  percLabel: {
    fontSize: "0.75rem",
    whiteSpace: "nowrap",
    marginBottom: "8px",
  },
  percWrapper: {
    display: "flex",
    alignItems: "center",
    "&> .MuiInputBase-root": {
      backgroundColor: theme.palette.background.default,
      borderRadius: "3px",
    },
    "&> .MuiInputBase-root > input": {
      textAlign: "right",
      color: theme.palette.primary.main,
      paddingLeft: "8px",
      paddingRight: "8px",
      letterSpacing: "1px",
      fontWeight: "600",
      borderRadius: "3px",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.divider.replace("0.12)", "0.08)"),
      transition: "all 200ms",
    },
    "&> .MuiInputBase-root > input:focus": {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.main + "75",
    },
  },
}));

const InvoicePartyForm = ({ ind, data = getDefaultInvoicePartyObj(), removeFromList, handleChange = () => {} }) => {
  const classes = useStyles();

  const _handleChange = (e) => handleChange(ind, e.target.name, e.target.value);

  return (
    <form className={clsx("InvoicePartyForm", classes.invPartyForm, classes.bgDefault)}>
      <Grid container justifyContent="space-between" alignItems="center" className="p175">
        <Grid item>
          <Typography variant="h6" color="textSecondary">
            {ind + 1}.
          </Typography>
        </Grid>
        <Grid item>
          <IconButton size="small" color="secondary" onClick={() => removeFromList(ind)}>
            <CloseRounded fontSize="small" color="error" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className="p175">
          <TextField
            label="Company Name"
            fullWidth
            required
            name="companyName"
            value={data.companyName}
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={12} className="p175">
          <TextField
            label="Attention To"
            fullWidth
            name="attentionTo"
            value={data.attentionTo}
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={6} className="p175">
          <InputLabel className={classes.percLabel}>Percentage (%)</InputLabel>
          <div className={classes.percWrapper}>
            <InputBase className="flex-grow" name="percentage" value={data.percentage} onChange={_handleChange} />
          </div>
        </Grid>
        <Grid item xs={6} className="p175">
          <CommonSelect
            fullWidth
            required
            name="percentageShare"
            label="Percentage Share"
            options={percentageShareOptions}
            value={data.percentageShare}
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={12} className="p175">
          <TextField
            label="Address Line 1"
            fullWidth
            required
            name="address1"
            value={data.address1}
            onChange={_handleChange}
          />
        </Grid>
        <Grid item xs={12} className="p175">
          <TextField
            label="Address Line 2"
            fullWidth
            required
            name="address2"
            value={data.address2}
            onChange={_handleChange}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default InvoicePartyForm;
