import React, { useContext } from "react";
import TabPanel from "../../../components/TabPanel";
import {
  Grid,
  List,
  ListItem,
  Typography,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import CommonAccordion from "../../../components/CommonAccordion";
import SimpleBox from "../../../components/SimpleBox";
import { getDefaultCaseObj } from "../../../model";
import { DataContext } from "../../../DataContext";
import { PersonRounded } from "@material-ui/icons";
import LabelValueDetail from "../../../components/LabelValueDetail";
import { formatDecimal } from "../../../utils/functions";
import ClientSection from "../ClientSection";
import CaseHeaderSection from "../CaseHeaderSection";
import PointOfContact from "../../../components/PointOfContact";

const DetailsTabPanel = ({
  data = getDefaultCaseObj(),
  clientInfo,
  ...others
}) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const { staffList, caseCatList, rateList } = useContext(DataContext);

  const getNameById = (list = [], id, idKey) => {
    const item = list.find((el) => el[idKey] === id);
    return list.length && item ? item.value : "";
  };

  return (
    <TabPanel className="DetailsTabPanel" {...others}>
      <CaseHeaderSection
        status={data.status}
        openDate={data.openDate}
        closedDate={data.closeDate}
      />
      <List className="fullWidth">
        <CommonAccordion title="Info">
          <Grid container>
            <LabelValueDetail
              label="Case Ref#"
              value={data.caseRef}
              valueClassName="e360-id"
              valueColor="secondary"
              valueStyle={{ marginLeft: "8px" }}
              justifyClassName="justify-content-start"
              hideUnderline
            />
            <LabelValueDetail
              label="Court Case No."
              value={data.courtCaseNo || "N/A"}
              valueStyle={{
                marginLeft: "8px",
                color: data.courtCaseNo
                  ? theme.palette.primary.main
                  : theme.palette.action.disabled,
              }}
              justifyClassName="justify-content-start"
              isNumber
              hideUnderline
            />
            <LabelValueDetail
              label="Case Name :"
              value={data.name}
              valueStyle={{ paddingLeft: "8px" }}
              alignClassName="align-items-start"
              md={12}
              isCol
            />
            <LabelValueDetail
              label="Case Category"
              value={
                data.caseCat &&
                caseCatList.length &&
                caseCatList.find((cat) => cat.id === data.caseCat).value
              }
            />
            <LabelValueDetail label="Created By" value={data.createdBy || ""} />
            <Grid item container xs={12}>
              <LabelValueDetail
                label="Agreed Fee"
                value={"$" + formatDecimal(data.agreedFee)}
                isNumber
              />
              <Grid
                item
                xs={12}
                md={6}
                style={{ padding: desktop ? "14px" : "14px 0" }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    padding: desktop ? "8px 16px" : "8px",
                  }}
                >
                  Permissions:{" "}
                  <Typography
                    variant="body2"
                    color="primary"
                    component="span"
                    style={{ textTransform: "uppercase" }}
                  >
                    {data.permission}
                  </Typography>
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    padding: desktop ? "8px 16px" : "8px",
                  }}
                >
                  Rate Type:{" "}
                  <Typography
                    variant="body2"
                    color="primary"
                    component="span"
                    style={{ textTransform: "uppercase" }}
                  >
                    {rateList.find((el) => el.id === data.rate)?.value}
                  </Typography>
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    padding: desktop ? "8px 16px" : "8px",
                  }}
                >
                  {`Matter is ${data.isBillable ? "" : "not "}billable`}
                </Typography>
              </Grid>
            </Grid>
            {data.desc && data.desc.trim() && (
              <Grid item xs={12} md={12}>
                <ListItem>
                  <div className="justify-content-between align-items-center fullWidth">
                    <SimpleBox>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className="descTitle"
                      >
                        Case Description
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="p175"
                        style={{ paddingTop: 0 }}
                      >
                        {data.desc.trim()}
                      </Typography>
                    </SimpleBox>
                  </div>
                </ListItem>
              </Grid>
            )}
          </Grid>
        </CommonAccordion>

        <CommonAccordion title="Client">
          <ClientSection clientInfo={clientInfo} showDetail />
        </CommonAccordion>

        <CommonAccordion title="Lawyer">
          <Grid container>
            <LabelValueDetail
              label="Responsible Lawyer"
              value={data?.respAtt?.value}
            />
            <LabelValueDetail
              label="Originating Lawyer"
              value={data?.origAtt?.value}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} style={{ marginTop: "24px" }}>
              <Typography
                variant="body2"
                color="textSecondary"
                className="segLabel"
                align="center"
                noWrap
              >
                Additional Lawyer
              </Typography>
              <Box p={2} style={{ paddingBottom: 0 }}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  display="block"
                >
                  List of additional lawyers:
                </Typography>
                {data.additionalLawyers && data.additionalLawyers.length > 0 ? (
                  <List>
                    {data.additionalLawyers.map((l) => (
                      <ListItem key={l.personSid}>
                        <ListItemAvatar>
                          <Avatar>
                            <PersonRounded />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={getNameById(staffList, l.personSid, "id")}
                          secondary={"$" + l.customRate + " per hour"}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ fontStyle: "italic", margin: "14px" }}
                  >
                    - No Data
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginTop: "24px" }}>
              <Typography
                variant="body2"
                color="textSecondary"
                className="segLabel"
                align="center"
                noWrap
              >
                Secretary in Charge
              </Typography>
              <Box p={2} style={{ paddingBottom: 0 }}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  display="block"
                >
                  List of secretaries in charge:
                </Typography>
                {data.secretariesInCharge &&
                data.secretariesInCharge.length > 0 ? (
                  <List>
                    {data.secretariesInCharge.map((l) => (
                      <ListItem key={l.personSid}>
                        <ListItemAvatar>
                          <Avatar>
                            <PersonRounded />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={l.firstName + " " + l.lastName}
                          secondary={
                            l.primary ? "Lead Secretary" : "Backup Secretary"
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ fontStyle: "italic", margin: "14px" }}
                  >
                    - No Data
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </CommonAccordion>

        <CommonAccordion title="Point of Contact (POC)">
          {data.isPocDefault ? (
            <ListItem>
              <Typography variant="body1" color="textSecondary">
                POC is the same as client's default contact info.
              </Typography>
            </ListItem>
          ) : (
            <div className="d-flex">
              <PointOfContact
                fullName={`${data.firstName} ${data.lastName}`}
                email={data.email}
                phone={data.mobile}
                role={data.role}
              />
            </div>
          )}
        </CommonAccordion>
      </List>
    </TabPanel>
  );
};

export { DetailsTabPanel };
