import React, { useState } from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";
import LinkButton from "../../components/LinkButton";
import CommonAccordion from "../../components/CommonAccordion";
import {
  Grid,
  TextField,
  makeStyles,
  InputLabel,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
  Typography,
  Collapse,
  Button,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  FormHelperText,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  AddRounded,
  CloseRounded,
  EditRounded,
  PersonRounded,
} from "@material-ui/icons";
import CustomModal from "../../components/CustomModal";
import AddCategoryForm from "./AddCategoryForm";
import CommonSelect from "../../components/CommonSelect";
import { useContext } from "react";
import { DataContext, SettingContext } from "../../DataContext";
import { getDefaultCaseObj } from "../../model";
import ComplexSelect from "../../components/ComplexSelect";
import { formatDateDash } from "../../utils/dateUtils";
import Axios from "axios";
import { MasterTypeCats } from "../../utils/constants";
import { getError } from "../../utils/functions";
import ClientSection from "./ClientSection";

const useStyles = makeStyles((theme) => ({
  mr: {
    marginRight: theme.spacing(3),
  },
  notes: {
    width: "100%",
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    border: "1px solid",
    borderColor: theme.palette.divider,
    fontFamily: theme.typography.fontFamily,
    fontSize: "14px",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    outline: "none",
    appearance: "none",
  },
  listRound: {
    borderRadius: "5px",
    overflow: "hidden",
    padding: 0,
    margin: "8px 0",
  },
  hover: {
    backgroundColor: theme.palette.action.hover,
  },
  helperText: {
    paddingLeft: "18px",
    fontStyle: "italic",
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  caseRef: {
    marginBottom: "6px",
    "& > span": {
      marginLeft: "8px",
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
  borderLeft: {
    [theme.breakpoints.up("md")]: {
      borderLeft: "1px solid rgba(128, 128, 128, 0.2)",
    },
  },
}));

const CaseForm = ({
  className,
  data = getDefaultCaseObj(),
  error,
  handleChange = () => {},
  handleChangeByKey = () => {},
  setError = () => {},
  onBlur = () => {},
  addLawyer,
  removeLawyer,
  addedLawyers,
  cusLawyer,
  setCusLawyer,
  cusRate,
  setCusRate,
  clientInfo,
  loadingClient,
  addSecretary,
  removeSecretary,
  addedSecretaries,
  secretary,
  setSecretary,
}) => {
  const classes = useStyles();
  const {
    staffList,
    clientList,
    caseCatList,
    setCaseCatList,
    statusList,
    permissionList,
    rateList,
    alertError,
    alertSuccess,
  } = useContext(DataContext);
  const { setProcessing } = useContext(SettingContext);
  const [openAddCaseCategory, setOpenAddCaseCategory] = useState(false);
  const [caseCatInput, setCaseCatInput] = useState("");

  const saveCaseCat = () => {
    if (caseCatInput && caseCatInput.trim()) {
      setProcessing(true);
      Axios.post("/master-type", {
        category: MasterTypeCats.CASE_CATEGORY,
        shortDesc: caseCatInput.trim(),
        longDesc: caseCatInput.trim(),
      })
        .then((res) => {
          const mt = res.data;
          ReactDOM.unstable_batchedUpdates(() => {
            setProcessing(false);
            setCaseCatList((prev) => [
              { id: mt.masterTypeSid, value: mt.typeShortDescription },
              ...prev,
            ]);
            setOpenAddCaseCategory(false);
            alertSuccess("Success! New case category successfully added.");
            handleChangeByKey("caseCat", mt.masterTypeSid);
            setError((err) => ({ ...err, caseCat: false }));
          });
        })
        .catch((err) => {
          ReactDOM.unstable_batchedUpdates(() => {
            setProcessing(false);
            alertError(getError(err, "Failed to add new case category"));
          });
        });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form
        className={clsx("CaseForm", className)}
        style={{ paddingBottom: "16px" }}
      >
        <CommonAccordion title="Info">
          <Grid container>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              item
              xs={12}
              md={6}
              className="p175"
            >
              <Typography
                className={classes.caseRef}
                variant="body1"
                color="textSecondary"
                noWrap
              >
                Case Ref#
                {data.caseRef ? (
                  <span className={clsx("e360-id", classes.secondary)}>
                    {data.caseRef}
                  </span>
                ) : (
                  <span className={clsx("e360-id", classes.disabled)}>N/A</span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <TextField
                label="Court Case No."
                name="courtCaseNo"
                value={data.courtCaseNo}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} className="p175">
              <TextField
                label="Case Name"
                name="name"
                value={data.name}
                onChange={handleChange}
                error={error.name}
                onBlur={onBlur}
                fullWidth
                required
              />
            </Grid>
          </Grid>

          <Grid container alignItems="flex-end">
            <Grid item xs={12} md={6} className="p175">
              <CommonSelect
                options={caseCatList}
                label="Select Case Category"
                name="caseCat"
                fullWidth
                required
                value={data.caseCat}
                onChange={handleChange}
                error={error.caseCat}
                onBlur={onBlur}
              />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <div className="d-flex">
                <LinkButton
                  className={classes.mr}
                  onClick={() => setOpenAddCaseCategory(true)}
                >
                  Add Category
                </LinkButton>
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6} md={4} className="p175">
              <FormControl component="fieldset">
                <FormLabel component="legend">Permission</FormLabel>
                <RadioGroup
                  aria-label="permission"
                  name="permission"
                  value={data.permission}
                  onChange={handleChange}
                >
                  {permissionList.map((el) => (
                    <FormControlLabel
                      key={el.id}
                      value={el.id}
                      control={<Radio size="small" color="primary" />}
                      label={
                        <Typography variant="body2" color="textSecondary">
                          {el.value}
                        </Typography>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4} className="p175">
              <FormControl component="fieldset">
                <FormLabel component="legend">Rate Type</FormLabel>
                <RadioGroup
                  aria-label="Rate Type"
                  name="rate"
                  value={data.rate}
                  onChange={handleChange}
                >
                  {rateList.map((el) => (
                    <FormControlLabel
                      key={el.id}
                      value={el.id}
                      control={<Radio size="small" color="primary" />}
                      label={
                        <Typography variant="body2" color="textSecondary">
                          {el.value}
                        </Typography>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="p175">
              <TextField
                fullWidth
                label="Agreed Fee"
                name="agreedFee"
                onChange={handleChange}
                value={data.agreedFee || ""}
                error={isNaN(data.agreedFee) || +data.agreedFee < 0}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="isBillable"
                    color="primary"
                    checked={data.isBillable}
                    onChange={() =>
                      handleChangeByKey("isBillable", !data.isBillable)
                    }
                  />
                }
                style={{ marginTop: "10px" }}
                label={
                  <Typography variant="body2" color="textSecondary">
                    Matter is billable
                  </Typography>
                }
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} className="p175">
              <InputLabel id="lblDesc" htmlFor="desc">
                Case Description :
              </InputLabel>
              <TextareaAutosize
                id="desc"
                name="desc"
                minRows={3}
                className={classes.notes}
                value={data.desc}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </CommonAccordion>

        <CommonAccordion title="Client">
          <Grid container alignItems="flex-end">
            <Grid item xs={12} md={6} className="p175">
              <ComplexSelect
                options={clientList.map((el) => ({
                  id: el.id,
                  value: el.value,
                  active: el.active,
                }))}
                required
                fullWidth
                label="Select Client"
                name="client"
                onChange={handleChange}
                onBlur={onBlur}
                value={data.client}
                error={error.client}
              />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              {loadingClient ? (
                <CircularProgress size={24} disableShrink />
              ) : data.client &&
                data.client.id &&
                clientInfo &&
                clientInfo.clientSid ? (
                <Typography variant="body2" color="secondary" noWrap>
                  {clientInfo.clientId}
                </Typography>
              ) : null}
            </Grid>
          </Grid>

          {data.client &&
            data.client.id &&
            clientInfo &&
            clientInfo.clientSid && <ClientSection clientInfo={clientInfo} />}
        </CommonAccordion>

        <CommonAccordion title="Lawyer">
          <Grid container>
            <Grid item xs={12} md={6} className="p175">
              <ComplexSelect
                options={staffList && staffList.filter((el) => el.rate !== 0)}
                required
                fullWidth
                label="Responsible Lawyer"
                name="respAtt"
                onBlur={onBlur}
                onChange={handleChange}
                value={data.respAtt}
                error={error.respAtt}
              />
            </Grid>
            <Grid item xs={12} md={6} className="p175">
              <ComplexSelect
                options={staffList && staffList.filter((el) => el.rate !== 0)}
                required
                fullWidth
                label="Originating Lawyer"
                name="origAtt"
                onChange={handleChange}
                value={data.origAtt}
                onBlur={onBlur}
                error={error.origAtt}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              className="p175"
              style={{ marginTop: "24px" }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                className="segLabel"
                align="center"
                noWrap
              >
                Additional Lawyer
              </Typography>
              <Grid container alignItems="flex-end">
                <Grid item xs={12} className="py175">
                  <CommonSelect
                    label="Select Lawyer"
                    fullWidth
                    required
                    name="cusLawyer"
                    options={
                      staffList && staffList.filter((el) => el.rate !== 0)
                    }
                    value={cusLawyer}
                    onChange={(e) => {
                      setCusLawyer(e.target.value);
                      setCusRate(
                        staffList &&
                          staffList.find((el) => el.id === e.target.value).rate
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-end"
                wrap="nowrap"
              >
                <Grid item className="pb175 pr175 flex-grow">
                  <TextField
                    label="Custom Hourly Rate"
                    required
                    fullWidth
                    name="cusRate"
                    value={cusRate}
                    onChange={(e) => {
                      setCusRate(e.target.value);
                    }}
                    error={isNaN(cusRate) || +cusRate < 0}
                  />
                </Grid>
                <Grid item className="py175">
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<AddRounded />}
                    disableElevation
                    style={{ paddingRight: "16px", whiteSpace: "nowrap" }}
                    onClick={addLawyer}
                    disabled={
                      !(cusLawyer && (cusRate === 0 || cusRate)) ||
                      isNaN(cusRate) ||
                      +cusRate <= 0
                    }
                  >
                    {addedLawyers.find((el) => el.personSid === cusLawyer)
                      ? "Override"
                      : "Add Lawyer"}
                  </Button>
                </Grid>
              </Grid>
              <div className="pt175" style={{ marginTop: "24px" }}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  display="block"
                >
                  List of additional lawyers:
                </Typography>
              </div>
              {addedLawyers && addedLawyers.length > 0 ? (
                <Grid container>
                  <Grid item xs={12}>
                    <Box px={2} pt={2}>
                      <List className={classes.listRound}>
                        {addedLawyers.map((l) => (
                          <ListItem key={l.personSid} className={classes.hover}>
                            <ListItemAvatar>
                              <Avatar>
                                <PersonRounded />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                staffList &&
                                staffList.find((el) => el.id === l.personSid)
                                  ?.value
                              }
                              secondary={"$" + l.customRate + " per hour"}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                size="small"
                                style={{ marginRight: "4px" }}
                                onClick={() => {
                                  setCusLawyer(l.personSid);
                                  setCusRate(l.customRate);
                                }}
                              >
                                <EditRounded color="primary" fontSize="small" />
                              </IconButton>
                              <IconButton
                                edge="end"
                                aria-label="remove"
                                size="small"
                                onClick={() => removeLawyer(l.personSid)}
                              >
                                <CloseRounded color="error" />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Grid>
                  {data.caseSid && (
                    <Grid item xs={12}>
                      <FormHelperText className={classes.helperText}>
                        * Adding, overriding or removing additional lawyer will
                        be saved immediately.
                      </FormHelperText>
                      <FormHelperText className={classes.helperText}>
                        * Changing additional lawyer or custom hourly rate will
                        cause a re-calculation of existing{" "}
                        <strong>uninvoiced time entries</strong> and{" "}
                        <strong>
                          time entries that are included in draft invoice
                        </strong>
                        . The affected draft invoices will also be
                        re-calculated.
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontStyle: "italic", margin: "14px" }}
                >
                  - No Data
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className={clsx("p175", classes.borderLeft)}
              style={{ marginTop: "24px" }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                className="segLabel"
                align="center"
                noWrap
              >
                Secretary in Charge
              </Typography>
              <Grid container alignItems="flex-end">
                <Grid item xs={12} className="py175">
                  <CommonSelect
                    label="Select Secretary"
                    fullWidth
                    required
                    name="secretary"
                    options={
                      staffList &&
                      staffList.filter(
                        (el) =>
                          !addedSecretaries
                            .map((s) => s.personSid)
                            .includes(el.id)
                      )
                    }
                    value={secretary}
                    onChange={(e) => setSecretary(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-end"
                wrap="nowrap"
              >
                <Grid item className="py175">
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<AddRounded />}
                    disableElevation
                    style={{ paddingRight: "16px", whiteSpace: "nowrap" }}
                    onClick={addSecretary}
                    disabled={!secretary}
                  >
                    Add Secretary
                  </Button>
                </Grid>
              </Grid>
              <div className="pt175" style={{ marginTop: "24px" }}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  display="block"
                >
                  List of secretaries in charge:
                </Typography>
              </div>
              {addedSecretaries && addedSecretaries.length > 0 ? (
                <Grid container>
                  <Grid item xs={12}>
                    <Box px={2} pt={2}>
                      <List className={classes.listRound}>
                        {addedSecretaries.map((l) => (
                          <ListItem key={l.personSid} className={classes.hover}>
                            <ListItemAvatar>
                              <Avatar>
                                <PersonRounded />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                staffList &&
                                staffList.find((el) => el.id === l.personSid)
                                  ?.value
                              }
                              secondary={
                                l.primary
                                  ? "Lead Secretary"
                                  : "Backup Secretary"
                              }
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="remove"
                                size="small"
                                onClick={() => removeSecretary(l.personSid)}
                              >
                                <CloseRounded color="error" />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Grid>
                  {data.caseSid && (
                    <Grid item xs={12}>
                      <FormHelperText className={classes.helperText}>
                        * Adding or removing secretary in charge will be saved
                        immediately.
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ fontStyle: "italic", margin: "14px" }}
                >
                  - No Data
                </Typography>
              )}
            </Grid>
          </Grid>
        </CommonAccordion>

        <CommonAccordion title="Status">
          <Grid container alignItems="flex-end">
            <Grid item xs={12} md={2} className="p175">
              <CommonSelect
                options={statusList}
                name="status"
                label="Status"
                value={data.status}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={5} className="p175">
              <KeyboardDatePicker
                fullWidth
                margin="none"
                format="dd-MMM-yyyy"
                label="Open Date"
                name="openDate"
                value={new Date(data.openDate)}
                onChange={(v) =>
                  handleChangeByKey("openDate", formatDateDash(v))
                }
                disableFuture
              />
            </Grid>

            {data.status === "close" && (
              <Grid item xs={12} md={5} className="p175">
                <KeyboardDatePicker
                  fullWidth
                  margin="none"
                  format="dd-MMM-yyyy"
                  label="Close Date"
                  name="closeDate"
                  value={data.closeDate ? new Date(data.closeDate) : null}
                  onChange={(v) =>
                    handleChangeByKey("closeDate", formatDateDash(v))
                  }
                />
              </Grid>
            )}
          </Grid>
        </CommonAccordion>

        <CommonAccordion title="Point of Contact">
          <FormControlLabel
            className={classes.textSecondary}
            style={{ paddingLeft: "14px" }}
            control={
              <Checkbox
                name="isPocDefault"
                color="primary"
                checked={data.isPocDefault}
                onChange={() =>
                  handleChangeByKey("isPocDefault", !data.isPocDefault)
                }
              />
            }
            label="Use client's default Point of Contact"
          />
          <Collapse in={!data.isPocDefault}>
            <Grid container>
              <Grid item xs={12} md={6} className="p175">
                <TextField
                  label="First Name"
                  name="firstName"
                  value={data.firstName}
                  onChange={handleChange}
                  error={error.firstName}
                  onBlur={onBlur}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} className="p175">
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={data.lastName}
                  onChange={handleChange}
                  error={error.lastName}
                  onBlur={onBlur}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} className="p175">
                <TextField
                  label="Mobile"
                  name="mobile"
                  value={data.mobile}
                  onChange={handleChange}
                  error={error.mobile}
                  onBlur={onBlur}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} className="p175">
                <TextField
                  label="Email"
                  type="email"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                  error={error.email}
                  onBlur={onBlur}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} className="p175">
                <TextField
                  label="Role"
                  name="role"
                  value={data.role}
                  onChange={handleChange}
                  error={error.role}
                  onBlur={onBlur}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Collapse>
        </CommonAccordion>
      </form>
      <CustomModal
        maxWidth="xs"
        isOpen={openAddCaseCategory}
        title="Add Case Category"
        saveBtnTitle="Save"
        handleClose={() => setOpenAddCaseCategory(false)}
        handleSave={saveCaseCat}
      >
        <AddCategoryForm
          caseCategoryName={caseCatInput}
          setCaseCategoryName={setCaseCatInput}
        />
      </CustomModal>
    </MuiPickersUtilsProvider>
  );
};

export default CaseForm;
