import React from "react";
import QteModal from "./QteModal";
import QeeModal from "./QeeModal";
import TimerModal from "./TimerModal";
import ConfirmDialog from "./ConfirmDialog";
import AuditHistoryPopup from "./AuditHistoryPopup";
import ChangePasswordModal from "./ChangePasswordModal";
import BulkQteModal from "./BulkQteModal";
import CaseModal from "./CaseModal";
import SplitInvoiceModal from "./SplitInvoiceModal";

const AllModals = () => {
  return (
    <>
      <CaseModal />
      <TimerModal />
      <QteModal />
      <QeeModal />
      <BulkQteModal />
      <SplitInvoiceModal />
      <ConfirmDialog />
      <AuditHistoryPopup />
      <ChangePasswordModal />
    </>
  );
};

export default AllModals;
