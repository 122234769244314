import React from "react";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

const LabelValueSummary = ({
  label,
  value,
  className,
  split = "default",
  align = "center",
  labelColor,
  valueColor,
}) => {
  const labelStyle = {};
  const valueStyle = {};
  if (split === "halves") {
    labelStyle.width = "50%";
    valueStyle.width = "50%";
  }
  if (labelColor) labelStyle.color = labelColor;
  if (valueColor) valueStyle.color = valueColor;

  return (
    <div
      className={clsx(
        "LabelValueSummary d-flex flex-nowrap",
        split === "default" && "justify-content-between",
        `align-items-${align}`,
        className
      )}
      style={{ padding: "8px" }}
    >
      <Typography variant="body2" color="textSecondary" style={labelStyle}>
        {label}
      </Typography>
      <Typography variant="body2" color="textSecondary" style={valueStyle}>
        {value}
      </Typography>
    </div>
  );
};

export default LabelValueSummary;
