import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./CommonPage";

const SimplePage = ({ className, pageTitle, Buttons, children }) => {
  const classes = useStyles();
  return (
    <div
      className={clsx("SimplePage", classes.limitHeight, className)}
      style={{
        position: "relative",
      }}
    >
      <Grid container className={classes.limitHeight}>
        <Grid item xs={12} className={classes.limitHeight}>
          <Paper
            elevation={0}
            className={clsx(classes.limitHeight, "resp-rounded")}
            style={{ padding: "16px 12px" }}
          >
            <div
              className={clsx(
                classes.limitHeight,
                "d-flex flex-col align-items-stretch"
              )}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                style={{ paddingBottom: "8px" }}
              >
                <Grid
                  container
                  item
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Typography variant="h6" color="textSecondary">
                      {pageTitle}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}
                  xs={5}
                  wrap="nowrap"
                >
                  {Buttons}
                </Grid>
              </Grid>
              <div
                style={{
                  flexGrow: "1 1 auto",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {children}
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default SimplePage;
