import { emailRegex, brnRegex1, brnRegex2, brnRegex3 } from "./constants";

export const isEmail = (email) => {
  return emailRegex.test(String(email).toLowerCase());
};

export const isBrn = (brn) => {
  return (
    brnRegex1.test(String(brn)) ||
    brnRegex2.test(String(brn)) ||
    brnRegex3.test(String(brn))
  );
};

export const getDefaultErrorObj = (obj) => {
  Object.keys(obj).forEach((k) => (obj[k] = false));
  return obj;
};

export const hasError = (err, ...excludes) => {
  for (const k in err) {
    if (err[k] && !excludes.includes(k)) return true;
  }
  return false;
};

export const hasErrorOnlyOn = (err, ...keys) => {
  for (const k of keys) {
    if (err.hasOwnProperty(k) && err[k]) return true;
  }
  return false;
};

export const hasMissingData = (data, ...excludes) => {
  for (const k in data) {
    if (!data[k] && !excludes.includes(k)) {
      return true;
    }
  }
  return false;
};

export const hasMissingDataOnlyOn = (data, ...keys) => {
  for (const k of keys) {
    if (data.hasOwnProperty(k) && !data[k]) return true;
  }
  return false;
};

export const padZero = (num, numOfDigits = 0, frac = 0) =>
  num.toLocaleString("us", {
    minimumIntegerDigits: numOfDigits,
    minimumFractionDigits: frac,
    useGrouping: false,
  });

export const formatDecimal = (num, numOfDecimal = 2) =>
  (+num).toLocaleString("us", {
    useGrouping: true,
    minimumFractionDigits: numOfDecimal,
    maximumFractionDigits: numOfDecimal,
  });

export const calcTimeInterval = (start, finish) => {
  if (finish > start) {
    const diff = parseInt((finish - start) / 1000);
    let ss = diff % 60;
    let mm = parseInt((diff / 60) % 60);
    let hh = parseInt(diff / 3600);
    return padZero(hh, 2) + ":" + padZero(mm, 2) + ":" + padZero(ss, 2);
  } else {
    return "00:00:00";
  }
};

export const groupBy = (list = [], key = "") => {
  return list.reduce((r, a) => {
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create(null));
};

export const getInitials = (firstName = "", lastName = "") => {
  return (
    (firstName ? firstName[0].toUpperCase() : "") +
    (lastName ? lastName[0].toUpperCase() : "")
  );
};

export const getError = (err, defMsg = "Error! Please try again.") => {
  if (err && err.response && err.response.data) {
    if (err.response.data.message) {
      return err.response.data.message === "No message available"
        ? defMsg
        : err.response.data.message;
    } else if (err.response.data.error) {
      return "Error! " + err.response.data.error;
    } else {
      return defMsg;
    }
  } else {
    return defMsg;
  }
};
