import { formatDateDash } from "../utils/dateUtils";

export const getDefaultClientObj = () => ({
  id: 0,
  clientId: "",
  clientSid: "",
  businessRegistrationNumber: "",
  idCardNumber: "",
  clientType: "company",
  taxRegistered: "",
  dateOfBirth: formatDateDash(new Date()),
  clientCreatedDate: formatDateDash(new Date()),
  isClientCompany: true,
  salutation: null, // CommonDto
  firstName: "",
  lastName: "",
  companyName: "",
  email: "",
  mobile: "",
  phone: "",
  fax: "",
  createdBy: null,
  referredBy: null,
  note: "",
  postalAddressOne: "",
  postalAddressTwo: "",
  postalCity: "",
  postalState: "",
  postalPostalCode: "",
  postalCountry: null,
  isBillingSameAsPostal: true,
  billingAddressOne: "",
  billingAddressTwo: "",
  billingCity: "",
  billingState: "",
  billingPostalCode: "",
  billingCountry: null,
  paymentTerm: null,
  billingTerm: null,
  yearlyInterestRate: 0,
  activeCases: 0,
  closedCases: 0,
  active: true,
});

export const getDefaultClientSummaryObj = () => ({
  awaitingPayment: 0,
  totalInvoiced: 0,
  totalReceived: 0,
  totalWrittenOff: 0,
  totalUnbilled: 0,
  totalUnbilledFee: 0,
  totalBilledFee: 0,
  totalBillableFee: 0,
  totalNonBillableFee: 0,
  totalBillableHour: 0,
  totalNonBillableHour: 0,
  totalBillableMinute: 0,
  totalNonBillableMinute: 0,
  totalUnbilledExpense: 0,
  totalBilledExpense: 0,
  totalExpense: 0,
});
