import React from "react";
import SimpleBox from "../components/SimpleBox";
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Divider,
} from "@material-ui/core";
import { formatDecimal } from "../utils/functions";
import DebitCreditHeaderSection from "../pages/cases/DebitCreditHeaderSection";
import { useQuickEntryTableStyles } from "../theme/quickEntryTableStyles";

const useStyles = makeStyles((theme) => ({
  p175: {
    padding: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1.75),
      paddingBottom: theme.spacing(1.75),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  p125: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(1.75),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

const DebitCreditDetail = ({ debitCredit }) => {
  const classes = useStyles();
  const tblCls = useQuickEntryTableStyles();

  return (
    <SimpleBox className="debitCreditDetail" title="">
      <DebitCreditHeaderSection debitCredit={debitCredit} />
      <Divider style={{ margin: "16px 0 12px 0" }} />
      {debitCredit && (
        <>
          <Grid container>
            {debitCredit &&
              debitCredit.debitCreditLines &&
              debitCredit.debitCreditLines.length !== 0 && (
                <Grid item xs={12} md={12} className={classes.p175}>
                  <TableContainer component={Paper}>
                    <Typography
                      className={classes.p175}
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                    >
                      {`Added ${
                        debitCredit?.type === "debit" ? "Debit" : "Credit"
                      } Lines`}
                    </Typography>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography variant="body2" color="primary">
                              Description
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" color="primary">
                              Amount
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={tblCls.tableBody}>
                        {debitCredit &&
                          debitCredit.debitCreditLines &&
                          debitCredit.debitCreditLines.map((line, i) => (
                            <TableRow key={line.debitCreditLineSid}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{line.description}</TableCell>
                              <TableCell>{line.amount}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
          </Grid>
          <Grid container justifyContent="flex-end" wrap="wrap-reverse">
            <Grid
              item
              xs={12}
              md={6}
              className={classes.p175}
              style={{ paddingBottom: 0 }}
            >
              <Grid container>
                <Grid item xs={7} className={classes.p175}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    Total Amount ($) :
                  </Typography>
                </Grid>
                <Grid item xs={5} className={classes.p175}>
                  <Typography
                    variant="body1"
                    color="primary"
                    align="right"
                    className="e360-number"
                    style={{ fontWeight: 600 }}
                    noWrap
                  >
                    {formatDecimal(debitCredit.totalAmount)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </SimpleBox>
  );
};

export default DebitCreditDetail;
