import React from "react";
import clsx from "clsx";
import { Link, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8
    }
  }
}));

const LinkButton = ({ className, underline = "none", children, ...others }) => {
  const classes = useStyles();
  return (
    <Link
      className={clsx("LinkButton", className, classes.root)}
      underline={underline}
      {...others}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
