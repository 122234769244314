import React from "react";
import {
  HistoryRounded,
  CreateRounded,
  DeleteRounded,
} from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PopupMenuButton from "../../components/PopupMenuButton";
import { useTableStyle } from "../../theme/tableStyles";

export default function CreditNoteTable({ list }) {
  const classes = useTableStyle();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>Case</TableCell>
            <TableCell>Staff</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Date</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {list &&
            list.map((row) => (
              <TableRow key={row.name} hover>
                <TableCell>{row.caseInfo}</TableCell>
                <TableCell>{row.createdBy}</TableCell>
                <TableCell>{row.type && row.type.toUpperCase()}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                <TableCell align="right">
                  <PopupMenuButton
                    size="small"
                    color="default"
                    isIconButton
                    menuItems={[
                      {
                        text: "View Audit History",
                        icon: <HistoryRounded fontSize="small" />,
                        // handler: () => m && openAuditModal(m),
                      },
                      {
                        text: "Edit",
                        icon: <CreateRounded fontSize="small" />,
                        // handler: () => {
                        //   setIsFormOpen(true);
                        //   setMasterCodeType(mapMasterCodeType(m));
                        // },
                      },
                      {
                        text: "Delete",
                        icon: <DeleteRounded fontSize="small" />,
                        // handler: () => {
                        //   confirmDelete(mapMasterCodeType(m));
                        // },
                        // disabled: m.used,
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
