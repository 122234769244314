import React from "react";
import TabPanel from "../../../components/TabPanel";
import DisplayTimeEntry from "../../../components/DisplayTimeEntry";

export const TimeTabPanel = ({ selectedCaseSid, ...others }) => {
  return (
    <TabPanel className="TimeTabPanel" {...others}>
      <DisplayTimeEntry
        selectedCaseSid={selectedCaseSid}
        hideCaseCol
        hideClockedTimeCol
        tabIndex={others.tabIndex}
      />
    </TabPanel>
  );
};
