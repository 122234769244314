import React from "react";
import clsx from "clsx";
import { Grid, TextField, Typography } from "@material-ui/core";

const CompanyForm = ({
  className,
  data,
  error,
  handleChange = () => {},
  handleChangeByKey = () => {},
  setError = () => {},
  onBlur = () => {},
}) => {
  return (
    <form className={clsx("CompanyForm", className)}>
      <Grid container>
        <Grid item xs={12} className="p175">
          <TextField
            label="Company Name"
            fullWidth
            required
            name="companyName"
            value={data.companyName}
            error={error.companyName}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </Grid>
        <Grid item xs={12} className="p175">
          <TextField
            label="Business Registration Number"
            helperText={
              <>
                <Typography variant="caption" component="span">
                  9 digits and 1 capital letter (eg. 123456789A).
                </Typography>
                <br />
                <Typography variant="caption" component="span">
                  Or 8 digits and 1 capital letter (eg. 12345678A).
                </Typography>
                <br />
                <Typography variant="caption" component="span">
                  Or Full year, 4 digits and 1 capital letter (eg. 20005678A).
                </Typography>
                <br />
                <Typography variant="caption" component="span">
                  Or 1 capital letter, 2 digit year, 2 capital letters, 4 digits
                  and 1 capital letter (eg. S09LL0001B).
                </Typography>
              </>
            }
            fullWidth
            required
            error={error.businessRegistrationNumber}
            value={data.businessRegistrationNumber}
            name="businessRegistrationNumber"
            onChange={handleChange}
            onBlur={onBlur}
          />
        </Grid>
        <Grid item xs={12} className="p175">
          <TextField
            label="Tax Registered"
            fullWidth
            required
            value={data.taxRegistered}
            name="taxRegistered"
            onChange={handleChange}
            error={error.taxRegistered}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default CompanyForm;
