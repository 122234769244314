import React from "react";
import {
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemText,
  colors,
} from "@material-ui/core";
import clsx from "clsx";
import { Operations } from "../utils/constants";
import { ColorValues } from "../theme/colors";
import { Skeleton } from "@material-ui/lab";

const OperatorBadge = ({ children }) => {
  let color = "";
  switch (children) {
    case Operations.CREATE:
      color = ColorValues.GREEN;
      break;
    case Operations.UPDATE:
      color = ColorValues.LIGHTBLUE;
      break;
    case Operations.ARCHIVE:
      color = ColorValues.ORANGE;
      break;
    case Operations.UNARCHIVE:
      color = ColorValues.CYAN;
      break;
    case Operations.DELETE:
      color = ColorValues.RED;
      break;
    default:
      break;
  }
  const style = {
    display: "inline-block",
    borderRadius: "8px",
    padding: "4px 8px",
    fontSize: "12px",
    fontWeight: 600,
    letterSpacing: "1px",
    color: color && colors[color][500],
    backgroundColor: color && colors[color][500] + "25",
  };
  return (
    <span className="OperatorBadge" style={style}>
      {children}
    </span>
  );
};

const PrimaryListItemText = ({ history, ...others }) => {
  let domainType = history.domainType ? " the " + history.domainType : "";
  const domainName = history.domainName ? ` "${history.domainName}"` : "";
  return (
    <Typography variant="body2" color="textSecondary" {...others}>
      <span style={{ fontWeight: 600 }}>{history.operatorName}</span>{" "}
      {history.operation.toLowerCase() + "d"}
      {domainType}
      <span style={{ fontWeight: 600 }}>{domainName}</span>
    </Typography>
  );
};

const AuditHistory = ({ className, loading = false, historyList = [] }) => {
  const theme = useTheme();

  if (loading) {
    return (
      <List className={clsx("AuditHistory", className)} dense>
        {[...Array(3).keys()].map((i) => (
          <ListItem
            key={i}
            style={{
              borderBottomColor: "transparent",
              marginTop: "8px",
              marginBottom: "8px",
            }}
          >
            <div style={{ minWidth: "76px" }}>
              <Skeleton animation="wave" width="100%" height={32} />
            </div>
            <div style={{ flexGrow: 1, paddingLeft: "20px" }}>
              <Skeleton
                animation="wave"
                width="85%"
                height={12}
                style={{ marginBottom: "4px" }}
              />
              <Skeleton animation="wave" width="40%" height={12} />
            </div>
          </ListItem>
        ))}
      </List>
    );
  } else if (!historyList || historyList.length <= 0) {
    return (
      <Typography
        variant="body1"
        color="textSecondary"
        align="center"
        style={{
          padding: "16px",
          minWidth: "500px",
          borderTop: "1px solid rgba(136, 136, 136, 0.2)",
        }}
      >
        No Audit History
      </Typography>
    );
  }

  return (
    <List className={clsx("AuditHistory", className)} dense>
      {historyList.map((h, i) => (
        <ListItem key={i}>
          <div style={{ minWidth: "76px" }}>
            <OperatorBadge>{h.operation}</OperatorBadge>
          </div>

          <ListItemText
            style={{ paddingLeft: theme.spacing(2.5) }}
            primary={<PrimaryListItemText history={h} />}
            secondary={h.timestamp}
            secondaryTypographyProps={{
              style: { fontSize: "12px", letterSpacing: "1px" },
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default AuditHistory;
