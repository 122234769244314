import { Link, makeStyles, Typography } from "@material-ui/core";
import { ContactMailRounded } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
  poc: {
    width: "200px",
    padding: "14px",
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    "&> p": {
      padding: "4px 0",
      textAlign: "center",
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: "60px",
    opacity: "0.7",
    marginBottom: "8px",
  },
}));

const PointOfContact = ({ img, fullName, email, phone, role }) => {
  const classes = useStyles();
  return (
    <div className={classes.poc}>
      {img ? (
        img
      ) : (
        <div className="d-flex justify-content-center">
          <ContactMailRounded className={classes.icon} />
        </div>
      )}
      {fullName && (
        <Typography variant="body1" noWrap>
          {fullName}
        </Typography>
      )}
      {email && (
        <Typography variant="body2" color="textSecondary" noWrap>
          <Link href={`mailto:${email}`} color="textSecondary">
            {email}
          </Link>
        </Typography>
      )}
      {phone && (
        <Typography variant="body2" color="textSecondary" noWrap>
          <Link href={`tel:${phone}`} color="textSecondary">
            {phone}
          </Link>
        </Typography>
      )}
      {role && (
        <Typography variant="body2" color="textSecondary">
          {role}
        </Typography>
      )}
    </div>
  );
};

export default PointOfContact;
