import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useQuickEntryTableStyles } from "../../../theme/quickEntryTableStyles";
import clsx from "clsx";
import React from "react";
import PopupMenuButton from "../../../components/PopupMenuButton";
import { CreateRounded, DeleteRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  scrollableTbody: {
    display: "block",
    maxHeight: "calc(100vh - 240px)",
    overflowY: "auto",
    width: "100%",
    "& > tr": {
      display: "table",
      width: "100%",
    },
  },
  columnSizing: {
    "& > .MuiTableCell-root:nth-child(1)": {
      width: "20%",
    },
    "& > .MuiTableCell-root:nth-child(2)": {
      width: "45%",
    },
    "& > .MuiTableCell-root:nth-child(3)": {
      width: "25%",
    },
  },
}));

export const MasterCodeTypeTable = ({
  data = [],
  confirmDelete,
  setIsFormOpen,
  setMasterCodeType,
}) => {
  const classes = useQuickEntryTableStyles();
  const classesCus = useStyles();

  const mapMasterCodeType = (m) => {
    let masterCodeType = {};
    if (m.masterTypeSid) {
      masterCodeType.id = m.masterTypeSid;
      masterCodeType.type = "type";
    } else {
      masterCodeType.id = m.masterCodeSid;
      masterCodeType.type = "code";
    }
    masterCodeType.typeShortDescription = m.typeShortDescription;
    masterCodeType.typeLongDescription = m.typeLongDescription;
    masterCodeType.active = m.active;
    masterCodeType.category = m.category;
    return masterCodeType;
  };

  return (
    <>
      <TableContainer style={{ paddingTop: "16px" }}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow className={classesCus.columnSizing}>
              <TableCell>Short Description</TableCell>
              <TableCell>Long Description</TableCell>
              <TableCell>Category</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table>
          <TableBody
            className={clsx(
              classes.tableBodyMiddle,
              classesCus.scrollableTbody
            )}
          >
            {data && data.length > 0 ? (
              data.map((m, i) => (
                <TableRow key={i} hover className={classesCus.columnSizing}>
                  <TableCell
                    className={classes.number}
                    style={{ fontWeight: 600 }}
                  >
                    {m.typeShortDescription}
                  </TableCell>
                  <TableCell>{m.typeLongDescription}</TableCell>
                  <TableCell>{m.category}</TableCell>
                  <TableCell align="right">
                    <PopupMenuButton
                      size="small"
                      color="default"
                      isIconButton
                      menuItems={[
                        //   currentUser.roleType.roleTypeName ===
                        //     RoleTypes.MANAGER && {
                        //     text: "View Audit History",
                        //     icon: <HistoryRounded fontSize="small" />,
                        //     handler: () => m && openAuditModal(m),
                        //   },
                        {
                          text: "Edit",
                          icon: <CreateRounded fontSize="small" />,
                          handler: () => {
                            setIsFormOpen(true);
                            setMasterCodeType(mapMasterCodeType(m));
                          },
                        },
                        {
                          text: "Delete",
                          icon: <DeleteRounded fontSize="small" />,
                          handler: () => {
                            confirmDelete(mapMasterCodeType(m));
                          },
                          disabled: m.used,
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography color="error" align="center" variant="body2">
                    No Data
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
