import React, { useState, useContext, useEffect } from "react";
import { Grid, useTheme, useMediaQuery } from "@material-ui/core";
import {
  AddRounded,
  CreateRounded,
  ArchiveRounded,
  DeleteRounded,
  HistoryRounded,
} from "@material-ui/icons";
import PageSidebar from "../../components/PageSidebar";
import CustomModal from "../../components/CustomModal";
import CommonPage from "../CommonPage";
import TabPane from "../../components/TabPane";
import SummaryTabPanel from "./panels/SummaryTabPanel";
import PopupMenuButton from "../../components/PopupMenuButton";
import ClientForm from "./ClientForm";
import DetailsTabPanel from "./panels/DetailsTabPanel";
import Axios from "axios";

import { getDefaultClientObj } from "../../model";
import {
  getDefaultErrorObj,
  hasMissingData,
  hasError,
  isBrn,
  getError,
} from "../../utils/functions";
import { DataContext, PopupContext, SettingContext } from "../../DataContext";
import SlideUp from "../../components/SlideUp";
import { Modules, RoleTypes, SortColumns } from "../../utils/constants";

const ClientsPage = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, alertSuccess, alertWarning, alertError, ...others } =
    useContext(DataContext);
  const { openDialog, setAuditOptions } = useContext(PopupContext);
  const { clientSetting, setClientSetting } = useContext(SettingContext);
  const [clientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientTemp, setClientTemp] = useState(getDefaultClientObj());
  const [error, setError] = useState(getDefaultErrorObj(getDefaultClientObj()));
  const [client, setClient] = useState(clientList[0]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sorts, setSorts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const fetchClientListWithPagination = (
    page = clientSetting.currentPage,
    size = clientSetting.pageSize,
    showArchivedData = clientSetting.showArchivedData,
    q = searchTerm,
    sort = sorts
  ) => {
    setLoading(true);
    Axios.get(
      `/clients/paginate?page=${page}&size=${size}&showArchivedData=${showArchivedData}${
        q ? "&q=" + q : ""
      }${
        sort.length > 0
          ? "&sort=" + sort.map((s) => s.field + "-" + s.order).join(",")
          : ""
      }`
    )
      .then((res) => {
        setLoading(false);
        setClientList(res.data.content);
        setClientSetting((prev) => ({
          ...prev,
          totalPages: res.data.totalPages,
          currentPage: page,
        }));
      })
      .catch((err) => {
        setLoading(false);
        setClientList([]);
        setClientSetting((prev) => ({ ...prev, totalPages: 0 }));
      });
  };

  const openAuditModal = (clientSid) =>
    setAuditOptions({
      open: true,
      moduleType: Modules.CLIENT,
      sid: clientSid,
    });

  useEffect(() => {
    fetchClientListWithPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientSetting.currentPage,
    clientSetting.pageSize,
    clientSetting.showArchivedData,
    searchTerm,
    sorts,
  ]);

  const setSearchTermAndGotToFirstPage = (term) => {
    setSearchTerm(term);
    setClientSetting((prev) => ({ ...prev, currentPage: 1 }));
  };

  const openSaveModal = () => {
    setError(getDefaultErrorObj(getDefaultClientObj()));
    setClientTemp(getDefaultClientObj());
    setIsOpen(true);
  };
  const openEditModal = () => {
    setError(getDefaultErrorObj(getDefaultClientObj()));
    setClientTemp(mapToClientTempObj(client));
    setIsOpen(true);
  };

  const mapToClientTempObj = (client) => {
    let clientTemp = getDefaultClientObj();
    Object.keys(clientTemp).forEach((key) => {
      clientTemp[key] = client[key];
    });
    return clientTemp;
  };

  const handleChange = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    const value = event.target.value;
    if (name === "postalCountry") {
      setSelectedCountry(value);
    }
    setClientTemp({
      ...clientTemp,
      [name]: value,
    });
    setError({
      ...error,
      [name]:
        (event.target.validity && !event.target.validity.valid) ||
        (name === "businessRegistrationNumber" &&
          isBRNRequired() &&
          !isBrn(value)),
    });
  };

  const handleChangeByKey = (k, v) => {
    setClientTemp({
      ...clientTemp,
      [k]: v,
    });
  };

  const onBlur = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setError({
      ...error,
      [name]:
        (event.target.validity && !event.target.validity.valid) ||
        (name === "businessRegistrationNumber" &&
          isBRNRequired() &&
          !isBrn(event.target.value)),
    });
  };

  const isBRNRequired = () => selectedCountry?.value === "SG - Singapore";

  const isTaxRegisteredRequired = () => {
    return selectedCountry?.value === "SG - Singapore";
  };

  const saveOrEditClient = () => {
    try {
      let fieldsToIgnore = [
        "id",
        "clientId",
        "clientSid",
        "clientTypeSid",
        "isClientCompany",
        "fax",
        "phone",
        "note",
        "postalAddressTwo",
        "billingAddressTwo",
        "paymentTerm",
        "billingTerm",
        "yearlyInterestRate",
        "activeCases",
        "closedCases",
        "isBillingSameAsPostal",
        "active",
      ];

      if (!isBRNRequired() && !isTaxRegisteredRequired()) {
        fieldsToIgnore = [
          ...fieldsToIgnore,
          "businessRegistrationNumber",
          "taxRegistered",
        ];
      }

      if (clientTemp.clientType !== "company") {
        fieldsToIgnore = [
          ...fieldsToIgnore,
          "businessRegistrationNumber",
          "taxRegistered",
          "companyName",
        ];
      } else {
        fieldsToIgnore = [...fieldsToIgnore, "dateOfBirth", "idCardNumber"];
      }
      if (clientTemp.isBillingSameAsPostal) {
        fieldsToIgnore = [
          ...fieldsToIgnore,
          "billingAddressOne",
          "billingCity",
          "billingCountry",
          "billingPostalCode",
          "billingState",
        ];
      }

      if (hasMissingData(clientTemp, ...fieldsToIgnore)) {
        alertWarning("Warning! Missing data in required fields.");
      } else if (hasError(error, ...fieldsToIgnore)) {
        alertWarning("Invalid! Check your input data entered.");
      } else {
        setSelectedCountry(null);

        if (clientTemp.clientId) {
          // UPDATE
          clientTemp.isClientCompany = clientTemp.clientType === "company";
          Axios.put("/clients", clientTemp)
            .then((res) => {
              setIsOpen(false);
              alertSuccess("Successfully updated!");
              fetchClientListWithPagination();
              others.setClientList((prev) => {
                const newList = prev.filter(
                  (cl) => cl.id !== res.data.clientSid
                );
                const value =
                  res.data.clientType === "company"
                    ? res.data.companyName
                    : res.data.firstName + " " + res.data.lastName;
                // convert res into CommonDto
                const newClient = {
                  id: res.data.clientSid,
                  value,
                  active: res.data.active,
                };
                return [newClient, ...newList];
              });
              setClient(res.data);
            })
            .catch((err) => {
              alertError(getError(err, "Error! Unable to update."));
            });
        } else {
          // SAVE
          clientTemp.paymentTerm = clientTemp.paymentTerm
            ? clientTemp.paymentTerm
            : { id: "", value: "" };
          clientTemp.billingTerm = clientTemp.billingTerm
            ? clientTemp.billingTerm
            : { id: "", value: "" };
          clientTemp.isClientCompany = clientTemp.clientType === "company";
          Axios.post("/clients", clientTemp)
            .then((res) => {
              setIsOpen(false);
              alertSuccess("Successfully saved!");
              const value =
                res.data.clientType === "company"
                  ? res.data.companyName
                  : res.data.firstName + " " + res.data.lastName;
              // convert res into CommonDto
              const newClient = {
                id: res.data.clientSid,
                value,
                active: res.data.active,
              };
              others.setClientList((prev) => [newClient, ...prev]);
              fetchClientListWithPagination(1);
              setClient(res.data);
            })
            .catch((err) => {
              alertError(getError(err, "Error! Unable to save."));
            });
        }
      }
    } catch (error) {
      setSelectedCountry(null);
      alertError(`Error! Failed to ${clientTemp.id ? "update" : "save"}.`);
    }
  };

  const goDelete = () => {
    Axios.delete("/clients/" + client.clientSid)
      .then((res) => {
        others.setClientList((prev) =>
          prev.filter((cl) => cl.id !== client.clientSid)
        );
        fetchClientListWithPagination(1);
        setClient(null);
        alertSuccess("Successfully deleted!");
      })
      .catch((err) => {
        alertError(getError(err, "Error! Unable to delete."));
      });
  };

  const goToggleArchive = () => {
    const term = client.active ? "archive" : "unarchive";
    Axios.patch(`/clients/${term}/${client.clientSid}`)
      .then((res) => {
        others.setClientList((prev) => {
          const oldInd = prev.findIndex((cl) => cl.id === client.clientSid);
          const newClient = prev[oldInd];
          newClient.active = !newClient.active;
          prev.splice(oldInd, 1, newClient);
          return prev;
        });
        alertSuccess(`Successfully ${term}d!`);
        fetchClientListWithPagination();
        if (!client.active || clientSetting.showArchivedData) {
          setClient((prev) => ({ ...prev, active: !prev.active }));
        } else {
          setClient(null);
        }
      })
      .catch((err) => {
        alertError(getError(err, `Error! Unable to ${term}.`));
      });
  };

  const confirmDelete = () =>
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: "Delete",
      okBtnColor: "secondary",
      onOkClicked: goDelete,
      title: "Delete",
      message: `Are you sure you want to delete "${
        client.isClientCompany
          ? client.companyName
          : (client.salutation ? client.salutation.value + " " : "") +
            client.firstName +
            " " +
            client.lastName
      }"?`,
    }));

  const confirmToggleArchive = () => {
    const term = client.active ? "archive" : "unarchive";
    openDialog((prev) => ({
      ...prev,
      open: true,
      okBtnText: term,
      okBtnColor: client.active ? "secondary" : "primary",
      onOkClicked: goToggleArchive,
      title: term,
      message: `Are you sure you want to ${term} "${
        client.isClientCompany
          ? client.companyName
          : (client.salutation ? client.salutation.value + " " : "") +
            client.firstName +
            " " +
            client.lastName
      }"?`,
    }));
  };

  const closeClientFormModal = () => {
    setSelectedCountry(null);
    setIsOpen(false);
  };

  return (
    <>
      <CommonPage
        className="ClientPage"
        setSelectedData={setClient}
        archived={client && !client.active}
        detailTitle={
          client &&
          (client.isClientCompany
            ? client.companyName
            : (client.salutation ? client.salutation.value + " " : "") +
              client.firstName +
              " " +
              client.lastName)
        }
        PageSidebar={
          <PageSidebar
            loading={loading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTermAndGotToFirstPage}
            sortFields={SortColumns.CLIENT}
            defaultSortDesc="Sort clients by their last name in ascending order"
            setSorts={setSorts}
            searchInputPlaceholder="Search Client"
            listName="Active Clients"
            listData={clientList.map((c) => {
              return {
                active: c.active,
                id: c.clientSid,
                primary: c.isClientCompany
                  ? c.companyName
                  : (c.salutation ? c.salutation.value + " " : "") +
                    c.firstName +
                    " " +
                    c.lastName,
                secondary: c.createdBy ? c.createdBy.value : "",
                selected: client && c.clientSid === client.clientSid,
                onClick: () =>
                  setClient(
                    clientList.find((cl) => cl.clientSid === c.clientSid)
                  ),
                badges: [
                  {
                    value: c.openedCases,
                    color: theme.palette.success.main,
                    tooltip: c.openedCases + " Opened Cases",
                  },
                  {
                    value: c.closedCases,
                    color: theme.palette.grey[500],
                    tooltip: c.closedCases + " Closed Cases",
                  },
                ],
              };
            })}
            addClicked={openSaveModal}
            module={Modules.CLIENT}
          />
        }
        Buttons={
          <>
            <Grid item>
              <PopupMenuButton
                size="small"
                color="default"
                isIconButton
                menuItems={[
                  {
                    text: "Create New Client",
                    icon: <AddRounded fontSize="small" />,
                    handler: openSaveModal,
                  },
                  currentUser.roleType.roleTypeName === RoleTypes.MANAGER && {
                    text: "View Audit History",
                    icon: <HistoryRounded fontSize="small" />,
                    disabled: !client,
                    handler: () => client && openAuditModal(client.clientSid),
                  },
                  {
                    text: "Edit",
                    icon: <CreateRounded fontSize="small" />,
                    disabled: Boolean(!client || !client.active),
                    tooltip:
                      client &&
                      !client.active &&
                      "Archived client cannot be edited!",
                    handler: openEditModal,
                  },
                  {
                    text: client && !client.active ? "Unarchive" : "Archive",
                    icon: <ArchiveRounded fontSize="small" />,
                    disabled: !client,
                    handler: confirmToggleArchive,
                  },
                  {
                    text: "Delete",
                    icon: <DeleteRounded fontSize="small" />,
                    disabled: !client,
                    handler: confirmDelete,
                  },
                ]}
              />
            </Grid>
          </>
        }
      >
        {client && clientList.length > 0 && (
          <TabPane
            className="ClientsTabPane"
            tabTitles={[{ label: "Summary" }, { label: "Details" }]}
          >
            <SummaryTabPanel client={client} />
            <DetailsTabPanel client={client} />
          </TabPane>
        )}
      </CommonPage>
      <CustomModal
        TransitionComponent={SlideUp}
        fullScreen={fullScreen}
        maxWidth="md"
        isOpen={isOpen}
        title={clientTemp.clientId ? "Edit Client" : "New Client"}
        closeBtnTitle="Cancel"
        saveBtnTitle={clientTemp.clientId ? "Update" : "Save"}
        handleClose={closeClientFormModal}
        handleSave={saveOrEditClient}
      >
        <ClientForm
          data={clientTemp}
          handleChange={handleChange}
          handleChangeByKey={handleChangeByKey}
          error={error}
          setError={setError}
          onBlur={onBlur}
          isBRNRequired={isBRNRequired()}
          isTaxRegisteredRequired={isTaxRegisteredRequired()}
        />
      </CustomModal>
    </>
  );
};

export default ClientsPage;
