import React from "react";
import {
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core/";
import { useQuickEntryTableStyles } from "../../theme/quickEntryTableStyles";
import NoDataRow from "../../components/NoDataRow";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: theme.palette.action.hover,
  },
  number: {
    letterSpacing: "1px",
  },
  highlight: {
    color: "#f50057!important",
  },
}));

const ConflictTable = ({ conflictList }) => {
  const classes = useStyles();
  const tableStyles = useQuickEntryTableStyles();

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead className={tableStyles.tableHead}>
          <TableRow>
            <TableCell>Client Id</TableCell>
            <TableCell>Company Name</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Note</TableCell>
            <TableCell align="right">Opened Cases</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={tableStyles.tableBody}>
          {conflictList && conflictList.length > 0 ? (
            conflictList.map((el) => (
              <TableRow key={el.caseRef} hover>
                <TableCell>
                  <Typography
                    noWrap
                    color="primary"
                    style={{
                      letterSpacing: "1px",
                      fontSize: "0.875rem",
                    }}
                  >
                    {el.clientId}
                  </Typography>
                </TableCell>
                <TableCell className={el.conflictedField && el.conflictedField.indexOf("companyName")>-1?classes.highlight:""}>{el.companyName}</TableCell>
                <TableCell className={el.conflictedField && el.conflictedField.indexOf("firstName")>-1?classes.highlight:""}>{el.firstName}</TableCell>
                <TableCell className={el.conflictedField && el.conflictedField.indexOf("lastName")>-1?classes.highlight:""}>{el.lastName}</TableCell>
                <TableCell className={el.conflictedField && el.conflictedField.indexOf("email")>-1?classes.highlight:""}>{el.email}</TableCell>
                <TableCell>{el.note}</TableCell>
                <TableCell align="right" className={classes.number}>
                  {el.openedCases}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <NoDataRow colSpan={7} />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConflictTable;
