import React, { useContext, useState, useEffect } from "react";
import { PopupContext, DataContext, SettingContext } from "../DataContext";
import QuickTimeEntryForm from "../pages/cases/QuickTimeEntryForm";
import { getDefaultTimeExpObj, getDefaultTimerObj } from "../model";
import {
  getDefaultErrorObj,
  hasMissingDataOnlyOn,
  hasErrorOnlyOn,
  getError,
} from "../utils/functions";
import {
  Dialog,
  useTheme,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import SlideUp from "./SlideUp";
import Axios from "axios";

const TimerModal = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { openTimer, setOpenTimer } = useContext(PopupContext);
  const {
    currentUser,
    startTime,
    setStartTime,
    alertError,
    alertSuccess,
    alertWarning,
  } = useContext(DataContext);
  const { setRefetch, setProcessing } = useContext(SettingContext);

  const [timeExp, setTimeExp] = useState(
    getDefaultTimerObj(startTime, {
      id: currentUser.personSid,
      value: `${currentUser.firstName} ${currentUser.lastName}`,
    })
  );
  const [error, setError] = useState(
    getDefaultErrorObj(getDefaultTimeExpObj())
  );

  useEffect(() => {
    setError(getDefaultErrorObj(getDefaultTimeExpObj()));
    setTimeExp(
      getDefaultTimerObj(startTime, {
        id: currentUser.personSid,
        value: `${currentUser.firstName} ${currentUser.lastName}`,
      })
    );
  }, [currentUser, openTimer, startTime]);

  const handleChange = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setTimeExp({
      ...timeExp,
      [name]: event.target.value,
    });
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const handleChangeByKey = (k, v) => {
    setTimeExp({
      ...timeExp,
      [k]: v,
    });
  };

  const onBlur = (event) => {
    event.persist && event.persist();
    const name = event.target.name;
    setError({
      ...error,
      [name]: event.target.validity && !event.target.validity.valid,
    });
  };

  const goSave = () => {
    try {
      const { hours, minutes, billedHours, billedMinutes, amount, reason } =
        timeExp;

      let fieldsToChk = [
        "caseDto",
        "staff",
        "type",
        "date",
        "actExpCode",
        "desc",
      ];

      if ((+hours !== +billedHours || +minutes !== +billedMinutes) && !reason) {
        setError({ ...error, reason: true });
        alertWarning(
          "Specify the reason why clocked and billed times are different."
        );
      } else if (hasMissingDataOnlyOn(timeExp, ...fieldsToChk)) {
        alertWarning("Warning! Missing data in required fields.");
      } else if (hasErrorOnlyOn(error, ...fieldsToChk)) {
        alertWarning("Invalid! Check your input data entered.");
      } else {
        const timeExpToBeSaved = {
          ...timeExp,
          hours: isNaN(hours) ? 0 : Number(hours),
          minutes: isNaN(minutes) ? 0 : Number(minutes),
          amount: isNaN(amount) ? 0 : Number(amount),
          billedHours: isNaN(billedHours) ? 0 : Number(billedHours),
          billedMinutes: isNaN(billedMinutes) ? 0 : Number(billedMinutes),
        };

        setProcessing(true);
        Axios.post("/quick-entries", timeExpToBeSaved)
          .then((res) => {
            setOpenTimer(false);
            alertSuccess("Successfully saved!");
            setStartTime(0);
            setProcessing(false);
            setRefetch((prev) => !prev);
          })
          .catch((err) => {
            alertError(getError(err, "Error! Unable to save"));
            setProcessing(false);
          });
      }
    } catch (error) {
      alertError(`Error! Failed to save`);
    }
  };

  const goDiscard = () => {
    setOpenTimer(false);
    setStartTime(0);
  };

  const goCancel = () => setOpenTimer(false);

  return (
    <Dialog
      maxWidth="md"
      TransitionComponent={fullScreen ? SlideUp : undefined}
      fullScreen={fullScreen}
      open={openTimer}
      onClose={goCancel}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Typography variant="h6" component="span" color="primary">
          Save Timer
        </Typography>
      </DialogTitle>
      <DialogContent>
        <QuickTimeEntryForm
          data={timeExp}
          handleChange={handleChange}
          handleChangeByKey={handleChangeByKey}
          onBlur={onBlur}
          error={error}
          disableStaffSelection
          disableTime
          disableDate
          hideRateAndAmount
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={goCancel}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 500 }}
          >
            Cancel
          </Typography>
        </Button>
        <Button onClick={goDiscard} color="secondary">
          Discard
        </Button>
        <Button
          onClick={goSave}
          color="primary"
          autoFocus
          style={{ fontWeight: 600, marginRight: "8px" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimerModal;
