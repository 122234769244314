import React from "react";
import TabPanel from "../../../components/TabPanel";
import DisplayExpenseEntry from "../../../components/DisplayExpenseEntry";

export const ExpenseTabPanel = ({ selectedCaseSid, ...others }) => {
  return (
    <TabPanel className="ExpenseTabPanel" {...others}>
      <DisplayExpenseEntry
        selectedCaseSid={selectedCaseSid}
        hideCaseCol
        tabIndex={others.tabIndex}
      />
    </TabPanel>
  );
};
