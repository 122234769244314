import { makeStyles } from "@material-ui/core";

export const useQuickEntryTableStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: theme.palette.action.hover,
    "&>tr": {
      verticalAlign: "top",
    },
    "&>tr>th.MuiTableCell-head": {
      color: theme.palette.primary.main,
    },
  },
  tableBody: {
    "&>tr": {
      verticalAlign: "top",
    },
    "&>tr>td.MuiTableCell-body": {
      color: theme.palette.text.secondary,
    },
    "&>tr>td.MuiTableCell-body.noWrap": {
      whiteSpace: "nowrap",
    },
    "&>tr:last-child>td": {
      borderBottomColor: "transparent",
    },
  },
  tableBodyMiddle: {
    "&>tr>td.MuiTableCell-body": {
      color: theme.palette.text.secondary,
    },
    "&>tr:last-child>td": {
      borderBottomColor: "transparent",
    },
  },
  number: {
    letterSpacing: "1px",
    whiteSpace: "nowrap",
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
}));
