import React from "react";
import { Typography } from "@material-ui/core";
import { BrokenImageRounded } from "@material-ui/icons";

const NotFoundPage = () => {
  return (
    <div className="NotFoundPage d-flex flex-col align-items-center">
      <Typography
        variant="h3"
        style={{ marginTop: "20vh", marginBottom: "8px", opacity: 0.6 }}
      >
        <BrokenImageRounded color="primary" fontSize="inherit" />
      </Typography>
      <Typography variant="h4" color="textSecondary">
        404
      </Typography>
      <Typography variant="h6" color="textSecondary">
        NOT FOUND
      </Typography>
    </div>
  );
};

export default NotFoundPage;
